import {
  ApiError,
  ApiErrorInitialState,
  Match,
  MatchInitialState,
} from "@hellodarwin/core/lib/features/entities";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import AdminApiClient from "../admin-api-client";

export const fetchDirectoryMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; id: string },
  { rejectValue: ApiError }
>(
  "admin/matchmaking/directory",
  async (
    { api, id }: { api: AdminApiClient; id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getDirectoryMatches(id);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export interface MatchesState {
  status: "idle" | "pending";
  error: ApiError;
  selectedMatch: Match;
  directoryMatches: Match[];
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: MatchesState = {
  status: "idle",
  error: ApiErrorInitialState,
  selectedMatch: MatchInitialState,
  directoryMatches: [],
  modal: {
    isVisible: false,
    type: "",
  },
};

export const setSelectedMatch = createAction<Match>("admin/setSelectedMatch");
export const toggleMatchesModal = createAction<{
  isVisible: boolean;
  type: string;
}>("admin/toggleMatchesModal");

const matchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedMatch, (state, { payload }) => {
      state.selectedMatch = payload;
    });
    builder.addCase(toggleMatchesModal, (state, { payload }) => {
      state.modal = payload;
    });

    builder.addCase(fetchDirectoryMatches.fulfilled, (state, { payload }) => {
      state.directoryMatches = payload ? payload : [];

      state.status = "idle";
    });
    builder.addCase(fetchDirectoryMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchDirectoryMatches.pending, (state) => {
      state.status = "pending";
    });
  },
});

export const selectMatchesModal = (state: RootState) => state.matches.modal;
export const selectSelectedMatch = (state: RootState) =>
  state.matches.selectedMatch;

export const selectMatchesLoading = (state: RootState) =>
  state.matches.status !== "idle";

export const selectDirectoryMatches = (state: RootState) =>
  state.matches.directoryMatches;

export const matchesReducer = matchesSlice.reducer;
