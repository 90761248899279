import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";

const FormCurrentPageEndFooter = ({
  isEndPage,
  isOpening,
  isContact,
  currentPageIndex,
  lastPageIndex,
  firstPageIndex,
  handlePrevious,
  handleNext,
}: {
  isEndPage: boolean;
  isOpening: boolean;
  isContact: boolean;
  currentPageIndex: number;
  lastPageIndex: number;
  firstPageIndex: number;
  handlePrevious: () => void;
  handleNext: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslations();

  if (isEndPage) return <></>;

  return (
    <Div
      flex="row"
      align="center"
      justify={isOpening ? "center" : "flex-end"}
      gap={16}
      style={{ width: "100%", marginBottom: isOpening ? 54 : 0 }}
      tablet={{ flex: "column-reverse", gap: 8 }}
    >
      {!isOpening && currentPageIndex > firstPageIndex && (
        <Button
          onClick={handlePrevious}
          defaultStyle={theme.colors.white_1}
          size="small"
          style={{ transition: "background-color 200ms linear" }}
        >
          {t("button|previous")}
        </Button>
      )}
      {(currentPageIndex < lastPageIndex || isContact) && (
        <Button
          onClick={handleNext}
          defaultStyle={isOpening ? theme.colors.yellow_1 : theme.colors.grey_1}
          size={isOpening ? "medium" : "small"}
          style={{ transition: "background-color 200ms linear" }}
          withArrowRight={isOpening}
        >
          {isContact
            ? t("button|submit")
            : isOpening
              ? t("button|startNow")
              : t("button|next")}
        </Button>
      )}
    </Div>
  );
};

export default FormCurrentPageEndFooter;

