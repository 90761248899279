import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import Input from "antd/es/input";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchGrantSearchPrompt,
  selectAllGrantSearchPrompts,
  selectIsLoading,
  updatePrompt,
} from "../../features/api/slices/chatgpt-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

const { TextArea } = Input;

interface EditPromptDrawerProps {
  visible: boolean;
  onClose: () => void;
  promptName: string;
}

const EditPromptDrawer = ({
  visible,
  onClose,
  promptName,
}: EditPromptDrawerProps) => {
  const prompts = useAppSelector(selectAllGrantSearchPrompts);
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const [promptContent, setPromptContent] = useState("");

  useEffect(() => {
    if (promptName) {
      dispatch(fetchGrantSearchPrompt({ api, name: promptName }));
    }
  }, [promptName, dispatch, api]);

  useEffect(() => {
    if (prompts?.length > 0) {
      setPromptContent(prompts[0].content);
    }
  }, [prompts]);

  const savePrompt = async () => {
    if (prompts?.length > 0) {
      const updatedPrompt = {
        ...prompts[0],
        content: promptContent,
        content_en: promptContent,
        content_fr: promptContent,
        created_at: prompts[0].created_at || "",
        updated_at: prompts[0].updated_at || "",
      };
      const saved = await dispatch(
        updatePrompt({ api, prompt: updatedPrompt })
      ).unwrap();
      if (saved) {
        onClose();
      }
    }
  };

  const promptTitle =
    prompts.length > 0
      ? prompts[0].name
          .replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase())
      : "";

  const handlePromptContentChange = useCallback((e: any) => {
    setPromptContent(e.target.value);
  }, []);

  return (
    <Drawer
      title={`Edit '${promptTitle}' Prompt`}
      size="large"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      extra={
        <Button loading={isLoading} onClick={savePrompt}>
          Save
        </Button>
      }
    >
      <TextArea
        rows={25}
        value={promptContent}
        onChange={handlePromptContentChange}
      />
    </Drawer>
  );
};

export default EditPromptDrawer;

