import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag, {
  TagProps,
} from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Tooltip from "antd/es/tooltip";
import React from "react";
import { Link } from "react-router-dom";
import CardInfoRow, { CardInfoRowProps } from "./card-info-row";
import { CardLayoutContainer } from "./styles";

interface CardLayoutProps {
  title: string;
  subtitle?: string;
  infos: CardInfoRowProps[];
  tag?: TagProps;
  to?: string;
  onClick?: (e: any) => void;
}

const CardLayout = ({
  title,
  subtitle,
  infos,
  tag,
  to,
  onClick,
}: CardLayoutProps) => {
  const theme = useTheme();

  const content = (
    <CardLayoutContainer onClick={onClick}>
      {!!tag && <HdTag {...tag} size="small" />}
      <Div flex="column" gap={2}>
        <Tooltip title={title}>
          <div>
            <Typography elementTheme="body2" ellipsis overflow>
              {title}
            </Typography>
          </div>
        </Tooltip>
        {!!subtitle && (
          <Typography
            elementTheme="body3"
            color={theme.colors.grey_2}
            ellipsis
            overflow
          >
            {subtitle}
          </Typography>
        )}
      </Div>
      <Div flex="column" gap={6}>
        {React.Children.toArray(infos.map((info) => <CardInfoRow {...info} />))}
      </Div>
    </CardLayoutContainer>
  );

  return to ? (
    <Link to={to} style={{ color: "inherit" }}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default CardLayout;

