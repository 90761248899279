import { useMemo } from "react";
import { EditableFormSubmissionResponse } from "../../../../../features/entities/form-entities";
import { MappedSubmissionItems } from "../../types";

const getMappedSubmissionItems = ({
  formSubmission,
}: {
  formSubmission: EditableFormSubmissionResponse;
}): MappedSubmissionItems => {
  const { all_items } = formSubmission;
  return useMemo(
    () =>
      all_items
        .filter((item) => !!item.forms_submissions_item)
        .reduce(
          (o, item) => ({ ...o, [item.form_item.form_item_id]: item }),
          {}
        ),
    [all_items]
  );
};
export default getMappedSubmissionItems;

