import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
} from "@hellodarwin/core/lib/features/entities";

import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export interface BillingState {
  status: "idle" | "pending";
  error: ApiError;
  creditBalance: number;
}

const initialState: BillingState = {
  status: "idle",
  error: ApiErrorInitialState,
  creditBalance: 0,
};

export const getCreditBalance = createAsyncThunk<
  number,
  { api: AdminApiClient; billingId: string },
  { rejectValue: ApiError }
>(
  "admin/getCreditBalance",
  async (
    { api, billingId }: { api: AdminApiClient; billingId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getCreditBalance(billingId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreditBalance.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getCreditBalance.fulfilled, (state, { payload }) => {
      state.creditBalance = payload;
      state.status = "idle";
    });
    builder.addCase(getCreditBalance.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectBillingIsLoading = (state: RootState) =>
  state.billing.status === "pending";
export const selectCreditBalance = (state: RootState) =>
  state.billing.creditBalance;

export const billingReducer = billingSlice.reducer;
