enum SingleGrantSections {
  Summary = "summary",
  Preview = "preview",
  Admissibility = "admissibility",
  Criteria = "criteria",
  Register = "register",
  OtherInfo = "otherInfo",
  Documents = "documents",
  Contact = "contact",
  Projects = "projects",
}

export default SingleGrantSections;

