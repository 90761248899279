import Progress from "antd/es/progress/progress";
import React from "react";
import { InfoRowProps, PreviewProps } from ".";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../button";
import Div from "../../div";
import HdTag from "../../hd-tag";
import Typography from "../../typography";
import { PreviewContainer } from "./styles";

const PreviewTile = ({
  percentage,
  title,
  tag,
  infos,
  ctaText,
  onPreviewClick,
  style,
}: PreviewProps & { onPreviewClick: () => void }) => {
  const theme = useTheme();
  const InfoRow = ({ Icon, label, value }: InfoRowProps) => {
    return (
      <Div
        flex="row"
        gap={7}
        align="center"
        style={{ color: theme.colors.grey_3 }}
      >
        <Icon width={11} height={11} />
        <span>
          <Typography elementTheme="overline" inline>
            {label}
          </Typography>{" "}
          <Typography elementTheme="overline" bold inline>
            {value}
          </Typography>
        </span>
      </Div>
    );
  };
  return (
    <PreviewContainer
      style={{
        ...style,
        width: 300,
        height: 238,
        padding: "16px 23px",
        borderRadius: 2,
      }}
      onClick={onPreviewClick}
    >
      <Div
        flex="column"
        gap={16}
        align="center"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Div flex="column" gap={16}>
          <HdTag color={tag.color} text={tag.text} />
          <Typography.Title
            level={3}
            elementTheme="subtitle2"
            overflow
            noMargin
            nowrap
            ellipsis
          >
            {title}
          </Typography.Title>
          <Div>
            {!!infos?.length ? (
              React.Children.toArray(
                infos.map((info) =>
                  !info.hide ? (
                    <InfoRow {...info} />
                  ) : (
                    <div style={{ height: 22 }} />
                  )
                )
              )
            ) : (
              <div style={{ height: 22 }} />
            )}
            {typeof percentage === "number" && (
              <Div flex="column" align="flex-end">
                <Typography
                  elementTheme="overline"
                  bold
                  color={theme.colors.grey_3}
                >
                  {percentage}%
                </Typography>
                <Progress
                  percent={percentage}
                  showInfo={false}
                  strokeColor={theme.colors.green_high_contrast}
                  trailColor={theme.colors.green_4}
                  style={{ alignSelf: "center", marginInlineEnd: "0" }}
                />
              </Div>
            )}
          </Div>
        </Div>
        <Button
          size="small"
          style={{ width: "100%" }}
          defaultStyle={theme.colors.primary}
          onClick={onPreviewClick}
        >
          {ctaText}
        </Button>
      </Div>
    </PreviewContainer>
  );
};

export default PreviewTile;

