import styled, { css } from "../../../../plugins/styled";

export const CardStyling = css`
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}px) {
    width: 100% !important;
  }
`;
export const RowStyling = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 44px;
  & > * {
    flex: 2 1 0%;
    &:last-child {
      flex: 1 1 0%;
    }
  }
`;

interface PreviewContainerProps {
  $isRow?: boolean;
}

export const PreviewLabelsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 44px;
`;

export const PreviewContainer = styled.div<PreviewContainerProps>`
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white_1};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  ${({ $isRow }) => (!!$isRow ? RowStyling : CardStyling)}
  box-shadow: 0px 4px 25px 4px rgba(43, 43, 43, 0.03);
  &:hover {
    box-shadow: 0px 4px 25px 4px rgba(43, 43, 43, 0.06);
    transform: scale(1.01);
  }
`;

