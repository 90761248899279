import { Match, Project } from "@hellodarwin/core/lib/features/entities";
import Card from "antd/es/card";
import { Col, Row } from "antd/es/grid";
import { useAppSelector } from "../../app/app-hooks";
import { selectProviderPurchasedMatches } from "../../features/api/slices/providers-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import MatchPreview from "../matches/match-preview";
type MatchesBlockProps = {
  matches: Match[];
  targetId: string;
  forProvider?: boolean;
  project?: Project;
};

const MatchesBlock = ({
  matches,
  targetId,
  forProvider,
  project,
}: MatchesBlockProps) => {
  const api = useAdminApi();

  const purchasedProvider = useAppSelector(selectProviderPurchasedMatches);
  matches = forProvider ? purchasedProvider : matches;

  const updateWinner = (matchId: string, isWinner: boolean) => {
    (async () => {
      try {
        await api.updateWinner(matchId, isWinner);
      } catch (e: any) {
        console.error(e);
      }
    })();
  };

  return (
    <div>
      <Row gutter={16}>
        {matches.length >= 1 ? (
          matches.map((m, i) => (
            <Col span={24} key={i}>
              <MatchPreview
                key={m.match_id}
                match={m}
                updateWinner={updateWinner}
                isProvider={forProvider}
                project={!!project ? project : m.project}
              />
            </Col>
          ))
        ) : (
          <Card
            style={{ width: "100%", paddingLeft: "1rem" }}
            title="No one bought this project yet"
          />
        )}
      </Row>
    </div>
  );
};

export default MatchesBlock;

