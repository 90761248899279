import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Desktop = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2593 24.2963V25.8876C11.2593 26.5104 11.1367 27.1271 10.8984 27.7024C10.66 28.2778 10.3107 28.8006 9.87022 29.2409L8.88889 30.2222H23.1111L22.1298 29.2409C21.6893 28.8006 21.34 28.2778 21.1016 27.7024C20.8633 27.1271 20.7407 26.5104 20.7407 25.8876V24.2963M30.2222 5.33333V20.7407C30.2222 21.6837 29.8476 22.5881 29.1808 23.2549C28.514 23.9217 27.6097 24.2963 26.6667 24.2963H5.33333C4.39034 24.2963 3.48597 23.9217 2.81918 23.2549C2.15238 22.5881 1.77778 21.6837 1.77778 20.7407V5.33333M30.2222 5.33333C30.2222 4.39033 29.8476 3.48597 29.1808 2.81917C28.514 2.15237 27.6097 1.77777 26.6667 1.77777H5.33333C4.39034 1.77777 3.48597 2.15237 2.81918 2.81917C2.15238 3.48597 1.77778 4.39033 1.77778 5.33333M30.2222 5.33333V16C30.2222 16.943 29.8476 17.8474 29.1808 18.5142C28.514 19.1809 27.6097 19.5556 26.6667 19.5556H5.33333C4.39034 19.5556 3.48597 19.1809 2.81918 18.5142C2.15238 17.8474 1.77778 16.943 1.77778 16V5.33333"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Desktop;

