import { WebsiteGrantResult } from "../../../../../features/entities/grants-entities";
import List from "../../../../common/List";
import DocumentTile from "./DocumentTile";

const GrantDocuments = ({
  official_documents,
  grant_display_title,
}: WebsiteGrantResult) => {
  return (
    <>
      <List
        divider
        styles={{ content: { gap: 0 } }}
        dataSource={[official_documents]}
        renderItem={(link) => (
          <List.Item>
            <DocumentTile type="link" label={grant_display_title} link={link} />
          </List.Item>
        )}
      />
    </>
  );
};

export default GrantDocuments;
