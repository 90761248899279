import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { useAppSelector } from "../../app/app-hooks";
import CreateFormBuilder from "../../components/forms/single-form/create-form-builder";
import { selectFormIsLoading } from "../../features/api/slices/forms-slice";

const CreateFormPage = () => {
  const isLoading = useAppSelector(selectFormIsLoading);

  return (
    <PageLayout
      noPadding
      app="admin"
      tabTitle="Create a Form"
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Forms",
          path: "/forms",
        },
        {
          breadcrumbName: "Create a Form",
        },
      ]}
      isLoading={isLoading}
    >
      <CreateFormBuilder />
    </PageLayout>
  );
};

export default CreateFormPage;

