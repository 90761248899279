import Warning from "@hellodarwin/icons/dist/icons/Warning";
import { CSSProperties, PropsWithChildren } from "react";
import { useTranslations } from "../../../../features/providers/translations-provider";
import theme from "../../../../theme";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import {
  GrantSectionContainer,
  GrantSectionContent,
  GrantSectionHeaderContent,
} from "./styles";

export interface GrantSectionProps extends PropsWithChildren {
  title: string;
  id: string;
  styles?: {
    body?: CSSProperties;
    header?: CSSProperties;
  };
  applyButton: boolean;
  alreadyApplied: boolean;
  onClick?: () => void;
}

const GrantSectionHeader = ({
  title,
  styles,
  onClick,
  applyButton,
  alreadyApplied,
}: GrantSectionProps) => {
  const { t } = useTranslations();
  return (
    <GrantSectionHeaderContent style={{ ...styles?.header }}>
      <Typography.Title noMargin level={2} elementTheme="h4">
        {title}
      </Typography.Title>
      {applyButton && !!onClick && (
        <div style={{ position: "relative" }}>
          <Button size="small" onClick={onClick}>
            {t("grant_single|applyNow")}
          </Button>
          {alreadyApplied ? (
            <Div
              flex="row"
              gap={4}
              align="center"
              style={{
                position: "absolute",
                width: "fit-content",
                top: "calc(100% + 4px)",
                right: 0,
              }}
            >
              <Warning width={14} height={14} color={theme.colors.grey_2} />
              <Typography
                elementTheme="overline"
                color={theme.colors.grey_2}
                nowrap
              >
                {t("grant_single|alreadyApplied")}
              </Typography>
            </Div>
          ) : undefined}
        </div>
      )}
    </GrantSectionHeaderContent>
  );
};

const GrantSection = (props: GrantSectionProps) => {
  const { id, children, styles } = props;
  return (
    <GrantSectionContainer style={{ width: "100%" }} id={id}>
      <GrantSectionHeader {...props} />
      <GrantSectionContent style={{ ...styles?.body }}>
        {children}
      </GrantSectionContent>
    </GrantSectionContainer>
  );
};

export default GrantSection;

