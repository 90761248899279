import Sider from "antd/es/layout/Sider";
import styled from "styled-components";

export const SidebarBlockContent = styled.div`
  padding: 20px 32px;
`;
export const SidebarBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.white_1};
  width: 100%;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    z-index: 2;
    pointer-events: none;

    top: 100%;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.white_1},
      rgba(0, 0, 0, 0)
    );
  }
`;
export const SidebarBlockContainer = styled.div`
  flex: 1;
  max-height: calc(
    100vh - ${({ theme }) => theme.appearance.appHeaderHeight}px
  );

  overflow: hidden auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.purple_1}EE;
    transition: background 400ms ease;
    border-radius: 99px;

    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.purple_2};
  }
`;

export const SidebarPageSiderContainer = styled(Sider)<{
  $position: "left" | "right";
}>`
 
  .ant-layout-sider-children {
    ${({ $position, theme }) =>
      $position === "left"
        ? `border-right: 1px solid ${theme.colors.grey_5};`
        : `border-left: 1px solid ${theme.colors.grey_5};`}
    overflow: hidden;
    max-height: calc(
      100vh - ${({ theme }) => theme.appearance.appHeaderHeight}px
    );
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white_1};
     box-shadow:   ${({ $position }) =>
       $position === "left"
         ? `2px 0px 4px 0px rgba(0, 0, 0, 0.05);`
         : `-2px 0px 4px 0px rgba(0, 0, 0, 0.05);`}
   
    position: sticky;
    top: ${({ theme }) => theme.appearance.appHeaderHeight}px;
  }
  .ant-layout-sider-trigger {
    position: absolute;
    top: 0;
    z-index: 1100;
    background-color: transparent !important;
    color: white;
    width: fit-content !important;
    height: 100%;
    cursor: pointer;
    ${({ $position }) =>
      $position === "right" ? "left: -12px;" : "right: -12px;"}
  }
`;

export const SidebarPageContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${SidebarPageSiderContainer} {
    background: transparent;
    flex-shrink: 0;
    padding-bottom: 0;
  }
`;

export const SidebarPageBody = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;
`;

