import coreTheme from "@hellodarwin/core/lib/theme";
const theme = {
  ...coreTheme,

  appearance: {
    ...coreTheme.appearance,
    pageHeaderHeight: 56,
    appHeaderHeight: 74,
  },
};

export default theme;

