import styled from "../../../plugins/styled";

export const StepsManagerContainer = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 20px;
  /* width */
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.purple_1}EE;
    transition: background 400ms ease;
    border-radius: 99px;

    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.purple_2};
  }
`;
export const StepsManagerContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: stretch;
  height: 100%;
  a {
    text-decoration: none;
  }
`;

export const StepsManagerColContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 332px;
  flex-grow: 0;
  flex-shrink: 0;

  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.beige_1};
  padding-bottom: 52px;
  position: relative;
  height: auto;
`;
export const StepsManagerColContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: visible;
  overflow-y: auto;
  height: fit-content;
  padding: 12px;
  padding-bottom: 12px;
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.white_2};
    transition: background 400ms ease;
    border-radius: 99px;

    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.purple_2};
  }
`;
export const StepsManagerColHeader = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.colors.beige_3};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
`;
export const StepsManagerColFooter = styled.div`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  border-bottom: none;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  background: ${({ theme }) => theme.colors.beige_3};
  border-radius: 24px 24px 0 0;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
`;

export const StepsManagerTile = styled.div<{ $hoverable?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 250px;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  flex-shrink: 0;
  ${({ $hoverable, theme }) =>
    $hoverable
      ? `
  &:hover {
    cursor:pointer;
    box-shadow: ${theme.appearance.box_shadow_hover};
    transform:scale(1.01);
  }
  `
      : undefined}
  border-radius: 4px;
  padding: 24px;
`;

