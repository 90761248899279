import { useLocation } from "react-router-dom";
import { GrantResult } from "../../../features/entities/grants-entities";
import GrantCardBody from "./grant-card-body";
import GrantCardHeader from "./grant-card-header";
import { GrantCardContainer, GrantCardLink } from "./styles";

export interface GrantCardProps extends GrantResult {
  segments?: string[];
  isSmall?: boolean;
  isAdmin?: boolean;
  featuredTags?: string[];
  search_score?: string;
  ginList?: boolean;
}

const GrantCard = (props: GrantCardProps) => {
  const location = useLocation();
  const { grant_id, ginList } = props;
  return (
    <GrantCardLink
      to={
        location.pathname.startsWith("/gin")
          ? `/gin/${grant_id}`
          : `/funding-explorer/${grant_id}`
      }
    >
      <GrantCardContainer>
        <GrantCardHeader {...props} />
        {!ginList && <GrantCardBody {...props} />}
      </GrantCardContainer>
    </GrantCardLink>
  );
};

export default GrantCard;

