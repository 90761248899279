import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Efficiency = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2857 16.1913C26.2857 14.483 26.2226 11.7986 26.0964 10.1221C25.9994 8.79391 25.4336 7.54514 24.5022 6.60339C23.5708 5.66163 22.3358 5.08957 21.0221 4.99146C17.6788 4.73642 14.3212 4.73642 10.9778 4.99146C9.66418 5.08957 8.42913 5.66163 7.49773 6.60339C6.56632 7.54514 6.00055 8.79391 5.90352 10.1221C5.8802 10.4272 5.85963 10.8936 5.84043 11.2M26.2857 16.1913L30.4 12.0313M26.2857 16.1913L22.1714 12.0313M5.71426 16.1913C5.71426 17.8997 5.77735 20.5842 5.90352 22.2607C6.00055 23.5889 6.56632 24.8377 7.49773 25.7795C8.42913 26.7212 9.66418 27.2933 10.9778 27.3914C14.3212 27.6465 17.6788 27.6465 21.0221 27.3914C22.3358 27.2933 23.5708 26.7212 24.5022 25.7795C25.4336 24.8377 25.9994 23.5889 26.0964 22.2607C26.1197 21.9556 26.1403 21.9065 26.1595 21.6M5.71426 16.1913L9.82855 20.3514M5.71426 16.1913L1.59998 20.3514"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Efficiency;

