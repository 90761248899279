import styled, { css } from "../plugins/styled";

export const li = css`
  padding-inline: 4px 0;
  margin-inline: 24px 0;
  margin-block: 4px 0;
`;
export const ul = css`
  list-style-position: outside;
  margin-block: 0;
  padding: 0;
  * {
    vertical-align: top;
  }
`;
export const ol = css`
  list-style-position: outside;
  margin-block: 0;
  padding: 0;
  * {
    vertical-align: top;
  }
`;

export const CustomUl = styled.ul`
  ${ul};
  li {
    ${li};
  }
`;

export const CustomOl = styled.ol`
  ${ol};
  li {
    ${li};
  }
`;

