import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { HdChat } from "@hellodarwin/core/lib/features/entities";
import {
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Chat from "@hellodarwin/icons/dist/icons/Chat";
import Layout from "antd/es/layout";
import List from "antd/es/list";
import Skeleton from "antd/es/skeleton";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  createNewChat,
  fetchAllChats,
  selectAllChats,
  selectChatsLoading,
  selectSelectedChatType,
} from "../../features/api/slices/hd-chats-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import { ChatsHistorySider } from "./chats-history";

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 20;

const ChatsTab = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedLocale } = useLocale();
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const loading = useAppSelector(selectChatsLoading);
  const chats = useAppSelector((state) => selectAllChats(state));
  const [hasMore, setHasMore] = useState(true);
  const headerHeight = 310 + theme.appearance.pageHeaderHeight;
  const chatType = useAppSelector(selectSelectedChatType);

  useEffect(() => {
    dispatch(
      fetchAllChats({
        api,
        page: DEFAULT_PAGE,
        size: DEFAULT_SIZE,
        type: chatType,
      })
    )
      .unwrap()
      .then((chatsFetched) => {
        if (!chatsFetched || chatsFetched?.length < DEFAULT_SIZE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType]);

  const loadMoreData = () => {
    if (loading || !hasMore) {
      return;
    }

    const nextPage = Math.floor(chats?.length / DEFAULT_SIZE) + 1;
    dispatch(
      fetchAllChats({ api, page: nextPage, size: DEFAULT_SIZE, type: chatType })
    )
      .unwrap()
      .then((chatsFetched) => {
        if (!chatsFetched || chatsFetched.length < DEFAULT_SIZE) {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching more chats:", error);
      });
  };

  const createChat = async () => {
    try {
      const newChat = await dispatch(
        createNewChat({
          api: api,
          chat: {
            company_id: "",
            company_name: "",
            template_id: "",
            grant_name: "",
            locale: selectedLocale,
          },
        })
      ).unwrap();

      if (newChat) {
        navigate("/chats/" + newChat.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout hasSider={true}>
      <Div
        flex="column"
        justify="end"
        style={{
          width: "100%",
          backgroundColor: theme.colors.white_2,
        }}
      >
        <Div
          flex="column"
          align="center"
          justify="center"
          gap={24}
          style={{ zIndex: 1000 }}
        >
          <Button
            onClick={createChat}
            size="large"
            headingIcon={<Chat />}
            defaultStyle={theme.colors.purple_1}
          >
            <Typography>Start New Chat</Typography>
          </Button>
          <Div flex="row" justify="center" gap={16}>
            {/* Add more buttons if needed */}
          </Div>
        </Div>
      </Div>
      <ChatsHistorySider
        collapsible
        collapsed={siderCollapsed}
        onCollapse={setSiderCollapsed}
        trigger={null}
        width={"35%"}
        style={{ backgroundColor: theme.colors.white_2, padding: 16 }}
      >
        <Typography.Title level={4} elementTheme="h6" noMargin>
          Chats
        </Typography.Title>
        <Div
          id="scrollableDiv"
          style={{
            height: `calc(100vh - ${headerHeight}px)`,
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={chats?.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={
              chats?.length > 0 && <Skeleton paragraph={{ rows: 1 }} active />
            }
            scrollableTarget="scrollableDiv"
          >
            {chats?.length > 0 ? (
              <List
                dataSource={chats}
                renderItem={(chat: HdChat) => (
                  <List.Item
                    key={chat.id}
                    style={{ padding: 16, width: "100%" }}
                  >
                    <List.Item.Meta
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "right",
                      }}
                      title={
                        <Typography
                          color={theme.colors.black}
                          elementTheme="body1"
                          medium
                        >
                          <Link target="_blank" to={`/chats/${chat.id}`}>
                            {chat.title}
                          </Link>
                        </Typography>
                      }
                      description={
                        <Div flex="row" justify="space-between">
                          <Typography elementTheme="body3" medium>
                            ID: {getShortId(chat.id)}
                          </Typography>
                          <Typography elementTheme="body3" medium>
                            Last Updated:{" "}
                            {getShortFormattedDate(chat.updated_at)}
                          </Typography>
                        </Div>
                      }
                    />
                  </List.Item>
                )}
              />
            ) : (
              <></>
            )}
          </InfiniteScroll>
        </Div>
      </ChatsHistorySider>
    </Layout>
  );
};

export default ChatsTab;

