import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Notifications = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2079 24.0343C24.1527 23.6856 27.046 22.9907 29.8279 21.9639C27.472 19.3541 26.1702 15.9619 26.1754 12.4461V11.3398V11.2607C26.1754 8.7457 25.1763 6.33367 23.3979 4.55527C21.6195 2.77687 19.2075 1.77777 16.6924 1.77777C14.1774 1.77777 11.7654 2.77687 9.98696 4.55527C8.20856 6.33367 7.20946 8.7457 7.20946 11.2607V12.4461C7.21423 15.9621 5.91186 19.3544 3.55536 21.9639C6.29436 22.9754 9.18192 23.6787 12.177 24.0343M21.2079 24.0343C18.2081 24.3901 15.1768 24.3901 12.177 24.0343M21.2079 24.0343C21.4357 24.7453 21.4923 25.5 21.3732 26.2371C21.2541 26.9741 20.9627 27.6726 20.5226 28.2757C20.0825 28.8788 19.5062 29.3694 18.8406 29.7077C18.1751 30.0459 17.439 30.2222 16.6924 30.2222C15.9458 30.2222 15.2098 30.0459 14.5442 29.7077C13.8787 29.3694 13.3024 28.8788 12.8623 28.2757C12.4222 27.6726 12.1308 26.9741 12.0117 26.2371C11.8926 25.5 11.9492 24.7453 12.177 24.0343"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Notifications;

