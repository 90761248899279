import { DefaultTheme } from "../../../../plugins/styled";

const getFormTypeColor = (status: string, theme: DefaultTheme): string => {
  const colors: { [index: string]: string } = {
    qualification: theme.colors.blue_1,
    application: theme.colors.green_1,
  };

  return colors[status];
};

export default getFormTypeColor;

