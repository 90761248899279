import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  FormContentStyled,
  FormListStyled,
} from "@hellodarwin/core/lib/components/forms/form-builder/styles";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import { Forms, GrantPreview } from "@hellodarwin/core/lib/features/entities";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/app-hooks";
import { createForm } from "../../../features/api/slices/forms-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { AdminPagesForms } from "../../../pages/single-project-page";
import GrantsSearch from "../../grants/grants-search";
import GrantsSearchSelectedList from "../../grants/grants-search-selected-list";
import FormBuilderHeader from "./form-builder-header";
export type CreateFormValues = {
  title_en: string;
  title_fr: string;
  subtitle_en: string;
  subtitle_fr: string;
  grants: any[];
  form_type: string;
};

const CreateFormBuilder = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<CreateFormValues>();
  const [selectedGrants, setSelectedGrants] = useState<GrantPreview[]>([]);
  const theme = useTheme();
  const [forms, setForms] = useState<AdminPagesForms>({
    createForm: undefined,
  });

  const initialFormValues: CreateFormValues = {
    title_en: "",
    title_fr: "",
    subtitle_en: "",
    subtitle_fr: "",
    grants: [],
    form_type: "qualification",
  };
  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      createForm: form,
    }));
  }, [form, setForms]);

  const handleDeleteGrant = (grant: GrantPreview) => {
    setSelectedGrants((prev) => {
      const newGrants = prev.filter(
        (grantVal) => grantVal.grant_id !== grant.grant_id
      );
      form.setFieldValue(
        "grants",
        newGrants.map((g) => g.grant_id)
      );
      form.validateFields();
      return newGrants;
    });
  };

  const onFinish = async () => {
    try {
      await form.validateFields().then(async (createFormValues) => {
        if (createFormValues === undefined) {
          return;
        }

        const form: Forms = {
          form_id: "",
          ...createFormValues,
        };
        const newForm = await dispatch(
          createForm({ api, adminForm: form })
        ).unwrap();

        if (newForm) {
          message.success("Form created with success !");
          forms.grantForm?.resetFields();
          navigate("/forms/" + newForm.form_id);
        }
      });
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  return (
    <div>
      <FormBuilderHeader />
      <Div
        flex="row"
        align="stretch"
        justify="center"
        style={{ height: "calc(100vh - 58px - 76px)" }}
      >
        <FormListStyled style={{ padding: "40px 32px" }}>
          <Typography.Title level={2} elementTheme="h6">
            Selected Grants:{" "}
          </Typography.Title>

          <GrantsSearchSelectedList
            handleDeleteGrant={handleDeleteGrant}
            grants={selectedGrants}
          />
        </FormListStyled>
        <FormContentStyled style={{ padding: "40px 32px" }}>
          <Div
            flex="column"
            gap={32}
            style={{ maxWidth: 800, position: "relative", margin: "0 auto" }}
          >
            <Typography.Title level={1} elementTheme="h5" textAlign="center">
              Create a form
            </Typography.Title>
            <Form
              initialValues={initialFormValues}
              form={form}
              layout="vertical"
              name="form"
              onFinish={onFinish}
            >
              <Div flex="column" gap={32}>
                <Div flex="row" gap={54}>
                  <FormItem
                    label={<FormLabel label="Type" />}
                    name="form_type"
                    style={{ flex: 1 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a type",
                      },
                    ]}
                  >
                    <Select placeholder="Select option" size="large">
                      <Select.Option value="application">
                        Application
                      </Select.Option>
                      <Select.Option value="qualification">
                        Qualification
                      </Select.Option>
                    </Select>
                  </FormItem>
                </Div>
                <Div flex="row" gap={54}>
                  <Div flex="column" gap={32} style={{ flex: 1 }}>
                    <FormItem
                      label={<FormLabel label="Title EN" />}
                      name="title_en"
                      rules={[
                        {
                          required: true,
                          message: "Please specify a title",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Form title EN"
                        variant="borderless"
                        style={{
                          fontSize: 24,
                          borderBottom: `2px solid ${theme.colors.grey_4}`,
                          borderRadius: 0,
                          width: "100%",
                        }}
                        onFocus={(e) => {
                          e.target.style.borderColor = theme.colors.purple_1;
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = theme.colors.grey_4;
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Subtitle En"
                      name="subtitle_en"
                      rules={[
                        {
                          required: true,
                          message: "Please specify a subtitle",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Form subtitle EN"
                        variant="borderless"
                        style={{
                          fontSize: 16,
                          borderBottom: `1px solid ${theme.colors.grey_4}`,
                          borderRadius: 0,
                          width: "100%",
                        }}
                        onFocus={(e) => {
                          e.target.style.borderColor = theme.colors.purple_1;
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = theme.colors.grey_4;
                        }}
                      />
                    </FormItem>
                  </Div>
                  <Div flex="column" style={{ flex: 1 }} gap={32}>
                    <FormItem
                      label={<FormLabel label="Title FR" />}
                      name="title_fr"
                      rules={[
                        {
                          required: true,
                          message: "Please specify a title",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Form title FR"
                        variant="borderless"
                        style={{
                          fontSize: 24,
                          borderBottom: `2px solid ${theme.colors.grey_4}`,
                          borderRadius: 0,
                          width: "100%",
                        }}
                        onFocus={(e) => {
                          e.target.style.borderColor = theme.colors.purple_1;
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = theme.colors.grey_4;
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Subtitle Fr"
                      name="subtitle_fr"
                      rules={[
                        {
                          required: true,
                          message: "Please specify a subtitle",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Form subtitle FR"
                        variant="borderless"
                        style={{
                          fontSize: 16,
                          borderBottom: `1px solid ${theme.colors.grey_4}`,
                          borderRadius: 0,
                          width: "100%",
                        }}
                        onFocus={(e) => {
                          e.target.style.borderColor = theme.colors.purple_1;
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = theme.colors.grey_4;
                        }}
                      />
                    </FormItem>
                  </Div>
                </Div>
                <Div
                  flex="row"
                  justify="start"
                  align="center"
                  style={{
                    marginBottom: 30,
                    width: "100%",
                  }}
                >
                  <FormItem
                    name="grants"
                    label={<FormLabel label="Grants" />}
                    rules={[
                      {
                        required: true,
                        message: "Please select at least 1 grant",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <GrantsSearch
                      handleSubmit={(grant) => {
                        if (
                          selectedGrants.find(
                            (g) => g.grant_id === grant.grant_id
                          )
                        ) {
                          message.error("This grant is already selected");
                          return;
                        }

                        const newGrant: GrantPreview = {
                          grant_id: grant.grant_id,
                          display_title: grant.grant_display_title!,
                          verified: grant.verified!,
                        };

                        setSelectedGrants((prev) => {
                          const newGrants = [...prev, newGrant];
                          form.setFieldValue("grants", newGrants);
                          form.validateFields();
                          return newGrants;
                        });
                      }}
                    />
                  </FormItem>
                </Div>
                <Div flex="row" justify="center">
                  <Button defaultStyle={theme.colors.primary}>Create</Button>
                </Div>
              </Div>
            </Form>
          </Div>
        </FormContentStyled>
      </Div>
    </div>
  );
};

export default CreateFormBuilder;

