import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const PayPerClick = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6156 30.3385L18.5917 23.6174M18.5917 23.6174L14.454 26.9168L15.392 12.8742L24.0086 24.6139L18.5917 23.6174ZM15.8156 2.33847V5.67489M25.4329 5.92105L22.8102 8.28027M29.4156 14.572H25.7065M8.82112 20.8638L6.20002 23.2215M5.92473 14.572H2.21564M8.82112 8.28027L6.20002 5.92253"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PayPerClick;

