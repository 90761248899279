import {
  ApiError,
  ApiErrorInitialState,
  GrantProject,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApi from "../admin-api";

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantProjectState {
  status: "idle" | "pending";
  error: ApiError;
  grantProjects: EntityState<GrantProject, string>;
  bestGrantProjects: EntityState<GrantProject, string>;
  dirtyMap: Record<string, boolean>;
}

const initialState: GrantProjectState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantProjects: grantProjectsAdapter.getInitialState(),
  bestGrantProjects: grantProjectsAdapter.getInitialState(),
  dirtyMap: {},
};

export const createGrantProject = createAsyncThunk<
  GrantProject,
  { api: AdminApi; grantProject: GrantProject },
  { rejectValue: ApiError }
>(
  "admin/createGrantProject",
  async (
    { api, grantProject }: { api: AdminApi; grantProject: GrantProject },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post<GrantProject>(
        `/grant-projects`,
        grantProject
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantProject = createAsyncThunk<
  GrantProject,
  { api: AdminApi; grantProject: GrantProject },
  { rejectValue: ApiError }
>(
  "admin/updateGrantProject",
  async (
    { api, grantProject }: { api: AdminApi; grantProject: GrantProject },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put<GrantProject>(
        `/grant-projects`,
        grantProject
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApi; grantId: string; count: number },
  { rejectValue: ApiError }
>(
  "admin/generateGrantProjects",
  async (
    { api, grantId, count }: { api: AdminApi; grantId: string; count: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post<GrantProject[]>(
        `/grant-projects/grants/${grantId}/generate?count=${count}`
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGrantProject = createAsyncThunk<
  string,
  { api: AdminApi; grantProjectId: string; grantProjectLocale: string },
  { rejectValue: ApiError }
>(
  "admin/deleteGrantProject",
  async (
    {
      api,
      grantProjectId,
      grantProjectLocale,
    }: { api: AdminApi; grantProjectId: string; grantProjectLocale: string },
    { rejectWithValue }
  ) => {
    try {
      await api.delete<string>(
        `/grants/grant-projects/${grantProjectId}/${grantProjectLocale}`
      );
      return grantProjectId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAllGrantProjects = createAsyncThunk<
  string[],
  { api: AdminApi; grantProjectIds: string[] },
  { rejectValue: ApiError }
>(
  "admin/deleteGrantProjects",
  async (
    { api, grantProjectIds }: { api: AdminApi; grantProjectIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post<string[]>(
        `/grants/grant-projects/delete`,
        grantProjectIds
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApi; locale: string; grantIds: string[] },
  { rejectValue: ApiError }
>(
  "grantProjects/fetchGrantProjects",
  async ({ api, locale, grantIds }, { rejectWithValue }) => {
    try {
      const response = await api.get<GrantProject[]>(
        `/grant-projects/best?locale=${locale}`,
        {
          params: { grantIds },
        }
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantProjectsSlice = createSlice({
  name: "grantProjects",
  initialState,
  reducers: {
    setIsGrantProjectDirty: (
      state,
      action: PayloadAction<{ grantProjectId: string; locale: string }>
    ) => {
      const { grantProjectId, locale } = action.payload;
      state.dirtyMap[grantProjectId + locale] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(generateGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.upsertMany(state.grantProjects, payload);
      for (const grantProject of payload) {
        state.dirtyMap[grantProject.grant_project_id + grantProject.locale] =
          true;
      }
      state.status = "idle";
    });
    builder.addCase(generateGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(createGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.upsertOne(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(createGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(updateGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.updateOne(state.grantProjects, {
        id: payload.grant_project_id,
        changes: payload,
      });
      state.dirtyMap[payload.grant_project_id + payload.locale] = false;
      state.status = "idle";
    });
    builder.addCase(updateGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(deleteGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.removeMany(state.grantProjects, [payload]);
      state.dirtyMap[payload + "en"] = false;
      state.dirtyMap[payload + "fr"] = false;
      state.status = "idle";
    });
    builder.addCase(deleteGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteAllGrantProjects.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(deleteAllGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.removeMany(state.grantProjects, payload);
      state.dirtyMap = {};
      state.status = "idle";
    });
    builder.addCase(deleteAllGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.setAll(state.bestGrantProjects, payload ?? []);
      state.status = "idle";
    });
    builder.addCase(fetchGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllGrantProjects,
  selectById: selectGrantProjectById,
} = grantProjectsAdapter.getSelectors(
  (state: RootState) => state.grantProjects.grantProjects
);

export const { selectAll: selectBestGrantProjects } =
  grantProjectsAdapter.getSelectors(
    (state: RootState) => state.grantProjects.bestGrantProjects
  );
export const selectGrantProjectsLoading = (state: RootState) =>
  state.grantProjects.status === "pending";

export const selectError = (state: RootState) =>
  state.grantProjects.error.error_code;

export const selectIsLoading = (state: RootState) =>
  state.grantProjects.status === "pending";

export const selectIsAllDirty = (state: RootState) =>
  Object.values(state.grantProjects.dirtyMap).some((status) => status);

export const selectIsDirty = (state: RootState, id: string, locale: string) =>
  state.grantProjects.dirtyMap[id + locale] ?? false;

export const { setIsGrantProjectDirty } = grantProjectsSlice.actions;

export const grantProjectsReducer = grantProjectsSlice.reducer;

