import {
  ApiError,
  ApiErrorInitialState,
  Company,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const companiesAdapter = createEntityAdapter({
  selectId: (model: Company) => model.company_id,
});

export interface CompanyState {
  status: "idle" | "pending";
  companyCardStatus: "idle" | "pending";
  error: ApiError;
  companies: EntityState<Company, string>;
}

const initialState: CompanyState = {
  status: "idle",
  companyCardStatus: "idle",
  error: ApiErrorInitialState,
  companies: companiesAdapter.getInitialState(),
};

export const queryCompanies = createAsyncThunk<
  Company[],
  { api: AdminApiClient; page: number; limit: number; query: string },
  { rejectValue: ApiError }
>(
  "admin/queryCompanies",
  async (
    {
      api,
      page,
      limit,
      query,
    }: { api: AdminApiClient; page: number; limit: number; query: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.queryCompanies(page, limit, query);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchCompany",
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchCompany(companyId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company: Company },
  { rejectValue: ApiError }
>(
  "admin/updateCompany",
  async (
    { api, company }: { api: AdminApiClient; company: Company },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateCompany(company);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryCompanies.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(queryCompanies.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertMany(state.companies, payload);
      state.status = "idle";
    });
    builder.addCase(queryCompanies.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchCompany.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = "idle";
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      companiesAdapter.upsertOne(state.companies, payload);
      state.status = "idle";
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllCompanies, selectById: selectCompanyById } =
  companiesAdapter.getSelectors(
    (state: RootState) => state.companies.companies
  );

export const selectError = (state: RootState) =>
  state.companies.error.error_code;

export const selectIsLoading = (state: RootState) =>
  state.companies.status === "pending";

export const companiesReducer = companiesSlice.reducer;
