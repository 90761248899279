import {
  DollarOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
  ProjectOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Container from "@hellodarwin/core/lib/components/common/container";
import ProfileUsersList from "@hellodarwin/core/lib/components/profile/profile-users-list";
import {
  AdminProjectRequest,
  Company,
  Contact,
  InitialAdminSelectedTags,
  Match,
  Project,
} from "@hellodarwin/core/lib/features/entities";
import {
  copyToClipboard,
  getFormattedDate,
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Button from "antd/es/button";
import { FormInstance } from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import InputNumber from "antd/es/input-number";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { TabsProps } from "antd/es/tabs";
import Tooltip from "antd/es/tooltip";
import { ReactNode, useEffect, useMemo, useState } from "react";

import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import Auth0UserButtons from "../../components/auth0/contacts-auth0-buttons";
import BlockButton from "../../components/blocks/block-button";
import BlocksList from "../../components/blocks/blocks-list";
import MatchesBlock from "../../components/blocks/matches-block";
import Clipboard from "../../components/clipboard";
import CompanyCard from "../../components/companies/company-card";
import CompanyForm, {
  CompanyFormValues,
} from "../../components/companies/company-form";
import ContactsForm from "../../components/contacts/contacts-form";
import { CreateFormValues } from "../../components/forms/single-form/create-form-builder";
import { GrantFormValues } from "../../components/grants/grant-form/grant-form";
import { GrantProviderFormValues } from "../../components/grants/grant-provider-form";
import List from "../../components/list/list";
import Manifest from "../../components/manifests/manifest";
import ManualChargeForm from "../../components/matches/manual-charge-form";
import ManualMatchForm from "../../components/matches/manual-match-form";
import ManualReferralCredits from "../../components/matches/manual-referral-credits";
import RaiseHandForm from "../../components/matches/raise-hand-form";
import ShortlistForm from "../../components/matches/shortlist-form";
import ChargeWinFeeForm from "../../components/matches/win-fee-form";
import { showTagsWarning } from "../../components/messages/show-tags-warning";
import { ProgramFormValues } from "../../components/programs/program-form";
import BlacklistForm from "../../components/projects/blacklist-form";
import { ProjectDescriptionTemplateFormValues } from "../../components/projects/project-description-template-form";
import ProjectForm, {
  ProjectFormValues,
} from "../../components/projects/project-form";
import { EMAIL_ALREADY_EXISTS_ERROR } from "../../components/projects/projects-action-modal/create-project-form";
import { ProviderFormValues } from "../../components/providers/provider-form";
import QuickAction from "../../components/quick-action";
import ReviewNotificationForm, {
  ReviewNotificationFormValues,
} from "../../components/reviews/review-notification-form";
import ExternalLink from "../../components/widgets/external-link";
import ReferenceWidget from "../../components/widgets/reference-widget";
import { useActionBar } from "../../context/action-bar-provider";
import {
  fetchAdmins,
  selectAllAdmins,
} from "../../features/api/slices/admins-slice";
import {
  fetchCompany,
  selectCompanyById,
  updateCompany,
} from "../../features/api/slices/companies-slice";
import {
  activateProject,
  fetchProject,
  fetchProjectPurchasedMatches,
  fetchProjectRefusedMatches,
  fetchProjectTags,
  selectActiveTab,
  selectManualMatches,
  selectProjectById,
  selectPurchasedMatches,
  selectRefusedMatches,
  setActiveTab,
  setSelectedProject,
  toggleProjectsModal,
  updateProject,
  updateProjectTags,
} from "../../features/api/slices/projects-slice";
import { fetchTags, selectAllTags } from "../../features/api/slices/tags-slice";
import {
  assignContact,
  createContact,
  deleteContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  selectContactById,
  updateContact,
} from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { getProjectStatusColor, getStatusTooltip } from "../../features/utils";
import { BlockContent, useBlocks } from "../../hooks/use-blocks";
import { useForceUpdate } from "../../hooks/use-force-update";
import { default as ActionBar } from "../../layout/action-bar";
import { GrantContentRawFormValues } from "../single-grant-context-page";
import AdminTag from "./admin-tag";
import ProjectActions from "./project-actions";
import ProjectStatusDropdown from "./project-status-dropdown";

const { Option } = Select;

const blocksInitialState: BlockContent[] = [
  {
    element: (
      <ProjectForm
        admins={[]}
        setForms={() => null}
        tags={InitialAdminSelectedTags}
      />
    ),
    icon: <ProjectOutlined />,
    label: "",
    visible: true,
    unique: false,
  },
  {
    element: (
      <ContactsForm
        contact={{} as Contact}
        setForms={() => null}
        destination={"Client"}
      />
    ),
    icon: <UserOutlined />,
    label: "",
    visible: true,
    unique: false,
  },
  {
    element: <MatchesBlock matches={[]} targetId={""} />,
    icon: <TeamOutlined />,
    label: "",
    visible: false,
    unique: true,
  },
];

export interface AdminPagesForms {
  companyForm?: FormInstance<CompanyFormValues> | undefined;
  projectForm?: FormInstance<ProjectFormValues> | undefined;
  contactForm?: FormInstance<Contact> | undefined;
  providerForm?: FormInstance<ProviderFormValues> | undefined;
  programForm?: FormInstance<ProgramFormValues> | undefined;
  reviewNotificationForm?:
    | FormInstance<ReviewNotificationFormValues>
    | undefined;
  projectDescriptionTemplate?:
    | FormInstance<ProjectDescriptionTemplateFormValues>
    | undefined;
  grantForm?: FormInstance<GrantFormValues> | undefined;
  grantProviderForm?: FormInstance<GrantProviderFormValues> | undefined;
  grantContentRawForm?: FormInstance<GrantContentRawFormValues> | undefined;
  createForm?: FormInstance<CreateFormValues> | undefined;
}

enum ModalType {
  DiscountProjectModal = "DiscountProjectModal",
}

const SingleProjectPage = () => {
  const { setActionBar } = useActionBar();
  const { id } = useParams<{ id: string }>();
  const project = useAppSelector((state) => selectProjectById(state, id || ""));
  const company = useAppSelector((state) =>
    selectCompanyById(state, project?.company_id || "")
  );
  const contact = useAppSelector((state) =>
    selectContactById(state, project?.contact_id || "")
  );
  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, company?.company_id || "")
  );

  const [blocks, setBlock, toggleBlock, span] = useBlocks(blocksInitialState);
  const [isDiscountInputVisible, setIsDiscountInputVisible] = useState(false);
  const [projectDiscount, setProjectDiscount] = useState<number>(0);
  const [hasAttachments, setHasAttachments] = useState<Boolean>(false);
  const admins = useAppSelector(selectAllAdmins);
  const [forms, setForms] = useState<AdminPagesForms>({
    companyForm: undefined,
    projectForm: undefined,
    contactForm: undefined,
  });
  const api = useAdminApi();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const refusedMatches = useAppSelector(selectRefusedMatches);
  const purchasedMatches = useAppSelector(selectPurchasedMatches);
  const manualMatches = useAppSelector(selectManualMatches);
  const tags = useAppSelector(selectAllTags);
  const activeTab = useAppSelector(selectActiveTab);
  const { t } = useTranslation();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(fetchProject({ api, projectId: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project && project.company_id && project.company_id !== "") {
      setProjectDiscount(project?.discounted_amount || 0);
      dispatch(fetchCompanyContacts({ api, companyId: project.company_id }));
      dispatch(fetchCompany({ api, companyId: project.company_id }));
      dispatch(
        fetchAdmins({
          api,
          needsAuth0: false,
          page: 0,
          limit: 100,
          searchedTerm: "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.company_id, project?.discounted_amount]);

  useEffect(() => {
    if (project) {
      setBlock(
        0,
        <Div flex="column" gap="1">
          <ProjectForm
            admins={admins}
            project={project}
            setForms={setForms}
            save={onSave}
            setHasAttachments={setHasAttachments}
            tags={tags}
          />
          <ReviewNotificationForm project={project} setForms={setForms} />
        </Div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admins, project, tags]);

  useEffect(() => {
    if (project) {
      setBlock(
        1,
        <Div flex="column" gap="1">
          <ContactsForm
            contact={contact}
            setForms={setForms}
            company={company}
            destination={"Client"}
            project={project}
          />
          {company && (
            <CompanyForm
              company={company}
              setForms={setForms}
              project={project}
            />
          )}
        </Div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, company, project]);

  const onSave = async () => {
    try {
      const projectFormValues = forms.projectForm?.getFieldsValue()!;
      const companyFormValues = forms.companyForm?.getFieldsValue()!;
      const contactFormValues = forms.contactForm?.getFieldsValue()!;
      if (
        projectFormValues === undefined ||
        companyFormValues === undefined ||
        contactFormValues === undefined
      ) {
        return;
      }

      const updatedcompany: Company = {
        ...company,
        ...companyFormValues,
        company_id: company!.company_id,
      };
      updatedcompany.industry = tags.selectedIndustry;
      await dispatch(updateCompany({ api, company: updatedcompany }));

      if (contact) {
        const updatedContact: Contact = {
          ...contact,
          ...contactFormValues,
        };
        await dispatch(updateContact({ api, contact: updatedContact }));
      }

      const updatedProjectValues: AdminProjectRequest = {
        ...project,
        ...projectFormValues,
      };
      if (updatedProjectValues.description === "<p><br></p>") {
        updatedProjectValues.description = "";
      }
      await dispatch(updateProject({ api, project: updatedProjectValues }));

      await dispatch(
        updateProjectTags({
          api,
          projectID: project!.project_id,
          serviceTags: tags.selectedServices,
          specialtyTags: tags.selectedSpecialties,
          industryTag: tags.selectedIndustry,
        })
      );

      message.success("Saved!");
    } catch (e: any) {
      if (e.response.data.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: `Email already in use!`,
          description:
            "Please use a new email or search for an existing client.",
          placement: "bottomLeft",
        });
        return;
      }
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const quickActions = [
    <QuickAction
      content={<BlacklistForm projectId={id || ""} />}
      key={id}
      title="Blacklist"
      icon={<ExceptionOutlined />}
    />,
  ];

  const actionBar = (
    <ActionBar children quickActions={quickActions}></ActionBar>
  );

  const openModal = (modalType: ModalType) => {
    switch (modalType) {
      case "DiscountProjectModal":
        dispatch(setSelectedProject(project!));
        dispatch(toggleProjectsModal({ type: "discount", isVisible: true }));
        break;
    }
  };

  const handleChange = (value: string) => {
    const updatedProject = {
      ...project,
      type: value as ProjectType,
    } as AdminProjectRequest;

    dispatch(updateProject({ api, project: updatedProject }));
  };

  const handleActivationVerification = async () => {
    if (
      forms.contactForm === undefined ||
      forms.projectForm === undefined ||
      forms.companyForm === undefined
    ) {
      return;
    }

    try {
      await forms.contactForm.validateFields();
      await forms.projectForm.validateFields();
      // await forms.companyForm.validateFields();
      await onSave();
      // TODO find a way to handle known errors APP-295 https://hellodarwin.atlassian.net/browse/APP-295
      if (tags.selectedServices.length === 0) {
        showTagsWarning();
        return new Error("failed confirmation");
      }
      if (project?.status === ProjectStatus.Active) {
        return;
      }

      if (
        project?.referral_provider_id &&
        project?.referral_provider_id !== ""
      ) {
        const result = window.confirm(
          `Activating this project? will credit ${project?.referral_provider_id} for ${project.lead_price}.`
        );

        if (!result) return new Error("failed confirmation");
      }

      if (
        project?.type === ProjectType.RaiseHand &&
        forms.projectForm.getFieldValue("raise_hand_max") === 0
      ) {
        Modal.info({
          title: "Please set a max raised hands",
        });
        return new Error("failed confirmation");
      }

      if (
        project?.type === ProjectType.RaiseHand &&
        forms.projectForm.getFieldValue("raise_hand_price") === 0
      ) {
        const result = Modal.confirm({
          title: "Everybody can Raise Hand for free ?",
          okText: "Confirm",
          cancelText: "Cancel",
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: "24px",
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: "24px",
            },
          },
          onOk() {
            handleActivation();
          },
          onCancel() {
            return false;
          },
        });
        if (!result) return new Error("failed confirmation");
      } else {
        handleActivation();
      }
    } catch (e: any) {
      message.error("Some required fields are missing!");
      console.error("Validation Failed:", e);
      return new Error("Validation Failed");
    }
  };

  const handleActivation = () => {
    (async () => {
      try {
        await dispatch(activateProject({ api, projectId: id || "" }));
        message.success("Project Activated!");
      } catch (e: any) {
        message.error("Activation failed. Try again later!");
        console.error(e);
        return new Error("Activation Failed");
      }
    })();
  };

  const handleDiscount = () => {
    (async () => {
      try {
        await api.discountProject(id || "", projectDiscount);
        setIsDiscountInputVisible(false);
        openModal(ModalType.DiscountProjectModal);
        setBlock(
          0,
          <Div flex="column" gap={16}>
            <ProjectForm
              admins={admins}
              project={project}
              setForms={setForms}
              save={onSave}
              setHasAttachments={setHasAttachments}
              tags={tags}
            />
            <ReviewNotificationForm
              project={project!}
              setForms={setForms}
            ></ReviewNotificationForm>
          </Div>
        );
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    if (activeTab !== "project") {
      dispatch(setActiveTab("project"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!project?.project_id) return;
    dispatch(fetchProjectPurchasedMatches({ api, projectId: id || "" }));

    dispatch(fetchTags({ api })).then((res) => {
      dispatch(fetchProjectTags({ api, projectId: id || "" }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.project_id]);

  useEffect(() => {
    if (!project?.project_id) return;
    if (activeTab === "refusingProviders") {
      dispatch(fetchProjectRefusedMatches({ api, projectId: id || "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, project?.project_id]);

  useEffect(() => {
    setBlock(
      2,
      <MatchesBlock
        matches={purchasedMatches}
        targetId={id || ""}
        project={project}
      />,
      purchasedMatches.length === 0
        ? undefined
        : { count: purchasedMatches.length }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, purchasedMatches, project]);

  useEffect(() => {
    if (
      !!project?.status &&
      [
        ProjectStatus.Active,
        ProjectStatus.FilterCandidates,
        ProjectStatus.Completed,
      ].includes(project?.status)
    ) {
      quickActions.push(
        <QuickAction
          key={id}
          content={<ManualMatchForm projectId={id || ""} />}
          title="Manual Match"
          icon={<UsergroupAddOutlined />}
        />
      );
      if (project?.type === ProjectType.RaiseHand) {
        quickActions.push(
          <QuickAction
            key={id}
            content={
              <RaiseHandForm
                projectId={id || ""}
                leadPrice={project.lead_price || 0}
              />
            }
            title="Manual Raise Hand"
            icon={<DollarOutlined />}
          />,
          <QuickAction
            key={id}
            content={
              <ShortlistForm
                projectId={id || ""}
                leadPrice={project.lead_price || 0}
              />
            }
            title="Manual Shortlist"
            icon={<DollarOutlined />}
          />,
          <QuickAction
            key={id}
            content={<ChargeWinFeeForm projectId={id || ""} />}
            title="Manual Win Fee Charge"
            icon={<DollarOutlined />}
          />
        );
      } else {
        quickActions.push(
          <QuickAction
            key={id}
            content={<ManualChargeForm projectId={id || ""} />}
            title="Manual Charge"
            icon={<DollarOutlined />}
          />
        );
      }
      if (
        !!project.referral_provider_id &&
        project.referral_credit_applied_at == null
      ) {
        quickActions.push(
          <QuickAction
            key={id}
            content={
              <ManualReferralCredits
                projectId={id || ""}
                leadPrice={project.lead_price || 0}
                onSave={(project: Project) =>
                  dispatch(updateProject({ api, project }))
                }
              />
            }
            title="Manual Referral credits"
            icon={<DollarOutlined />}
          />
        );
      }
    }

    setActionBar(actionBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.status, project?.type, project]);

  const refusedMatchesHeaders = [
    "Provider ID",
    "Category",
    "Estimated Value",
    "Lead Price",
    "Refused Reason",
    "Refused At",
  ];

  const getRefusedMatchesRows = (matches: Match[]) => {
    if (matches.length === 0) {
      return [[]];
    }

    return matches.map((m, index) => {
      const date = getFormattedDate(m.refused_at || "");
      return [
        <Tooltip key={index} placement="bottom" title="Project page">
          <Link to={"/providers/" + m.provider_id}>
            {getShortId(m.provider_id)}
          </Link>
        </Tooltip>,
        m.project.category,
        `$${m.project.estimated_value}`,
        `$${m.price}`,
        m.refused_reason,
        date,
      ];
    });
  };

  const getManualMatchesRows = (matches: Match[]) => {
    if (matches.length === 0) {
      return [[]];
    }

    return matches.map((m, index) => {
      return [
        <Tooltip key={index} placement="bottom" title="Project page">
          <Link to={"/providers/" + m.provider_id}>
            {getShortId(m.provider_id)}
          </Link>
        </Tooltip>,
        String(m.score),
        "No drip schedule",
        "___",
        "___",
        m.source,
        "No drip notification",
      ];
    });
  };

  const ProjectButtons = (): ReactNode => (
    <Div flex="row" justify="space-between">
      <Div flex="row" align="center" gap={8}>
        <Div flex="row" align="center" gap={8} fitContent>
          <Button onClick={onSave} size="small">
            Save
          </Button>
          {project?.status !== ProjectStatus.Active &&
            project?.status !== ProjectStatus.EvaluateFinalists &&
            project?.status !== ProjectStatus.FilterCandidates && (
              <>
                <Select
                  value={project?.type}
                  onChange={handleChange}
                  size="small"
                >
                  <Option value={ProjectType.Classic}>Classic</Option>
                  <Option value={ProjectType.RaiseHand}>Raise Hand</Option>
                </Select>
              </>
            )}
          {project?.status !== ProjectStatus.PendingApproval &&
            project?.status !== ProjectStatus.Completed &&
            projectDiscount !== -1 && (
              <Div flex="row" justify="space-between" gap={8}>
                <Button
                  onClick={() =>
                    setIsDiscountInputVisible(!isDiscountInputVisible)
                  }
                  size="small"
                >
                  {isDiscountInputVisible ? "Close discount" : "Discount"}
                </Button>
                {isDiscountInputVisible && (
                  <>
                    <InputNumber
                      value={projectDiscount}
                      onChange={(value) => {
                        return setProjectDiscount(Number(value));
                      }}
                      formatter={(value) => `${value || 0} $`}
                      style={{ width: "100px" }}
                      size="small"
                    />

                    <Button onClick={handleDiscount}>Save Discount</Button>
                  </>
                )}
              </Div>
            )}
        </Div>
        <>
          {project && (
            <ProjectStatusDropdown
              project={project}
              matches={purchasedMatches}
              setProject={(project: Project) =>
                dispatch(updateProject({ api, project }))
              }
              forceUpdate={forceUpdate}
              handleActivationVerification={handleActivationVerification}
              handleSave={onSave}
            />
          )}
        </>
      </Div>
      <Div flex="row" align="center" gap={16} fitContent>
        {blocks.map((block, i) => (
          <BlockButton
            icon={block.icon}
            key={i}
            onClick={() => toggleBlock(i)}
            tooltip={block.label}
            active={block.visible}
            notification={block.notification}
          />
        ))}
      </Div>
    </Div>
  );

  const handleContactDelete = async (contactId: string) => {
    return await dispatch(deleteContact({ api, contactId }));
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: "Client",
      })
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        Modal.confirm({
          title: "Contact already exists",
          icon: <ExclamationCircleOutlined />,
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: "24px",
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: "24px",
            },
          },
          content:
            "A contact with this email already exists. It has either been deleted or assigned to another company. Do you wish to assign it to this company?",
          async onOk() {
            contact.company_id = company?.company_id || "";
            return await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel() {},
        });
      } else {
        message.error("Something went wrong, try again later!");
      }
    }
    return createContactResult;
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || "";
    return await dispatch(assignContact({ api, contact: contact }));
  };

  const handleEmailExists = async (email: string) => {
    if (companyContacts.find((c) => c.email === email)) {
      return Promise.reject("Email already exists");
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const tabs: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: "project",
        label: t(`project|projectTab.project`),
        children: (
          <BlocksList
            blocks={blocks}
            actions={<ProjectButtons />}
            span={span}
          />
        ),
      },
      {
        key: "matchmaking",
        label: t(`project|projectTab.matchmaking`),
        children: (
          <Div flex="column" gap={16}>
            <Manifest
              projectId={id || ""}
              projectStatus={project?.status ?? ProjectStatus.PendingApproval}
              handleActivation={handleActivationVerification}
            />
            <List
              headers={[]}
              rows={getManualMatchesRows(manualMatches || [])}
              onClick={() => null}
            />
          </Div>
        ),
      },
      {
        key: "refusingProviders",
        label: t(`project|projectTab.refusingProviders`),
        children: (
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col span={24}>
              <List
                headers={refusedMatchesHeaders}
                rows={getRefusedMatchesRows(refusedMatches || [])}
                onClick={() => null}
              />
            </Col>
          </Row>
        ),
      },
      {
        key: "companyContacts",
        label: t(`project|projectTab.users`),
        children: (
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col span={24}>
              {company && company.company_id !== "" && (
                <ProfileUsersList
                  users={companyContacts}
                  contactId={project?.contact_id || ""}
                  companyId={company.company_id}
                  handleContactDelete={handleContactDelete}
                  handleContactEdit={handleContactEdit}
                  handleContactAdd={handleContactAdd}
                  handleEmailExists={handleEmailExists}
                  handleContactAssign={handleContactAssign}
                  Auth0Buttons={Auth0UserButtons}
                  isAdmin
                  destination={"Client"}
                  ClipboardComponent={({ copy, title }) => (
                    <Clipboard copy={copy} title={title} />
                  )}
                />
              )}
            </Col>
          </Row>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContacts, project, company, refusedMatches, id, blocks]);

  return (
    <>
      {project && (
        <PageLayout
          app="admin"
          tabTitle={
            project.title ? project.title : `Project ${getShortId(id || "")}`
          }
          title={
            <Div
              flex="row"
              justify="space-between"
              align="center"
              gap={8}
              fitContent
            >
              <AdminTag
                onClick={() => {
                  copyToClipboard(getShortId(project.project_id));
                  message.success("Copied!");
                }}
                color={"purple"}
                text={"Project: " + getShortId(id || "")}
                tooltip={"Click to copy to clipboard"}
              ></AdminTag>
              <AdminTag text={project?.type} tooltip={"Project Type"} />
              <AdminTag
                text={t(`project|projectStatus|${project.status}`)}
                tooltip={getStatusTooltip(project.status)}
                color={getProjectStatusColor(project.status)}
              />

              {project.diy_requested_at && (
                <AdminTag
                  text={"DIY Requested"}
                  tooltip={getFormattedDate(project.diy_requested_at)}
                  color={"magenta"}
                />
              )}

              {project.referral_provider_id && (
                <AdminTag
                  text={"Partner Referral"}
                  tooltip={
                    project.referral_credit_applied_at
                      ? `${
                          project.referral_credit_amount
                        }$ credited at ${getFormattedDate(
                          project.referral_credit_applied_at
                        )}`
                      : "Provider will be credited lead price on activation"
                  }
                  color={"purple"}
                  onClick={() =>
                    navigate("/providers/" + project.referral_provider_id)
                  }
                />
              )}

              {project.activated_at && (
                <AdminTag
                  text={
                    "Activated " +
                    getShortFormattedDate(project.activated_at || "")
                  }
                  tooltip={getFormattedDate(project.activated_at)}
                  color={"purple"}
                />
              )}

              {project.canceled_at && (
                <AdminTag
                  text={
                    "Canceled " +
                    getShortFormattedDate(project.canceled_at || "")
                  }
                  tooltip={`${project.canceled_source} // ${
                    project.canceled_reason
                  } // ${getFormattedDate(project.canceled_at)}`}
                  color={"red"}
                />
              )}

              {project.completed_at && (
                <AdminTag
                  text={
                    "Completed " +
                    getShortFormattedDate(project.completed_at || "")
                  }
                  tooltip={`${project.completed_source} // ${getFormattedDate(
                    project.completed_at
                  )}`}
                  color={"gold"}
                />
              )}

              {project.requested_more_providers_at && (
                <AdminTag
                  text={"More Providers Requested"}
                  tooltip={`${getShortFormattedDate(
                    project.requested_more_providers_at || ""
                  )} // ${project.request_more_providers_reason}`}
                  color={"magenta"}
                />
              )}

              {hasAttachments && (
                <Div fitContent>
                  <Tooltip title={"Project has attachments"}>
                    <PaperClipOutlined style={{ fontSize: "20px" }} />
                  </Tooltip>
                </Div>
              )}

              {project.status === ProjectStatus.PendingApproval && (
                <ReferenceWidget id={project.project_id} />
              )}

              <ExternalLink
                id={project.crm_id}
                title="Hubspot ID"
                url={project.hubspot_url}
                onEnd={(newValue) => {
                  dispatch(
                    updateProject({
                      api,
                      project: {
                        ...project,
                        ...{ crm_id: newValue },
                      },
                    })
                  );
                }}
              />
            </Div>
          }
          breadcrumbs={[
            {
              breadcrumbName: "Home",
              path: "/",
            },
            {
              breadcrumbName: "Projects",
              path: "/projects",
            },
            {
              breadcrumbName:
                project?.title ??
                project?.service ??
                `Project ${getShortId(project?.project_id || "")}`,
            },
          ]}
        >
          <CompanyCard
            company={company ? company : ({} as Company)}
            contact={contact ? contact : ({} as Contact)}
          />
          <Container
            className="project"
            tabs={tabs}
            tabBarExtraContent={<ProjectActions project={project} />}
            activeTab={activeTab}
            handleTabClick={(key) => dispatch(setActiveTab(key))}
            fullWidth
          />
        </PageLayout>
      )}
    </>
  );
};

export default SingleProjectPage;

