import { GrantStatus } from "../../../../../features/entities";
import GrantStatusTag from "../../../card/grant-status-tag";

const StatusTags = ({
  application_status,
}: {
  application_status: GrantStatus;
}) => {
  return (
    <div style={{ display: "flex", gap: 8 }}>
      {application_status === "closingSoon" && <GrantStatusTag status="open" />}
      <GrantStatusTag status={application_status} />
    </div>
  );
};

export default StatusTags;

