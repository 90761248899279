import message from "antd/es/message";
import { ReactNode, useState } from "react";

export interface BlockContent {
  element: ReactNode | undefined;
  icon: ReactNode;
  label: string;
  visible: boolean;
  notification?: {
    count?: number;
    problem?: boolean;
  };
  unique: boolean;
}

const FULL_SPAN = 24;
const MAX_BLOCKS = 4;

export type setBlockFunction = (
  i: number,
  e: ReactNode,
  notification?: BlockContent["notification"]
) => void;
export type toggleBlockFunction = (i: number) => void;

export const useBlocks = (
  initialState: BlockContent[]
): [
  BlockContent[],
  (
    i: number,
    e: ReactNode,
    notification?: BlockContent["notification"]
  ) => void,
  (i: number) => void,
  number,
] => {
  const [blocks, setBlocks] = useState<BlockContent[]>(initialState);
  const [span, setSpan] = useState(
    FULL_SPAN / blocks.filter((b) => b.visible).length
  );

  const setBlock: setBlockFunction = (
    i: number,
    e: ReactNode,
    notification?: BlockContent["notification"]
  ) => {
    const block = blocks[i];
    block.element = e;
    block.notification = notification;
    const newBlocks = [...blocks];
    newBlocks[i] = block;

    setBlocks(newBlocks);
  };

  const toggleBlock = (i: number) => {
    const block = blocks[i];

    if (
      blocks.filter((b) => b.visible).length === MAX_BLOCKS &&
      !block.visible
    ) {
      message.error("You have reach the maximum of blocks");
      return;
    }

    block.visible = !block.visible;

    const newBlocks = [...blocks];
    newBlocks[i] = block;

    setBlocks(newBlocks);

    setSpan(FULL_SPAN / blocks.filter((b) => b.visible).length);
  };

  return [blocks, setBlock, toggleBlock, span];
};

