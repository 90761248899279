import styled from "../../../../../plugins/styled";

export const SummaryBoxContent = styled.div``;
export const SummaryBoxContainer = styled.div`
  padding: 26px 32px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
  }
  &:last-child {
    border: none;
  }
`;

