import Div from "@hellodarwin/core/lib/components/common/div";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import FundingExplorerFilter from "@hellodarwin/core/lib/components/grants/funding-explorer/filter";
import Button from "antd/es/button/button";
import Collapse from "antd/es/collapse/Collapse";
import Drawer from "antd/es/drawer";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select, { SelectProps } from "antd/es/select";
import Switch from "antd/es/switch";
import { useState } from "react";
import { SearchType } from ".";
import EditPromptDrawer from "./edit-prompt-drawer";
const { Panel } = Collapse;

interface SearchGrantsSettingsDrawerProps {
  visible: boolean;
  onClose: () => void;
  grantsFiltersForm: any;
  gptFiltersForm: any;
  setTextQuery: (text: string) => void;
  financingType: SelectProps["options"];
  industries: SelectProps["options"];
  subIndustries: SelectProps["options"];
  provinces: SelectProps["options"];
  services: SelectProps["options"];
  applyFilter: () => Promise<void>;
  resetFilter: () => void;
  selectedIndustries: string[];
  setSelectedIndustries: (industries: string[]) => void;
}

const SearchGrantsSettingsDrawer = ({
  visible,
  onClose,
  grantsFiltersForm,
  gptFiltersForm,
  setTextQuery,
  financingType,
  industries,
  subIndustries,
  provinces,
  services,
  applyFilter,
  resetFilter,
  selectedIndustries,
  setSelectedIndustries,
}: SearchGrantsSettingsDrawerProps) => {
  const [rephrase, setRephrase] = useState(true);
  const [extractFilters, setExtractFilters] = useState(true);
  const [rerank, setRerank] = useState(false);
  const [isEditDrawerVisible, setEditDrawerVisible] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState<string | null>(null);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.HYBRID);

  const handleEditPrompt = (promptName: string) => {
    setCurrentPrompt(promptName);
    setEditDrawerVisible(true);
  };

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextQuery(e.target.value);
  };

  const validateMaxResults = (rule: any, value: any, callback: any) => {
    if (!Number.isInteger(Number(value)) || value > 50 || value < 1) {
      callback("Max Results must be an integer between 1 and 50");
    } else {
      callback();
    }
  };

  const validateMaxTokens = (rule: any, value: any, callback: any) => {
    if (!Number.isInteger(Number(value)) || value > 4096 || value < 500) {
      callback("Max Tokens must be an integer between 500 and 4096");
    } else {
      callback();
    }
  };

  const validateDecimalValues = (rule: any, value: any, callback: any) => {
    if (value > 1 || value < 0 || !/^\d+(\.\d{1,2})?$/.test(value)) {
      callback(
        "Must be a decimal between 0 and 1 with maximum two decimal places"
      );
    } else {
      callback();
    }
  };

  const handleDrawerClose = () => {
    const grantsFormErrors = grantsFiltersForm
      .getFieldsError()
      .some((field: any) => field.errors.length > 0);
    const gptFormErrors = gptFiltersForm
      .getFieldsError()
      .some((field: any) => field.errors.length > 0);

    if (!grantsFormErrors && !gptFormErrors) {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        title="Settings"
        size="large"
        placement="right"
        closable={true}
        onClose={handleDrawerClose}
        open={visible}
      >
        <Collapse defaultActiveKey={["1", "2"]}>
          <Panel header="Advanced Filters" key="1">
            <FundingExplorerFilter
              initialQuerySearch=""
              filterform={grantsFiltersForm}
              onTextQueryChange={onTextQueryChange}
              financingType={financingType}
              industries={industries}
              subIndustries={subIndustries}
              provinces={provinces}
              services={services}
              annualRevenue={annualRevenue}
              companySize={companySize}
              applyFilter={applyFilter}
              selectedIndustries={selectedIndustries}
              setSelectedIndustries={setSelectedIndustries}
              handleProjectSelection={() => {}}
            />
          </Panel>
          <Panel header="AI Search Settings" key="2">
            <Form
              layout="vertical"
              form={gptFiltersForm}
              initialValues={{
                search_type: SearchType.HYBRID,
                max_results: 15,
                hybrid_search_alpha: 0.85,
                hybrid_search_autocut: 3,
                rephrase: true,
                extract_filters: true,
                rephrase_model: "gpt-4o-mini",
                rephrase_max_tokens: 2000,
                rephrase_temperature: 0.7,
                rerank: false,
                rerank_model: "gpt-4o-mini",
                rerank_max_tokens: 2000,
                rerank_temperature: 0.7,
              }}
            >
              <Grid xl={2} lg={2} md={2} sm={1} xs={1} gutter={16}>
                <Div flex="column" gap={8}>
                  <Form.Item
                    label="Max Results"
                    name={"max_results"}
                    style={{ width: "50%", marginBottom: 0 }}
                    rules={[{ validator: validateMaxResults }]}
                    tooltip="Select the maximum number of search results to display"
                  >
                    <Input type="number" max={50} min={1} />
                  </Form.Item>
                </Div>
                <Div flex="column" gap={8}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={"search_type"}
                    label="Search Type"
                    tooltip="Generative search provides an explanation for the grants. Hybrid search simply returns the search results (more efficient)."
                  >
                    <Select onChange={(value) => setSearchType(value)}>
                      <Select.Option value={SearchType.HYBRID}>
                        Hybrid Search
                      </Select.Option>
                      <Select.Option value={SearchType.GENERATIVE_GROUPED}>
                        Generative Grouped Search
                      </Select.Option>
                      <Select.Option value={SearchType.GENERATIVE_SINGLE}>
                        Generative Single Search
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {searchType === SearchType.HYBRID ? (
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={1}
                      xs={1}
                      gutter={12}
                      style={{ width: "100%", marginBottom: 0 }}
                    >
                      <Form.Item
                        label="Hybrid Alpha"
                        name={"hybrid_search_alpha"}
                        style={{ marginBottom: 0 }}
                        rules={[{ validator: validateDecimalValues }]}
                        tooltip="Select the alpha value for the hybrid search. 1 is pure vector search, 0 is pure keyword search."
                      >
                        <Input type="number" step={0.1} max={1} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Hybrid Auto Cut"
                        name={"hybrid_search_autocut"}
                        style={{ marginBottom: 0 }}
                        tooltip="Limits the number of results returned by a search based on discontinuities or jumps in the result metrics. 1 will return only the top results whereas 3 will return more results. 0 disables the feature."
                      >
                        <Input type="number" step={1} max={3} min={0} />
                      </Form.Item>
                    </Grid>
                  ) : (
                    <Button
                      style={{ marginBottom: 0 }}
                      onClick={() =>
                        handleEditPrompt(
                          searchType === SearchType.GENERATIVE_GROUPED
                            ? "grouped_grant_search"
                            : "single_grant_search"
                        )
                      }
                    >
                      Edit Search Prompt
                    </Button>
                  )}
                </Div>
                <Div flex="column" gap={8}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={"rephrase"}
                    label="Rephrase Results"
                    tooltip="Enables rephrasing of the search prompt to improve the search results."
                  >
                    <Switch
                      onChange={() => {
                        setRephrase(!rephrase);
                      }}
                    />
                  </Form.Item>
                  <Button
                    onClick={() =>
                      handleEditPrompt("rephrase_grant_search_query")
                    }
                    disabled={!rephrase}
                  >
                    Edit Rephrase Prompt
                  </Button>
                  {rephrase && (
                    <>
                      <Grid
                        xl={2}
                        lg={2}
                        md={2}
                        sm={1}
                        xs={1}
                        gutter={12}
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          label="Model"
                          style={{ marginBottom: 0 }}
                          name="rephrase_model"
                          tooltip="Select the GPT model to use for the rephrasing"
                        >
                          <Select>
                            <Select.Option value="gpt-4o-mini">
                              GPT-4o mini
                            </Select.Option>
                            <Select.Option value="gpt-4o">GPT-4o</Select.Option>
                            <Select.Option value="gpt-4-turbo">
                              GPT-4 Turbo
                            </Select.Option>
                            <Select.Option value="gpt-3.5-turbo">
                              GPT-3.5 Turbo
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Extract Filters"
                          style={{ marginBottom: 0 }}
                          name={"extract_filters"}
                          tooltip="When enabled, the rephrasing process will automatically extract filters from the prompt and use them to filter the search results. Only the 'Region' parameter extraction is currently supported."
                        >
                          <Switch
                            onChange={() => {
                              setExtractFilters(!extractFilters);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Max Tokens"
                          style={{ marginBottom: 0 }}
                          name={"rephrase_max_tokens"}
                          rules={[{ validator: validateMaxTokens }]}
                          tooltip="Select the maximum number of tokens used for the rephrasing"
                        >
                          <Input type="number" max={4096} min={500} />
                        </Form.Item>
                        <Form.Item
                          label="Temperature"
                          style={{ marginBottom: 0 }}
                          name={"rephrase_temperature"}
                          rules={[{ validator: validateDecimalValues }]}
                          tooltip="The temperature controls the randomness of the model's output. A lower temperature will make the model more deterministic, a higher temperature will make the model more creative."
                        >
                          <Input type="number" step={0.1} min={0} max={1} />
                        </Form.Item>
                      </Grid>
                    </>
                  )}
                </Div>
                <Div flex="column" gap={8}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={"rerank"}
                    label="Rerank Results"
                    tooltip="Enables the reordering of results by ChatGPT after the initial search."
                  >
                    <Switch
                      onChange={() => {
                        setRerank(!rerank);
                      }}
                    />
                  </Form.Item>
                  <Button
                    onClick={() =>
                      handleEditPrompt("rerank_grant_search_results")
                    }
                    disabled={!rerank}
                  >
                    Edit Rerank Prompt
                  </Button>
                  {rerank && (
                    <>
                      <Grid
                        xl={2}
                        lg={2}
                        md={2}
                        sm={1}
                        xs={1}
                        gutter={12}
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          label="Model"
                          name="rerank_model"
                          style={{ marginBottom: 0 }}
                          tooltip="Select the GPT model to use for the reranking"
                        >
                          <Select>
                            <Select.Option value="gpt-4o-mini">
                              GPT-4o mini
                            </Select.Option>
                            <Select.Option value="chatgpt-4o-latest">
                              GPT-4o Latest
                            </Select.Option>
                            <Select.Option value="gpt-4o-2024-08-06">
                              GPT-4 2024-08-06
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Max Tokens"
                          style={{ marginBottom: 0 }}
                          name={"rerank_max_tokens"}
                          rules={[{ validator: validateMaxTokens }]}
                          tooltip="Select the maximum number of tokens used for the reranking"
                        >
                          <Input type="number" max={4096} min={500} />
                        </Form.Item>
                        <Form.Item
                          label="Temperature"
                          name={"rerank_temperature"}
                          style={{ marginBottom: 0 }}
                          rules={[{ validator: validateDecimalValues }]}
                          tooltip="The temperature controls the randomness of the model's output. A lower temperature will make the model more deterministic, a higher temperature will make the model more creative."
                        >
                          <Input type="number" step={0.1} min={0} max={1} />
                        </Form.Item>
                      </Grid>
                    </>
                  )}
                </Div>
              </Grid>
            </Form>
          </Panel>
        </Collapse>
        {currentPrompt && (
          <EditPromptDrawer
            visible={isEditDrawerVisible}
            onClose={() => setEditDrawerVisible(false)}
            promptName={currentPrompt}
          />
        )}
      </Drawer>
    </>
  );
};

export default SearchGrantsSettingsDrawer;

