import Div from "@hellodarwin/core/lib/components/common/div";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import ProfileLogo from "@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo";
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from "@hellodarwin/core/lib/features/entities";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import message from "antd/es/message";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../../app";
import { generateSingleGrantContentInfo } from "../../../features/api/slices/grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import theme from "../../../theme";
import { default as GrantPromptSection } from "../grant-prompt-content-section";
import { GrantFormValues, transformSectionIntoLabel } from "./grant-form";
type GrantFormHeaderProps = {
  grant?: AdminGrantResult;
  grantLogo?: string;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  initialValues: GrantFormValues;
  setSelectedPrompt?: Dispatch<SetStateAction<HdChatPromptRequest>>;
  setAddNewPromptDrawer?: Dispatch<SetStateAction<boolean>>;
  onSave: (() => Promise<void>) | undefined;
  isCreateGrant?: boolean;
  form: any;
  prompts?: HdChatPromptRequest[];
};
const GrantFormHeader = ({
  grant,
  grantLogo,
  setOpen,
  initialValues,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  onSave,
  isCreateGrant,
  form,
  prompts,
}: GrantFormHeaderProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [englishUrls, setEnglishUrls] = useState<string[]>(
    initialValues.grant_urls_en
  );
  const [frenchUrls, setFrenchUrls] = useState<string[]>(
    initialValues.grant_urls_fr
  );

  const generateSection = async (section: string) => {
    try {
      const sectionText = await dispatch(
        generateSingleGrantContentInfo({
          api,
          grantId: grant!.grant_id,
          sectionName: section,
          industrySectorsIDs: [],
        })
      ).unwrap();
      const titleLabel = transformSectionIntoLabel(section);
      if (sectionText === "") {
        message.info(
          `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`
        );
      } else {
        form.setFieldValue(section, sectionText);

        message.success(
          `Section ${titleLabel} generated! Don't forget to save.`
        );
      }
    } catch (e: any) {
      message.error("Error !");
      message.error(e.cause);
    }
  };

  const handleUrlChange = (
    index: number,
    value: string,
    field: string,
    setUrls: Dispatch<SetStateAction<string[]>>
  ) => {
    var newUrls: string[];
    if (field === "grant_urls_en") {
      newUrls = [...englishUrls];
    } else {
      newUrls = [...frenchUrls];
    }

    newUrls[index] = value;
    setUrls(newUrls);
    form.setFieldValue(field, newUrls);
  };

  const displayUrls = (language: string) => {
    const urls = language === "en" ? englishUrls : frenchUrls;
    return urls.map((url, index) => (
      <Row align={"middle"} style={{ marginBottom: 16 }}>
        <Input
          value={url}
          onChange={(e) =>
            handleUrlChange(
              index,
              e.target.value,
              "grant_urls_" + language,
              language === "en" ? setEnglishUrls : setFrenchUrls
            )
          }
          style={{ width: "80%", marginRight: 8 }}
        />
        {url !== "" && (
          <Button
            onClick={() =>
              window.open(
                url?.includes("https://") ? url : "https://" + url,
                "_blank"
              )
            }
            ghost
            icon={
              <NewWindow
                style={{ color: theme.colors.purple_1 }}
                width={16}
                height={16}
              />
            }
            type="text"
          />
        )}
        <Button
          onClick={() =>
            handleRemoveUrl(
              index,
              language === "en" ? englishUrls : frenchUrls,
              "grant_urls_" + language,
              language === "en" ? setEnglishUrls : setFrenchUrls
            )
          }
          type="text"
          size="small"
          danger={true}
          icon={<Delete width={24} height={24} />}
        />
      </Row>
    ));
  };

  const handleAddUrl = (
    urls: string[],
    field: string,
    setUrls: Dispatch<SetStateAction<string[]>>
  ) => {
    const newUrls = [...urls, ""];
    setUrls(newUrls);
    form.setFieldValue(field, newUrls);
  };

  const handleRemoveUrl = (
    index: number,
    urls: string[],
    field: string,
    setUrls: Dispatch<SetStateAction<string[]>>
  ) => {
    if (urls.length > 1) {
      const newUrls = urls.filter((_, i) => i !== index);

      setUrls(newUrls);
      form.setFieldValue(field, newUrls);
    }
  };
  return (
    <>
      <Div flex="column" align="start">
        <Div flex="row" align="start">
          {grant?.grant_id !== "" && !isCreateGrant && grantLogo && setOpen && (
            <Form.Item label="Logo" name="grant_logo">
              <ProfileLogo
                name={"logo"}
                logo={grantLogo}
                isEditable={true}
                toggleEditModal={() => setOpen(true)}
              />
            </Form.Item>
          )}
          <Div
            style={
              !isCreateGrant
                ? { width: "100%", marginLeft: 20 }
                : { width: "100%" }
            }
          >
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={"Grant title EN"}
                  name={"grant_title_en"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a title for the grant",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Grant title FR"}
                  name={"grant_title_fr"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a title for the grant",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={"Display Title EN"}
                  name={"grant_display_title_en"}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please add a display title for the grant. It can be the same as the title",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Display Title FR"}
                  name={"grant_display_title_fr"}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please add a display title for the grant. It can be the same as the title",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={"Grant Slug EN"}
                  name={"grant_slug_en"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a title for the grant",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      grant?.grant_slug_en !== "" &&
                      grant?.grant_slug_en !== undefined
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Grant Slug FR"}
                  name={"grant_slug_fr"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a title for the grant",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      grant?.grant_slug_fr !== "" &&
                      grant?.grant_slug_fr !== undefined
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={"Websites EN"}
                  name={"grant_urls_en"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a valid website url",
                    },
                  ]}
                >
                  {displayUrls("en")}
                  <Button
                    onClick={() => {
                      handleAddUrl(
                        englishUrls,
                        "grant_urls_en",
                        setEnglishUrls
                      );
                    }}
                    type="text"
                    size="middle"
                    icon={<AddNew width={24} height={24} />}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Websites FR"}
                  name={"grant_urls_fr"}
                  rules={[
                    {
                      required: true,
                      message: "Please add a valid website url",
                    },
                  ]}
                >
                  {displayUrls("fr")}
                  <Button
                    onClick={() =>
                      handleAddUrl(frenchUrls, "grant_urls_fr", setFrenchUrls)
                    }
                    type="text"
                    size="middle"
                    icon={<AddNew width={24} height={24} />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: 6, paddingLeft: 6 }}>
                <FormItem label={"Notes"} name={"notes"}>
                  <TextArea
                    style={{ width: "100%", minHeight: 100 }}
                  ></TextArea>
                </FormItem>
              </Col>
              <Col></Col>
            </Row>
          </Div>
        </Div>
      </Div>
      {!isCreateGrant && (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <GrantPromptSection
                section={"grant_description_short_en"}
                setSelectedPrompt={setSelectedPrompt}
                setAddNewPromptDrawer={setAddNewPromptDrawer}
                onSave={onSave}
                grant={grant}
                isTextArea={true}
                prompts={prompts}
                generateSection={generateSection}
              />
            </Col>
            <Col span={12}>
              <GrantPromptSection
                section={"grant_description_short_fr"}
                setSelectedPrompt={setSelectedPrompt}
                setAddNewPromptDrawer={setAddNewPromptDrawer}
                onSave={onSave}
                grant={grant}
                isTextArea={true}
                prompts={prompts}
                generateSection={generateSection}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <GrantPromptSection
                section={"grant_description_long_en"}
                setSelectedPrompt={setSelectedPrompt}
                setAddNewPromptDrawer={setAddNewPromptDrawer}
                onSave={onSave}
                grant={grant}
                isTextArea={true}
                prompts={prompts}
                generateSection={generateSection}
              />
            </Col>
            <Col span={12}>
              <GrantPromptSection
                section={"grant_description_long_fr"}
                setSelectedPrompt={setSelectedPrompt}
                setAddNewPromptDrawer={setAddNewPromptDrawer}
                onSave={onSave}
                grant={grant}
                isTextArea={true}
                prompts={prompts}
                generateSection={generateSection}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default GrantFormHeader;

