import Collapse, {
  CollapseActiveKeysType,
} from "@hellodarwin/core/lib/components/common/Collapse";
import { CollapsePanelProps } from "@hellodarwin/core/lib/components/common/Collapse/CollapsePanel";
import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Company, HdChat } from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import theme from "@hellodarwin/core/lib/theme";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Drawer from "antd/es/drawer";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../app";
import {
  removeChatCompany,
  selectChatCompany,
  updateChat,
} from "../../features/api/slices/hd-chats-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import CompaniesSearch from "../companies/companies-search";

interface SelectionDrawerProps {
  chat: HdChat;
  loading: boolean;
  company: Company | null;
  visible: boolean;
  onClose: () => void;
}
export type ChatFormValues = {
  locale?: string;
  max_tokens?: number;
  model?: string;
  role?: string;
};

const ChatSettingsDrawer = ({
  chat,
  loading,
  company,
  visible,
  onClose,
}: SelectionDrawerProps) => {
  const [activeTab, setActiveTab] = useState<CollapseActiveKeysType>("");
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [form] = Form.useForm<ChatFormValues>();

  const initialValues: ChatFormValues = useMemo(() => {
    return {
      locale: chat?.locale || "",
      max_tokens: chat?.max_tokens || 0,
      model: chat?.model || "",
      role: chat?.role || "",
    };
  }, [chat.locale, chat.max_tokens, chat.model, chat.role]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleSave = async () => {
    try {
      const actionResult = await dispatch(
        updateChat({ api, chat: { ...chat, ...form.getFieldsValue() } })
      ).unwrap();
      if (actionResult) {
        onClose();
        form.resetFields();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCompanySelect = (company: Company | null) => {
    if (company) {
      dispatch(
        selectChatCompany({
          api,
          chatId: chat.id,
          companyId: company?.company_id || "",
        })
      );
    } else {
      dispatch(
        removeChatCompany({
          api,
          chatId: chat.id,
        })
      );
    }
  };

  const ItemLayout = ({
    isActive,
    title,
    itemId,
    itemTitle,
    onCancel,
    searchBar,
    type,
  }: {
    isActive: boolean;
    title: string;
    itemId?: string;
    itemTitle?: string;
    onCancel: () => void;
    searchBar: ReactNode;
    type: "grants" | "companies";
  }) => (
    <Div flex="column" align="center" justify="center" gap={16}>
      {isActive && itemId && (
        <Div
          flex="row"
          justify="space-between"
          align="center"
          gap={16}
          borderColor={theme.colors.black}
          borderRadius={24}
          style={{ width: "100%", padding: 14 }}
        >
          <Div fitContent flex="row" gap={16} align="center">
            <Typography.Title
              noMargin
              level={4}
              elementTheme="h6"
              fitContent
              bold
            >
              {title}
            </Typography.Title>
            <Typography.Paragraph fitContent>
              <Link target="_blank" to={`/${type}/${itemId}`}>
                {getShortId(itemId)}
              </Link>{" "}
              - {itemTitle}
            </Typography.Paragraph>
          </Div>
          <Div fitContent onClick={onCancel} style={{ cursor: "pointer" }}>
            <Close width={14} />
          </Div>
        </Div>
      )}
      {searchBar}
    </Div>
  );

  const collapseItems: CollapsePanelProps[] = [
    {
      id: "company",
      title: "Select Company",
      children: (
        <ItemLayout
          isActive={!!company}
          title="Selected company:"
          type="companies"
          itemId={company?.company_id}
          itemTitle={company?.name}
          searchBar={<CompaniesSearch handleSubmit={onCompanySelect} />}
          onCancel={() => onCompanySelect(null)}
        />
      ),
    },
  ];

  return (
    <Drawer
      title="Settings"
      size="large"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      extra={
        <Button
          size="small"
          onClick={handleSave}
          loading={loading}
          defaultStyle={theme.colors.purple_1}
        >
          Save
        </Button>
      }
    >
      <Div flex="column" fullHeight gap={16}>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          style={{ paddingBottom: 16 }}
        >
          <Form.Item label="Max Tokens" name="max_tokens">
            <Input></Input>
          </Form.Item>
          <Form.Item label="Model" name="model">
            <Select>
              <Select.Option value={"gpt-4o-mini"}>
                {"gpt-4o-mini"}
              </Select.Option>
              <Select.Option value={"gpt-4o"}>{"gpt-4o"}</Select.Option>
              <Select.Option value={"gpt-4-turbo"}>
                {"gpt-4-turbo"}
              </Select.Option>
              <Select.Option value={"gpt-3.5-turbo"}>
                {"gpt-3.5-turbo"}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Role" name="role">
            <Input></Input>
          </Form.Item>
          <Form.Item label="Language" name="locale">
            <Select>
              <Select.Option value={"fr"}>{"FR"}</Select.Option>
              <Select.Option value={"en"}>{"EN"}</Select.Option>
            </Select>
          </Form.Item>
          <Collapse
            items={collapseItems}
            activeKey={activeTab}
            onChange={setActiveTab}
          />
        </Form>
      </Div>
    </Drawer>
  );
};

export default ChatSettingsDrawer;

