import Div from "@hellodarwin/core/lib/components/common/div";
import Layout from "antd/es/layout";
import { MenuProps } from "antd/es/menu";
import { useAppDispatch } from "../app";
import { toggleProgramsModal } from "../features/api/slices/programs-slice";
import { toggleProjectsModal } from "../features/api/slices/projects-slice";
import { toggleProviderModal } from "../features/api/slices/providers-slice";
import HeaderBarUser from "./header-bar-user";

import Button from "@hellodarwin/core/lib/components/common/button";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import Dots from "@hellodarwin/icons/dist/icons/Dots";
import SearchBar from "./search-bar";
const { Header } = Layout;
const HeaderBar = () => {
  const dispatch = useAppDispatch();
  const toggleProjectForm = () => {
    dispatch(toggleProjectsModal({ type: "create", isVisible: true }));
  };
  const { t } = useTranslations();
  const toggleProviderForm = () => {
    dispatch(toggleProviderModal({ type: "create", isVisible: true }));
  };

  const toggleProgramForm = () => {
    dispatch(toggleProgramsModal({ type: "create", isVisible: true }));
  };

  const actionMenu: MenuProps["items"] = [
    {
      label: t("headerBar|create_project_title"),
      key: 1,
      onClick: toggleProjectForm,
    },
    {
      label: t("headerBar|create_provider_title"),
      key: 2,
      onClick: toggleProviderForm,
    },
    {
      label: t("headerBar|create_application_title"),
      key: 3,
      onClick: toggleProgramForm,
    },
  ];

  return (
    <Header style={{ padding: 0, height: "auto" }}>
      <Div
        flex="row"
        style={{
          width: "100%",
          height: 44,
        }}
        backgroundColor={theme.colors.white_1}
      >
        <SearchBar />
        <Div
          flex="row"
          align="center"
          justify="end"
          style={{ padding: "0 32px", height: "100%" }}
          gap={15}
          fitContent
        >
          <Dropdown
            items={actionMenu}
            ctaRender={() => (
              <Button
                size="square"
                style={{ padding: 0 }}
                transparent
                defaultStyle={theme.colors.primary}
              >
                <Dots size={32} />
              </Button>
            )}
          />

          <HeaderBarUser />
        </Div>
      </Div>
    </Header>
  );
};

export default HeaderBar;

