import Table, { TableProps } from "antd/es/table";
import { ReactNode } from "react";
import styled from "../../../../plugins/styled";
import { createFontSizeCSS } from "../../../../theme/global-style";

type TableNode = <T>(table: TableProps<T>) => ReactNode;
export const FormSubmissionFieldsListContainer = styled(Table)`
  &.ant-table-wrapper {
    .ant-table {
      border-radius: 0;

      .ant-table-container {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        table {
          border: 1px solid ${({ theme }) => theme.colors.primary};
          border-radius: 0;
          thead {
            ${createFontSizeCSS("subtitle1", false, "medium")}
            & > tr {
              & > th {
                background: ${({ theme }) => theme.colors.beige_1};
                border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
                border-right: 1px solid ${({ theme }) => theme.colors.primary};
                &:first-child {
                  border-start-start-radius: 0;
                }
                &::before  {
                  display: none;
                }
                &:last-child {
                  border-start-end-radius: 0;
                  border-right: none;
                }
              }
            }
          }
          tbody {
            ${createFontSizeCSS("body1", false, "regular")}

            tr {
              &:nth-child(even) {
                background: ${({ theme }) => theme.colors.beige_3};
              }
              td {
                border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
                &:last-child {
                  border-right: none;
                }
              }
              &:last-child {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
` as TableNode;

