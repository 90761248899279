import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import React, { MouseEvent, useEffect, useState } from "react";
import { FundingExplorerFilterProps } from ".";
import { GrantProject } from "../../../../features/entities/grants-entities";
import { useAppData } from "../../../../features/providers/app-provider";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import HdTag from "../../../common/hd-tag";
import FormItem from "../../../forms/form-layouts/form-item";
import FormLabel from "../../../forms/form-layouts/form-label";
import Loading from "../../../loading";
import {
  FilterSectionContainterStyled,
  FilterSectionStyled,
  GrantProjectsRow,
  ScrollingContainer,
  ScrollingContent,
} from "./styles";

export interface FilterProjectsProps extends FundingExplorerFilterProps {}

const FilterProjects = ({
  bestProjects,
  grantsProjectsLoading,
  handleProjectSelection,
  selectedProjects,
  applyFilter,
}: FilterProjectsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { navCollapsed } = useAppData();

  const [projectHasChanged, setProjectsHasChanged] = useState(false);
  const [projectsHidden, setProjectsHidden] = useState(false);
  const handleHideProject = (e: MouseEvent<HTMLDivElement>) => {
    setProjectsHidden(!projectsHidden);
  };

  useEffect(() => {
    if (projectHasChanged) {
      applyFilter({ type: "filterChanged" });
      setProjectsHasChanged(false);
    }
  }, [applyFilter, projectHasChanged]);

  const chunkArray = (array, chunkSize) => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  const handleProjectClick = (project: GrantProject) => {
    handleProjectSelection(project);
    setProjectsHasChanged(true);
  };

  const bubbleRows = chunkArray(
    bestProjects,
    Math.ceil(bestProjects?.length / 2)
  ).map((rowProjects, rowIndex) => (
    <GrantProjectsRow key={`row-${rowIndex}`}>
      {React.Children.toArray(
        rowProjects.map(
          (project, index) =>
            project.short_description && (
              <HdTag
                onClick={(e) => {
                  e.stopPropagation();
                  handleProjectClick(project);
                }}
                key={`project-${index}`}
                hoverable
                text={project.short_description}
                color={
                  selectedProjects?.find(
                    (sp) => sp.grant_project_id === project.grant_project_id
                  )
                    ? theme.colors.purple_3
                    : theme.colors.purple_4
                }
              />
            )
        )
      )}
    </GrantProjectsRow>
  ));

  if (!bestProjects?.length) return <></>;

  return (
    <FilterSectionContainterStyled
      $position="bottom"
      $navCollapsed={navCollapsed}
    >
      <FilterSectionStyled
        onClick={handleHideProject}
        style={{ cursor: "pointer" }}
        $position="bottom"
      >
        <FormItem
          label={
            <FormLabel
              label={t(`grants|fundingExplorerFilter.proposedProjects`)}
              color={theme.colors.primary}
              actions={
                <TabArrow
                  down={!projectsHidden}
                  color={theme.colors.primary}
                  style={{ cursor: "pointer" }}
                  width={12}
                  height={8}
                />
              }
            />
          }
          style={{ margin: 0 }}
          hideContent={projectsHidden}
        >
          <ScrollingContainer>
            {grantsProjectsLoading ? (
              <Loading />
            ) : (
              <ScrollingContent>{bubbleRows}</ScrollingContent>
            )}
          </ScrollingContainer>
        </FormItem>
      </FilterSectionStyled>
    </FilterSectionContainterStyled>
  );
};
export default FilterProjects;

