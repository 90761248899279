import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Loading from "@hellodarwin/core/lib/components/loading";
import { GrantResult } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Search from "@hellodarwin/icons/dist/icons/Search";
import Input from "antd/es/input";
import { useEffect, useState } from "react";
import { useAdminApi } from "../../features/api/use-admin-api";
import useDebounce from "../../hooks/use-debounce";
import theme from "../../theme";

type GrantsSearchProps = {
  handleSubmit: (grant: GrantResult) => void;
};

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const GrantsSearch = ({ handleSubmit }: GrantsSearchProps) => {
  const api = useAdminApi();
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<GrantResult[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const { selectedLocale } = useLocale();

  const onSearch = (query: string) => {
    if (query === "") return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.searchGrants(
          DEFAULT_PAGE,
          DEFAULT_LIMIT,
          query,
          selectedLocale
        );
        if (!response || !response.length) {
          setResults(undefined);
        } else {
          setResults(response);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const clearSearch = () => {
    setSearchTerm("");
    setResults(undefined);
  };

  const handleResultClick = (grant: GrantResult) => {
    handleSubmit(grant);
    clearSearch();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div style={{ position: "relative" }}>
      <Div flex="row" gap={16}>
        <Div flex="row" align="center" justify="space-between" gap={16}>
          <Search width={24} height={24} />
          <Input
            variant="borderless"
            value={searchTerm}
            placeholder="Search... (grant title, description)"
            onChange={(e) => {
              e.stopPropagation();
              setSearchTerm(e.target.value);
            }}
            style={{
              fontSize: 24,
              borderBottom: `2px solid ${theme.colors.grey_4}`,
              borderRadius: 0,
              width: "100%",
            }}
            onFocus={(e) => {
              e.target.style.borderBottom = `2px solid ${theme.colors.purple_1}`;
            }}
            onBlur={(e) => {
              e.target.style.borderBottom = `2px solid ${theme.colors.grey_4}`;
            }}
          />
          <Div fitContent>
            {isSearching ? (
              <Loading />
            ) : (
              searchTerm !== "" && (
                <Div onClick={clearSearch} style={{ cursor: "pointer" }}>
                  <Close width={14} />
                </Div>
              )
            )}
          </Div>
        </Div>
      </Div>
      {!!results && (
        <Div
          flex="row"
          wrap="wrap"
          gap={8}
          backgroundColor={theme.colors.white_1}
          borderColor={theme.colors.primary}
          style={{
            padding: "24px 40px 24px 16px",
            position: "absolute",
            top: "calc(100% + 16px)",
            width: "90%",
            left: "5%",
            zIndex: "1000",
          }}
        >
          {results?.map((grant) => (
            <HdTag
              text={
                grant.grant_title
                  ? grant.grant_title.length > 50
                    ? `${grant.grant_title?.substring(0, 50)}...`
                    : grant.grant_title
                  : ""
              }
              hoverable
              color={theme.colors.purple_2}
              onClick={(e) => {
                e.stopPropagation();
                handleResultClick(grant);
              }}
            />
          ))}
        </Div>
      )}
    </div>
  );
};

export default GrantsSearch;

