import { FormInstance } from "antd/es/form/hooks/useForm";
import { useCallback } from "react";
import { Company } from "../../../../features/entities/core-entities";
import {
  GrantProject,
  NewTag,
} from "../../../../features/entities/grants-entities";
import useDebounceFunction from "../../../../features/hooks/use-debounce-function";
import { usePagination } from "../../../../features/providers/pagination-provider";
import CheckCompanyFilters from "./check-company-values";
import usePrepareFundingExplorerCompanyFilters from "./prepare-company-filters";
import usePrepareFundingExplorerFilters from "./prepare-filters";
import usePrepareFundingExplorerURL from "./prepare-url";

const FILTER_DELAY_MS = 500;

export interface QueryFundingExplorerProps {
  locale: string;
  page: number;
  limit: number;
  textQuery?: string;
  companyId?: string;
  filterBy?: string;
  industry?: string[];
  subindustry?: string[];
  status?: string[];
  financingType?: string[];
  region?: string[];
  closingDate?: Date;
  service?: string[];
  selectedProjects?: string[];
  annualRevenue?: string;
  companySize?: string;
}

interface ApplyFundingExplorerFilterProps {
  type: "default" | "init" | "companyInit" | "reset" | "filterChanged";
}

export type ApplyFundingExplorerFilterFunction = (
  props?: ApplyFundingExplorerFilterProps
) => Promise<void>;

interface UseApplyFundingExplorerFilterProps {
  fetch: () => void;
  form: FormInstance;
  selectedIndustries: string[];
  setSelectedIndustries: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedProjects: React.Dispatch<React.SetStateAction<GrantProject[]>>;
  company?: Company;
  showCompanyFilterCardState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];

  setSelectedSectors?: React.Dispatch<React.SetStateAction<NewTag[]>>;
  setSelectedActivityTypes?: React.Dispatch<React.SetStateAction<NewTag[]>>;
  setSelectedEligibleExpenses?: React.Dispatch<React.SetStateAction<NewTag[]>>;
  setSelectedActivities?: React.Dispatch<React.SetStateAction<NewTag[]>>;
}

const COMPANY_IS_INITIAL_VALUES_KEY = "initialValues";

const useApplyFundingExplorerFilter = ({
  fetch,
  form,
  selectedIndustries,
  setSelectedIndustries,
  setSelectedProjects,
  company,
  showCompanyFilterCardState,

  setSelectedActivities,
  setSelectedActivityTypes,
  setSelectedSectors,
  setSelectedEligibleExpenses,
}: UseApplyFundingExplorerFilterProps) => {
  const paginationContext = usePagination();
  const { activePage, pageSize } = paginationContext;

  const prepareFundingExplorerURL = usePrepareFundingExplorerURL({
    form,
    selectedIndustries,
  });

  const prepareFundingExplorerFilters = usePrepareFundingExplorerFilters({
    form,
    setSelectedIndustries,
  });

  const prepareFundingExplorerCompanyFilters =
    usePrepareFundingExplorerCompanyFilters({
      form,
      setSelectedIndustries,
      company,
    });

  const checkCompanyAreInFilter = CheckCompanyFilters({
    form,
    company,
    showCompanyFilterCardState,
  });

  return useDebounceFunction(
    useCallback(
      async (props?: ApplyFundingExplorerFilterProps) => {
        const {
          clearAllParams,
          addParam,

          activePage,
          getParam,
        } = paginationContext;
        const applyType = props?.type ?? "default";

        const setShowCompanyFilterCard = !!showCompanyFilterCardState
          ? showCompanyFilterCardState[1]
          : undefined;

        var pageFilter = applyType === "filterChanged" ? 1 : activePage;

        let initialCompanyValues =
          getParam(COMPANY_IS_INITIAL_VALUES_KEY) === "true";

        const resetArrays = () => {
          setSelectedProjects([]);
          setSelectedIndustries([]);

          if (!!setSelectedSectors) setSelectedSectors([]);
          if (!!setSelectedActivityTypes) setSelectedActivityTypes([]);
          if (!!setSelectedActivities) setSelectedActivities([]);
          if (!!setSelectedEligibleExpenses) setSelectedEligibleExpenses([]);
        };

        switch (applyType) {
          case "companyInit":
            resetArrays();
            form.resetFields();

            prepareFundingExplorerCompanyFilters();
            prepareFundingExplorerURL(0);
            initialCompanyValues = true;
            break;
          case "reset":
            resetArrays();
            form.resetFields();

            clearAllParams(0, pageSize);

            initialCompanyValues = false;
            break;
          case "init":
            prepareFundingExplorerFilters();
            break;
          case "filterChanged":
            prepareFundingExplorerURL(pageFilter);
            initialCompanyValues = false;
          default:
            prepareFundingExplorerURL(pageFilter);
            break;
        }

        addParam(COMPANY_IS_INITIAL_VALUES_KEY, `${initialCompanyValues}`);

        const companyIsInFilter = checkCompanyAreInFilter();
        if (!!setShowCompanyFilterCard)
          setShowCompanyFilterCard(companyIsInFilter);

        fetch();
      },
      [
        selectedIndustries,
        company,
        showCompanyFilterCardState,
        setSelectedIndustries,
        fetch,
        form,
        paginationContext,
        prepareFundingExplorerURL,
        prepareFundingExplorerFilters,
        prepareFundingExplorerCompanyFilters,
        checkCompanyAreInFilter,
        activePage,
        pageSize,
      ]
    ),
    FILTER_DELAY_MS
  ) as ApplyFundingExplorerFilterFunction;
};

export default useApplyFundingExplorerFilter;

