import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { TabsProps } from "antd/es/tabs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import GrantApplicationsList from "../../components/grants/grant-applications-list";
import GrantProvidersList from "../../components/grants/grant-providers-list";
import GrantsList from "../../components/grants/grants-list";
import {
  selectActiveTab,
  setActiveTab,
} from "../../features/api/slices/grants-slice";
type GrantsPageTab = "grants" | "grants-providers" | "grants-applications";

const GrantsPage = ({ defaultKey }: { defaultKey?: GrantsPageTab }) => {
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();

  const tabs: TabsProps["items"] = [
    { key: "grants", label: "Grants", children: <GrantsList /> },
    {
      key: "grants-providers",
      label: "Grants Providers",
      children: <GrantProvidersList />,
    },
    {
      key: "grants-applications",
      label: "Grant Applications",
      children: <GrantApplicationsList />,
    },
  ];

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab("grants"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!!defaultKey) {
      dispatch(setActiveTab(defaultKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKey]);

  const navigate = useNavigate();
  const handleTabChange = (key: string) => {
    switch (key) {
      case "grants":
        navigate("/grants");
        break;
      case "grants-providers":
        navigate("/grants/providers");
        break;
      case "grants-applications":
        navigate("/grants/applications");
        break;
      default:
        break;
    }
    dispatch(setActiveTab(key));
  };

  return (
    <PageLayout
      app="admin"
      title="Grants"
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants",
        },
      ]}
    >
      <Container
        activeTab={activeTab}
        tabs={tabs}
        handleTabClick={handleTabChange}
        fullWidth
      />
    </PageLayout>
  );
};

export default GrantsPage;

