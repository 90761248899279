import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Search = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2222 30.2222L22.01 22.01M22.01 22.01C24.2327 19.7874 25.4813 16.7728 25.4813 13.6296C25.4813 10.4863 24.2327 7.47172 22.01 5.24908C19.7874 3.02644 16.7728 1.77777 13.6296 1.77777C10.4863 1.77777 7.47172 3.02644 5.24908 5.24908C3.02644 7.47172 1.77777 10.4863 1.77777 13.6296C1.77777 16.7728 3.02644 19.7874 5.24908 22.01C7.47172 24.2327 10.4863 25.4813 13.6296 25.4813C16.7728 25.4813 19.7874 24.2327 22.01 22.01Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Search;

