import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { ProviderSearchResult } from "@hellodarwin/core/lib/features/entities";
import {
  getFormattedDate,
  getPaginationTotal,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import Table, { ColumnsType } from "antd/es/table";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Clipboard from "../components/clipboard";
import { useActionBar } from "../context/action-bar-provider";
import { useAdminApi } from "../features/api/use-admin-api";
import { getStatusColor } from "../features/utils";
import ErrorPage from "./error-page";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

type PageState = {
  providers: ProviderSearchResult[];
  pagination: {
    page: number;
    size: number;
  };
};

const pageStateInitialValues: PageState = {
  providers: [],
  pagination: {
    page: DEFAULT_PAGE,
    size: DEFAULT_LIMIT,
  },
};

const ProvidersPage = () => {
  const { setActionBar } = useActionBar();

  const navigate = useNavigate();
  const api = useAdminApi();
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    query: string,
    loading: boolean,
    searching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: searching,
        isErrored: null,
      });
      try {
        const response = await api.queryProviders(page, size, query);
        const newPageState = {
          providers: response,
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, "", true, false);
    setActionBar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, "", true, false);
  };

  if (apiState.isErrored) {
    return <ErrorPage />;
  }

  const columns: ColumnsType<ProviderSearchResult> = [
    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      render: (v) => <Clipboard copy={v} title={getShortId(v)} />,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      sorter: {
        compare: (a, b) => a.company_name.localeCompare(b.company_name),
        multiple: 4,
      },
    },
    {
      title: "Company Email",
      dataIndex: "company_email",
      key: "company_email",
      sorter: {
        compare: (a, b) => a.company_email.localeCompare(b.company_email),
        multiple: 3,
      },
      ellipsis: {
        showTitle: false,
      },
      render: (v) => <Tooltip title={v}>{v}</Tooltip>,
    },
    {
      title: "Status",
      dataIndex: "provider_status",
      key: "provider_status",
      render: (v) => <Tag color={getStatusColor(v)}>{v}</Tag>,
      sorter: {
        compare: (a, b) => a.provider_status.localeCompare(b.provider_status),
        multiple: 1,
      },
    },
    {
      title: "Subscription",
      dataIndex: "provider_subscription_status",
      key: "provider_subscription_status",
      render: (v) => <Tag color={getStatusColor(v)}>{v}</Tag>,
      sorter: {
        compare: (a, b) =>
          a.provider_subscription_status.localeCompare(
            b.provider_subscription_status
          ),
        multiple: 1,
      },
    },
    {
      title: "Created at",
      dataIndex: "provider_created_at",
      key: "provider_created_at",
      sorter: {
        compare: (a, b) =>
          new Date(a.provider_created_at).getTime() -
          new Date(b.provider_created_at).getTime(),
      },
      render: (v) => <Tooltip title={v}>{getFormattedDate(v)}</Tooltip>,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return (
    <PageLayout
      app="admin"
      title={"Providers"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Providers",
        },
      ]}
    >
      <Table
        dataSource={pageState.providers}
        columns={columns}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: getPaginationTotal(
            pageState.pagination.page,
            pageState.pagination.size,
            pageState.providers.length
          ),
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => {
              navigate(`/providers/${record.provider_id}`);
            },
          };
        }}
      />
    </PageLayout>
  );
};

export default ProvidersPage;

