import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

export interface ProgramEmptyStateStepCardProps {
  Icon: IconNode;
  title: string;
  text: string;
}

const ProgramEmptyStateStepCard = ({
  Icon,
  title,
  text,
}: ProgramEmptyStateStepCardProps) => {
  const theme = useTheme();
  return (
    <Div
      flex="column"
      gap={17}
      align="center"
      style={{
        padding: 20,
        flexShrink: 0,
        flexGrow: 0,
        flex: 1,
        width: 320,
        height: "100%",
      }}
      borderRadius={4}
      backgroundColor={theme.colors.white_1}
      borderColor={theme.colors.grey_4}
    >
      <Icon />

      <Typography.Title level={4} elementTheme="h6" textAlign="center">
        {title}
      </Typography.Title>
      <Typography elementTheme="body2" textAlign="center">
        {text}
      </Typography>
    </Div>
  );
};

export default ProgramEmptyStateStepCard;
