import Checkbox from "antd/es/checkbox";
import Divider from "antd/es/divider";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Select, { SelectProps } from "antd/es/select";
import { FormItemEntity } from "../../../../features/entities/form-entities";
import theme from "../../../../theme";
import AddressAutocompleteInput from "../../form-groups/adress-autocomplete-input";
import SelectCountry from "../../form-groups/country-select";
import GenderSelect from "../../form-groups/gender-select";
import SelectProvince from "../../form-groups/province-select";
import Uploader from "../../form-groups/uploader";

export const FORM_PREBUILT_COMPONENTS_TYPES: FormItemEntity[] = [
  {
    key: "province",
    label: "Province / State Select",
    content: <SelectProvince.Demo />,
  },
  { key: "gender", label: "Gender Select", content: <GenderSelect.Demo /> },
  { key: "country", label: "Country Select", content: <SelectCountry.Demo /> },
  {
    key: "address",
    label: "Address Autocomplete Input",
    content: <AddressAutocompleteInput.Demo />,
  },
  {
    key: "uploader",
    label: "Uploader",
    content: <Uploader.Demo />,
  },
];

export const FORM_MAIN_TYPES: FormItemEntity[] = [
  {
    key: "page",
    label: "New page",
    content: (
      <Divider style={{ borderColor: theme.colors.primary }}>New Page</Divider>
    ),
  },
];

export const FORM_GROUPS_TYPES: FormItemEntity[] = [
  {
    key: "group",
    label: "Group",
    content: (
      <Divider style={{ borderColor: theme.colors.primary }}>Group</Divider>
    ),
  },
];

export const FORM_ITEMS_TYPES: FormItemEntity[] = [
  {
    key: "text",
    label: "Text",
    content: <TextArea />,
  },
  {
    key: "shortText",
    label: "Short Text",
    content: <Input />,
  },
  {
    key: "datetime",
    label: "Date",
    content: <Input type="date" />,
  },
  {
    key: "select",
    label: "Select",
    content: <Select />,
  },
  {
    key: "radio",
    label: "Radio",
    content: <Radio />,
  },
  {
    key: "checkbox",
    label: "Checkbox",
    content: <Checkbox />,
  },
];

export const FormItemOptions: SelectProps["options"] = [
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Select", value: "select" },
  { label: "Text", value: "text" },
  { label: "Short Text", value: "shortText" },
  { label: "Date", value: "datetime" },
];

