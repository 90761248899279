import Divider from "antd/es/divider";
import Form, { FormInstance, FormProps } from "antd/es/form";
import React, { useEffect, useState } from "react";
import { AppType } from "../../../../features/entities/core-entities";
import { EditableFormSubmissionResponse } from "../../../../features/entities/form-entities";
import theme from "../../../../theme";
import Container from "../../../common/container";
import Div from "../../../common/div";
import { FormData, MappedSubmissionItems } from "../types";
import FormSubmissionEditorPage from "./layouts/form-submission-page";
import getFormSubmissionData from "./utils/get-form-submission-data";
import getSubmissionActivePages from "./utils/get-submission-active-pages";

interface FormSubmissionEditorProps {
  formSubmission: EditableFormSubmissionResponse;
  handleChange: () => void;
  mappedSubmissionItems: MappedSubmissionItems;
  form: FormInstance<FormData>;
  source: AppType;
}

const FormSubmissionEditor = ({
  formSubmission,
  handleChange,
  mappedSubmissionItems,
  form,
  source,
}: FormSubmissionEditorProps) => {
  const initialFormData = getFormSubmissionData({ formSubmission });
  const [formData, setFormData] = useState<FormData>(initialFormData);

  useEffect(() => {
    setFormData(initialFormData);
    form.setFieldsValue(initialFormData);
  }, [initialFormData, form]);

  const activePages = getSubmissionActivePages({
    formSubmission,
    formData,
    mappedSubmissionItems,
  });

  const onFormChange: FormProps["onFieldsChange"] = (changedFields) => {
    if (source === "admin") {
      const newValues = changedFields.reduce(
        (o, itm) => ({ ...o, [itm.name]: itm.value }),
        {}
      );
      setFormData((prevData) => ({ ...prevData, ...newValues }));
    }

    handleChange();
  };

  return (
    <Div flex="column" gap={24}>
      <Container>
        <Form
          initialValues={formData}
          form={form}
          onFieldsChange={onFormChange}
        >
          {React.Children.toArray(
            activePages?.map((page, i) => (
              <>
                <FormSubmissionEditorPage page={page} />
                {i < activePages.length - 1 && (
                  <Divider
                    style={{
                      borderColor: theme.colors.grey_1,
                      margin: "24px 0",
                    }}
                  />
                )}
              </>
            ))
          )}
        </Form>
      </Container>
    </Div>
  );
};

export default FormSubmissionEditor;

