import styled from "../../../../../plugins/styled";
import Typography from "../../../../common/typography";

export const GrantProjectCardContainer = styled.div<{ $open?: boolean }>`
  width: 320px;
  min-height: 185px;
  max-height: ${({ $open }) => ($open ? "auto" : "185px")};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:hover {
    transform: scale(1.01);
  }
`;
export const GrantProjectCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const GrantProjectCardContent = styled(Typography)``;

