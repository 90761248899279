import { useMemo } from "react";
import { AppName } from "../../../features/entities/core-entities";
import { HubspotMeetingContainer } from "./styles";

export interface HubspotMeetingProps {
  meetingUrl: string;
  app: AppName;
  email?: string;
  phone?: string;
  name?: string;
  companyName?: string;
  additionalParams?: { [key: string]: string };
}
const HubspotMeeting = ({
  meetingUrl,
  app,
  additionalParams,
  ...props
}: HubspotMeetingProps) => {
  const meetingUrlWithProps = useMemo(() => {
    const allProps = { ...props, ...additionalParams };
    let params = "";
    Object.keys(allProps).forEach((key, i) => {
      const value = allProps[key];
      if (!!value) {
        const separator = i > 0 ? "&" : "";
        if (key === "name") {
          const names: string[] = value.split(" ");
          const last_name = names.pop();
          const first_name = names.join(" ");

          params += `${separator}firstName=${first_name}&lastName=${last_name}`;
        } else {
          params += `${separator}${key}=${value}`;
        }
      }
    });

    const separator = meetingUrl.includes("?") ? "&" : "?";

    const url = `${meetingUrl}${separator}${params}`;

    return url;
  }, [props, additionalParams]);

  return (
    <HubspotMeetingContainer>
      <iframe
        src={meetingUrlWithProps}
        width="100%"
        height="650"
        allowTransparency
      ></iframe>
    </HubspotMeetingContainer>
  );
};

export default HubspotMeeting;

