import Modal from "antd/es/modal";
interface DiscountProjectModalProps {
  modalVisible: boolean;
  closeModal: () => void;
  sendDiscountNotification: () => void;
}

const ProjectDiscountModal = ({
  modalVisible,
  closeModal,
  sendDiscountNotification,
}: DiscountProjectModalProps) => {
  return (
    <Modal
      title="Project Discounted!"
      open={modalVisible}
      onOk={() => sendDiscountNotification()}
      onCancel={closeModal}
      okText={"Send notification"}
      okType={"primary"}
      cancelText={"Don't send notification"}
    >
      <p>
        Do you want to notify all service providers that matched with this
        project?
      </p>
    </Modal>
  );
};
export default ProjectDiscountModal;
