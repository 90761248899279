import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Invoices = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 1.59998H6.47999C5.55263 1.59998 4.79999 2.34434 4.79999 3.26151V28.7384C4.79999 29.6556 5.55263 30.4 6.47999 30.4H25.52C26.4473 30.4 27.2 29.6556 27.2 28.7384V15.4461M13.2 1.59998H13.76C17.3245 1.59998 20.743 3.00041 23.2635 5.4932C25.784 7.98599 27.2 11.3669 27.2 14.8923V15.4461M13.2 1.59998C14.5367 1.59998 15.8186 2.12514 16.7638 3.05994C17.709 3.99473 18.24 5.26259 18.24 6.58459V8.79998C18.24 9.71714 18.9926 10.4615 19.92 10.4615H22.16C23.4967 10.4615 24.7786 10.9867 25.7238 11.9215C26.669 12.8563 27.2 14.1241 27.2 15.4461M16 13.0461V26.3384M13.0133 23.217L13.8884 23.9469C15.0542 24.9206 16.9448 24.9206 18.1116 23.9469C19.2784 22.9733 19.2784 21.3959 18.1116 20.4223C17.5292 19.9349 16.7646 19.6923 16 19.6923C15.2782 19.6923 14.5564 19.4486 14.0059 18.9623C12.9048 17.9887 12.9048 16.4113 14.0059 15.4376C15.107 14.464 16.893 14.464 17.9941 15.4376L18.4072 15.8032"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Invoices;

