import React, { useState } from "react";
import { GrantProject } from "../../../../../features/entities/grants-entities";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import GrantProjectCard from "./grant-project-card";

const GrantProjectsList = ({
  grant_projects,
}: {
  grant_projects: GrantProject[];
}) => {
  const { t } = useTranslations();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <Div flex="column" gap={24}>
      <Typography elementTheme="h6">
        {t("grant_single|admissibleProjectsExample")}
      </Typography>
      <Div flex="row" wrap="wrap" gap={16}>
        {React.Children.toArray(
          grant_projects.map((project) => (
            <GrantProjectCard
              project={project}
              handleOpen={handleOpen}
              open={open}
            />
          ))
        )}
      </Div>
    </Div>
  );
};

export default GrantProjectsList;

