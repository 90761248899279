import styled from "@hellodarwin/core/lib/plugins/styled";
import Layout from "antd/es/layout";

export const ChatsHistorySider = styled(Layout.Sider)`
  height: 100%;
  overflow: auto;

  .ant-layout-sider-trigger {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-size: cover;
    border: none;
  }

  &.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    right: 0;
    transform: translateY(-50%) rotate(180deg);
  }
`;

