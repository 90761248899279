import {
  ApiError,
  ApiErrorInitialState,
  CompanyQuickInfos,
  Contact,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const contactsAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

const companiesQuickInfoAdapter = createEntityAdapter({
  selectId: (model: CompanyQuickInfos) => model?.company_id || "",
});

export interface UserState {
  status: "idle" | "pending";
  companyCardStatus: "idle" | "pending";
  error: ApiError;
  contacts: EntityState<Contact, string>;
  providerContacts: EntityState<Contact, string>;

  companyHeader: EntityState<CompanyQuickInfos, string>;
}

const initialState: UserState = {
  status: "idle",
  companyCardStatus: "idle",
  error: ApiErrorInitialState,
  contacts: contactsAdapter.getInitialState(),
  providerContacts: contactsAdapter.getInitialState(),
  companyHeader: companiesQuickInfoAdapter.getInitialState(),
};

export const fetchCompanyContacts = createAsyncThunk<
  Contact[],
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchCompanyContacts",
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchCompanyContacts(companyId);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProviderContacts = createAsyncThunk<
  Contact[],
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProviderContacts",
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchCompanyContacts(companyId);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchContact = createAsyncThunk<
  Contact,
  { api: AdminApiClient; contactId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchContact",
  async (
    { api, contactId }: { api: AdminApiClient; contactId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchContact(contactId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateContact = createAsyncThunk<
  Contact,
  { api: AdminApiClient; contact: Contact },
  { rejectValue: ApiError }
>(
  "admin/updateCompanyContact",
  async (
    { api, contact }: { api: AdminApiClient; contact: Contact },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateContact(contact);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContact = createAsyncThunk<
  string,
  { api: AdminApiClient; contactId: string },
  { rejectValue: ApiError }
>(
  "admin/deleteCompanyContact",
  async (
    { api, contactId }: { api: AdminApiClient; contactId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteContact(contactId);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createContact = createAsyncThunk<
  Contact,
  { api: AdminApiClient; contact: Contact; destination: string },
  { rejectValue: ApiError }
>(
  "admin/addCompanyContact",
  async (
    {
      api,
      contact,
      destination,
    }: { api: AdminApiClient; contact: Contact; destination: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.createContact(contact, destination);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const assignContact = createAsyncThunk<
  Contact,
  { api: AdminApiClient; contact: Contact },
  { rejectValue: ApiError }
>(
  "admin/assignContact",
  async (
    { api, contact }: { api: AdminApiClient; contact: Contact },
    { rejectWithValue }
  ) => {
    try {
      return await api.assignContact(contact);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContactByEmail = createAsyncThunk<
  Contact,
  { api: AdminApiClient; email: string },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/getContactByEmail",
  async (
    { api, email }: { api: AdminApiClient; email: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getContactByEmail(email);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCompanyBillingId = createAsyncThunk<
  string,
  { api: AdminApiClient; companyId: string; billingId: string },
  { rejectValue: ApiError }
>(
  "admin/updateBillingId",
  async (
    {
      api,
      companyId,
      billingId,
    }: { api: AdminApiClient; companyId: string; billingId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateCompanyBillingId(companyId, billingId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCompanyQuickInfos = createAsyncThunk<
  CompanyQuickInfos,
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchClientQuickInfos",
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchCompanyQuickInfos(companyId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyContacts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCompanyContacts.fulfilled, (state, { payload }) => {
      contactsAdapter.setAll(state.contacts, payload);
      state.status = "idle";
    });
    builder.addCase(fetchCompanyContacts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchProviderContacts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProviderContacts.fulfilled, (state, { payload }) => {
      contactsAdapter.setAll(state.providerContacts, payload);
      state.status = "idle";
    });
    builder.addCase(fetchProviderContacts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchContact.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchContact.fulfilled, (state, { payload }) => {
      contactsAdapter.upsertOne(state.contacts, payload);
      state.status = "idle";
    });
    builder.addCase(fetchContact.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createContact.pending, (state) => {
      state.status = "pending";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(createContact.fulfilled, (state, { payload }) => {
      contactsAdapter.addOne(state.contacts, payload);
      state.status = "idle";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(createContact.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ? payload : ApiErrorInitialState;
    });
    builder.addCase(assignContact.pending, (state) => {
      state.status = "pending";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(assignContact.fulfilled, (state, { payload }) => {
      contactsAdapter.addOne(state.contacts, payload);
      state.status = "idle";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(assignContact.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ? payload : ApiErrorInitialState;
    });
    builder.addCase(updateContact.pending, (state) => {
      state.status = "pending";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      contactsAdapter.upsertOne(state.contacts, payload);
      state.status = "idle";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(updateContact.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ? payload : ApiErrorInitialState;
    });
    builder.addCase(deleteContact.pending, (state) => {
      state.status = "pending";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(deleteContact.fulfilled, (state, { payload }) => {
      contactsAdapter.removeOne(state.contacts, payload);
      state.status = "idle";
      state.error = ApiErrorInitialState;
    });
    builder.addCase(deleteContact.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ? payload : ApiErrorInitialState;
    });
    builder.addCase(fetchCompanyQuickInfos.pending, (state) => {
      state.companyCardStatus = "pending";
    });
    builder.addCase(fetchCompanyQuickInfos.fulfilled, (state, { payload }) => {
      if (payload) {
        companiesQuickInfoAdapter.upsertOne(state.companyHeader, payload);
      }
      state.companyCardStatus = "idle";
    });
    builder.addCase(fetchCompanyQuickInfos.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.companyCardStatus = "idle";
    });
  },
});

export const { selectAll: selectAllContacts, selectById: selectContactById } =
  contactsAdapter.getSelectors((state: RootState) => state.users.contacts);
export const {
  selectAll: selectAllProviderContacts,
  selectById: selectProviderContactById,
} = contactsAdapter.getSelectors(
  (state: RootState) => state.users.providerContacts
);

export const {
  selectAll: selectCompanyHeader,
  selectById: selectCompanyHeaderById,
} = companiesQuickInfoAdapter.getSelectors(
  (state: RootState) => state.users.companyHeader
);

export const selectCompanyContacts = createSelector(
  [selectAllContacts, (state: RootState, companyId: string) => companyId],
  (contacts: Contact[], companyId: string) =>
    contacts.filter((contact) => contact.company_id === companyId)
);

export const selectProviderContacts = createSelector(
  [
    selectAllProviderContacts,
    (state: RootState, companyId: string) => companyId,
  ],
  (contacts: Contact[], companyId: string) =>
    contacts.filter((contact) => contact.company_id === companyId)
);

export const selectError = (state: RootState) => state.users.error.error_code;

export const selectIsLoading = (state: RootState) =>
  state.users.status === "pending";

export const selectCompanyCardIsLoading = (state: RootState) =>
  state.users.companyCardStatus === "pending";

export const usersReducer = usersSlice.reducer;

