import ReviewFormModal from "@hellodarwin/core/lib/components/reviews/review/review-form-modal";
import { Match } from "@hellodarwin/core/lib/features/entities/matches-entities";
import { ProjectUpdateFunctions } from "@hellodarwin/core/lib/features/entities/project-entities";
import { useAdminApi } from "../../features/api/use-admin-api";

interface LeaveReviewModalProps {
  selectedMatch: Match;
  open: boolean;
  handleClose: () => void;
}
const LeaveReviewModal = ({
  selectedMatch,
  open,
  handleClose,
}: LeaveReviewModalProps) => {
  const api = useAdminApi();

  const editionFunctions: ProjectUpdateFunctions = {
    review: async (_, __, reviewRequest, ___) => {
      await api.reviewProvider({
        ...reviewRequest,
        review: {
          ...reviewRequest.review,
          verified: "Verified",
          review_source: "Admin App",
        },
      });
    },
  };

  return (
    <ReviewFormModal
      open={open}
      project_id={selectedMatch.project_id}
      provider={selectedMatch.provider}
      matchId={selectedMatch.match_id}
      handleClose={handleClose}
      editionFunctions={editionFunctions}
    />
  );
};

export default LeaveReviewModal;

