import styled from "../../../plugins/styled";
import Div from "../../common/div";

export const GrantSingleContainer = styled(Div)`
  a {
    text-decoration: none;
  }
  p + ul {
    margin-top: 16px;
  }

  ul,
  p {
    margin-block: 1em;
    margin: 0;
    font-size: ${(props) => props.theme?.fonts?.body1};
    line-height: ${(props) => props.theme?.fontsLineHeights?.body1};
    letter-spacing: ${(props) => props.theme?.letterSpacing?.body1};
    @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
      font-size: ${(props) => props.theme?.fonts?.body1_md};
      line-height: ${(props) => props.theme?.fontsLineHeights?.body1_md};
      letter-spacing: ${(props) => props.theme?.letterSpacing?.body1_md};
    }
  }
  p:first-child,
  ul:first-child {
    margin-block-start: 0;
  }

  h3 {
    font-family: Figtree;
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${(props) => props.theme?.fonts?.h6};
    line-height: ${(props) => props.theme?.fontsLineHeights?.h6};
    letter-spacing: ${(props) => props.theme?.letterSpacing?.h6};
    @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
      font-size: ${(props) => props.theme?.fonts?.h6_md};
      line-height: ${(props) => props.theme?.fontsLineHeights?.h6_md};
      letter-spacing: ${(props) => props.theme?.letterSpacing?.h6_md};
    }
  }
  h4 {
    font-family: Figtree;
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${(props) => props.theme?.fonts?.subtitle1};
    line-height: ${(props) => props.theme?.fontsLineHeights?.subtitle1};
    letter-spacing: ${(props) => props.theme?.letterSpacing?.subtitle1};
    @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
      font-size: ${(props) => props.theme?.fonts?.subtitle1_md};
      line-height: ${(props) => props.theme?.fontsLineHeights?.subtitle1_md};
      letter-spacing: ${(props) => props.theme?.letterSpacing?.subtitle1_md};
    }
  }
`;
