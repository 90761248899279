
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import { GrantContentRaw } from "@hellodarwin/core/lib/features/entities";
import Refresh from "@hellodarwin/icons/dist/icons/Refresh";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  createGrantContentRaw,
  fetchGrantContentRaw,
  selectGrantContentRaw,
  updateGrantContentRaw,
} from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import { AdminPagesForms } from "../single-project-page";
const { TextArea } = Input;

export type GrantContentRawFormValues = {
  content: string;
  locale: string;
};

const SingleGrantContextPage = () => {
  const [form] = Form.useForm<GrantContentRawFormValues>();
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [locale, setLocale] = useState("en");
  const [isGenerating, setIsGenerating] = useState(false);

  const params = useParams<{
    id: string;
  }>();
  const grantContentRaw = useAppSelector((state) =>
    selectGrantContentRaw(state, params.id || "")
  );
  const [forms, setForms] = useState<AdminPagesForms>({
    grantContentRawForm: undefined,
  });

  useEffect(() => {});

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantContentRawForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrantContentRaw({ api, grantId: params.id, locale: locale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!params.id) return <Loading />;

  if (!grantContentRaw) {
    return <Loading />;
  }

  const onSave = async () => {
    try {
      const grantContentRawFormValues =
        forms.grantContentRawForm?.getFieldsValue()!;

      if (grantContentRawFormValues === undefined) {
        return;
      }

      const updatedGrantContentRaw: GrantContentRaw = {
        ...grantContentRaw,
        ...grantContentRawFormValues,
      };

      await dispatch(
        updateGrantContentRaw({ api, grantContentRaw: updatedGrantContentRaw })
      );

      message.success(
        `Saved Scraped Context ${updatedGrantContentRaw.locale}!`
      );
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const goToGrantSinglePage = () => {
    navigate(`/grants/${params.id}`);
  };

  const initialValues: GrantContentRawFormValues = {
    content: grantContentRaw.content || "",
    locale: grantContentRaw.locale || locale,
  };

  const changeScrapeContext = async (value: string) => {
    try {
      if (!params.id) {
        return;
      }
      setLocale(value);
      const newGrantContentRaw = await dispatch(
        fetchGrantContentRaw({
          api,
          grantId: params.id,
          locale: value,
        })
      ).unwrap();

      form.setFieldValue("content", newGrantContentRaw.content);
    } catch (e: any) {
      message.error("Error !");
    }
  };

  const generateNewScrapeContext = async () => {
    try {
      setIsGenerating(true);
      if (!params.id) {
        return;
      }
      const newGrantContentRaw = await dispatch(
        createGrantContentRaw({
          api,
          grantId: params.id,
          locale: locale,
        })
      ).unwrap();

      form.setFieldValue("content", newGrantContentRaw.content);
    } catch (e: any) {
      message.error("Error !");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Context"}
      title={"Context"}
      handleBack={goToGrantSinglePage}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants",
          path: "/grants",
        },
        {
          breadcrumbName: "Single Grant Page",
          path: `/grants/${params.id}`,
        },
        {
          breadcrumbName: "Scrape Context",
        },
      ]}
    >
      <Div flex="row" align="center" justify="space-between">
        <Div>
          <Button
            type="primary"
            onClick={onSave}
            size="large"
            color={theme.colors.purple_1}
            style={{ width: "150px" }}
          >
            Save
          </Button>
        </Div>
        <Div flex="row" align="center" justify="center">
          <Button
            onClick={() => generateNewScrapeContext()}
            loading={isGenerating}
            icon={
              <Refresh
                style={{ color: theme.colors.purple_1 }}
                width={14}
                height={14}
              />
            }
          >
            Generate Section Only
          </Button>
        </Div>
        <Div flex="row" align="center" justify="flex-end">
          <Radio.Group
            size={"large"}
            onChange={(e) => changeScrapeContext(e.target.value)}
            defaultValue="en"
            buttonStyle="solid"
          >
            <Space direction="horizontal">
              <Radio.Button value="en">English</Radio.Button>
              <Radio.Button value="fr">Français</Radio.Button>
            </Space>
          </Radio.Group>
        </Div>
      </Div>

      <Card style={{ padding: "1rem" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grantContentRaw"
        >
          <Form.Item label="Scrape Content" name="content">
            <TextArea autoSize style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Card>
    </PageLayout>
  );
};

export default SingleGrantContextPage;

