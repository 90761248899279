import React, { FC, ReactNode } from "react";
import { Draggable, DraggableProps } from "react-beautiful-dnd";

interface DragProps extends Omit<DraggableProps, "children"> {
  className?: string;
  children: ReactNode;
  dragAll?: boolean;
}

const Drag: FC<DragProps> = ({
  className,
  children,
  dragAll = true,
  ...props
}) => {
  if (!React.isValidElement(children)) return <></>;

  return (
    <Draggable {...props}>
      {(provided) => {
        const dragHandleProps = dragAll ? provided.dragHandleProps : {};
        return (
          <div
            {...dragHandleProps}
            {...provided.draggableProps}
            className={className}
            ref={provided.innerRef}
          >
            {React.cloneElement<any>(children, { provided })}
          </div>
        );
      }}
    </Draggable>
  );
};

export default Drag;

