import { StoreValue } from "@apollo/client";
import { RuleObject } from "antd/es/form";
import Select, { DefaultOptionType, SelectProps } from "antd/es/select";
import { useMemo } from "react";
import { useTranslations } from "../../../../features/providers/translations-provider";
import FormItem from "../../form-layouts/form-item";
import FormLabel from "../../form-layouts/form-label";

interface ProvincesSelectProps {
  name?: string;
  errorMessage?: string;
  isRequired?: boolean;
  isLongName?: boolean;
}

enum Provinces {
  ON = "ON",
  QC = "QC",
  NS = "NS",
  NB = "NB",
  MB = "MB",
  BC = "BC",
  PE = "PE",
  SK = "SK",
  AB = "AB",
  NL = "NL",
}

const ProvincesSelect = ({
  name = "province",
  errorMessage,
  isRequired,
  isLongName,
}: ProvincesSelectProps) => {
  const { t } = useTranslations();

  const options: SelectProps["options"] = useMemo(() => {
    return Object.keys(Provinces).map((province) => {
      const data: DefaultOptionType = {
        value: province,
        label: isLongName ? t(`province|${province}`) : province,
      };
      return data;
    });
  }, []);
  return (
    <FormItem
      style={{ flex: 1, margin: 0 }}
      name={name}
      label={<FormLabel label={t("province|province")} />}
      rules={[
        {
          required: isRequired,
          message: errorMessage ?? t("province|provinceRequired"),
          validator: (_: RuleObject, value: StoreValue) => {
            if (!isRequired) return Promise.resolve();

            return value === "QC"
              ? Promise.resolve()
              : Promise.reject(
                  new Error(errorMessage ?? t("province|provinceRequired"))
                );
          },
        },
      ]}
    >
      <Select size="large" style={{ width: "100%" }} options={options} />
    </FormItem>
  );
};

export default ProvincesSelect;

