import Div from "@hellodarwin/core/lib/components/common/div";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { ReactNode } from "react";
import { BlockContent } from "../../hooks/use-blocks";
import Block from "./block";

const BlocksList = ({
  actions,
  blocks,
  span,
}: {
  actions: ReactNode;
  blocks: BlockContent[];
  span: number;
}) => {
  return (
    <Div flex="column" gap={16}>
      {actions}
      <Row gutter={24}>
        {blocks
          .filter((b) => b.visible)
          .map((b, index) => {
            if (b.unique) {
              return (
                <Col
                  key={index}
                  span={span}
                  hidden={!b.visible}
                  style={{ flex: 1 }}
                >
                  {b.element}
                </Col>
              );
            }
            return (
              <Col
                key={index}
                span={span}
                hidden={!b.visible}
                style={{ flex: 1 }}
              >
                <Block
                  key={index}
                  title={b.label}
                  visible={b.visible}
                  span={span}
                >
                  {b.element}
                </Block>
              </Col>
            );
          })}
      </Row>
    </Div>
  );
};

export default BlocksList;
