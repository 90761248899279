import AppLayout from "@hellodarwin/core/lib/components/common/layout/app-layout";
import { useAppData } from "@hellodarwin/core/lib/features/providers/app-provider";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./app";
import AppRoutes from "./AppRoutes";
import MatchesActionModal from "./components/matches/matches-action-modal";
import ProgramsActionModal from "./components/programs/programs-action-modal";
import ProjectActionModal from "./components/projects/projects-action-modal";
import ProviderActionModal from "./components/providers/provider-action-modal";
import { useActionBar } from "./context/action-bar-provider";
import {
  me,
  selectAuthentifiedUser,
  selectIsLoading,
} from "./features/api/slices/global-slice";
import { useAdminApi } from "./features/api/use-admin-api";
import HeaderBar from "./layout/header-bar";
import Navigation from "./layout/navigation";
import UnverifiedAdminPage from "./pages/unverified-page";

const AdminApp = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const admin = useAppSelector(selectAuthentifiedUser);
  const { actionBar } = useActionBar();
  const { changeLanguage } = useLocale();
  const isLoading = useAppSelector(selectIsLoading);
  const isLoggedIn = useMemo(() => !!admin.admin_id, [admin]);

  const isAppLoading = useMemo(
    () => isLoading || !isLoggedIn,
    [isLoading, isLoggedIn]
  );

  useEffect(() => {
    if (isLoggedIn) {
      changeLanguage(admin.preferred_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(me({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appData = useAppData();
  useEffect(() => {
    const { setHeader, setNav } = appData;

    setHeader(<HeaderBar />);
    setNav(<Navigation />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (admin.roles === "Unverified") return <UnverifiedAdminPage />;

  return (
    <>
      <AppLayout app={"admin"} isLoading={isAppLoading}>
        <AppRoutes />
        <>{actionBar}</>
      </AppLayout>
      <ProjectActionModal />
      <ProviderActionModal />
      <MatchesActionModal />
      <ProgramsActionModal />
    </>
  );
};

export default AdminApp;

