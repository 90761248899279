import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchGinApplications,
  selectGinsIsLoading,
  selectSelectedGinApplications,
} from "../../../features/api/slices/gins-slice";
import { useNewAdminApi } from "../../../features/api/use-admin-api";
import { ProgramCard } from "./cards/application-card";

interface GinRelatedProgramsProps {
  grantId: string;
}

const GinRelatedPrograms = ({ grantId }: GinRelatedProgramsProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const { selectedLocale } = useLocale();

  const ginPrograms = useAppSelector(selectSelectedGinApplications);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, "relatedApplications")
  );

  useEffect(() => {
    dispatch(fetchGinApplications({ api, grantId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantId]);

  return (
    <Div flex="column" gap={16}>
      {isLoading ? (
        <Loading />
      ) : (
        ginPrograms?.map((program, index) => (
          <ProgramCard
            key={index}
            program={program}
            selectedLocale={selectedLocale}
          />
        ))
      )}
    </Div>
  );
};

export default GinRelatedPrograms;

