import { useMemo } from "react";
import { GinBlockConfig } from "../gin-single/gin-block";
import GinTextEditor from "../gin-single/gin-editor";
import GinStepCard from "../gin-single/gin-step-card";

export enum GinGrantSinglePageSection {
  BusinessAdmissibility = "business_eligibility",
  ActivitiesAdmissibility = "activity_eligibility",
  ExpenseAdmissibility = "expense_eligibility",
  Files = "files",
  FinancialAssistance = "financial_aid_terms",
  ProcessSummary = "process_summary",
  ProductionStages = "production_stages",
  Notes = "notes",
}

const useGinBlocks = () => {
  return useMemo(() => {
    const GinSectionsBlocks: { [section: string]: GinBlockConfig } = {
      [GinGrantSinglePageSection.BusinessAdmissibility]: {
        id: GinGrantSinglePageSection.BusinessAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ActivitiesAdmissibility]: {
        id: GinGrantSinglePageSection.ActivitiesAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ExpenseAdmissibility]: {
        id: GinGrantSinglePageSection.ExpenseAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.Files]: {
        id: GinGrantSinglePageSection.Files,
        Content(props) {
          return <></>;
        },
      },
      [GinGrantSinglePageSection.FinancialAssistance]: {
        id: GinGrantSinglePageSection.FinancialAssistance,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ProcessSummary]: {
        id: GinGrantSinglePageSection.ProcessSummary,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ProductionStages]: {
        id: GinGrantSinglePageSection.ProductionStages,
        Content: GinStepCard,
      },
    };

    return GinSectionsBlocks;
  }, []);
};

export default useGinBlocks;

