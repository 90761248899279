import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Unlocked = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0571 14.5143V9.37144C18.0571 7.73468 18.7074 6.16495 19.8647 5.00758C21.0221 3.85021 22.5918 3.20001 24.2286 3.20001C25.8653 3.20001 27.4351 3.85021 28.5924 5.00758C29.7498 6.16495 30.4 7.73468 30.4 9.37144V14.5143M4.68572 29.9429H19.0857C19.9041 29.9429 20.689 29.6178 21.2676 29.0391C21.8463 28.4604 22.1714 27.6755 22.1714 26.8572V17.6C22.1714 16.7816 21.8463 15.9968 21.2676 15.4181C20.689 14.8394 19.9041 14.5143 19.0857 14.5143H4.68572C3.86734 14.5143 3.08247 14.8394 2.50379 15.4181C1.92511 15.9968 1.60001 16.7816 1.60001 17.6V26.8572C1.60001 27.6755 1.92511 28.4604 2.50379 29.0391C3.08247 29.6178 3.86734 29.9429 4.68572 29.9429Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Unlocked;

