import { FormInstance } from "antd";
import { useCallback } from "react";
import { usePagination } from "../../../../features/providers/pagination-provider";

interface UsePrepareFundingExplorerFiltersProps {
  form: FormInstance;
  setSelectedIndustries: React.Dispatch<React.SetStateAction<string[]>>;
}

const usePrepareFundingExplorerFilters = ({
  form,
  setSelectedIndustries,
}: UsePrepareFundingExplorerFiltersProps) => {
  const { params } = usePagination();

  return useCallback(() => {
    const filterBy = params["filterBy"];
    const industry = params["industry"];
    const subindustry = params["subindustry"];
    const financingType = params["financingType"];
    const status = params["status"];
    const closingDate = params["closingDate"];
    const region = params["region"];
    const service = params["service"];
    const companySize = params["companySize"];
    const annualRevenue = params["annualRevenue"];

    if (!!filterBy?.length) {
      form.setFieldValue("filterBy", filterBy);
    }
    if (!!industry?.length) {
      const newIndustry = industry.split(",");
      form.setFieldValue("industry", newIndustry);
      setSelectedIndustries(newIndustry);
    }
    if (!!subindustry?.length) {
      form.setFieldValue("subindustry", subindustry.split(","));
    }
    if (!!service?.length) {
      form.setFieldValue("service", service.split(","));
    }
    if (!!financingType?.length) {
      form.setFieldValue("financingType", financingType.split(","));
    }
    if (!!status?.length) {
      form.setFieldValue("status", status.split(","));
    }
    if (!!closingDate) {
      form.setFieldValue("closingDate", closingDate);
    }
    if (!!region?.length) {
      form.setFieldValue("region", region.split(","));
    }
    if (!!companySize?.length) {
      form.setFieldValue("companySize", companySize);
    }
    if (!!annualRevenue?.length) {
      form.setFieldValue("annualRevenue", annualRevenue);
    }
  }, [params, form, setSelectedIndustries]);
};

export default usePrepareFundingExplorerFilters;

