import Div from "@hellodarwin/core/lib/components/common/div";
import RichTextEditor from "@hellodarwin/core/lib/components/common/rich-editor";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GinApplication } from "@hellodarwin/core/lib/features/entities";
import parse, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Empty from "antd/es/empty";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  clearSection,
  fetchSection,
  saveSection,
  selectContent,
} from "../../../features/api/slices/gin-sections-slice";
import {
  selectGinApplication,
  updateGinApplication,
} from "../../../features/api/slices/gins-slice";
import {
  useAdminApi,
  useNewAdminApi,
} from "../../../features/api/use-admin-api";
import { GinBlockComponentProps } from "./gin-block";

export const ginEditorParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === "a") {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="nofollow" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (domNode.attribs && domNode.name === "p") {
      return (
        <Typography elementTheme="body2">
          {domToReact(domNode.children)}
        </Typography>
      );
    }
    if (domNode.attribs && domNode.name === "li") {
      return (
        <li>
          <Typography elementTheme="body2">
            {domToReact(domNode.children)}
          </Typography>
        </li>
      );
    }
  },
};

const GinTextEditor = ({
  entityId,
  section,
  isEditing,
  setHandleSave,
  type,
}: GinBlockComponentProps) => {
  const content = useAppSelector((state) => selectContent(state, section));
  const application = useAppSelector(selectGinApplication);
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [editorContent, setEditorContent] = useState("");
  const { t } = useTranslations();
  useEffect(() => {
    dispatch(clearSection(section));
    if (type === "program") {
      dispatch(fetchSection({ api, grantId: entityId, section }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, section, type]);

  const textContent = useMemo(() => {
    if (type === "program" && !!content) {
      return content;
    } else if (type === "application") {
      const applicationContent = `${
        application[section as keyof GinApplication] ?? ""
      }`;
      return applicationContent;
    } else {
      return "";
    }
  }, [content, section, application, type]);

  useEffect(() => {
    setEditorContent(textContent);
  }, [textContent]);

  useEffect(() => {
    setHandleSave(() => {
      return () => {
        if (type === "program") {
          dispatch(
            saveSection({
              api,
              grantId: entityId,
              section,
              content: editorContent,
            })
          );
        } else {
          dispatch(
            updateGinApplication({
              api: newApi,
              updatedApplication: {
                ...application,
                [section]: editorContent,
              },
            })
          );
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, section, editorContent, type]);

  const handleEditorChange = (newValue: string) => {
    setEditorContent(newValue);
  };

  return isEditing ? (
    <RichTextEditor
      initialValue={textContent || ""}
      onChange={handleEditorChange}
    />
  ) : (
    <Typography elementTheme="body2">
      <Div flex="column" gap={8}>
        {!!textContent.length ? (
          parse(textContent || "", ginEditorParseOptions)
        ) : (
          <Empty
            description={t(`grant_single|emptyField`, {
              fieldName: t(`gin_section|${section}`),
            })}
          />
        )}
      </Div>
    </Typography>
  );
};

export default GinTextEditor;

