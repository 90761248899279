import { Tablet } from "@hellodarwin/core/lib/components/common/Media";
import UserMenu from "@hellodarwin/core/lib/components/layout/header/user-menu";
import { NavigationLink } from "@hellodarwin/core/lib/features/entities";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import Spin from "antd/es/spin";
import { useMemo } from "react";
import { useAppSelector } from "../app";
import { selectAuthentifiedUser } from "../features/api/slices/global-slice";

const HeaderBarUser = () => {
  const { user } = useLogin();
  const profile = useAppSelector(selectAuthentifiedUser);

  const userSettingsNeedFilling = useMemo(
    () =>
      !profile.admin_homepage ||
      !profile.meeting_link_en ||
      !profile.meeting_link_fr,
    [profile]
  );

  const userMenu: NavigationLink[] = useMemo(
    () => [
      {
        label: "Settings",
        pathname: "/settings",
        Icon: Settings,
        withNotif: userSettingsNeedFilling,
      },
      {
        Icon: Employees,
        label: "Admins",
        pathname: "/admins",
      },
    ],
    [userSettingsNeedFilling]
  );

  const notifsCount = useMemo(() => {
    let count = 0;
    if (userSettingsNeedFilling) {
      count += 1;
    }
    return count;
  }, [userSettingsNeedFilling]);

  if (!user) return <Spin size="small" />;

  return (
    <Tablet direction="up">
      <UserMenu
        user={{ name: user.name || "", logo: user.picture || "" }}
        links={userMenu}
        app="admin"
        notifs={notifsCount}
      />
    </Tablet>
  );
};

export default HeaderBarUser;

