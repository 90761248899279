import React, { CSSProperties } from "react";
import StepsManagerCol, { StepsManagerColProps } from "./steps-manager-col";
import { StepsManagerContainer, StepsManagerContent } from "./styles";

export interface StepsManagerProps {
  items: StepsManagerColProps[];
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
    col?: CSSProperties;
    item?: CSSProperties;
  };
}

const StepsManager = ({ items, styles }: StepsManagerProps) => {
  return (
    <StepsManagerContainer style={styles?.container}>
      <StepsManagerContent style={styles?.content}>
        {React.Children.toArray(
          items.map((col) => <StepsManagerCol {...col} />)
        )}
      </StepsManagerContent>
    </StepsManagerContainer>
  );
};

export default StepsManager;

