import Form from "antd/es/form";
import { DefaultOptionType, SelectProps } from "antd/es/select";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import {
  ExperienceFormValues,
  ReviewForms,
} from "../../../../features/entities/form-entities";
import { ReviewFormSteps } from "../../../../features/entities/project-status-flow-entities";
import { MappedParseTags } from "../../../../features/entities/tags-entities";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import ReviewNavigation from "./review-navigation";
import ReviewStepCard from "./review-step-card";

type ReviewExperienceProps = {
  providerName: string;
  experiences: ExperienceFormValues;
  setForms: Dispatch<SetStateAction<ReviewForms>>;
  setFlowStep: (step: ReviewFormSteps) => void;
  isActive?: boolean;
  tags?: MappedParseTags;
  isFromWebsite: boolean;
  handleSubmit?: () => Promise<void>;
};

const ReviewExperience = ({
  providerName,
  experiences,
  setForms,
  setFlowStep,
  isActive,
  tags,
  isFromWebsite,
  handleSubmit,
}: ReviewExperienceProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const { t: translateTag } = useTranslation(["tags"], { useSuspense: false });

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const [form] = Form.useForm<ExperienceFormValues>();

  useEffect(() => {
    setForms((prevState: ReviewForms) => ({
      ...prevState,
      experienceForm: form,
    }));
  }, [form, setForms]);

  const tagsOptions: SelectProps["options"] = useMemo(() => {
    return Object.values(tags ?? {}).map((category) => {
      const group: DefaultOptionType = {
        label: translateTag(`tags|${category.category}`),
        title: category.category,
        options: category.services.map((service) => {
          const item: DefaultOptionType = {
            label: translateTag(`tags|${service.tag}`),
            value: service.tag,
          };
          return item;
        }),
      };
      return group;
    });
  }, [tags]);

  const initialValues: ExperienceFormValues = {
    service: experiences?.service,
    quality: experiences?.quality,
    relationship: experiences?.relationship,
    summary: experiences?.summary,
    recommend: experiences?.recommend,
  };

  const handleNextStep = async () => {
    form.validateFields().then((values) => {
      isFromWebsite
        ? setFlowStep(ReviewFormSteps.Contact)
        : setFlowStep(ReviewFormSteps.Confirm);
    });
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={initialValues}
      name={"experience"}
      hidden={!isActive}
    >
      <Div flex="column" gap={16}>
        {isFromWebsite && !!tagsOptions.length && (
          <ReviewStepCard
            key={"Service"}
            label={t(`reviews|newReviewServiceLabel`, { providerName })}
            extra={t(`reviews|newReviewServiceQuestion`)}
            error={t(`reviews|newReviewServiceError`, { providerName })}
            name={"service"}
            type="select"
            options={tagsOptions}
            placeholder={t(`reviews|selectAService`)}
          />
        )}
        <ReviewStepCard
          key={"Quality"}
          error={t(`reviews|newReviewQualityError`, { providerName })}
          label={t(`reviews|newReviewQualityLabel`, { providerName })}
          name={"quality"}
          type={"rating"}
        />

        <ReviewStepCard
          key={"Relationship"}
          error={t(`reviews|newReviewRelationshipError`, { providerName })}
          label={t(`reviews|newReviewRelationshipLabel`, { providerName })}
          name={"relationship"}
          type={"rating"}
        />

        <ReviewStepCard
          key={"Summary"}
          error={t(`reviews|newReviewDescribeError`, { providerName })}
          label={t(`reviews|newReviewDescribeLabel`, { providerName })}
          name={"summary"}
          type={"textarea"}
          placeholder={t("reviews|newReviewDescribePlaceholder", {
            providerName,
          })}
        />

        <ReviewStepCard
          key={"Recommend"}
          error={t(`reviews|newReviewRecommendError`, { providerName })}
          label={t(`reviews|newReviewRecommendLabel`, { providerName })}
          name={"recommend"}
          type="radio"
          options={[
            { label: t("reviews|yes"), value: true },
            { label: t("reviews|no"), value: false },
          ]}
        />
        <ReviewNavigation
          handleNext={handleNextStep}
          handlePrev={undefined}
          handleSubmit={handleSubmit}
          isSubmit={!isFromWebsite}
        />
      </Div>
    </Form>
  );
};

export default ReviewExperience;

