import Div from "@hellodarwin/core/lib/components/common/div";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { GinGrantSinglePageSection } from "../../grant-single/use-gin-grant-blocks";
import { ginEditorParseOptions } from "../gin-editor";
import { GinSectionContainer } from "../styles";
import GinBlockHeader from "./gin-block-header";

export interface GinBlockComponentProps {
  setHandleSave: HandleSaveFuncStateDispatch;
  entityId: string;
  section: string;
  isEditing: boolean;
  type: "program" | "application";
}

export interface GinBlockConfig {
  Content: (props: GinBlockComponentProps) => ReactNode;
  id: string;
  hide?: boolean;
}

export type HandleSaveFunc = () => void;
export type HandleSaveFuncState = HandleSaveFunc | undefined;
export type HandleSaveFuncStateDispatch = Dispatch<
  SetStateAction<HandleSaveFuncState>
>;
export interface GinBlockProps extends GinBlockConfig {
  entityId: string;
  type: "program" | "application";
}

const GinBlock = (props: GinBlockProps) => {
  const { Content, entityId, id } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [handleSave, setHandleSave] = useState<HandleSaveFuncState>(undefined);
  const [content, setContent] = useState<string>("");

  const handleEditing = () => setIsEditing(!isEditing);
  const handleHistory = () => setShowHistory(!showHistory);
  const onSave = () => {
    if (!!handleSave) {
      handleSave();
    }
    handleEditing();
  };

  return (
    <GinSectionContainer id={id}>
      <GinBlockHeader
        {...props}
        handleSave={!!handleSave ? onSave : undefined}
        handleEditing={handleEditing}
        isEditing={isEditing}
        handleHistory={handleHistory}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        setContent={setContent}
      />
      <div style={{ width: "100%" }}>
        {!showHistory || content === "" ? (
          <Content
            setHandleSave={
              id === GinGrantSinglePageSection.ProductionStages
                ? handleEditing
                : setHandleSave
            }
            entityId={entityId}
            section={id}
            type={props.type}
            isEditing={isEditing}
          />
        ) : (
          <Div flex="column" gap={8}>
            {parse(content, ginEditorParseOptions)}
          </Div>
        )}
      </div>
    </GinSectionContainer>
  );
};

export default GinBlock;

