import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import Select, { SelectProps } from "antd/es/select";
import { useMemo } from "react";
import adminAppPages from "..";

const HomePageSelect = () => {
  const options: SelectProps["options"] = useMemo(
    () =>
      Object.values(adminAppPages)
        .filter((page) => !page.not_homepage)
        .map((page) => ({
          label: page.title,
          value: page.pathname,
        })),
    []
  );

  return (
    <FormItem label="Homepage" name="admin_homepage">
      <Select options={options} defaultValue={"/projects"} />
    </FormItem>
  );
};

export default HomePageSelect;

