import {
  ApiError,
  ApiErrorInitialState,
  ChatType,
  HdChatPrompt,
  HdChatTemplate,
  HdCreateChatTemplateRequest,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const templatesAdapter = createEntityAdapter({
  selectId: (model: HdChatTemplate) => model.id,
  sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

const templatePromptsAdapter = createEntityAdapter({
  selectId: (model: HdChatPrompt) => model.id,
});

export interface ChatTemplateState {
  status: "idle" | "pending";
  error: ApiError;
  templates: EntityState<HdChatTemplate, string>;
  pagination: {
    page: number;
    size: number;
    total: number;
  };
  prompts: EntityState<HdChatPrompt, string>;
  modal: {
    isVisible: boolean;
    templateId: string;
  };
}

const initialState: ChatTemplateState = {
  status: "idle",
  error: ApiErrorInitialState,
  templates: templatesAdapter.getInitialState(),
  pagination: { page: 1, size: 10, total: 0 },
  prompts: templatePromptsAdapter.getInitialState(),
  modal: {
    isVisible: false,
    templateId: "",
  },
};

export const fetchTemplate = createAsyncThunk<
  HdChatTemplate,
  { api: AdminApiClient; templateId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchTemplate",
  async (
    { api, templateId }: { api: AdminApiClient; templateId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchTemplateById(templateId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllTemplates = createAsyncThunk<
  { templates: HdChatTemplate[]; total: number },
  { api: AdminApiClient; page: number; size: number; type: ChatType },
  { rejectValue: ApiError }
>(
  "admin/fetchAllTemplates",
  async (
    {
      api,
      page,
      size,
      type,
    }: { api: AdminApiClient; page: number; size: number; type: ChatType },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchAllTemplates(page, size, type);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNewTemplate = createAsyncThunk<
  HdChatTemplate,
  { api: AdminApiClient; template: HdCreateChatTemplateRequest },
  { rejectValue: ApiError }
>(
  "admin/createNewTemplate",
  async (
    {
      api,
      template,
    }: { api: AdminApiClient; template: HdCreateChatTemplateRequest },
    { rejectWithValue }
  ) => {
    try {
      return await api.createTemplate(template);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTemplate = createAsyncThunk<
  HdChatTemplate,
  { api: AdminApiClient; template: HdChatTemplate },
  { rejectValue: ApiError }
>(
  "admin/updateTemplate",
  async (
    { api, template }: { api: AdminApiClient; template: HdChatTemplate },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateTemplate(template);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTemplate = createAsyncThunk<
  string,
  { api: AdminApiClient; templateId: string },
  { rejectValue: ApiError }
>(
  "admin/deleteTemplate",
  async (
    { api, templateId }: { api: AdminApiClient; templateId: string },
    { rejectWithValue }
  ) => {
    try {
      await api.deleteTemplate(templateId);
      return templateId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTemplatePrompts = createAsyncThunk<
  HdChatPrompt[],
  { api: AdminApiClient; templateId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchTemplatePrompts",
  async (
    {
      api,
      templateId,
      locale,
    }: { api: AdminApiClient; templateId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchTemplatePrompts(templateId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTemplatePrompts = createAsyncThunk<
  HdChatPrompt[],
  { api: AdminApiClient; templateId: string; prompts: HdChatPrompt[] },
  { rejectValue: ApiError }
>(
  "admin/updateTemplatePrompts",
  async (
    {
      api,
      templateId,
      prompts,
    }: { api: AdminApiClient; templateId: string; prompts: HdChatPrompt[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateTemplatePrompts(templateId, prompts);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleTemplatePromptsModal = createAction<{
  isVisible: boolean;
  templateId: string;
}>("admin/toggleGrantPromptsModal");

export const resetTemplatePrompts = createAction("admin/resetTemplatePrompts");

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllTemplates.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAllTemplates.fulfilled, (state, { payload }) => {
      if (payload && payload.templates) {
        templatesAdapter.setAll(state.templates, payload.templates);
        state.pagination.total = payload.total;
      } else {
        templatesAdapter.removeAll(state.templates);
        state.pagination = { page: 1, size: 10, total: 0 };
      }
      state.status = "idle";
    });
    builder.addCase(fetchAllTemplates.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTemplate.fulfilled, (state, { payload }) => {
      templatesAdapter.upsertOne(state.templates, payload);
      state.status = "idle";
    });
    builder.addCase(fetchTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createNewTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(createNewTemplate.fulfilled, (state, { payload }) => {
      templatesAdapter.upsertOne(state.templates, payload);
      state.status = "idle";
    });
    builder.addCase(createNewTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchTemplatePrompts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTemplatePrompts.fulfilled, (state, { payload }) => {
      if (payload) {
        templatePromptsAdapter.setAll(state.prompts, payload);
      } else {
        templatePromptsAdapter.removeAll(state.prompts);
      }
      state.status = "idle";
    });
    builder.addCase(fetchTemplatePrompts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateTemplatePrompts.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateTemplatePrompts.fulfilled, (state, { payload }) => {
      templatePromptsAdapter.setAll(state.prompts, payload);
      state.status = "idle";
    });
    builder.addCase(updateTemplatePrompts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {
      templatesAdapter.upsertOne(state.templates, payload);
      state.status = "idle";
    });
    builder.addCase(updateTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      templatesAdapter.removeOne(state.templates, payload);
      state.status = "idle";
    });
    builder.addCase(deleteTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(toggleTemplatePromptsModal, (state, { payload }) => {
      state.modal = payload;
    });
    builder.addCase(resetTemplatePrompts, (state) => {
      templatePromptsAdapter.removeAll(state.prompts);
    });
  },
});

export const selectTemplatesLoading = (state: RootState) =>
  state.templates.status === "pending";

export const selectTemplatesPagination = (state: RootState) =>
  state.templates.pagination;

export const selectTemplatePromptsModal = (state: RootState) =>
  state.templates.modal;

export const { selectAll: selectAllTemplates, selectById: selectTemplateById } =
  templatesAdapter.getSelectors(
    (state: RootState) => state.templates.templates
  );

export const {
  selectAll: selectAllTemplatePrompts,
  selectById: selectTemplatePromptById,
} = templatePromptsAdapter.getSelectors(
  (state: RootState) => state.templates.prompts
);

export const templatesReducer = templatesSlice.reducer;

