import WarningOutlined from "@ant-design/icons/WarningOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import getFormattedDate from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Email from "@hellodarwin/icons/dist/icons/Email";
import GoogleLogo from "@hellodarwin/icons/dist/icons/GoogleLogo";
import Key from "@hellodarwin/icons/dist/icons/Key";
import Link from "@hellodarwin/icons/dist/icons/Link";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { copyIntoClipboard } from "../clipboard";

type Auth0UserButtonsProps = {
  auth0UserID: string;
  auth0GoogleUserID: string;
  last_login_at?: string;
  auth0UserIDMatch: boolean;
  isLoading?: boolean;
  noGoogle?: boolean;
  compactView?: boolean;
  auth0CreateAccount: () => void;
  auth0resetPasswordEmail: () => void;
  auth0resetPasswordLink: () => void;
  saveContact: () => void;
};

const Auth0UserButtons = ({
  auth0GoogleUserID,
  auth0UserID,
  last_login_at,
  auth0UserIDMatch,
  noGoogle,
  isLoading,
  compactView,
  auth0CreateAccount,
  auth0resetPasswordEmail,
  auth0resetPasswordLink,
  saveContact,
}: Auth0UserButtonsProps) => {
  const theme = useTheme();
  if (isLoading) return <></>;

  const hasAuthID = auth0UserID !== "" || auth0GoogleUserID !== "";
  const hasGoogleAuth = auth0GoogleUserID !== "" && auth0UserIDMatch;
  const hasAuth = auth0UserID !== "" && auth0UserIDMatch;

  return hasAuthID ? (
    <Div flex="row" align="center" gap={8} justify="space-between">
      <Div flex="row" gap={8} fitContent>
        {!noGoogle && (
          <Tooltip
            title={
              hasGoogleAuth
                ? "User is authentified with Google"
                : "User never logged in with Google"
            }
            color={hasGoogleAuth ? theme.colors.green_1 : theme.colors.red_1}
          >
            <Button
              style={{
                borderColor: hasGoogleAuth
                  ? theme.colors.green_1
                  : theme.colors.red_1,
              }}
              onClick={() =>
                hasGoogleAuth
                  ? copyIntoClipboard(auth0GoogleUserID)
                  : saveContact()
              }
              size="middle"
              danger={!hasGoogleAuth}
              icon={
                <GoogleLogo
                  color={
                    hasGoogleAuth ? theme.colors.green_1 : theme.colors.red_1
                  }
                  width={20}
                  height={20}
                />
              }
            />
          </Tooltip>
        )}

        <Tooltip
          title={
            hasAuth
              ? "User is authentified with Email/Password"
              : "User never logged in with Email/Password"
          }
          color={hasAuth ? theme.colors.green_1 : theme.colors.red_1}
        >
          <Button
            style={{
              borderColor: hasAuth ? theme.colors.green_1 : theme.colors.red_1,
            }}
            onClick={() =>
              hasAuth ? copyIntoClipboard(auth0UserID) : saveContact()
            }
            size="middle"
            danger={!hasAuth}
            icon={
              <Key
                color={hasAuth ? theme.colors.green_1 : theme.colors.red_1}
                width={20}
                height={20}
              />
            }
          />
        </Tooltip>

        <Tooltip
          title={"send email password reset link "}
          color={theme.colors.red_1}
        >
          <Button
            onClick={auth0resetPasswordEmail}
            size="middle"
            danger
            icon={<Email color={theme.colors.red_1} width={20} height={20} />}
          />
        </Tooltip>
        <Tooltip
          title={"copy reset password link to clipboard"}
          color={theme.colors.red_1}
        >
          <Button
            onClick={auth0resetPasswordLink}
            size="middle"
            danger
            icon={<Link color={theme.colors.red_1} width={20} height={20} />}
          />
        </Tooltip>
      </Div>
      {!compactView && (
        <div style={{ width: "fit-content" }}>
          {last_login_at ? (
            <Typography nowrap fitContent>
              {" "}
              {`Last Login: ${getFormattedDate(last_login_at)}`}
            </Typography>
          ) : (
            <Typography nowrap fitContent>{`Last Login: --/--/--`}</Typography>
          )}
        </div>
      )}
    </Div>
  ) : (
    <Div flex="row" align="center" gap={8} justify="space-between">
      <Button onClick={auth0CreateAccount}>Create Auth0 Account</Button>
      {!compactView && (
        <Div flex="row" gap={8} fitContent>
          <WarningOutlined frameBorder={"#23D6A8"} />
          <Typography>User does not have an account.</Typography>
        </Div>
      )}
    </Div>
  );
};

export default Auth0UserButtons;

