import { Element } from "html-react-parser";
import {
  domToReact,
  HTMLReactParserOptions,
} from "../../../../features/helpers/parse";
import Typography from "../../../common/typography";

export const itemDescriptionOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode.type === "tag") {
      const nodeTag = domNode as Element;

      if (nodeTag.name === "h2") {
        return (
          <Typography.Title
            level={2}
            elementTheme="body3"
            bold
            style={{ marginBottom: 8, marginTop: 16 }}
          >
            {domToReact(nodeTag.children)}
          </Typography.Title>
        );
      } else if (nodeTag.name === "ul") {
        return (
          <Typography elementTheme="body3" style={{ marginBottom: 4 }}>
            <ul
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                gap: 6,
              }}
            >
              {domToReact(nodeTag.children, itemDescriptionOptions)}
            </ul>
          </Typography>
        );
      } else if (nodeTag.name === "li") {
        return <li>{domToReact(nodeTag.children)}</li>;
      } else if (nodeTag.name === "p") {
        return (
          <Typography elementTheme="body3" style={{ marginBottom: 8 }}>
            {domToReact(nodeTag.children)}
          </Typography>
        );
      }
    }
  },
};

export const pageDescriptionParseOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode.type === "tag") {
      const nodeTag = domNode as Element;

      if (nodeTag.name === "strong") {
        return (
          <Typography
            elementTheme="subtitle1"
            style={{ marginBottom: 8, marginTop: 8 }}
            textAlign="center"
          >
            {domToReact(nodeTag.children)}
          </Typography>
        );
      } else if (nodeTag.name === "p") {
        if (
          nodeTag.children.length === 1 &&
          (nodeTag.children[0] as Element).name === "strong"
        ) {
          return (
            <>{domToReact(nodeTag.children, pageDescriptionParseOptions)}</>
          );
        } else {
          return (
            <Typography
              elementTheme="body1"
              textAlign="center"
              style={{ marginBottom: 8 }}
            >
              {domToReact(nodeTag.children)}
            </Typography>
          );
        }
      }
    }
  },
};

