import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

interface FilterCompanyInfosProps extends PropsWithChildren {
  label: string;
}

const FilterCompanyInfos: React.FC<FilterCompanyInfosProps> = ({
  label,
  children,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Div flex="column" gap={6}>
      <Typography elementTheme="overline" color={theme.colors.grey_2}>
        {t(`grants|fundingExplorerFilter.${label}`)}
      </Typography>
      <Typography elementTheme="body2">{children}</Typography>
    </Div>
  );
};

export default FilterCompanyInfos;

