import { PropsWithChildren } from "react";
import { FilterDropdownDesktopDrawerStyled } from "../styles";

interface FilterDropdownDesktopDrawerProps extends PropsWithChildren {
  open: boolean;
  handleClose: () => void;
}
const FilterDropdownDesktopDrawer = ({
  open,
  handleClose,
  children,
}: FilterDropdownDesktopDrawerProps) => {
  return (
    <>
      {open && (
        <div
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 99,
          }}
        ></div>
      )}

      <FilterDropdownDesktopDrawerStyled $open={open}>
        {children}
      </FilterDropdownDesktopDrawerStyled>
    </>
  );
};
export default FilterDropdownDesktopDrawer;

