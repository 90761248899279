import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GenderSelect from "@hellodarwin/core/lib/components/forms/form-groups/gender-select";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import {
  AdminProfile,
  AdminRoles,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Form from "antd/es/form";
import Input from "antd/es/input";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import { updateAdmin } from "../../features/api/slices/admins-slice";
import { selectAuthentifiedUser } from "../../features/api/slices/global-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import HomePageSelect from "./home-page-select";
import HubspotSettingsInfos from "./hubspot-infos";

const AdminSettingsForm = ({
  admin,
  noSettings,
}: {
  admin: AdminProfile;
  noSettings?: boolean;
}) => {
  const api = useAdminApi();
  const [form] = Form.useForm<AdminProfile>();
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const authenticatedAdmin = useAppSelector(selectAuthentifiedUser);

  const onFinish = () => {
    try {
      form.validateFields().then((values) => {
        const newAdmin: AdminProfile = {
          ...admin,
          ...values,
        };

        dispatch(updateAdmin({ api, data: newAdmin })).then((payload) =>
          payload.meta.requestStatus === "fulfilled"
            ? notification.success({
                message: "Saved!",
                placement: "bottomLeft",
              })
            : notification.error({
                message: "Error while saving...",
                placement: "bottomLeft",
              })
        );
      });
    } catch (error) {
      notification.error({
        message: "Error while saving...",
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue(admin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  if (!admin) return <></>;

  const isOnHubspot = !!admin.hubspot?.crm_id;
  return (
    <Div
      flex={noSettings ? "column-reverse" : "row"}
      gap={noSettings ? 24 : 54}
      align={noSettings ? "center" : undefined}
    >
      {!noSettings ? (
        <Div flex="column" gap={16} fitContent style={{ minWidth: 500 }}>
          <Div flex="row" align="center" gap={8} justify="space-between">
            <Typography elementTheme="subtitle1">
              {t("admin_settings|admin_setting_title")}
            </Typography>
            <Button
              size="small"
              style={{ alignSelf: "flex-end" }}
              defaultStyle={theme.colors.primary}
              onClick={onFinish}
            >
              {t("button|save")}
            </Button>
          </Div>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            initialValues={admin}
            layout="vertical"
            form={form}
          >
            <Div flex="column" gap={16}>
              {!isOnHubspot && (
                <FormItem
                  label={t("admin_settings|firstName")}
                  name="first_name"
                >
                  <Input />
                </FormItem>
              )}
              {!isOnHubspot && (
                <FormItem label={t("admin_settings|lastName")} name="last_name">
                  <Input />
                </FormItem>
              )}
              {!isOnHubspot && (
                <FormItem label={t("admin_settings|phone")} name="phone">
                  <Input />
                </FormItem>
              )}
              {!isOnHubspot && (
                <FormItem label={t("admin_settings|job_title")} name="title">
                  <Input />
                </FormItem>
              )}
              <HomePageSelect />
              <FormItem label={t("admin_settings|admin_role")} name="roles">
                <Select
                  placeholder={t("admin_settings|selectOption")}
                  disabled={authenticatedAdmin.roles !== "SuperAdmin"}
                >
                  {AdminRoles.map((role) => (
                    <Select.Option key={role} value={role}>
                      {role}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label={t("admin_settings|meeting_link_en")}
                name="meeting_link_en"
              >
                <Input />
              </FormItem>
              <FormItem
                label={t("admin_settings|meeting_link_fr")}
                name="meeting_link_fr"
              >
                <Input />
              </FormItem>
              <FormItem
                label={t("admin_settings|preferredLanguage")}
                name="preferred_language"
                rules={[
                  {
                    required: true,
                    message: "Please select a preferred language",
                  },
                ]}
              >
                <Select placeholder={t("admin_settings|selectLanguage")}>
                  <Select.Option value="fr">{t("language|fr")}</Select.Option>
                  <Select.Option value="en">{t("language|en")}</Select.Option>
                </Select>
              </FormItem>
              <GenderSelect />
            </Div>
          </Form>
        </Div>
      ) : (
        <div>
          <Link
            to={`/settings/${admin.admin_id}`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Button
              size="small"
              withArrowRight
              defaultStyle={theme.colors.primary}
            >
              Go to {admin.first_name} {admin.last_name} Settings
            </Button>
          </Link>
        </div>
      )}

      <HubspotSettingsInfos admin={admin} />
    </Div>
  );
};

export default AdminSettingsForm;

