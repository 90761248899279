import Button from "@hellodarwin/core/lib/components/common/button";
import RichTextEditor from "@hellodarwin/core/lib/components/common/rich-editor";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItemEditList from "@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import SummaryBox from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/SummaryBox";
import {
  DocumentNeeded,
  InitialDocumentValue,
  InitialStepValue,
  Step,
  StepFormFunction,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import User from "@hellodarwin/icons/dist/icons/User";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { useState } from "react";
import {
  GinAssignmentStepOption,
  GinMediumStepOption,
  GinTypeStepOption,
} from "../utils/gin-select-option";
import { GinFileSummaryWrapper } from "./styles";

const GinStepForm = ({
  grantId,
  steps,
  functions,
}: {
  grantId: string;
  steps: Step[];
  functions: StepFormFunction;
}) => {
  const [form] = useForm<Step>();
  const [activeStep, setActiveStep] = useState<Step>();
  const [activeDocument, setActiveDocument] = useState<DocumentNeeded>();
  const { t } = useTranslations();

  const addNewStep = () => {
    setActiveStep({ ...InitialStepValue });
  };
  const addNewDocument = () => {
    setActiveDocument({ ...InitialDocumentValue });
  };
  const handleSubmitFormStep = async (step: Step) => {
    if (!!step.step_id) {
      const result = await functions.updateSelectedStep(step);
      if (result !== step) {
        setActiveStep(undefined);
      }
    } else {
      step.step_order = steps.length;
      const result = await functions.createNewStep(grantId, step);
      if (result !== InitialStepValue) {
        setActiveStep(undefined);
      }
    }
  };
  const handleSubmitFormDocument = (document: DocumentNeeded) => {
    if (!!document.document_id) {
      functions.updateSelectedDocument(document);
    } else {
      functions.createNewDocument(document);
    }
  };
  const handleDeleteStep = (step: Step) => {
    functions.deleteSelectedStep(grantId, step.step_id);
  };
  const handleDeleteDocument = (document: DocumentNeeded) => {
    functions.deleteSelectedDocument(document.document_id);
  };

  const reorder = (reorderdItems: Step[]) => {
    const newSteps = reorderdItems.map((step, index) => {
      return {
        ...step,
        step_order: index,
      };
    });
    functions.reorderSteps(grantId, newSteps);
  };

  return (
    <Form layout="vertical" initialValues={steps} form={form}>
      <FormItem
        name={"form_select_step"}
        label={
          <FormLabel
            label={t("gin_section|step")}
            actions={
              <Button
                transparent
                defaultStyle={theme.colors.primary}
                onClick={() => addNewStep()}
              >
                <AddNew width={16} height={16} />
              </Button>
            }
          />
        }
      >
        <FormItemEditList<Step>
          options={steps ?? []}
          optionLabel={"title"}
          typeLabel="Steps"
          renderLabel={(step, index) =>
            `${t("gin_section|step")} ${index! + 1}. ${step}`
          }
          activeElement={activeStep}
          setActiveElement={setActiveStep}
          update={handleSubmitFormStep}
          deleteItem={handleDeleteStep}
          reorder={reorder}
          inputs={[
            {
              label: "Title",
              name: "title",
              children: (
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: "A title is required" }]}
                >
                  <Input />
                </Form.Item>
              ),
            },
            {
              label: "Assignment",
              name: "assignment",
              children: (
                <Form.Item
                  name="assignment"
                  rules={[
                    { required: true, message: "An assignment is required" },
                  ]}
                >
                  <Select options={GinAssignmentStepOption} />
                </Form.Item>
              ),
            },
            {
              label: "Medium",
              name: "medium",
              children: (
                <Form.Item
                  name="medium"
                  rules={[{ required: true, message: "A medium is required" }]}
                >
                  <Select options={GinMediumStepOption} />
                </Form.Item>
              ),
            },
            {
              label: "Type",
              name: "type",
              children: (
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "A type is required" }]}
                >
                  <Select options={GinTypeStepOption} />
                </Form.Item>
              ),
            },
            {
              label: "Description",
              name: "description",
              children: (
                <RichTextEditor initialValue={activeStep?.description || ""} />
              ),
            },

            {
              label: "",
              name: "",
              children: (
                <FormItem
                  name="form_select_document"
                  label={
                    <FormLabel
                      label="Liste des documents à obtenir du client"
                      actions={
                        <Button
                          transparent
                          defaultStyle={theme.colors.primary}
                          onClick={() => addNewDocument()}
                        >
                          <AddNew width={16} height={16} />
                        </Button>
                      }
                    ></FormLabel>
                  }
                >
                  <FormItemEditList<DocumentNeeded>
                    typeLabel={"Document"}
                    options={activeStep?.document_needed ?? []}
                    optionLabel="name"
                    activeElement={activeDocument}
                    setActiveElement={setActiveDocument}
                    deleteItem={handleDeleteDocument}
                    update={handleSubmitFormDocument}
                    inputs={[
                      {
                        label: "Document Name",
                        name: "name",
                        children: <Input />,
                      },
                      {
                        label: "File Type",
                        name: "type",
                        children: <Select placeholder="Select Option"></Select>,
                      },
                      {
                        label: "Version",
                        name: "version",
                        children: <Input />,
                      },
                      {
                        label: "",
                        name: "date",
                        children: (
                          <GinFileSummaryWrapper>
                            <SummaryBox label={"UPDATE AT"} Icon={Timeline}>
                              <Typography
                                color={theme.colors.black}
                                elementTheme="body2"
                                style={{ paddingLeft: "22px" }}
                              >
                                {activeDocument?.date || "No date provided"}
                              </Typography>
                            </SummaryBox>
                          </GinFileSummaryWrapper>
                        ),
                      },
                      {
                        label: "",
                        name: "uploaded_by",
                        children: (
                          <GinFileSummaryWrapper>
                            <SummaryBox label={"UPLOADED ByY"} Icon={User}>
                              <Typography
                                color={theme.colors.black}
                                elementTheme="body2"
                                style={{ paddingLeft: "22px" }}
                              >
                                {activeDocument?.uploaded_by ||
                                  "No user provided"}
                              </Typography>
                            </SummaryBox>
                          </GinFileSummaryWrapper>
                        ),
                      },
                    ]}
                  />
                </FormItem>
              ),
            },
          ]}
        ></FormItemEditList>
      </FormItem>
    </Form>
  );
};

export default GinStepForm;

