import {
  AdminProjectRequest,
  AdminTag,
  ApiError,
  ApiErrorInitialState,
  Match,
  MatchmakingNotification,
  Message,
  Project,
  ProjectInitialState,
  ProjectSearchResult,
  ServiceTag,
  SpecialtyTag,
} from "@hellodarwin/core/lib/features/entities";
import MatchSource from "@hellodarwin/core/lib/features/enums/match-source";
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export const queryProjects = createAsyncThunk<
  ProjectSearchResult[],
  {
    api: AdminApiClient;
    page: number;
    size: number;
    query: string;
  },
  { rejectValue: ApiError }
>(
  "admin/queryProjects",
  async (
    {
      api,
      page,
      size,
      query,
    }: { api: AdminApiClient; page: number; size: number; query: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.queryProjects(page, size, query);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchProjectEmailActivity = createAsyncThunk<
  Message[],
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchEmailActivity",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectEmailActivity(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchJourneyMatchmakingNotifications = createAsyncThunk<
  MatchmakingNotification[],
  { api: AdminApiClient; journeyId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchNotificationsByJourneyID",
  async (
    { api, journeyId }: { api: AdminApiClient; journeyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getMatchmakingNotificationsByJourneyID(journeyId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProject = createAsyncThunk<
  Project,
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProject",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getProjectById(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProject = createAsyncThunk<
  Project,
  { api: AdminApiClient; project: AdminProjectRequest },
  { rejectValue: ApiError }
>(
  "admin/updateProject",
  async (
    { api, project }: { api: AdminApiClient; project: AdminProjectRequest },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateProject(project);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateProject = createAsyncThunk<
  Project,
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/activateProject",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.activateProject(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjectMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProjectMatches",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectMatches(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const adminRefuseMatch = createAsyncThunk<
  Match,
  {
    api: AdminApiClient;
    matchId: string;
    refusedReason: string;
    refusedReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/refuse",
  async (
    {
      api,
      matchId,
      refusedReason,
      refusedReasonSpecified,
    }: {
      api: AdminApiClient;
      matchId: string;
      refusedReason: string;
      refusedReasonSpecified: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.adminRefuseMatch(
        matchId,
        refusedReason,
        refusedReasonSpecified
      );
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const adminUnrefuseMatch = createAsyncThunk<
  Match,
  { api: AdminApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/unrefuse",
  async (
    { api, matchId }: { api: AdminApiClient; matchId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.adminUnrefuseMatch(matchId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const ignoreProject = createAsyncThunk<
  Match,
  {
    api: AdminApiClient;
    matchId: string;
    refusedReason: string;
    refuseReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/ignore",
  async (
    {
      api,
      matchId,
      refusedReason,
      refuseReasonSpecified,
    }: {
      api: AdminApiClient;
      matchId: string;
      refusedReason: string;
      refuseReasonSpecified: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.ignoreProject(
        matchId,
        refusedReason,
        refuseReasonSpecified
      );
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const unignoreProject = createAsyncThunk<
  Match,
  { api: AdminApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/unignore",
  async (
    { api, matchId }: { api: AdminApiClient; matchId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.unignoreProject(matchId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjectPurchasedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProjectPurchasedMatches",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectPurchasedMatches(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjectRefusedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProjectRefusedMatches",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectRefusedMatches(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjectTags = createAsyncThunk<
  AdminTag[],
  { api: AdminApiClient; projectId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchProjectTags",
  async (
    { api, projectId }: { api: AdminApiClient; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectTags(projectId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProjectTags = createAsyncThunk<
  string,
  {
    api: AdminApiClient;
    projectID: string;
    serviceTags: ServiceTag[];
    specialtyTags: SpecialtyTag[];
    industryTag: string | undefined;
  },
  { rejectValue: ApiError }
>(
  "admin/updateProjectTag",
  async (
    {
      api,
      projectID,
      serviceTags,
      specialtyTags,
      industryTag,
    }: {
      api: AdminApiClient;
      projectID: string;
      serviceTags: ServiceTag[];
      specialtyTags: SpecialtyTag[];
      industryTag: string | undefined;
    },
    { rejectWithValue }
  ) => {
    try {
      const tags: string[] = [];

      for (const tag of serviceTags) {
        tags.push(tag.tag);
      }

      for (const tag of specialtyTags) {
        tags.push(tag.tag);
      }

      industryTag && tags.push(industryTag);

      return await api.updateProjectTags(projectID, tags);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleProjectCategoryTag = createAction<string>(
  "admin/toggleProjectCategoryTag"
);
export const selectProjectServiceTag = createAction<string>(
  "admin/selectProjectServiceTag"
);
export const deselectProjectServiceTag = createAction<string>(
  "admin/deselectProjectServiceTag"
);

export const setActiveTab = createAction<string>("admin/setActiveTab");
export const selectActiveTab = (state: RootState) => state.projects.activeTab;

export const setSelectedProject = createAction<Project>(
  "admin/setSelectedProject"
);
export const toggleProjectsModal = createAction<{
  isVisible: boolean;
  type: string;
}>("admin/toggleProjectsModal");

const emailActivityAdapter = createEntityAdapter({
  selectId: (model: Message) => model.to_email,
});

const projectMatchesAdapter = createEntityAdapter({
  selectId: (model: Match) => model.provider_id,
});

const projectsSearchAdapter = createEntityAdapter({
  selectId: (model: ProjectSearchResult) => model.project_id,
});

const projectsAdapter = createEntityAdapter({
  selectId: (model: Project) => model.project_id,
});

export interface ProjectsState {
  status: "idle" | "pending";
  error: ApiError;
  emailActivity: EntityState<Message, string>;
  matchmakingNotifications: MatchmakingNotification[];
  matches: EntityState<Match, string>;
  refusedMatches: Match[];
  purchasedMatches: Match[];
  manualMatches: Match[];
  activeTab: string;
  waitingNotifications: Match[];
  projectsSearch: EntityState<ProjectSearchResult, string>;
  selectedProject: Project;
  project: EntityState<Project, string>;
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: ProjectsState = {
  status: "idle",
  error: ApiErrorInitialState,
  emailActivity: emailActivityAdapter.getInitialState(),
  matchmakingNotifications: [],
  matches: projectMatchesAdapter.getInitialState(),
  refusedMatches: [],
  purchasedMatches: [],
  manualMatches: [],
  activeTab: "",
  waitingNotifications: [],
  projectsSearch: projectsSearchAdapter.getInitialState(),
  selectedProject: ProjectInitialState,
  project: projectsAdapter.getInitialState(),
  modal: {
    isVisible: false,
    type: "",
  },
};

const filterProjectMatches = (
  matches: Match[]
): {
  purchasedMatches: Match[];
  refusedMatches: Match[];
  manualMatches: Match[];
} => {
  const purchased: Match[] = [];
  const refused: Match[] = [];
  const manual: Match[] = [];

  for (const match of matches) {
    if (
      match?.purchased_at ||
      match.status === "Waiting" ||
      match.raise_hand_rejected_at
    ) {
      purchased.push(match);
    }
    if (match.refused_at) {
      refused.push(match);
    }
    if (
      match.source === MatchSource.ManualMatch ||
      match.source === MatchSource.DirectoryMatch
    ) {
      manual.push(match);
    }
  }

  return {
    purchasedMatches: purchased,
    refusedMatches: refused,
    manualMatches: manual,
  };
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(queryProjects.fulfilled, (state, { payload }) => {
      if (payload != null) {
        projectsSearchAdapter.setAll(state.projectsSearch, payload);
      }
      state.status = "idle";
    });
    builder.addCase(queryProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchJourneyMatchmakingNotifications.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchJourneyMatchmakingNotifications.fulfilled,
      (state, { payload }) => {
        state.matchmakingNotifications = payload;
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchJourneyMatchmakingNotifications.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(fetchProjectEmailActivity.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchProjectEmailActivity.fulfilled,
      (state, { payload }) => {
        if (payload) emailActivityAdapter.setAll(state.emailActivity, payload);
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchProjectEmailActivity.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(fetchProjectMatches.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProjectMatches.fulfilled, (state, { payload }) => {
      projectMatchesAdapter.setAll(state.matches, payload);
      const { purchasedMatches, refusedMatches, manualMatches } =
        filterProjectMatches(payload);
      state.refusedMatches = refusedMatches;
      state.purchasedMatches = purchasedMatches;
      state.manualMatches = manualMatches;
      state.status = "idle";
    });
    builder.addCase(fetchProjectMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchProjectPurchasedMatches.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchProjectPurchasedMatches.fulfilled,
      (state, { payload }) => {
        state.purchasedMatches = payload;
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchProjectPurchasedMatches.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(fetchProjectRefusedMatches.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchProjectRefusedMatches.fulfilled,
      (state, { payload }) => {
        state.refusedMatches = payload;
        state.status = "idle";
      }
    );
    builder.addCase(
      fetchProjectRefusedMatches.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(ignoreProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(ignoreProject.fulfilled, (state, { payload }) => {
      projectMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(ignoreProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(unignoreProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(unignoreProject.fulfilled, (state, { payload }) => {
      projectMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(unignoreProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(adminRefuseMatch.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(adminRefuseMatch.fulfilled, (state, { payload }) => {
      projectMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(adminRefuseMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(adminUnrefuseMatch.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(adminUnrefuseMatch.fulfilled, (state, { payload }) => {
      projectMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(adminUnrefuseMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateProjectTags.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateProjectTags.fulfilled, (state, { payload }) => {
      state.status = "idle";
    });
    builder.addCase(updateProjectTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });

    builder.addCase(fetchProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProject.fulfilled, (state, { payload }) => {
      projectsAdapter.upsertOne(state.project, payload);
      state.status = "idle";
    });
    builder.addCase(fetchProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(activateProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(activateProject.fulfilled, (state, { payload }) => {
      projectsAdapter.upsertOne(state.project, payload);
      state.status = "idle";
    });
    builder.addCase(activateProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateProject.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      projectsAdapter.upsertOne(state.project, payload);
      state.status = "idle";
    });
    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });

    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(setSelectedProject, (state, { payload }) => {
      state.selectedProject = payload;
    });
    builder.addCase(toggleProjectsModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const selectGroupedMatchmakingNotifications = createSelector(
  (state: RootState) => state.projects.matchmakingNotifications,
  (_, matchmakingJourneyId) => matchmakingJourneyId,
  (entities, matchmakingJourneyId) => {
    const groupedNotifications: Record<string, MatchmakingNotification[]> = {};
    if (!entities || !matchmakingJourneyId) {
      return groupedNotifications;
    }
    const sortedNotifications = Object.values(
      entities as MatchmakingNotification[]
    )
      .filter(
        (notification) =>
          notification.matchmaking_journey_id === matchmakingJourneyId
      )
      .sort((a, b) => b.match_score - a.match_score);

    sortedNotifications.forEach((notification) => {
      const matchId = notification.match_id;

      if (!groupedNotifications[matchId]) {
        groupedNotifications[matchId] = [];
      }

      groupedNotifications[matchId].push(notification);
    });
    return groupedNotifications;
  }
);

export const {
  selectAll: selectEmailActivity,
  selectById: selectEmailActivityByEmail,
} = emailActivityAdapter.getSelectors(
  (state: RootState) => state.projects.emailActivity
);

export const {
  selectAll: selectAllProjectMatches,
  selectById: selectAllMatchesById,
} = projectMatchesAdapter.getSelectors(
  (state: RootState) => state.projects.matches
);
export const selectRefusedMatches = (state: RootState) =>
  state.projects.refusedMatches;
export const selectPurchasedMatches = (state: RootState) =>
  state.projects.purchasedMatches;
export const selectManualMatches = (state: RootState) =>
  state.projects.manualMatches;

export const selectProjectsIsLoading = (state: RootState) =>
  state.projects.status === "pending";

export const { selectAll: selectProjects } = projectsSearchAdapter.getSelectors(
  (state: RootState) => state.projects.projectsSearch
);

export const { selectById: selectProjectById } = projectsAdapter.getSelectors(
  (state: RootState) => state.projects.project
);

export const selectProjectsError = (state: RootState) =>
  state.projects.error.error_code;

export const selectProjectsModal = (state: RootState) => state.projects.modal;
export const selectSelectedProject = (state: RootState) =>
  state.projects.selectedProject;

export const projectsReducer = projectsSlice.reducer;

