import Form from "antd/es/form";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ItemsTypes } from "../../../../../features/entities/form-entities";
import { DragDropContainer } from "../../styles";
import { FormContentProps } from "../../types";
import FormPageBuilder from "../page";
import FormBuilderHeader from "./form-builder-header";

const FormBuilderController = ({
  formResponse,
  pages,
  functions,
  locale,
  openGrant,
}: FormContentProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: 24,
        flexDirection: "column",
        overflow: "hidden",
        marginTop: 32,
      }}
    >
      <FormBuilderHeader
        formResponse={formResponse}
        locale={locale}
        openGrant={openGrant}
      />
      <Form layout="vertical" style={{ width: "100%" }}>
        <Droppable droppableId={`form_droppable`} type={ItemsTypes.main}>
          {(provided) => (
            <DragDropContainer
              $isDroppable
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: "flex", flexDirection: "column", gap: 24 }}
            >
              {React.Children.toArray(
                pages.map((page, index) => (
                  <Draggable
                    key={`page_draggable_${page.id}`}
                    draggableId={`page_draggable_${page.id}`}
                    index={index}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormPageBuilder
                            pages={pages}
                            groups={page.groups}
                            isFirstPage={index === 0}
                            id={page.id}
                            formPageResponse={page.entity}
                            functions={functions}
                            locale={locale}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))
              )}

              {provided.placeholder}
            </DragDropContainer>
          )}
        </Droppable>
      </Form>
    </div>
  );
};

export default FormBuilderController;

