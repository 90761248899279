import { API_URL } from "@hellodarwin/core/lib/api";
import axios from "axios";

// hasAuthParams is copied from @auth0/auth0-react/src/utils.tsx because CRA can't seem to resolve it
export const hasAuthParams = (
  searchParams = window?.location?.search || ""
): boolean => {
  const CODE_RE = /[?&]code=[^&]+/;
  const STATE_RE = /[?&]state=[^&]+/;
  const ERROR_RE = /[?&]error=[^&]+/;
  return (
    (CODE_RE.test(searchParams) && STATE_RE.test(searchParams)) ||
    ERROR_RE.test(searchParams)
  );
};

export interface OauthOptions {
  domain: string;
  client_id: string;
  audience: string;
  scope: string;
}

// fetchOauthOptions from the API
export const fetchOauthOptions = async (
  appName: string
): Promise<OauthOptions> => {
  const response = await axios
    .create({ baseURL: API_URL })
    .get<OauthOptions>(`/oauth/${appName}`);

  return { ...response.data };
};

