import Avatar from "antd/es/avatar";
import { Link } from "react-router-dom";
import styled from "../../../plugins/styled";

const LOGO_WIDTH = 112;

export const GrantLogo = styled(Avatar)`
  font-size: 64px;
  border-radius: 4px;
  flex-basis: ${LOGO_WIDTH}px;
  font-weight: bolder;
  display: flex;
  width: ${LOGO_WIDTH}px;
  height: ${LOGO_WIDTH}px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const GrantCardLink = styled(Link)`
  &,
  &:hover {
    color: inherit;
  }
`;

export const GrantCardBodyContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
`;

export const GrantCardBodyContent = styled.div`
  display: flex;
  max-width: 90%;
  margin: auto;
  & > div {
    flex: 1;
    border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
    padding: 16px 46px;
    padding-bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      border: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    width: 100%;

    & > div {
      border-right: none;
      padding: 16px 24px;

      border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
    }
  }
`;

export const GrantCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    transform: scale(1.005);
    cursor: pointer;
  }
`;

export const GrantCardInfosBoxBody = styled.div`
  padding-inline-start: calc(14px + 10px);
  width: 100%;
  ul {
    margin: 0;
    padding-inline-start: 0;
  }
`;

export const GrantCardInfosBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

