import Button from "@hellodarwin/core/lib/components/common/button";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import {
  GrantProvider,
  GrantProviders,
} from "@hellodarwin/core/lib/features/entities";
import Col from "antd/es/col";
import message from "antd/es/message/index";
import Row from "antd/es/row";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/app-hooks";
import GrantProviderForm from "../../components/grants/grant-provider-form";
import { createGrantProvider } from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../single-project-page";

const CreateGrantProviderPage = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [grantProvider, setGrantProvider] = useState<GrantProvider[]>([
    {
      grant_provider_id: "",
    },
    {
      grant_provider_id: "",
    },
  ]);

  const [forms, setForms] = useState<AdminPagesForms>({
    grantProviderForm: undefined,
  });

  const onSave = async () => {
    try {
      const grantProviderFormValues =
        forms.grantProviderForm?.getFieldsValue()!;

      if (grantProviderFormValues === undefined) {
        return;
      }

      const newGrantProvider: GrantProvider[] = [
        {
          grant_provider_id: grantProvider[0].grant_provider_id,
          locale: "en",
          title: grantProviderFormValues.title_en,
          description: grantProviderFormValues.description_en,
          slug: grantProviderFormValues.slug_en,
          logo: grantProvider[0].logo,
          rank: grantProviderFormValues.rank,
          url: grantProviderFormValues.url_en,
          acronym: grantProviderFormValues.acronym_en,
        },
        {
          grant_provider_id: grantProvider[1].grant_provider_id,
          locale: "fr",
          title: grantProviderFormValues.title_fr,
          slug: grantProviderFormValues.slug_fr,
          description: grantProviderFormValues.description_fr,
          logo: grantProvider[1].logo,
          rank: grantProviderFormValues.rank,
          url: grantProviderFormValues.url_en,
          acronym: grantProviderFormValues.acronym_fr,
        },
      ];

      const updatedGrantProvider: GrantProvider[] = {
        ...grantProvider,
        ...newGrantProvider,
      };

      const sendGrantProvider: GrantProviders = {
        grant_provider_en: updatedGrantProvider[0],
        grant_provider_fr: updatedGrantProvider[1],
      };

      const grantProviderResponse = (await dispatch(
        createGrantProvider({ api, grantProvider: sendGrantProvider })
      ).unwrap()) as unknown as any[];

      if ("grant_provider_id" in grantProviderResponse[0]) {
        message.success("Grant Provider created with success !");
        setGrantProvider([
          {
            grant_provider_id: "",
          },
          {
            grant_provider_id: "",
          },
        ]);
        forms.grantProviderForm?.resetFields();
        navigate(
          "/grants/providers/" + grantProviderResponse[0].grant_provider_id
        );
      }
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const goToGrantsProviders = () => {
    navigate("/grants/providers");
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Create a Grant Provider"}
      title={"Create a Grant Provider"}
      actions={
        <Button onClick={onSave} size="small">
          Create a Grant Provider
        </Button>
      }
      handleBack={goToGrantsProviders}
      breadcrumbs={[
        {
          breadcrumbName: "Grant Providers",
          path: "/grants/providers",
        },
        {
          breadcrumbName: "Create a Grant Provider",
        },
      ]}
    >
      <Row gutter={24}>
        <Col span={24}>
          <GrantProviderForm
            grantProvider={grantProvider}
            setForms={setForms}
          ></GrantProviderForm>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default CreateGrantProviderPage;

