import PauseOutlined from "@ant-design/icons/PauseOutlined";
import PlayCircleOutlined from "@ant-design/icons/PlayCircleOutlined";
import SendOutlined from "@ant-design/icons/SendOutlined";
import Card from "@hellodarwin/core/lib/components/common/Card";
import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  MatchmakingNotification,
  MatchmakingResponse,
} from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import {
  getFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import Button from "antd/es/button";
import message from "antd/es/message";
import Spin from "antd/es/spin";
import Tag from "antd/es/tag";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppUnwrap } from "../../app/app-hooks";
import {
  fetchJourneyMatchmakingNotifications,
  fetchProjectEmailActivity,
  selectEmailActivityByEmail,
  selectGroupedMatchmakingNotifications,
  selectProjectsIsLoading,
} from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { getStatusColor } from "../../features/utils";
import Clipboard from "../clipboard";
import "./manifest.scss";
import NotifyAllModal from "./notify-all-modal";

export enum JourneyStatus {
  OnGoing = "OnGoing",
  Stopped = "Stopped",
  Completed = "Completed",
}

type ManifestProps = {
  projectId: string;
  projectStatus: ProjectStatus;
  handleActivation: () => Promise<undefined | Error>;
};

interface ManifestState {
  status: "LOADING" | "IDLE" | "SAVING";
  matchmaking: MatchmakingResponse;
}

interface ActivityProps {
  notification: MatchmakingNotification;
}

const initialState: ManifestState = {
  status: "LOADING",
  matchmaking: {} as MatchmakingResponse,
};

const Activity = ({ notification }: ActivityProps) => {
  const message = useAppSelector((state) =>
    selectEmailActivityByEmail(state, notification.provider_email || "")
  );

  let status = message?.status ?? "Waiting";
  if (status === "delivered") {
    status = "Delivered";
  }

  if (status === "not delivered") {
    status = "Errored";
  }

  if (notification.match_blacklisted_at != null) {
    status = "Blacklisted";
  }

  return (
    <>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <b>{message?.to_email}</b>
      </td>
      <td>
        {notification?.sent_at ? getFormattedDate(notification?.sent_at) : "-"}
      </td>
      <td>{getFormattedDate(notification?.scheduled_at)}</td>
      <td>{message?.opens_count ?? 0}</td>
      <td>{message?.clicks_count ?? 0}</td>
      <td>{notification.match_source}</td>
      <td className={"manifest-last-td"}>
        <Tag color={getStatusColor(status)}>{status}</Tag>
      </td>
    </>
  );
};

const Manifest = ({
  projectId,
  projectStatus,
  handleActivation,
}: ManifestProps) => {
  const api = useAdminApi();
  const unwrap = useAppUnwrap();
  const [state, setState] = useState<ManifestState>(initialState);
  const [notifyModalVisible, setNotifyModalVisible] = useState(false);
  const loadingStatus = useAppSelector((state) => state.projects.status);
  const loading = useAppSelector(selectProjectsIsLoading);
  const groupedNotifications = useAppSelector((s) =>
    selectGroupedMatchmakingNotifications(
      s,
      state.matchmaking?.journey?.matchmaking_journey_id || ""
    )
  );

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const matchmaking =
          await api.getMatchmakingManifestByProjectId(projectId);
        if (isMounted) {
          setState((state) => ({ ...state, matchmaking }));
        }
      } catch (e: any) {
        message.error(e);
      } finally {
        if (isMounted) {
          setState((state) => ({ ...state, status: "IDLE" }));
        }
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.matchmaking.journey !== undefined) {
      if (state.matchmaking.journey.matchmaking_journey_id !== "") {
        unwrap(
          fetchJourneyMatchmakingNotifications({
            api,
            journeyId: state.matchmaking.journey.matchmaking_journey_id,
          })
        ).catch((error) => console.error(error));
      }
      if (state.matchmaking.manifest.project_id !== "") {
        unwrap(
          fetchProjectEmailActivity({
            api,
            projectId: state.matchmaking.manifest.project_id,
          })
        ).catch((error) => console.error(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.matchmaking]);

  const createManifestAndDrip = () => {
    setState((state) => ({ ...state, status: "SAVING" }));
    (async () => {
      try {
        const error = await handleActivation();

        if (error !== undefined) {
          console.error(error);
          return;
        }
        let matchmaking = await api.match(projectId);
        matchmaking = await api.drip(
          matchmaking.manifest.matchmaking_manifest_id
        );
        setState((state) => ({ ...state, matchmaking: matchmaking }));
      } catch (e: any) {
        message.error(e);
      } finally {
        setState((state) => ({ ...state, status: "IDLE" }));
      }
    })();
  };

  const stopDrip = async () => {
    if (state.matchmaking.manifest.matchmaking_manifest_id === "") {
      return;
    }

    setState((state) => ({ ...state, status: "SAVING" }));
    try {
      const matchmaking = state.matchmaking;
      matchmaking.journey.status = JourneyStatus.Stopped;
      await api.stopDrip(matchmaking.journey.matchmaking_journey_id);

      setState((state) => ({ ...state, matchmaking: matchmaking }));
    } catch (e: any) {
      message.error(e);
    } finally {
      setState((state) => ({ ...state, status: "IDLE" }));
    }
  };

  const resumeDrip = async () => {
    if (state.matchmaking.manifest.matchmaking_manifest_id === "") {
      return;
    }

    setState((state) => ({ ...state, status: "SAVING" }));
    try {
      const matchmaking = state.matchmaking;
      matchmaking.journey.status = JourneyStatus.OnGoing;
      await api.resumeDrip(matchmaking.journey.matchmaking_journey_id);

      setState((state) => ({ ...state, matchmaking: matchmaking }));
    } catch (e: any) {
      message.error(e);
    } finally {
      setState((state) => ({ ...state, status: "IDLE" }));
    }
  };

  const notifyNow = async () => {
    if (state.matchmaking.manifest.matchmaking_manifest_id === "") {
      return;
    }

    setState((state) => ({ ...state, status: "SAVING" }));
    try {
      const { journey } = state.matchmaking;
      await api.notifyNow(journey.matchmaking_journey_id);

      const matchmaking =
        await api.getMatchmakingManifestByProjectId(projectId);
      setState((state) => ({ ...state, matchmaking }));
    } catch (e: any) {
      message.error(e);
    } finally {
      setState((state) => ({ ...state, status: "IDLE" }));
    }
  };

  const notifyAll = async () => {
    if (state.matchmaking.manifest.matchmaking_manifest_id === "") {
      return;
    }

    try {
      setNotifyModalVisible(false);
      const sent = await api.notifyAll(
        journey.matchmaking_journey_id,
        projectId
      );
      message.success(
        `${sent} providers were notified about the project update`,
        5
      );
    } catch (e: any) {
      message.error(e);
    }
  };

  const openModal = () => {
    setNotifyModalVisible(true);
  };

  const StartManifestButtons = () => {
    if (state.matchmaking.manifest.matchmaking_manifest_id !== "") {
      return <></>;
    }

    return (
      <div className="manifest-action-container">
        <Button
          icon={<PlayCircleOutlined />}
          loading={state.status === "SAVING"}
          onClick={createManifestAndDrip}
        >
          Create Manifest and Start Drip
        </Button>
      </div>
    );
  };

  const JourneyControlButtons = () => {
    const isActionable = projectStatus === ProjectStatus.Active;

    if (
      state.matchmaking.journey.matchmaking_journey_id === "" ||
      !isActionable
    ) {
      return <></>;
    }

    return (
      <div className="manifest-journey-controls-container">
        {state.matchmaking.journey.status === JourneyStatus.OnGoing && (
          <Button
            icon={<PauseOutlined />}
            className="journey-controls-button"
            loading={state.status === "SAVING"}
            onClick={stopDrip}
          >
            Stop Drip
          </Button>
        )}

        {state.matchmaking.journey.status === JourneyStatus.Stopped && (
          <Button
            icon={<PlayCircleOutlined />}
            className="journey-controls-button"
            loading={state.status === "SAVING"}
            onClick={resumeDrip}
          >
            Resume Drip
          </Button>
        )}

        {state.matchmaking.journey.status !== JourneyStatus.Completed && (
          <Button
            icon={<SendOutlined />}
            className="journey-controls-button"
            loading={state.status === "SAVING"}
            onClick={notifyNow}
          >
            Notify Remaining SPs
          </Button>
        )}

        <Button
          icon={<SendOutlined />}
          className="journey-controls-button"
          loading={state.status === "SAVING"}
          onClick={openModal}
        >
          Notify All
        </Button>

        <NotifyAllModal
          modalVisible={notifyModalVisible}
          notifyAll={notifyAll}
          closeModal={closeModal}
        />
      </div>
    );
  };

  const closeModal = () => {
    setNotifyModalVisible(false);
  };

  if (state.status === "LOADING") {
    return <Loading />;
  }

  const { manifest, journey } = state.matchmaking;

  if (!journey) return <p>failed to load journey</p>;

  return (
    <>
      <div className="manifest-container" style={{ width: "100%" }}>
        {<StartManifestButtons />}
        {<JourneyControlButtons />}
        <Card>
          <Div className="manifest-card-title" flex="row">
            <Div flex="column" align="center">
              <Div flex="row" gap={10} align="center">
                <Tag>{Object.keys(groupedNotifications).length}</Tag>
                <Tag>{manifest.algorithm_version}</Tag>
                <Tag color={getStatusColor(manifest.status)}>
                  {manifest.status}
                </Tag>
                <Tag>{journey.scheduler_version}</Tag>
                <Tag color={getStatusColor(journey.status)}>
                  {journey.status}
                </Tag>
              </Div>
            </Div>
            <Div flex="column" justify="end">
              Manifest created at: {getFormattedDate(manifest.created_at)}
            </Div>
            {loading && (
              <Div flex="column" justify="end">
                email activity<Spin></Spin>
              </Div>
            )}
          </Div>
          {loadingStatus === "pending" && <Loading />}
          {loadingStatus === "idle" && (
            <table className="manifest-table">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Match ID</th>
                  <th>Score</th>
                  <th>Provider Name</th>
                  <th>Sent to</th>
                  <th>Sent</th>
                  <th>Scheduled</th>
                  <th>Opens count</th>
                  <th>Clicks count</th>
                  <th>Match source</th>
                  <th>Notification status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedNotifications).map(
                  ([matchId, matchNotifications], i) => (
                    <>
                      <tr key={matchId} className="parent-rows">
                        <td>{i + 1}</td>
                        <td>
                          <Clipboard
                            tooltip={matchId}
                            copy={matchId}
                            title={`${getShortId(matchId)}`}
                          />
                        </td>
                        <td>{matchNotifications[0].match_score}</td>
                        <td>
                          <Link
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                              width: "200px",
                            }}
                            target="_blank"
                            to={`/providers/${matchNotifications[0].provider_id}`}
                          >
                            {matchNotifications[0].company_name}
                          </Link>
                        </td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      {matchNotifications.map((notification, index) => (
                        <tr className="child-rows">
                          <Activity
                            key={`${matchId} - ${index}`}
                            notification={notification}
                          />
                        </tr>
                      ))}
                    </>
                  )
                )}
              </tbody>
            </table>
          )}
          {Object.keys(groupedNotifications).length === 0 && (
            <div>No match</div>
          )}
        </Card>
      </div>
    </>
  );
};

export default Manifest;

