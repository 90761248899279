import Button from "@hellodarwin/core/lib/components/common/button";
import Modal from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  GinApplication,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import { FormInstance } from "antd/es/form";
import { useAppDispatch, useAppSelector } from "../../../../../app";
import {
  selectGinApplication,
  selectGinModalForm,
  toggleApplicationModalForm,
} from "../../../../../features/api/slices/gins-slice";
import AcceptedForm from "./accpted-form";
import CompletedToSubmitForm from "./completed-submit-form";
import ConfirmedOpportunitiesForm from "./confirm-opportunities";
import IdentifiedOpportunitiesForm from "./indentified-opportunities-form";
import OngoingWorkByHelloDarwinForm from "./ogoing-work-hellodarwin-form";
import RefusedForm from "./refused-form";
import SubmittedWaitingForResultsForm from "./submit-waiting-results-form";

const CreateApplicationForm = ({
  form,
  handleSave,
}: {
  form: FormInstance<GinApplication>;
  handleSave: (applicationStatus: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectGinApplication);
  const modalForm = useAppSelector(selectGinModalForm);
  const { t } = useTranslations();

  const renderForm = (programGrantStatus: string) => {
    switch (programGrantStatus) {
      case ProgramGrantStatus.IdentifiedOpportunities:
        return (
          <IdentifiedOpportunitiesForm form={form} application={application} />
        );
      case ProgramGrantStatus.ConfirmedOpportunities:
        return (
          <ConfirmedOpportunitiesForm form={form} application={application} />
        );
      case ProgramGrantStatus.OngoingWorkByHelloDarwin:
        return (
          <>
            <OngoingWorkByHelloDarwinForm
              form={form}
              application={application}
            />
          </>
        );
      case ProgramGrantStatus.CompletedToSubmit:
        return <CompletedToSubmitForm form={form} application={application} />;
      case ProgramGrantStatus.SubmittedWaitingForResults:
        return (
          <SubmittedWaitingForResultsForm
            form={form}
            application={application}
          />
        );
      case ProgramGrantStatus.Accepted:
        return <AcceptedForm form={form} application={application} />;
      case ProgramGrantStatus.Refused:
        return <RefusedForm form={form} application={application} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      header={
        <Typography elementTheme="h6">
          {t(`application_single|${modalForm.applicationStatus}_title`)}
        </Typography>
      }
      open={modalForm.isVisible}
      handleCancel={() =>
        dispatch(
          toggleApplicationModalForm({
            isVisible: false,
            applicationStatus: "",
          })
        )
      }
      styles={{
        content: {
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        },
      }}
      footer={
        <Button
          size="small"
          style={{ backgroundColor: theme.colors.black }}
          headingIcon={<Edit width={16} height={16} />}
          onClick={() => handleSave(modalForm.applicationStatus)}
        >
          {t("button|save")}
        </Button>
      }
    >
      {renderForm(modalForm.applicationStatus)}
    </Modal>
  );
};

export default CreateApplicationForm;

