import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import notification from "antd/es/notification";

export const showErrorNotification = (error: any) => {
  notification.error({
    message: error.error_code ? error.error_code : "Server Error",
    description: error.error,
    placement: "bottomLeft",
    duration: 3,
  });
};

export const getProjectStatus = (reason: string): ProjectStatus => {
  const projectStatus: { [index: string]: ProjectStatus } = {
    Spam: ProjectStatus.Rejected,
    Test: ProjectStatus.Rejected,
    "No budget / unrealistic expectations": ProjectStatus.Rejected,
    "No # number / Wrong contact info": ProjectStatus.Rejected,
    "Not a service": ProjectStatus.Rejected,
    "The project is too early/not mature enough": ProjectStatus.Rejected,
    "The client isn't serious enough / Bad targeting": ProjectStatus.Rejected,
    "Found a SP outside the platform": ProjectStatus.Canceled,
    "Project was canceled": ProjectStatus.Canceled,
    "Never reached the client": ProjectStatus.Canceled,
    "Stopped Answering / Became Unresponsive": ProjectStatus.Canceled,
  };

  return projectStatus[reason];
};

// getStatusColor returns the appropriate color depending on status
export const getProjectStatusColor = (status: string): string => {
  const colors: { [index: string]: string } = {
    Active: "green",
    Canceled: "red",
    Rejected: "volcano",
    Completed: "geekblue",
    PendingApproval: "gold",
    FilterCandidates: "purple",
    EvaluateFinalists: "blue",
  };

  return colors[status];
};

export const getStatusTooltip = (status: string): string => {
  const colors: { [index: string]: string } = {
    PendingApproval: "New Project",
    Active: "Ready to start drip",
    FilterCandidates: "Maximum number of raised hands reached",
    EvaluateFinalists: "Maximum number of shortlist reached",
    Completed: "This will trigger unselected emails",
    Canceled: "Canceled",
    Rejected: "Rejected",
  };

  return colors[status];
};

// getStatusColor returns the appropriate color depending on status
export const getStatusColor = (status: string): string => {
  const colors: { [index: string]: string } = {
    Active: "success",
    Canceled: "error",
    Rejected: "error",
    Completed: "processing",
    PENDING: "processing",
    Pending: "processing",
    APPROVED: "success",
    Approved: "success",
    ACTIVE: "success",
    NEW: "success",
    New: "success",
    VERIFIED: "success",
    Verified: "success",
    OnGoing: "processing",
    Waiting: "processing",
    Sent: "success",
    Stopped: "error",
    Errored: "error",
    Unverified: "warning",
    Suspended: "error",
    Delivered: "success",
    Blacklisted: "error",
    PendingApproval: "warning",
    FilterCandidates: "purple",
    EvaluateFinalists: "blue",
    ProviderReferral: "success",
  };

  return colors[status];
};

const uuidRegexString =
  "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$";

export const validateUUID = (input: string): boolean => {
  const uuidPattern = new RegExp(uuidRegexString);
  return uuidPattern.test(input);
};

