import { FormInstance } from "antd";
import { useCallback } from "react";
import { Company } from "../../../../features/entities";

interface UsePrepareFundingExplorerCompanyFiltersProps {
  form: FormInstance;
  setSelectedIndustries: React.Dispatch<React.SetStateAction<string[]>>;
  company: Company;
}

const usePrepareFundingExplorerCompanyFilters = ({
  form,
  setSelectedIndustries,
  company,
}: UsePrepareFundingExplorerCompanyFiltersProps) => {
  return useCallback(() => {
    if (!company) return false;

    if (!!company.industry_sector) {
      form.setFieldValue("industry", company.industry_sector);
      setSelectedIndustries(company.industry_sector);
    }

    if (!!company.industry_subsector?.length) {
      form.setFieldValue("subindustry", company.industry_subsector);
    }

    if (!!company.province?.length) {
      form.setFieldValue("region", company.province.split(","));
    }
    if (!!company.size?.length) {
      form.setFieldValue("companySize", company.size);
    }
    if (!!company.annual_revenue?.length) {
      form.setFieldValue("annualRevenue", company.annual_revenue);
    }
  }, [company, form, setSelectedIndustries]);
};

export default usePrepareFundingExplorerCompanyFilters;

