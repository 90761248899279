import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import Form, { useForm } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import Select from "antd/es/select";
import { useState } from "react";
import { FormGroupResponse } from "../../../../../features/entities/form-entities";
import { getShortId } from "../../../../../features/helpers";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Clipboard from "../../../../common/clipboard";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import FormItem from "../../../form-layouts/form-item";
import FormLabel from "../../../form-layouts/form-label";
import { FormBuilderGroupEditButtonProps } from "../../types";

const FormBuilderGroupEditButton = ({
  builderGroup,
  functions,
}: FormBuilderGroupEditButtonProps) => {
  const theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModal = () => setEditModalOpen(!editModalOpen);
  const [form] = useForm<FormGroupResponse>();

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    const editedGroup: FormGroupResponse = {
      ...builderGroup.entity,
      ...values,
    };

    functions.updateFormGroup(editedGroup);
  };

  return (
    <>
      <Button
        size="circle"
        trailingIcon={<Edit width={16} height={16} />}
        onClick={handleEditModal}
        defaultStyle={theme.colors.purple_2}
      />
      <Drawer
        open={editModalOpen}
        onClose={handleEditModal}
        title={
          <Div
            align="center"
            flex="row"
            style={{ height: "100%" }}
            justify="space-between"
          >
            <Div fitContent flex="row" gap={8} align="center">
              <Typography elementTheme="subtitle1" lineClamp={3}>
                Group :
              </Typography>
              <Typography
                elementTheme="subtitle1"
                color={theme.colors.purple_1}
                colorHover={theme.colors.purple_2}
              >
                <Clipboard
                  title={getShortId(builderGroup.entity.form_group_id)}
                  copy={getShortId(builderGroup.entity.form_group_id)}
                />
              </Typography>
            </Div>

            <Button
              size="circle"
              defaultStyle={theme.colors.white_1}
              onClick={handleSubmit}
            >
              <Save width={16} height={16} />
            </Button>
          </Div>
        }
      >
        <Form layout="vertical" form={form} initialValues={builderGroup.entity}>
          <Div flex="column" gap={24}>
            <FormItem label={<FormLabel label="Group ID" />}>
              <Input
                value={getShortId(builderGroup.entity.form_group_id)}
                disabled
              />
            </FormItem>
            <FormItem
              name={"group_title_en"}
              label={<FormLabel label="Title EN" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"group_title_fr"}
              label={<FormLabel label="Title FR" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"group_disposition"}
              label={<FormLabel label="Disposition" />}
            >
              <Select
                options={[
                  { label: "Horizontal", value: "horizontal" },
                  { label: "Vertical", value: "vertical" },
                ]}
              />
            </FormItem>
            <FormItem
              label={
                <FormLabel
                  label="Group Order"
                  extra="Please use the builder to change the order."
                />
              }
            >
              <Input value={builderGroup.entity.group_order} disabled />
            </FormItem>
          </Div>
        </Form>
      </Drawer>
    </>
  );
};
export default FormBuilderGroupEditButton;

