import {
  ApiError,
  ApiErrorInitialState,
  ChatType,
  HdChat,
  HdCreateChatRequest,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  PayloadAction,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const chatsAdapter = createEntityAdapter({
  selectId: (model: HdChat) => model.id,
  sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

export interface ChatState {
  status: "idle" | "pending";
  error: ApiError;
  chats: EntityState<HdChat, string>;
  pagination: {
    page: number;
    size: number;
  };
  activeTab: string;
  selectedChatType: ChatType;
  previousChatType: ChatType;
}

const initialState: ChatState = {
  status: "idle",
  error: ApiErrorInitialState,
  chats: chatsAdapter.getInitialState(),
  pagination: { page: 1, size: 20 },
  activeTab: "",
  selectedChatType: ChatType.GrantApplication,
  previousChatType: ChatType.GrantApplication,
};

export const fetchAllChats = createAsyncThunk<
  HdChat[],
  { api: AdminApiClient; page: number; size: number, type: ChatType },
  { rejectValue: ApiError }
>(
  "admin/fetchAllChats",
  async (
    { api, page, size, type }: { api: AdminApiClient; page: number; size: number; type: ChatType },
    { rejectWithValue }
  ) => {
    try {
      return await api.queryChats(page, size, type);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchChatById = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chatId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchChatById",
  async (
    { api, chatId }: { api: AdminApiClient; chatId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchChatById(chatId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNewChat = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chat: HdCreateChatRequest },
  { rejectValue: ApiError }
>(
  "admin/createNewChat",
  async (
    { api, chat }: { api: AdminApiClient; chat: HdCreateChatRequest },
    { rejectWithValue }
  ) => {
    try {
      return await api.createNewChat(chat);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteChat = createAsyncThunk<
  string,
  { api: AdminApiClient; chatId: string },
  { rejectValue: ApiError }
>(
  "admin/deleteChat",
  async (
    { api, chatId }: { api: AdminApiClient; chatId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteChat(chatId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateChat = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chat: HdChat },
  { rejectValue: ApiError }
>(
  "admin/updateChat",
  async (
    { api, chat }: { api: AdminApiClient; chat: HdChat },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateChat(chat);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectChatTemplate = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chatId: string; templateId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/SelectChatGrant",
  async (
    {
      api,
      chatId,
      templateId,
      locale,
    }: {
      api: AdminApiClient;
      chatId: string;
      templateId: string;
      locale: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.selectChatTemplate(chatId, templateId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeChatTemplate = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chatId: string },
  { rejectValue: ApiError }
>(
  "admin/removeChatGrant",
  async (
    { api, chatId }: { api: AdminApiClient; chatId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.removeChatTemplate(chatId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectChatCompany = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chatId: string; companyId: string },
  { rejectValue: ApiError }
>(
  "admin/updateChatCompany",
  async (
    {
      api,
      chatId,
      companyId,
    }: { api: AdminApiClient; chatId: string; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.selectChatCompany(chatId, companyId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeChatCompany = createAsyncThunk<
  HdChat,
  { api: AdminApiClient; chatId: string },
  { rejectValue: ApiError }
>(
  "admin/removeChatCompany",
  async (
    { api, chatId }: { api: AdminApiClient; chatId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.removeChatCompany(chatId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const exportChat = createAsyncThunk<
  string,
  {
    api: AdminApiClient;
    chatId: string;
    answerIds: string[];
    exportType: string;
  },
  { rejectValue: ApiError }
>(
  "admin/exportChat",
  async (
    {
      api,
      chatId,
      answerIds,
      exportType,
    }: {
      api: AdminApiClient;
      chatId: string;
      answerIds: string[];
      exportType: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.exportChat(chatId, answerIds, exportType);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const setActiveTab = createAction<string>("admin/setActiveTab");
export const selectActiveTab = (state: RootState) => state.hdChats.activeTab;

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setSelectedChatType(state, action: PayloadAction<ChatType>) {
      state.previousChatType = state.selectedChatType;
      state.selectedChatType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(fetchAllChats.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAllChats.fulfilled, (state, { payload }) => {
      if (state.previousChatType !== state.selectedChatType) {
        chatsAdapter.removeAll(state.chats);
      }
      if (payload) {
        chatsAdapter.upsertMany(state.chats, payload);
      }
      state.status = "idle";
      state.previousChatType = state.selectedChatType;
    });
    builder.addCase(fetchAllChats.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchChatById.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchChatById.fulfilled, (state, { payload }) => {
      chatsAdapter.upsertOne(state.chats, payload);
      state.status = "idle";
    });
    builder.addCase(fetchChatById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(selectChatTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(selectChatTemplate.fulfilled, (state, action) => {
      const chatId = action.payload.id;
      const chat = state.chats.entities[chatId] as HdChat | undefined;
      if (chat) {
        chat.template_id = action.payload.template_id;
      }
      state.status = "idle";
    });
    builder.addCase(selectChatTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(removeChatTemplate.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeChatTemplate.fulfilled, (state, action) => {
      const chatId = action.payload.id;
      const chat = state.chats.entities[chatId] as HdChat | undefined;
      if (chat) {
        chat.template_id = "";
      }
      state.status = "idle";
    });
    builder.addCase(removeChatTemplate.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(selectChatCompany.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(selectChatCompany.fulfilled, (state, action) => {
      const chatId = action.payload.id;
      const chat = state.chats.entities[chatId] as HdChat | undefined;
      if (chat) {
        chat.company_id = action.payload.company_id;
      }
      state.status = "idle";
    });
    builder.addCase(selectChatCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(removeChatCompany.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeChatCompany.fulfilled, (state, action) => {
      const chatId = action.payload.id;
      const chat = state.chats.entities[chatId] as HdChat | undefined;
      if (chat) {
        chat.company_id = "";
      }
      state.status = "idle";
    });
    builder.addCase(removeChatCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateChat.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateChat.fulfilled, (state, { payload }) => {
      chatsAdapter.updateOne(state.chats, { changes: payload, id: payload.id });
      state.status = "idle";
    });
    builder.addCase(updateChat.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteChat.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(deleteChat.fulfilled, (state, { payload }) => {
      chatsAdapter.removeOne(state.chats, payload);
      state.status = "idle";
    });
    builder.addCase(deleteChat.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllChats, selectById: selectChatById } =
  chatsAdapter.getSelectors((state: RootState) => state.hdChats.chats);

export const selectChatsPagination = (state: RootState) =>
  state.hdChats.pagination;

export const selectChatsLoading = (state: RootState) =>
  state.hdChats.status === "pending";

export const selectSelectedChatType = (state: RootState) =>
  state.hdChats.selectedChatType;

export const { setSelectedChatType } = chatsSlice.actions;

export const chatsReducer = chatsSlice.reducer;