import { SelectProps } from "antd/es/select";
import { useMemo } from "react";
import { useTranslation } from "../../../../../plugins/i18n";

const useGetOptions = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    const statusOptions: SelectProps["options"] = [
      {
        label: t(`grants|grantCardStatus.new`),
        value: "new",
      },
      {
        label: t(`grants|grantCardStatus.openingSoon`),
        value: "openingSoon",
      },
      {
        label: t(`grants|grantCardStatus.open`),
        value: "open",
      },
      {
        label: t(`grants|grantCardStatus.closingSoon`),
        value: "closingSoon",
      },
      {
        label: t(`grants|grantCardStatus.closed`),
        value: "closed",
      },
    ];
    const filterOptions: SelectProps["options"] = [
      {
        label: t(`grants|fundingExplorerFilter.filterByOption.recommended`),
        value: "",
      },
      {
        label: t(`grants|fundingExplorerFilter.filterByOption.latest`),
        value: "latest",
      },
      {
        label: t(`grants|fundingExplorerFilter.filterByOption.oldest`),
        value: "oldest",
      },
    ];

    return { statusOptions, filterOptions };
  }, [t]);
};

export default useGetOptions;

