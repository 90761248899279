import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import { Grant } from "@hellodarwin/core/lib/features/entities/grants-entities";
import dollarFormat from "@hellodarwin/core/lib/features/helpers/dollar-format";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Email from "@hellodarwin/icons/dist/icons/Email";
import Enterprise from "@hellodarwin/icons/dist/icons/Enterprise";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import React, { useMemo } from "react";

const useGinGrantSummary = (grant?: Grant) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  return useMemo(() => {
    if (!grant) return [];
    const {
      funding_min_amount,
      funding_max_amount,
      percentage_funding,
      grant_timeline,
      grant_financing_type,
      grant_providers,
      grant_industry_sectors,
    } = grant;
    return [
      {
        label: t("grant_single|eligibleFinancing"),
        Icon: Budget,
        children: (
          <ul>
            {" "}
            {!!funding_min_amount && !!funding_max_amount ? (
              <li>
                {t("grant_single|fromMinToMax", {
                  min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                  max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                })}
              </li>
            ) : !!funding_min_amount ? (
              <li>
                {t("grant_single|minCount", {
                  count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                })}
              </li>
            ) : (
              !!funding_max_amount && (
                <li>
                  {t("grant_single|maxCount", {
                    count: `${dollarFormat(
                      funding_max_amount,
                      selectedLocale
                    )}`,
                  })}
                </li>
              )
            )}
            {!!percentage_funding && (
              <li>
                {t("grant_single|projectCostPercent", {
                  percent: `${percentage_funding}`,
                })}
              </li>
            )}
            {!funding_min_amount &&
              !funding_max_amount &&
              !percentage_funding && <li>{t("grant_single|noCondition")}</li>}
          </ul>
        ),
      },
      {
        /* TODO change when added in contenful */
        label: "Template de courriels de lancement",
        Icon: Email,
        children: (
          <ul>
            <li>{"companyxyz.com/link-to-doc"}</li>
          </ul>
        ),
      },
      {
        label: t("grant_single|deadlines"),
        Icon: Timeline,
        children: (
          <ul>
            {!!grant_timeline && !!grant_timeline[0]?.opened_at && (
              <li>
                {t("grant_single|openingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].opened_at),
                  selectedLocale
                )}
              </li>
            )}
            {!!grant_timeline && !!grant_timeline[0]?.closed_at && (
              <li>
                {t("grant_single|closingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].closed_at),
                  selectedLocale
                )}
              </li>
            )}
            {(!grant_timeline ||
              (!grant_timeline[0]?.opened_at &&
                !grant_timeline[0]?.closed_at)) && (
              <li>{t("grant_single|timelineUnspecified")}</li>
            )}
          </ul>
        ),
      },
      {
        label: t("grant_single|financingType"),
        Icon: Invoices,
        children: (
          <Div flex="row" wrap="wrap" gap={8}>
            {React.Children.toArray(
              grant_financing_type?.map((type) => (
                <HdTag color={theme.colors.purple_2} text={type} />
              ))
            )}
          </Div>
        ),
      },
      {
        label: t("grant_single|grantors"),
        Icon: Enterprise,
        children: (
          <ul>
            {" "}
            {!!grant_providers?.length ? (
              React.Children.toArray(
                grant_providers.map((tag) => <li key={tag}>{tag}</li>)
              )
            ) : (
              <li>{t("grant_single|unspecified")}</li>
            )}
          </ul>
        ),
      },
      {
        label: t("grant_single|eligibleIndustries"),
        Icon: Industry,
        children: (
          <ul>
            {!!grant_industry_sectors?.length ? (
              React.Children.toArray(
                grant_industry_sectors.map((tag) => <li key={tag}>{tag}</li>)
              )
            ) : (
              <li>{t("grant_single|allIndustries")}</li>
            )}
          </ul>
        ),
      },
    ];
  }, [grant, selectedLocale, theme, t]);
};
export default useGinGrantSummary;

