import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import Accueil from "@hellodarwin/icons/dist/icons/Accueil";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import AddPage from "@hellodarwin/icons/dist/icons/AddPage";
import Ads from "@hellodarwin/icons/dist/icons/Ads";
import Adviser from "@hellodarwin/icons/dist/icons/Adviser";
import ArrowOutline from "@hellodarwin/icons/dist/icons/ArrowOutline";
import Attachment from "@hellodarwin/icons/dist/icons/Attachment";
import BottomArrow from "@hellodarwin/icons/dist/icons/BottomArrow";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import BurgerMenu from "@hellodarwin/icons/dist/icons/BurgerMenu";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Chat from "@hellodarwin/icons/dist/icons/Chat";
import Check from "@hellodarwin/icons/dist/icons/Check";
import Checkmark from "@hellodarwin/icons/dist/icons/Checkmark";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import Copy from "@hellodarwin/icons/dist/icons/Copy";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Design from "@hellodarwin/icons/dist/icons/Design";
import Desktop from "@hellodarwin/icons/dist/icons/Desktop";
import Development from "@hellodarwin/icons/dist/icons/Development";
import Doc from "@hellodarwin/icons/dist/icons/Doc";
import Dots from "@hellodarwin/icons/dist/icons/Dots";
import Download from "@hellodarwin/icons/dist/icons/Download";
import Ecommerce from "@hellodarwin/icons/dist/icons/Ecommerce";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Efficiency from "@hellodarwin/icons/dist/icons/Efficiency";
import Email from "@hellodarwin/icons/dist/icons/Email";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Enterprise from "@hellodarwin/icons/dist/icons/Enterprise";
import Error from "@hellodarwin/icons/dist/icons/Error";
import Event from "@hellodarwin/icons/dist/icons/Event";
import Expand from "@hellodarwin/icons/dist/icons/Expand";
import FacebookLogo from "@hellodarwin/icons/dist/icons/FacebookLogo";
import FacebookLogoOutline from "@hellodarwin/icons/dist/icons/FacebookLogoOutline";
import Favorite from "@hellodarwin/icons/dist/icons/Favorite";
import Filter from "@hellodarwin/icons/dist/icons/Filter";
import Fingerprint from "@hellodarwin/icons/dist/icons/Fingerprint";
import Flag from "@hellodarwin/icons/dist/icons/Flag";
import GoogleLogo from "@hellodarwin/icons/dist/icons/GoogleLogo";
import Grants from "@hellodarwin/icons/dist/icons/Grants";
import GreenCheck from "@hellodarwin/icons/dist/icons/GreenCheck";
import GridViewFill from "@hellodarwin/icons/dist/icons/GridViewFill";
import GridViewOutline from "@hellodarwin/icons/dist/icons/GridViewOutline";
import Happy from "@hellodarwin/icons/dist/icons/Happy";
import HdAcademy from "@hellodarwin/icons/dist/icons/HdAcademy";
import Hello from "@hellodarwin/icons/dist/icons/Hello";
import Help from "@hellodarwin/icons/dist/icons/Help";
import Hide from "@hellodarwin/icons/dist/icons/Hide";
import HubspotLogo from "@hellodarwin/icons/dist/icons/HubspotLogo";
import Image from "@hellodarwin/icons/dist/icons/Image";
import ImagePlus from "@hellodarwin/icons/dist/icons/ImagePlus";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import InformationCircle from "@hellodarwin/icons/dist/icons/InformationCircle";
import InstagramLogo from "@hellodarwin/icons/dist/icons/InstagramLogo";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import Key from "@hellodarwin/icons/dist/icons/Key";
import Language from "@hellodarwin/icons/dist/icons/Language";
import Launch from "@hellodarwin/icons/dist/icons/Launch";
import LeftArrow from "@hellodarwin/icons/dist/icons/LeftArrow";
import LineViewFill from "@hellodarwin/icons/dist/icons/LineViewFill";
import LineViewOutline from "@hellodarwin/icons/dist/icons/LineViewOutline";
import Link from "@hellodarwin/icons/dist/icons/Link";
import LinkedinLogo from "@hellodarwin/icons/dist/icons/LinkedinLogo";
import LinkedinLogoOutline from "@hellodarwin/icons/dist/icons/LinkedinLogoOutline";
import Location from "@hellodarwin/icons/dist/icons/Location";
import Locked from "@hellodarwin/icons/dist/icons/Locked";
import Logout from "@hellodarwin/icons/dist/icons/Logout";
import Marketing from "@hellodarwin/icons/dist/icons/Marketing";
import Minimize from "@hellodarwin/icons/dist/icons/Minimize";
import MinusCircleFilled from "@hellodarwin/icons/dist/icons/MinusCircleFilled";
import MinusCircleOutline from "@hellodarwin/icons/dist/icons/MinusCircleOutline";
import Mobile from "@hellodarwin/icons/dist/icons/Mobile";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import News from "@hellodarwin/icons/dist/icons/News";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Notifications from "@hellodarwin/icons/dist/icons/Notifications";
import Order from "@hellodarwin/icons/dist/icons/Order";
import PauseProject from "@hellodarwin/icons/dist/icons/PauseProject";
import PayPerClick from "@hellodarwin/icons/dist/icons/PayPerClick";
import PDF from "@hellodarwin/icons/dist/icons/PDF";
import Performance from "@hellodarwin/icons/dist/icons/Performance";
import Phone from "@hellodarwin/icons/dist/icons/Phone";
import Photo from "@hellodarwin/icons/dist/icons/Photo";
import PinterestLogo from "@hellodarwin/icons/dist/icons/PinterestLogo";
import Print from "@hellodarwin/icons/dist/icons/Print";
import Profile from "@hellodarwin/icons/dist/icons/Profile";
import Projects from "@hellodarwin/icons/dist/icons/Projects";
import ProjectsList from "@hellodarwin/icons/dist/icons/ProjectsList";
import ProjectTags from "@hellodarwin/icons/dist/icons/ProjectTags";
import Propulsion from "@hellodarwin/icons/dist/icons/Propulsion";
import RaisedHand from "@hellodarwin/icons/dist/icons/RaisedHand";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import RecommendNo from "@hellodarwin/icons/dist/icons/RecommendNo";
import RecommendYes from "@hellodarwin/icons/dist/icons/RecommendYes";
import Reference from "@hellodarwin/icons/dist/icons/Reference";
import Refereral from "@hellodarwin/icons/dist/icons/Refereral";
import Refresh from "@hellodarwin/icons/dist/icons/Refresh";
import RefreshHubspot from "@hellodarwin/icons/dist/icons/RefreshHubspot";
import Resources from "@hellodarwin/icons/dist/icons/Resources";
import RestartProject from "@hellodarwin/icons/dist/icons/RestartProject";
import RightArrow from "@hellodarwin/icons/dist/icons/RightArrow";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Search from "@hellodarwin/icons/dist/icons/Search";
import Send from "@hellodarwin/icons/dist/icons/Send";
import Service from "@hellodarwin/icons/dist/icons/Service";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import Share from "@hellodarwin/icons/dist/icons/Share";
import Sort from "@hellodarwin/icons/dist/icons/Sort";
import Stats from "@hellodarwin/icons/dist/icons/Stats";
import StepType from "@hellodarwin/icons/dist/icons/StepType";
import Strategy from "@hellodarwin/icons/dist/icons/Strategy";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import TeamSize from "@hellodarwin/icons/dist/icons/TeamSize";
import Telecom from "@hellodarwin/icons/dist/icons/Telecom";
import ThreadsLogo from "@hellodarwin/icons/dist/icons/ThreadsLogo";
import TiktokLogo from "@hellodarwin/icons/dist/icons/TiktokLogo";
import Time from "@hellodarwin/icons/dist/icons/Time";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Transformation from "@hellodarwin/icons/dist/icons/Transformation";
import TwitterLogoOutline from "@hellodarwin/icons/dist/icons/TwitterLogoOutline";
import Unlocked from "@hellodarwin/icons/dist/icons/Unlocked";
import Upload from "@hellodarwin/icons/dist/icons/Upload";
import User from "@hellodarwin/icons/dist/icons/User";
import Verified from "@hellodarwin/icons/dist/icons/Verified";
import Video from "@hellodarwin/icons/dist/icons/Video";
import View from "@hellodarwin/icons/dist/icons/View";
import WaitingMoney from "@hellodarwin/icons/dist/icons/WaitingMoney";
import Warning from "@hellodarwin/icons/dist/icons/Warning";
import XCircle from "@hellodarwin/icons/dist/icons/XCircle";
import XOutlined from "@hellodarwin/icons/dist/icons/XOutlined";
import YoutubeLogo from "@hellodarwin/icons/dist/icons/YoutubeLogo";
import ZoomIn from "@hellodarwin/icons/dist/icons/ZoomIn";
import ZoomOut from "@hellodarwin/icons/dist/icons/ZoomOut";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import { useState } from "react";

const Icons: { [key: string]: IconNode } = {
  Accueil: Accueil,
  AddNew: AddNew,
  AddPage: AddPage,
  Ads: Ads,
  Adviser: Adviser,
  ArrowOutline: ArrowOutline,
  Attachment: Attachment,
  BottomArrow: BottomArrow,
  Budget: Budget,
  BurgerMenu: BurgerMenu,
  CancelProject: CancelProject,
  Chat: Chat,
  Check: Check,
  Checkmark: Checkmark,
  Close: Close,
  Confirm: Confirm,
  Copy: Copy,
  Delete: Delete,
  Design: Design,
  Desktop: Desktop,
  Development: Development,
  Doc: Doc,
  Dots: Dots,
  Download: Download,
  Ecommerce: Ecommerce,
  Edit: Edit,
  Efficiency: Efficiency,
  Email: Email,
  Employees: Employees,
  Enterprise: Enterprise,
  Error: Error,
  Event: Event,
  Expand: Expand,
  FacebookLogo: FacebookLogo,
  FacebookLogoOutline: FacebookLogoOutline,
  Favorite: Favorite,
  Filter: Filter,
  Fingerprint: Fingerprint,
  Flag: Flag,
  GoogleLogo: GoogleLogo,
  Grants: Grants,
  GreenCheck: GreenCheck,
  GridViewFill: GridViewFill,
  GridViewOutline: GridViewOutline,
  Happy: Happy,
  HdAcademy: HdAcademy,

  Help: Help,
  Hide: Hide,
  HubspotLogo: HubspotLogo,
  Image: Image,
  ImagePlus: ImagePlus,
  Industry: Industry,
  InformationCircle: InformationCircle,
  InstagramLogo: InstagramLogo,
  Invoices: Invoices,
  Key: Key,
  Language: Language,
  Launch: Launch,
  LeftArrow: LeftArrow,
  LineViewFill: LineViewFill,
  LineViewOutline: LineViewOutline,
  Link: Link,
  LinkedinLogo: LinkedinLogo,
  LinkedinLogoOutline: LinkedinLogoOutline,
  Location: Location,
  Locked: Locked,
  Logout: Logout,
  Marketing: Marketing,
  Minimize: Minimize,
  MinusCircleFilled: MinusCircleFilled,
  MinusCircleOutline: MinusCircleOutline,
  Mobile: Mobile,
  MoreCandidates: MoreCandidates,
  News: News,
  NewWindow: NewWindow,
  Notifications: Notifications,
  Order: Order,
  PauseProject: PauseProject,
  PayPerClick: PayPerClick,
  PDF: PDF,
  Performance: Performance,
  Phone: Phone,
  Photo: Photo,
  PinterestLogo: PinterestLogo,
  Print: Print,
  Profile: Profile,
  Projects: Projects,
  ProjectsList: ProjectsList,
  ProjectTags: ProjectTags,
  Propulsion: Propulsion,
  RaisedHand: RaisedHand,
  RatingFill: RatingFill,
  RatingOutline: RatingOutline,
  RecommendNo: RecommendNo,
  RecommendYes: RecommendYes,
  Reference: Reference,
  Refereral: Refereral,
  Refresh: Refresh,
  RefreshHubspot: RefreshHubspot,
  Resources: Resources,
  RestartProject: RestartProject,
  RightArrow: RightArrow,
  Save: Save,
  Search: Search,
  Send: Send,
  Service: Service,
  Settings: Settings,
  Share: Share,
  Sort: Sort,
  Stats: Stats,
  StepType: StepType,
  Strategy: Strategy,
  TabArrow: TabArrow,
  TeamSize: TeamSize,
  Telecom: Telecom,
  ThreadsLogo: ThreadsLogo,
  TiktokLogo: TiktokLogo,
  Time: Time,
  Timeline: Timeline,
  Transformation: Transformation,
  TwitterLogoOutline: TwitterLogoOutline,
  Unlocked: Unlocked,
  Upload: Upload,
  User: User,
  Verified: Verified,
  Video: Video,
  View: View,
  WaitingMoney: WaitingMoney,
  Warning: Warning,
  XCircle: XCircle,
  XOutlined: XOutlined,
  YoutubeLogo: YoutubeLogo,
  ZoomIn: ZoomIn,
  ZoomOut: ZoomOut,
  Hello: Hello,
};

const IconViewer = ({
  Icon,
  name,
  size,
}: {
  Icon: IconNode;
  name: string;
  size: number;
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={name}>
      <div>
        <Icon size={size} color={theme.colors.primary} />
      </div>
    </Tooltip>
  );
};
const IconsViewerPage = () => {
  const [size, setSize] = useState(32);

  return (
    <PageLayout
      app="admin"
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Icons Viewer",
        },
      ]}
      title="Icons Viewer"
      actions={
        <Select
          value={size}
          onChange={setSize}
          options={[
            { label: "64px", value: 64 },
            { label: "32px", value: 32 },
            { label: "24px", value: 24 },
            { label: "16px", value: 16 },
          ]}
        />
      }
    >
      <Container>
        <Div flex="row" gap={8} wrap="wrap">
          {Object.keys(Icons).map((key) => (
            <IconViewer Icon={Icons[key]} name={key} size={size} />
          ))}
        </Div>
      </Container>
    </PageLayout>
  );
};

export default IconsViewerPage;

