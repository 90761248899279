import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import DislikeOutlined from "@ant-design/icons/DislikeOutlined";
import LikeOutlined from "@ant-design/icons/LikeOutlined";
import StarFilled from "@ant-design/icons/lib/icons/StarFilled";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import {
  ReviewStatus,
  ReviewVerified,
} from "@hellodarwin/core/lib/features/entities";
import {
  getFormattedDate,
  getPaginationTotal,
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { Col, Row } from "antd/es/grid";
import Select from "antd/es/select";
import Statistic from "antd/es/statistic";
import Table, { ColumnsType } from "antd/es/table";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";

import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminApi } from "../features/api/use-admin-api";
import theme from "../theme";
const ReviewsPage = () => {
  const { t } = useTranslation();
  const api = useAdminApi();
  const [reviewsStatus, setReviewsStatus] = useState<ReviewStatus>();
  const [reviewsVerified, setReviewsVerified] = useState<ReviewVerified>();

  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 50;

  type PageState = {
    reviews: any[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    reviews: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });

        const response = await api.fetchReviews(page, size);
        const newPageState = {
          reviews: response?.map((review, index) => ({
            key: index,
            ...review,
          })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, true, false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Project",
      dataIndex: "project_id",
      key: "project_id",
      render: (v) =>
        v ? (
          <Link target="_blank" to={`/projects/${v}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
      width: 110,
    },
    {
      title: "Contact",
      dataIndex: "contact_name",
      key: "contact_id",
      render: (v, r) =>
        v ? (
          <Link target="_blank" to={`/clients/${r.company_id}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "contact_email",
      key: "contact_email",
      render: (v) => (
        <Tooltip placement="topLeft" title={v}>
          {v}
        </Tooltip>
      ),
      width: 150,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Provider",
      dataIndex: "provider_name",
      key: "provider_id",
      render: (v, r) => (
        <Link target="_blank" to={`/providers/${r.provider_id}`}>
          {getShortId(v)}
        </Link>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v, r) => (
        <Select
          style={{ width: "100%" }}
          value={v}
          onChange={(selectedOption) => {
            v = selectedOption;
            r.status = v;
            api.updateReview(r).then((response) => {
              fetch(
                pageState.pagination.page,
                pageState.pagination.size,
                apiState.isLoading,
                apiState.isSearching
              );
            });
            setReviewsStatus(reviewsStatus);
          }}
        >
          {statusOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ),
      width: 125,
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      render: (v, r) => (
        <Select
          style={{ width: "100%" }}
          value={v}
          onChange={(selectedOption) => {
            v = selectedOption;
            r.verified = v;
            api.updateReview(r).then((response) => {
              fetch(
                pageState.pagination.page,
                pageState.pagination.size,
                apiState.isLoading,
                apiState.isSearching
              );
            });
            setReviewsVerified(reviewsVerified);
          }}
        >
          {verifiedOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ),
      width: 125,
    },
    {
      title: "Average",
      dataIndex: "average",
      key: "average",
      sorter: {
        compare: (a, b) => (a.average || 0) - (b.average || 0),
      },
      width: 90,
    },
    {
      title: (
        <Tooltip title="Recommend">
          <CheckCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "recommend",
      key: "recommend",
      render: (v) => `${v ? "Yes" : "No"}`,
      width: 75,
    },
    {
      title: "Date",
      dataIndex: "reviewed_at",
      key: "reviewed_at",
      render: (v) => (
        <Tooltip title={getFormattedDate(v)}>
          {getShortFormattedDate(v)}
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) =>
          new Date(a.reviewed_at || 0).getTime() -
          new Date(b.reviewed_at || 0).getTime(),
      },
      width: 125,
    },
    {
      title: "Source",
      dataIndex: "review_source",
      key: "review_source",
      render: (v) => <Tag>{v}</Tag>,
      width: 100,
    },
    Table.EXPAND_COLUMN,
  ];

  const { Option } = Select;

  const statusOptions = Object.values(ReviewStatus).map((status) => ({
    value: status,
    label: status.charAt(0) + status.slice(1),
  }));

  const verifiedOptions = Object.values(ReviewVerified).map((status) => ({
    value: status,
    label: status.charAt(0) + status.slice(1),
  }));

  return (
    <PageLayout
      app="admin"
      title={"Reviews"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Reviews",
        },
      ]}
    >
      <>
        <Table
          dataSource={pageState.reviews}
          columns={columns}
          pagination={{
            pageSize: pageState.pagination.size,
            current: pageState.pagination.page,
            total: getPaginationTotal(
              pageState.pagination.page,
              pageState.pagination.size,
              pageState.reviews?.length
            ),
            showSizeChanger: true,
            onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
          }}
          loading={apiState.isLoading}
          expandable={{
            expandedRowRender: (record) => (
              <Row gutter={24} align={"middle"}>
                <Col span={9}>
                  <p
                    style={{
                      marginLeft: "2%",
                      wordWrap: "break-word",
                      color: "var(--grey-2, #5C5C5C)",
                      fontSize: "17px",
                      fontWeight: 400,
                      lineHeight: "151%",
                    }}
                  >
                    {record.description_summary}
                  </p>
                </Col>
                <Col span={13}>
                  <Row
                    justify={"end"}
                    align={"middle"}
                    style={{
                      justifyContent: "space-evenly",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Col span={4}>
                      <Statistic
                        title={t("Recommend")}
                        value={record.recommend ? "Yes" : "No"}
                        style={{
                          color: theme.colors.grey_3,
                          fontSize: "10px",
                          fontWeight: 500,
                          width: "fit-content",
                        }}
                        valueStyle={{
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                        prefix={
                          record.recommend ? (
                            <LikeOutlined
                              style={{
                                color: "var(--green-1, #00F0AF)",
                              }}
                            />
                          ) : (
                            <DislikeOutlined
                              style={{
                                color: "#F82E47",
                              }}
                            />
                          )
                        }
                      />
                    </Col>
                    {record.rating_budget !== undefined &&
                      record.rating_budget !== 0 && (
                        <Col span={3}>
                          <Statistic
                            title={t("reviews|card.rating.budget")}
                            value={`${record.rating_budget} / 5`}
                            style={{
                              letterSpacing: "0.27px",
                              color: theme.colors.grey_3,
                              fontSize: "14px",
                              fontWeight: 500,
                              width: "min-content",
                            }}
                            valueStyle={{
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            prefix={<StarFilled style={{ color: "#E9A904" }} />}
                          />
                        </Col>
                      )}
                    {record.rating_quality !== undefined &&
                      record.rating_quality !== 0 && (
                        <Col span={3}>
                          <Statistic
                            title={t("Outcome")}
                            value={`${record.rating_quality} / 5`}
                            style={{
                              letterSpacing: "0.27px",
                              color: theme.colors.grey_3,
                              fontSize: "14px",
                              fontWeight: 500,
                              width: "min-content",
                            }}
                            valueStyle={{
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            prefix={<StarFilled style={{ color: "#E9A904" }} />}
                          />
                        </Col>
                      )}
                    {record.rating_schedule !== undefined &&
                      record.rating_schedule !== 0 && (
                        <Col span={3}>
                          <Statistic
                            title={t("reviews|card.rating.schedule")}
                            value={`${record.rating_schedule} / 5`}
                            style={{
                              letterSpacing: "0.27px",
                              color: theme.colors.grey_3,
                              fontSize: "14px",
                              fontWeight: 500,
                              width: "min-content",
                            }}
                            valueStyle={{
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            prefix={<StarFilled style={{ color: "#E9A904" }} />}
                          />
                        </Col>
                      )}
                    {record.rating_collaboration !== undefined &&
                      record.rating_collaboration !== 0 && (
                        <Col span={3}>
                          <Statistic
                            title={t("Relationship")}
                            value={`${record.rating_collaboration} / 5`}
                            style={{
                              letterSpacing: "0.27px",
                              color: theme.colors.grey_3,
                              fontSize: "14px",
                              fontWeight: 500,
                              width: "min-content",
                            }}
                            valueStyle={{
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            prefix={<StarFilled style={{ color: "#E9A904" }} />}
                          />
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
            ),
          }}
        />
      </>
    </PageLayout>
  );
};

export default ReviewsPage;

