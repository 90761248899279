import {
  CancelProjectBody,
  PotentialProviderMatch,
} from "@hellodarwin/core/lib/features/entities";
import message from "antd/es/message";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectProjectsModal,
  selectSelectedProject,
  toggleProjectsModal,
} from "../../../features/api/slices/projects-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { getProjectStatus } from "../../../features/utils";
import CancelProjectModal from "./cancel-project-modal";
import CreateProjectForm from "./create-project-form";
import PotentialProvidersModal from "./potential-provider-modal";
import ProjectDiscountModal from "./project-discount-modal";

const ProjectActionModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const api = useAdminApi();
  const modal = useAppSelector(selectProjectsModal);
  const project = useAppSelector(selectSelectedProject) || "";

  const [potentialMatches, setPotentialMatches] = useState<
    PotentialProviderMatch[]
  >({} as PotentialProviderMatch[]);

  const handleClose = () => {
    dispatch(toggleProjectsModal({ isVisible: false, type: "" }));
  };

  const getPotentialMatches = () => {
    (async () => {
      try {
        var matches = await api.getPotentialMatches(project.project_id);
        setPotentialMatches(matches);
      } catch (e) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    switch (modal.type) {
      case "potentialProvider":
        getPotentialMatches();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.type]);

  const handleCancel = async (canceledReason: string) => {
    try {
      const newStatus = getProjectStatus(canceledReason);
      const cancelCtx: CancelProjectBody = {
        status: newStatus,
        canceled_reason: canceledReason,
      };

      await api.cancelProject(project.project_id || "", cancelCtx);

      navigate(0);
      message.success("Project Canceled!");
      handleClose();
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
      handleClose();
    }
  };

  const sendDiscountNotification = async () => {
    if (!project.project_id) return;
    try {
      await api.sendDiscountNotification(project.project_id);
      message.success("Notification sent");
      handleClose();
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
      handleClose();
    }
  };

  switch (modal.type) {
    case "create":
      return (
        <CreateProjectForm
          visible={modal.isVisible}
          handleClose={handleClose}
        />
      );
    case "cancel":
      return (
        <CancelProjectModal
          modalVisible={modal.isVisible}
          handleCancel={handleCancel}
          closeModal={handleClose}
          matches={project.matches || []}
        />
      );
    case "potentialProvider":
      return (
        <PotentialProvidersModal
          modalVisible={modal.isVisible}
          closeModal={handleClose}
          matches={potentialMatches}
        />
      );
    case "discount":
      return (
        <ProjectDiscountModal
          modalVisible={modal.isVisible}
          sendDiscountNotification={sendDiscountNotification}
          closeModal={handleClose}
        />
      );
    default:
      return <></>;
  }
};

export default ProjectActionModal;
