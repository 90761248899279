import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

const validateAnyType =
  ({ message, isRequired }: { message: string; isRequired?: boolean }) =>
  (_: RuleObject, value: StoreValue) => {
    if (!isRequired) return Promise.resolve();

    switch (typeof value) {
      case "undefined":
        return Promise.reject(new Error(message));
      case "boolean":
      case "number":
      case "bigint":
        return Promise.resolve();
      case "string":
        return !!value?.length
          ? Promise.resolve()
          : Promise.reject(new Error(message));
      case "symbol":
      case "function":
      case "object":
      default:
        return Promise.reject(new Error(message));
    }
  };

export default validateAnyType;

