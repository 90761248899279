import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantProviderFormValues } from "@hellodarwin/core/lib/features/entities";
import theme from "@hellodarwin/core/lib/theme";
import { Col, Popconfirm, Row } from "antd";
import Form, { FormInstance } from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import React from "react";
import ReactQuill from "react-quill";
import { quillModules } from "../../components/text-editor";

interface FormRepresentativeProps {
  form: FormInstance<GrantProviderFormValues>;
  initialValues: GrantProviderFormValues;
  selectedContact: number | null;
  grantProvided: { label: string; value: string }[];
  createRepresentative: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const FormRepresentative: React.FC<FormRepresentativeProps> = ({
  form,
  initialValues,
  selectedContact,
  grantProvided,
  createRepresentative,
  onSave,
  onDelete,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      name="grant"
      style={{ width: "88%" }}
    >
      <Div flex="column" gap={8}>
        <Div flex="row" justify="space-between" align="center">
          <Typography.Title level={4} elementTheme="h6">
            Mandataries
          </Typography.Title>
          <Div style={{ height: "fit-content", width: "fit-content" }}>
            {selectedContact === null ? (
              <Button
                onClick={async () => {
                  createRepresentative();
                }}
                size="small"
              >
                Add
              </Button>
            ) : (
              <Div flex="row" gap={8}>
                <Popconfirm
                  title="Delete mandated Agent"
                  description="Are you sure to delete this mandated Agent?"
                  onConfirm={async () => {
                    onDelete();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="small"
                    fitContent
                    style={{ padding: 8, color: theme.colors.white_1 }}
                    defaultStyle={theme.colors.red_2}
                  >
                    Delete
                  </Button>
                </Popconfirm>
                <Button onClick={async () => onSave()} size="small">
                  Save
                </Button>
              </Div>
            )}
          </Div>
        </Div>

        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please add a first name for this mandated ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please add a last name for this mandated Agent",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please add a title for this mandated Agent",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={8}>
            <Form.Item label="Phone Number" name="phone_number">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Extension" name="extension">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Language"
              name="language"
              rules={[
                {
                  required: true,
                  message: "Please add a language for this mandated Agent",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="fr">French</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please add an email for this mandated Agens",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Grant"
              name="grant_id"
              rules={[
                {
                  required: true,
                  message:
                    "Please add an associated grant for this mandated Agent",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                {grantProvided &&
                  grantProvided.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Notes" name="notes">
              <ReactQuill
                className="text-editor"
                theme="snow"
                modules={quillModules}
              />
            </Form.Item>
          </Col>
        </Row>
      </Div>
    </Form>
  );
};

export default FormRepresentative;

