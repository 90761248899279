import Typography from "@hellodarwin/core/lib/components/common/typography";
import theme from "@hellodarwin/core/lib/theme";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { Link } from "react-router-dom";
import { RelatedLinkContainer } from "./styles";

interface RelatedLinkProps {
  label: string;
  to: string;
}

export const RelatedLink = ({ label, to }: RelatedLinkProps) => {
  return (
    <Link target="_blank" to={to}>
      <RelatedLinkContainer>
        <Typography color={theme.colors.purple_1} elementTheme="body3">
          {label}
        </Typography>

        <NewWindow
          style={{ flexShrink: 0 }}
          color={theme.colors.purple_1}
          width={16}
          height={16}
        />
      </RelatedLinkContainer>
    </Link>
  );
};

