import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { AppType } from "../../../features/entities/core-entities";
import {
  EditableFormSubmissionResponse,
  FormsSubmissionItem,
} from "../../../features/entities/form-entities";
import Div from "../../common/div";
import FormSubmissionEditor from "./form-submission-editor";
import getMappedSubmissionItems from "./form-submission-editor/utils/get-mapped-submission-items";
import FormSubmissionFieldsList from "./form-submission-fields-list";
import FormSubmissionHeader from "./form-submission-header";
import { FormData, FormEditorMode } from "./types";

export interface FormSumbissionViewerProps {
  formSubmission: EditableFormSubmissionResponse;
  handleSave: (items: FormsSubmissionItem[]) => Promise<void>;
  source: AppType;
  page_name: string;
}

const FormSumbissionViewer = (props: FormSumbissionViewerProps) => {
  const [form] = useForm<FormData>();

  const [mode, setMode] = useState<FormEditorMode>("table");
  const handleMode = () => {
    setMode((prevMode) => (prevMode === "table" ? "edit" : "table"));
  };

  const { formSubmission, handleSave, source } = props;
  const mappedSubmissionItems = getMappedSubmissionItems({ formSubmission });

  const [hasChanged, setHasChanged] = useState(false);

  const handleChange = () => {
    setHasChanged(true);
  };

  const onFinish = () => {
    const { submission } = formSubmission;
    try {
      form.validateFields().then(async (values) => {
        const submissionItems = Object.keys(values).map((form_item_id) => {
          const submissionItem: FormsSubmissionItem = {
            ...mappedSubmissionItems[form_item_id].forms_submissions_item,
            form_item_id,
            form_submission_id: submission?.form_submission_id,
            value: values[form_item_id],
          };
          return submissionItem;
        });

        await handleSave(submissionItems);
        setHasChanged(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div flex="column" gap={24}>
      <FormSubmissionHeader
        formSubmission={props.formSubmission}
        mode={mode}
        handleMode={handleMode}
        hasChanged={hasChanged}
        onFinish={onFinish}
      />
      {mode === "table" ? (
        <FormSubmissionFieldsList formSubmission={formSubmission} />
      ) : (
        <FormSubmissionEditor
          formSubmission={formSubmission}
          handleChange={handleChange}
          mappedSubmissionItems={mappedSubmissionItems}
          form={form}
          source={source}
        />
      )}
    </Div>
  );
};

export default FormSumbissionViewer;

