import { useTheme } from "styled-components";
import { GrantCardProps } from ".";
import { useTranslations } from "../../../features/providers/translations-provider";
import { Tablet } from "../../common/Media";
import Button from "../../common/button";
import Div from "../../common/div";
import Typography from "../../common/typography";
import GrantCardStatusList from "./grant-card-status-list";
import { GrantLogo } from "./styles";

const GrantCardHeader = ({
  isAdmin,
  featuredTags,
  search_score,
  grant_logo,
  country,
  city,
  province,
  grant_display_title,
  application_status,
  grant_description_short,
}: GrantCardProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <Div
      flex="row"
      gap={32}
      style={{ padding: "24px 32px 20px 17px" }}
      tablet={{ flex: "column", align: "center" }}
    >
      <GrantLogo src={grant_logo} alt={`${grant_display_title}`}>
        {grant_display_title[0]}
      </GrantLogo>

      <Div flex="column" gap={12} style={{ overflow: "hidden" }}>
        <GrantCardStatusList
          featuredTags={featuredTags}
          application_status={application_status}
        />
        <Div flex="column" gap={8}>
          <Typography.Title level={3} elementTheme="h6">
            {grant_display_title}
          </Typography.Title>
          <Typography elementTheme="body2" color={theme.colors.grey_2}>
            {grant_description_short}
          </Typography>
        </Div>
      </Div>
      <Tablet direction="up">
        <Div fitContent flex="column" align="flex-end" gap={8}>
          <Typography elementTheme="body3" color={theme.colors.grey_2}>
            {city}
            {!!city && province && ", "}
            {province}
            {(!!city || province) && country && ", "}
            {country}
          </Typography>
          <Button
            withArrowRight
            size="small"
            defaultStyle={theme.colors.purple_2}
          >
            {t("grant_card|learnMore")}
          </Button>
          {isAdmin && search_score && (
            <Div flex="column" align="flex-end">
              <Typography
                elementTheme="body3"
                color={theme.colors.grey_2}
                ellipsis
              >
                Search score: {search_score}
              </Typography>
            </Div>
          )}
        </Div>
      </Tablet>
    </Div>
  );
};

export default GrantCardHeader;

