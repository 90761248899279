import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import StatusTags from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/status-tags";
import {
  GinApplication,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  selectGinApplication,
  toggleApplicationModalForm,
  toggleApplicationModalMessage,
  updateGinApplication,
} from "../../../features/api/slices/gins-slice";
import { useNewAdminApi } from "../../../features/api/use-admin-api";
import ApplicationMessage from "./application-action-modal/application-message";
import CreateApplicationForm from "./application-action-modal/create-application-form";

const GinApplicationHeaderContent = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm<GinApplication>();
  const api = useNewAdminApi();
  const theme = useTheme();
  const { t } = useTranslations();
  const application = useAppSelector(selectGinApplication);

  if (!application) return <></>;
  const { application_name, application_company_name } = application;

  const applicationOptionsStatus = Object.entries(ProgramGrantStatus).map(
    ([key, value]) => ({
      key: key,
      value: value,
      label: t(`programs_labels|${value}`),
    })
  );

  const handleSave = async (applicationStatus: string) => {
    try {
      const values = await form.validateFields();
      dispatch(
        toggleApplicationModalForm({
          isVisible: false,
          applicationStatus: "",
        })
      );
      const updatedApplication = {
        ...application,
        ...values,
        application_status: applicationStatus,
      };
      const result = await dispatch(
        updateGinApplication({ api, updatedApplication })
      );
      if (updateGinApplication.fulfilled.match(result)) {
        dispatch(
          toggleApplicationModalMessage({
            isVisible: true,
            isSuccessful: true,
            applicationStatus: result.payload.application_status,
          })
        );
      } else {
        dispatch(
          toggleApplicationModalMessage({
            isVisible: true,
            isSuccessful: false,
            applicationStatus: "",
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnSelect = async (value: string) => {
    switch (value) {
      case ProgramGrantStatus.OngoingInformationRequired:
      case ProgramGrantStatus.ValidationRequired:
      case ProgramGrantStatus.NotSubmited:
        handleSave(value);
        break;
      default:
        dispatch(
          toggleApplicationModalForm({
            isVisible: true,
            applicationStatus: value,
          })
        );
    }
  };

  return (
    <Div flex="column" gap={12}>
      <Div onClick={(e) => e.stopPropagation()}>
        <Select
          onSelect={(value) => {
            handleOnSelect(value);
          }}
          placeholder="Select program grant status"
          value={application.application_status}
          style={{ width: "300px" }}
          options={applicationOptionsStatus}
        />
        <CreateApplicationForm form={form} handleSave={handleSave} />
        <ApplicationMessage />
      </Div>
      <Div flex="column" gap={2}>
        <Div flex="row" gap={16}>
          <Typography
            elementTheme="subtitle1"
            tablet={{ textAlign: "center" }}
            color={theme.colors.grey_2}
          >
            {t("application_single|companyApplication", {
              company: application_company_name,
            })}
          </Typography>
          {!!application.application_status && (
            <StatusTags
              application_status={application.application_program_status}
            />
          )}
        </Div>
        <Typography.Title
          level={1}
          elementTheme="h5"
          noMargin
          tablet={{ textAlign: "center" }}
        >
          {application_name}
        </Typography.Title>
      </Div>
    </Div>
  );
};

export default GinApplicationHeaderContent;

