import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import { GinApplication } from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Form, { FormInstance } from "antd/es/form";
import Select from "antd/es/select";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app";
import {
  fetchAdmins,
  selectAllAdmins,
} from "../../../../../features/api/slices/admins-slice";
import { useAdminApi } from "../../../../../features/api/use-admin-api";
import RenderInputNumber from "./application-form-input-number";

const ConfirmedOpportunitiesForm = ({
  form,
  application,
}: {
  form: FormInstance<GinApplication>;
  application: GinApplication;
}) => {
  const { t } = useTranslations();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchAdmins({
        api,
        needsAuth0: false,
        page: 0,
        limit: 100,
        searchedTerm: "",
      })
    );
  }, [api, dispatch]);

  const admins = useAppSelector(selectAllAdmins);
  const adminOptions = admins.map((admin) => ({
    key: admin.admin_id,
    value: admin.admin_id,
    label: `${admin.first_name} ${admin.last_name}`,
  }));
  return (
    <Form form={form} layout="vertical" initialValues={application}>
      <FormItem
        name="application_account_manager"
        label={
          <Typography elementTheme="body2">
            {t("application_single|application_manager")}
          </Typography>
        }
        rules={[
          {
            required: true,
            message: t("application_single|account_manager_required"),
          },
        ]}
      >
        <Select
          showSearch
          options={adminOptions}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toLowerCase()
              .indexOf(input?.toLowerCase()) !== -1
          }
        />
      </FormItem>
      <RenderInputNumber
        label="targeted_amount"
        name="application_targeted_amount"
      />
    </Form>
  );
};

export default ConfirmedOpportunitiesForm;

