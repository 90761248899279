import { useTheme } from "styled-components";
import {
  ArrowOutlineStyled,
  TriggerStyled,
} from "../../../layout/navigation/styles";
import SidebarBlock, { SidebarBlockConfig } from "./sidebar-block";
import { SidebarPageSiderContainer } from "./styles";

interface SidebarPageSiderProps {
  sidebarBlocks: SidebarBlockConfig[];
  isCollapsed: boolean;
  handleCollapse: () => void;
  side: "right" | "left";
}

const SidebarPageSider = ({
  sidebarBlocks,
  side,
  isCollapsed,
  handleCollapse,
}: SidebarPageSiderProps) => {
  const theme = useTheme();
  if (!sidebarBlocks?.length) return <></>;
  return (
    <SidebarPageSiderContainer
      $position={side}
      collapsible
      collapsed={isCollapsed}
      onCollapse={handleCollapse}
      width={300}
      collapsedWidth={20}
      trigger={
        <TriggerStyled style={{ top: 150 }}>
          <ArrowOutlineStyled
            size={24}
            style={{
              transform:
                (isCollapsed && side === "left") ||
                (!isCollapsed && side === "right")
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              transition: "transform 500ms linear",
            }}
          />
        </TriggerStyled>
      }
    >
      {!isCollapsed &&
        sidebarBlocks.map((block, index) => (
          <>
            <SidebarBlock {...block} />
            {index !== sidebarBlocks.length - 1 && (
              <span
                style={{
                  width: "100%",
                  borderBottom: `2px solid ${theme.colors.grey_5}`,
                }}
              />
            )}
          </>
        ))}
    </SidebarPageSiderContainer>
  );
};

export default SidebarPageSider;

