import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Video = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5385 13.8667L28.5095 7.1538C28.6645 7.00482 28.8617 6.90338 29.0765 6.8623C29.2912 6.82122 29.5138 6.84234 29.7161 6.92299C29.9184 7.00365 30.0913 7.14022 30.213 7.31546C30.3347 7.4907 30.3998 7.69675 30.4 7.90758V24.0925C30.3998 24.3033 30.3347 24.5093 30.213 24.6846C30.0913 24.8598 29.9184 24.9964 29.7161 25.0771C29.5138 25.1577 29.2912 25.1788 29.0765 25.1378C28.8617 25.0967 28.6645 24.9952 28.5095 24.8462L21.5385 18.1334M4.92308 25.6H18.2154C19.0967 25.6 19.942 25.2629 20.5652 24.6628C21.1884 24.0626 21.5385 23.2487 21.5385 22.4V9.60002C21.5385 8.75133 21.1884 7.9374 20.5652 7.33728C19.942 6.73717 19.0967 6.40002 18.2154 6.40002H4.92308C4.04175 6.40002 3.19651 6.73717 2.57331 7.33728C1.95011 7.9374 1.60001 8.75133 1.60001 9.60002V22.4C1.60001 23.2487 1.95011 24.0626 2.57331 24.6628C3.19651 25.2629 4.04175 25.6 4.92308 25.6Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Video;

