import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
const ThreadsLogo = (props: IconProps) => {
  const iconProps = useIconProps(props);
  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2733 14.8107C22.0573 10.828 19.8813 8.548 16.228 8.52533C14.0253 8.512 12.184 9.44533 11.056 11.156L13.0667 12.5333C13.912 11.252 15.2467 10.9867 16.2133 10.996C17.4173 11.004 18.324 11.3533 18.912 12.036C19.3387 12.532 19.6253 13.2187 19.768 14.084C18.7013 13.9027 17.5467 13.8467 16.3147 13.9173C12.84 14.1173 10.608 16.144 10.7573 18.9587C10.8333 20.3867 11.544 21.616 12.76 22.4187C13.7867 23.0973 15.112 23.428 16.4867 23.3533C18.304 23.2533 19.7293 22.56 20.724 21.2933C21.4787 20.3307 21.956 19.0827 22.1667 17.512C23.0333 18.0347 23.6733 18.7213 24.0293 19.548C24.632 20.9533 24.6667 23.2627 22.7827 25.1453C21.132 26.7933 19.148 27.508 16.1493 27.5293C12.8227 27.5053 10.3067 26.4373 8.67201 24.3587C7.13867 22.412 6.34801 19.5987 6.31867 16C6.34801 12.4 7.14001 9.588 8.67201 7.64C10.3067 5.56133 12.8227 4.49467 16.1493 4.47067C19.5 4.496 22.0587 5.56667 23.7573 7.65733C24.5907 8.68133 25.2173 9.96933 25.632 11.4707L27.9867 10.844C27.484 8.99467 26.6947 7.40133 25.6213 6.08C23.444 3.4 20.244 2.028 16.1413 2C12.0467 2.028 8.89734 3.40667 6.78267 6.09467C4.90134 8.488 3.92934 11.836 3.89734 16.0093C3.92934 20.1827 4.90134 23.512 6.78267 25.9053C8.89867 28.5933 12.0627 29.972 16.156 30C19.7973 29.9747 22.3627 29.0213 24.476 26.9107C27.2427 24.1467 27.1587 20.684 26.248 18.5587C25.548 16.9253 24.1547 15.6173 22.2747 14.812L22.2733 14.8107ZM16.356 20.8867C14.8333 20.972 13.252 20.2893 13.1733 18.8253C13.116 17.74 13.9467 16.5293 16.4493 16.3853C17.5804 16.3094 18.7166 16.3874 19.8267 16.6173C19.5387 20.22 17.8467 20.804 16.356 20.8867Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThreadsLogo;

