import {
  ApiError,
  ApiErrorInitialState,
  Milestone,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const milestoneAdapter = createEntityAdapter<Milestone, string>({
  selectId: (model: Milestone) => model.milestone_id,
});

export interface MilestoneState {
  status: "idle" | "pending";
  error: ApiError;
  milestones: EntityState<Milestone, string>;
}

const initialState: MilestoneState = {
  status: "idle",
  error: ApiErrorInitialState,
  milestones: milestoneAdapter.getInitialState(),
};

export const createMilestone = createAsyncThunk<
  Milestone,
  { api: AdminApiClient; milestone: Milestone },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/createMilestone",
  async (
    { api, milestone }: { api: AdminApiClient; milestone: Milestone },
    { rejectWithValue }
  ) => {
    try {
      return await api.createMilestone(milestone);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { milestones } = getState();
      if (milestones.status === "pending") return false;
    },
  }
);

export const deleteProgram = createAsyncThunk<
  string,
  { api: AdminApiClient; programId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/deleteProgram",
  async (
    { api, programId }: { api: AdminApiClient; programId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteProgram(programId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMilestone = createAsyncThunk<
  Milestone,
  { api: AdminApiClient; milestone: Milestone },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateMilestone",
  async (
    { api, milestone }: { api: AdminApiClient; milestone: Milestone },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateMilestone(milestone);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { milestones } = getState();
      if (milestones.status === "pending") return false;
    },
  }
);

export const fetchMilestones = createAsyncThunk<
  Milestone[],
  { api: AdminApiClient; programId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/fetchMilestones",
  async (
    { api, programId }: { api: AdminApiClient; programId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.findMilestones(programId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { milestones } = getState();
      if (milestones.status === "pending") return false;
    },
  }
);

const milestonesSlice = createSlice({
  name: "milestones",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMilestone.fulfilled, (state, { payload }) => {
      milestoneAdapter.setOne(state.milestones, payload);
      state.status = "idle";
    });
    builder.addCase(createMilestone.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createMilestone.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateMilestone.fulfilled, (state, { payload }) => {
      milestoneAdapter.updateOne(state.milestones, {
        id: payload.milestone_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(updateMilestone.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateMilestone.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchMilestones.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        milestoneAdapter.addMany(state.milestones, payload);
      }
      state.status = "idle";
    });
    builder.addCase(fetchMilestones.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchMilestones.pending, (state) => {
      state.status = "pending";
    });
  },
});

// Custom selector to get milestones by milestone_program_id
export const selectMilestonesByProgramId = createSelector(
  [
    (state: RootState, _) => selectAllMilestones(state),
    (_, programId: string) => programId,
  ],
  (milestones, programId) =>
    milestones.filter(
      (milestone) => milestone.milestone_program_id === programId
    )
);

// Your existing selectors remain unchanged
export const {
  selectAll: selectAllMilestones,
  selectById: selectMilestoneById,
} = milestoneAdapter.getSelectors(
  (state: RootState) => state.milestones.milestones
);

export const selectMilestonesLoading = (state: RootState) =>
  state.milestones.status === "pending";

export const milestonesReducer = milestonesSlice.reducer;

