import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import StatusTags from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/status-tags";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { useAppSelector } from "../../../app";
import { selectCurrentGin } from "../../../features/api/slices/gins-slice";

const GinGrantHeaderContent = () => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const adminGin = useAppSelector(selectCurrentGin);

  if (!adminGin) return <></>;
  const {
    grant: {
      application_status,
      grant_title_en,
      grant_title_fr,
      grant_description_short_en,
      grant_description_short_fr,
    },
  } = adminGin;

  return (
    <Div flex="column" gap={12}>
      {application_status && (
        <StatusTags application_status={application_status} />
      )}
      <Div flex="column" gap={2}>
        <Typography
          elementTheme="subtitle1"
          tablet={{ textAlign: "center" }}
          color={theme.colors.grey_2}
        >
          {t("gin_program|program")}
        </Typography>
        <Typography.Title
          level={1}
          elementTheme="h5"
          noMargin
          tablet={{ textAlign: "center" }}
        >
          {selectedLocale === "fr" ? grant_title_fr : grant_title_en}
        </Typography.Title>
      </Div>
      <Typography
        elementTheme="body1"
        color={theme.colors.grey_2}
        tablet={{ textAlign: "center" }}
      >
        {selectedLocale === "fr"
          ? grant_description_short_fr
          : grant_description_short_en}
      </Typography>
    </Div>
  );
};

export default GinGrantHeaderContent;

