import {
  ApiError,
  ApiErrorInitialState,
  HdChatQuestion,
  HdChatSubQuestion,
  HdChatSubQuestionRequest,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const questionsAdapter = createEntityAdapter({
  selectId: (model: HdChatQuestion) => model.id,
});

export interface QuestionsState {
  status: "idle" | "pending";
  error: ApiError;
  questions: EntityState<HdChatQuestion, string>;
  pagination: {
    page: number;
    size: number;
  };
}

const initialState: QuestionsState = {
  status: "idle",
  error: ApiErrorInitialState,
  questions: questionsAdapter.getInitialState(),
  pagination: { page: 1, size: 50 },
};

export const fetchChatQuestions = createAsyncThunk<
  HdChatQuestion[],
  { api: AdminApiClient; chatId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchChatQuestions",
  async (
    {
      api,
      chatId,
      locale,
    }: { api: AdminApiClient; chatId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchChatQuestions(chatId, locale);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateQuestionAnswer = createAsyncThunk<
  HdChatQuestion,
  { api: AdminApiClient; chatId: string; questionId: string; context: string },
  { rejectValue: ApiError }
>(
  "admin/generateQuestionAnswer",
  async (
    {
      api,
      chatId,
      questionId,
      context,
    }: {
      api: AdminApiClient;
      chatId: string;
      questionId: string;
      context: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateQuestionAnswer(chatId, questionId, context);
    } catch (err: any) {
      const generateError = err.response.data;
      if (generateError && generateError.cause) {
        generateError.error += `: ${generateError.cause}`;
      }
      showErrorNotification(generateError);
      return rejectWithValue(generateError);
    }
  }
);

export const generateSubQuestionAnswer = createAsyncThunk<
  { questionId: string; subQuestion: HdChatSubQuestion },
  {
    api: AdminApiClient;
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  },
  { rejectValue: ApiError }
>(
  "admin/generateSubQuestionAnswer",
  async (
    {
      api,
      chatId,
      questionId,
      subQuestion,
    }: {
      api: AdminApiClient;
      chatId: string;
      questionId: string;
      subQuestion: HdChatSubQuestion;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateSubQuestionAnswer(
        chatId,
        questionId,
        subQuestion
      );
    } catch (err: any) {
      const generateError = err.response.data;
      if (generateError && generateError.cause) {
        generateError.error += `: ${generateError.cause}`;
      }
      showErrorNotification(generateError);
      return rejectWithValue(generateError);
    }
  }
);

// export const addQuestionToChat = createAsyncThunk<
//   HdChatQuestion,
//   { api: AdminApiClient; chatId: string; promptId: string; locale: string },
//   { rejectValue: ApiError }
// >(
//   "admin/addQuestionToChat",
//   async (
//     {
//       api,
//       chatId,
//       promptId,
//       locale,
//     }: {
//       api: AdminApiClient;
//       chatId: string;
//       promptId: string;
//       locale: string;
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       return await api.addQuestionToChat(chatId, promptId, locale);
//     } catch (err: any) {
//       console.error(err.response.data);
//       showErrorNotification(err.response.data);
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

// export const removeQuestionFromChat = createAsyncThunk<
//   {
//     questionId: string;
//   },
//   { api: AdminApiClient; chatId: string; questionId: string },
//   { rejectValue: ApiError }
// >(
//   "admin/removeQuestionFromChat",
//   async (
//     {
//       api,
//       chatId,
//       questionId,
//     }: { api: AdminApiClient; chatId: string; questionId: string },
//     { rejectWithValue }
//   ) => {
//     try {
//       return await api.removeQuestionFromChat(chatId, questionId);
//     } catch (err: any) {
//       console.error(err.response.data);
//       showErrorNotification(err.response.data);
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const addSubQuestionToChat = createAsyncThunk<
  { questionId: string; subQuestion: HdChatSubQuestion },
  {
    api: AdminApiClient;
    questionId: string;
    subQuestion: HdChatSubQuestionRequest;
  },
  { rejectValue: ApiError }
>(
  "admin/addSubQuestionToChat",
  async (
    {
      api,
      questionId,
      subQuestion,
    }: {
      api: AdminApiClient;
      questionId: string;
      subQuestion: HdChatSubQuestionRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.addSubQuestionToChat(questionId, subQuestion);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeSubQuestionFromChat = createAsyncThunk<
  {
    questionId: string;
    answerId: string;
    subQuestionId: string;
  },
  {
    api: AdminApiClient;
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  },
  { rejectValue: ApiError }
>(
  "admin/removeSubQuestionFromChat",
  async (
    {
      api,
      chatId,
      questionId,
      subQuestion,
    }: {
      api: AdminApiClient;
      chatId: string;
      questionId: string;
      subQuestion: HdChatSubQuestion;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.removeSubQuestionFromChat(
        chatId,
        questionId,
        subQuestion
      );
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSubQuestion = createAsyncThunk<
  { questionId: string; subQuestion: HdChatSubQuestion },
  {
    api: AdminApiClient;
    chatId: string;
    questionId: string;
    subQuestion: HdChatSubQuestion;
  },
  { rejectValue: ApiError }
>(
  "admin/updateSubQuestion",
  async (
    {
      api,
      chatId,
      questionId,
      subQuestion,
    }: {
      api: AdminApiClient;
      chatId: string;
      questionId: string;
      subQuestion: HdChatSubQuestion;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateSubQuestion(chatId, questionId, subQuestion);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatQuestions.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchChatQuestions.fulfilled, (state, action) => {
      if (action.payload) {
        questionsAdapter.upsertMany(state.questions, action.payload);
      }
      state.status = "idle";
    });
    builder.addCase(fetchChatQuestions.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(generateQuestionAnswer.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(generateQuestionAnswer.fulfilled, (state, action) => {
      const updatedQuestion = action.payload;
      questionsAdapter.updateOne(state.questions, {
        id: updatedQuestion.id,
        changes: updatedQuestion,
      });
      state.status = "idle";
    });
    builder.addCase(generateQuestionAnswer.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(generateSubQuestionAnswer.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(generateSubQuestionAnswer.fulfilled, (state, action) => {
      const { questionId, subQuestion } = action.payload;
      const question = state.questions.entities[questionId] as
        | HdChatQuestion
        | undefined;
      if (question && question.answers) {
        question.answers.forEach((answer) => {
          if (answer.id === subQuestion.answer_id) {
            if (answer.sub_questions) {
              const index = answer.sub_questions.findIndex(
                (sq) => sq.id === subQuestion.id
              );
              if (index !== -1) {
                answer.sub_questions[index] = subQuestion;
              }
            }
          }
        });
      }
      state.status = "idle";
    });

    builder.addCase(
      generateSubQuestionAnswer.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    // builder.addCase(addQuestionToChat.pending, (state) => {
    //   state.status = "pending";
    // });
    // builder.addCase(addQuestionToChat.fulfilled, (state, action) => {
    //   const newQuestion = action.payload;
    //   questionsAdapter.addOne(state.questions, newQuestion);
    //   state.status = "idle";
    // });
    // builder.addCase(addQuestionToChat.rejected, (state, { payload }) => {
    //   state.error = payload ?? ApiErrorInitialState;
    //   state.status = "idle";
    // });
    // builder.addCase(removeQuestionFromChat.pending, (state) => {
    //   state.status = "pending";
    // });
    // builder.addCase(removeQuestionFromChat.fulfilled, (state, action) => {
    //   const { questionId } = action.payload;
    //   questionsAdapter.removeOne(state.questions, questionId);
    //   state.status = "idle";
    // });
    // builder.addCase(removeQuestionFromChat.rejected, (state, { payload }) => {
    //   state.error = payload ?? ApiErrorInitialState;
    //   state.status = "idle";
    // });
    builder.addCase(addSubQuestionToChat.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(addSubQuestionToChat.fulfilled, (state, action) => {
      const { questionId, subQuestion } = action.payload;
      const question = state.questions.entities[questionId] as
        | HdChatQuestion
        | undefined;
      if (question && question.answers) {
        question.answers.forEach((answer) => {
          if (answer.id === subQuestion.answer_id) {
            answer.sub_questions = answer.sub_questions
              ? [...answer.sub_questions, subQuestion]
              : [subQuestion];
          }
        });
      }
      state.status = "idle";
    });
    builder.addCase(addSubQuestionToChat.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(removeSubQuestionFromChat.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeSubQuestionFromChat.fulfilled, (state, action) => {
      const { questionId, answerId, subQuestionId } = action.payload;
      const question = state.questions.entities[questionId] as
        | HdChatQuestion
        | undefined;
      if (question && question.answers) {
        question.answers.forEach((answer) => {
          if (answer.id === answerId && answer.sub_questions) {
            answer.sub_questions = answer.sub_questions.filter(
              (sq) => sq.id !== subQuestionId
            );
          }
        });
      }
      state.status = "idle";
    });
    builder.addCase(
      removeSubQuestionFromChat.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
    builder.addCase(updateSubQuestion.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateSubQuestion.fulfilled, (state, action) => {
      const { questionId, subQuestion } = action.payload;
      const question = state.questions.entities[questionId] as
        | HdChatQuestion
        | undefined;
      if (question && question.answers) {
        question.answers.forEach((answer) => {
          if (answer.id === subQuestion.answer_id && answer.sub_questions) {
            answer.sub_questions = answer.sub_questions.map((sq) =>
              sq.id === subQuestion.id ? subQuestion : sq
            );
          }
        });
      }
      state.status = "idle";
    });
    builder.addCase(updateSubQuestion.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllQuestions, selectById: selectQuestionById } =
  questionsAdapter.getSelectors(
    (state: RootState) => state.hdChatQuestions.questions
  );

export const selectChatQuestions = createSelector(
  [
    (state: RootState, _) => state.hdChatQuestions.questions.entities,
    (_, chatId: string) => chatId,
  ],
  (questions, chatId) =>
    Object.values(questions).filter((question) => question.chat_id === chatId)
);
export const selectQuestionsPagination = (state: RootState) =>
  state.hdChatQuestions.pagination;

export const selectQuestionsLoading = (state: RootState) =>
  state.hdChatQuestions.status === "pending";

export const questionsReducer = questionsSlice.reducer;

