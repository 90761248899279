import Email from "@hellodarwin/icons/dist/icons/Email";
import Location from "@hellodarwin/icons/dist/icons/Location";
import Phone from "@hellodarwin/icons/dist/icons/Phone";
import { WebsiteGrantResult } from "../../../../../features/entities";
import Div from "../../../../common/div";
import InfoTile from "./info-tile";

const GrantContact = ({
  application_email_address,
  application_phone_number,
}: WebsiteGrantResult) => {
  return (
    <Div flex="column" gap={24}>
      <InfoTile Icon={Email} value={application_email_address} />
      <InfoTile Icon={Phone} value={application_phone_number} />
      <InfoTile Icon={Location} />
    </Div>
  );
};

export default GrantContact;
