import { Match, Project } from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import { useAppDispatch } from "../../app";
import {
  setSelectedProject,
  toggleProjectsModal,
} from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type ProjectStatusDropdownProps = {
  project: Project;
  matches: Match[];
  handleActivationVerification: () => void;
  setProject: (project: Project) => void;
  forceUpdate: () => void;
  handleSave: () => Promise<void>;
};

const ProjectStatusDropdown = ({
  project,
  matches,
  handleActivationVerification,
  setProject,
  forceUpdate,
  handleSave,
}: ProjectStatusDropdownProps) => {
  const { t } = useTranslation();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const onProjectCompletion = () => {
    const matches_raise_hand = matches.filter(
      (m) =>
        !!m.raised_hand_at &&
        !m.purchased_at &&
        !m.shortlisted_at &&
        !m.raise_hand_rejected_at
    );
    const emailed_providers = matches_raise_hand.map((p) => p.provider.name);

    if (
      [
        ProjectStatus.Rejected,
        ProjectStatus.Canceled,
        ProjectStatus.PendingApproval,
      ].includes(project.status)
    ) {
      message.error("You can't go from Pending or Rejected to Completed...");
    } else {
      Modal.confirm({
        title: "Are you sure you want to Complete this project ?",
        content: `${emailed_providers.slice(0, -1).join(", ")}${
          emailed_providers.length > 1 ? " and " : ""
        }${emailed_providers.slice(
          -1
        )} will receive an email to notify them of the project's completion.`,
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          return new Promise(async (resolve, reject) => {
            try {
              await api.completeProject(project.project_id);

              const newProject = project;
              newProject.status = ProjectStatus.Completed;
              setProject(newProject);

              forceUpdate();

              message.success("Project Completed!");
              resolve("Project Completed");
            } catch (e: any) {
              message.error("Something went wrong, try again later!");
              console.error(e);
              reject(e);
            }
          });
        },
      });
    }
  };
  const onProjectPending = () => {
    if ([ProjectStatus.Completed].includes(project.status)) {
      message.error("You can't go from Completed to Pending...");
    } else {
      Modal.confirm({
        title: "Are you sure you want to put this project at Pending ?",
        content:
          "Maybe it's preferrable that you Cancel the project instead. Do it only if you are absolutely sure of what you are doing.",
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          onProjectStatusUpdate(ProjectStatus.PendingApproval);
        },
      });
    }
  };

  const onProjectActivation = async () => {
    handleActivationVerification();
  };

  const onProjectStatusUpdate = async (newStatus: ProjectStatus) => {
    try {
      await handleSave();
      const newProject = await api.updateProjectStatus(
        project.project_id,
        newStatus
      );

      setProject(newProject);

      forceUpdate();

      message.success(`Project was set to ${newStatus}!`);
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const handleChange = (newStatus: ProjectStatus) => {
    if (project.status !== newStatus) {
      switch (newStatus) {
        case ProjectStatus.Canceled:
          dispatch(setSelectedProject(project));
          dispatch(toggleProjectsModal({ isVisible: true, type: "cancel" }));
          break;
        case ProjectStatus.Rejected:
          dispatch(setSelectedProject(project));
          break;
        case ProjectStatus.Completed:
          onProjectCompletion();
          break;
        case ProjectStatus.Active:
          onProjectActivation();
          break;
        case ProjectStatus.PendingApproval:
          onProjectPending();
          break;
        default:
          if (!project.activated_at) {
            onProjectActivation();
          }
          onProjectStatusUpdate(newStatus);
          break;
      }
    }
  };

  return (
    <>
      <Select
        value={project.status}
        onChange={handleChange}
        style={{ width: 160 }}
        size="small"
      >
        {Object.keys(ProjectStatus).map((status, i) => (
          <Select.Option value={status} key={`project-status-${i}`}>
            {t(`project|projectStatus.${status}`)}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default ProjectStatusDropdown;

