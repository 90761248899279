import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectProviderModal,
  toggleProviderModal,
} from "../../../features/api/slices/providers-slice";
import CreateProviderForm from "./create-provider-modal";

const ProviderActionModal = () => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectProviderModal);

  //   const provider = useAppSelector(selectSelectedProvider) || "";

  const handleClose = () => {
    dispatch(toggleProviderModal({ isVisible: false, type: "" }));
  };

  switch (modal.type) {
    case "create":
      return (
        <CreateProviderForm
          visible={modal.isVisible}
          handleClose={handleClose}
        />
      );
    default:
      return <></>;
  }
};

export default ProviderActionModal;
