import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { selectAllCompanies } from "../../../features/api/slices/companies-slice";
import {
  selectProgramsModal,
  toggleProgramsModal,
} from "../../../features/api/slices/programs-slice";
import CreateProgramForm from "./create-program-form";

const ProgramsActionModal = () => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectProgramsModal);
  const allCompanies = useAppSelector(selectAllCompanies);

  const handleClose = () => {
    dispatch(toggleProgramsModal({ isVisible: false, type: "" }));
  };

  switch (modal.type) {
    case "create":
      return (
        <CreateProgramForm
          visible={modal.isVisible}
          handleClose={handleClose}
          companies={allCompanies}
        />
      );
    default:
      return <></>;
  }
};

export default ProgramsActionModal;

