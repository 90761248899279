import Collapse from "@hellodarwin/core/lib/components/common/Collapse";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  DocumentNeeded,
  InitialDocumentValue,
  InitialStepValue,
  Step,
  StepFormFunction,
} from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { message } from "antd";
import Drawer from "antd/es/drawer";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  clearSteps,
  createNewSteps,
  deleteSingleSelectedStep,
  fetchGinsSteps,
  selectAllSteps,
  selectStepsIsLoading,
  updateSingleSelectedStep,
  updateStepsOrder,
} from "../../../features/api/slices/gin-steps-slice";
import {
  createNewDocuments,
  deleteSingleSelectedDocument,
  updateSingleSelectDocument,
} from "../../../features/api/slices/gins-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { GinBlockComponentProps } from "./gin-block";
import GinStepCardContent from "./gin-step-card-content";
import GinStepForm from "./gin-step-form";

const GinStepCard = ({
  entityId,
  section,
  isEditing,
  setHandleSave,
}: GinBlockComponentProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const steps = useAppSelector((state) => selectAllSteps(state));
  const isLoading = useAppSelector(selectStepsIsLoading);
  const { t } = useTranslations();
  const setEditing = () => {
    setHandleSave(() => () => {});
  };
  const functions: StepFormFunction = {
    updateSelectedStep: async (step: Step) => {
      try {
        const response = await dispatch(
          updateSingleSelectedStep({ api, step: step })
        ).unwrap();
        message.success("Step Selected updated");
        return response.updatedStep;
      } catch (error) {
        message.error("Error Updating Step");
        console.error(error);
      }
      return step;
    },
    createNewStep: async (entityId: string, step: Step) => {
      try {
        const response = await dispatch(
          createNewSteps({ api, grantId: entityId, step: step })
        ).unwrap();

        message.success("Step created");
        return response;
      } catch (error) {
        message.error("Error Creating Step");
        console.error(error);
      }
      return InitialStepValue;
    },
    updateSelectedDocument: async (document: DocumentNeeded) => {
      try {
        const response = await dispatch(
          updateSingleSelectDocument({ api, document: document })
        ).unwrap();
        message.success("Document Selected updated");
        return response;
      } catch (error) {
        message.error("Error Updating Document");
        console.error(error);
      }
      return InitialDocumentValue;
    },
    createNewDocument: async (document: DocumentNeeded) => {
      try {
        const response = await dispatch(
          createNewDocuments({ api, document: document })
        ).unwrap();
        message.success("Document created");
        return response;
      } catch (error) {
        message.error("Error Creating Document");
        console.error(error);
      }
      return InitialDocumentValue;
    },
    deleteSelectedStep: async (grant_id: string, step_id: string) => {
      try {
        const response = await dispatch(
          deleteSingleSelectedStep({
            api,
            grant_id: grant_id,
            step_id: step_id,
          })
        ).unwrap();
        message.success("Step deleted");
        return response;
      } catch (error) {
        message.error("Error Deleting step");
        console.error(error);
      }
      return [];
    },
    deleteSelectedDocument: async (step_id: string) => {
      try {
        const response = await dispatch(
          deleteSingleSelectedDocument({ api, document_id: step_id })
        ).unwrap();
        message.success("Step deleted");
        return response;
      } catch (error) {
        message.error("Error Deleting step");
        console.error(error);
      }
      return "";
    },
    reorderSteps: async (grant_id: string, steps: Step[]) => {
      try {
        const response = await dispatch(
          updateStepsOrder({ api, grantId: grant_id, steps: steps })
        ).unwrap();
        message.success("Steps reordered");
        return response;
      } catch (error) {
        message.error("Error Reordering steps");
        console.error(error);
      }
      return [];
    },
  };

  useEffect(() => {
    if (entityId) {
      dispatch(clearSteps());
      dispatch(fetchGinsSteps({ api, grant_id: entityId }));
    }
    return () => {
      dispatch(clearSteps());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, dispatch]);

  return (
    <Div flex="column" gap={10}>
      {isLoading ? (
        <Loading />
      ) : (
        steps &&
        React.Children.toArray(
          steps.map((elem, index) => (
            <Collapse
              noPadding
              items={[
                {
                  id: "id" + index,
                  styles: {
                    header: {
                      backgroundColor: theme.colors.beige_2,
                      padding: "8px 24px",
                    },
                  },
                  title: (
                    <>
                      <Typography>
                        {t("gin_section|step")} {index + 1} : {elem.title}
                      </Typography>
                    </>
                  ),
                  children: <GinStepCardContent step={elem} />,
                },
              ]}
              ExpandIcon={({ isActive }) => (
                <TabArrow width={10} height={10} down={isActive} />
              )}
            />
          ))
        )
      )}
      <Drawer
        open={isEditing}
        onClose={setEditing}
        title={t(`gin_section|${section}`)}
      >
        <GinStepForm grantId={entityId} steps={steps} functions={functions} />
      </Drawer>
    </Div>
  );
};

export default GinStepCard;

