import Check from "@hellodarwin/icons/dist/icons/Check";
import Progress, { ProgressProps } from "antd/es/progress/progress";
import { useTheme } from "../../../plugins/styled";
import Typography from "../typography";

const SpinProgress = ({ percent }: { percent: number }) => {
  const theme = useTheme();
  const spinColors: ProgressProps["strokeColor"] = {
    "0%": theme.colors.red_1,
    "50%": theme.colors.yellow_1,
    "100%": theme.colors.green_1,
  };
  return (
    <Progress
      type="circle"
      percent={percent}
      strokeColor={spinColors}
      trailColor={theme.colors.grey_5}
      strokeLinecap="square"
      strokeWidth={10}
      format={(percent) =>
        percent === 100 ? (
          <Check />
        ) : (
          <Typography elementTheme="subtitle1">{percent}%</Typography>
        )
      }
    />
  );
};

export default SpinProgress;

