import { WebsiteGrantResult } from "../../../../../features/entities/grants-entities";
import VideoPlayer from "../../../../common/VideoPlayer";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import {
  VideoWrapper,
  VideoWrapperContainer,
} from "../../../../provider/profile/styles";

const GrantPreview = ({
  grant_description_long,
  grant_youtube_url,
}: WebsiteGrantResult) => {
  return (
    <>
      <Typography>{grant_description_long}</Typography>
      {grant_youtube_url && (
        <Div
          flex="column"
          align="center"
          gap={24}
          style={{ paddingTop: "40px" }}
        >
          <VideoWrapperContainer>
            <VideoWrapper>
              <Div className="video">
                {typeof window !== "undefined" && (
                  <VideoPlayer url={grant_youtube_url} controls light playing />
                )}
              </Div>
            </VideoWrapper>
          </VideoWrapperContainer>
        </Div>
      )}
    </>
  );
};

export default GrantPreview;

