import { GrantStatus } from "../../../../../features/entities/grants-entities";
import { DefaultTheme } from "../../../../../plugins/styled";
import { getGrantStatusTagTheme } from "../../../card/grant-status-tag";

const getFilterTagBarTheme = (
  type: string,
  theme: DefaultTheme,
  value: string
): string => {
  switch (type) {
    case "industry":
      return theme.colors.purple_2;
    case "subindustry":
      return theme.colors.purple_3;
    case "financingType":
      return theme.colors.yellow_1;
    case "region":
      return theme.colors.blue_4;
    case "service":
      return theme.colors.orange_4;
    case "companySize":
      return theme.colors.beige_1;
    case "annualRevenue":
      return theme.colors.green_1;
    case "status":
      const grantStatusTheme = getGrantStatusTagTheme(
        theme,
        value as GrantStatus
      );
      return value === "new"
        ? theme.colors.purple_1
        : !!grantStatusTheme
          ? grantStatusTheme.tagColor
          : theme.colors.purple_1;

    default:
      return undefined;
  }
};

export default getFilterTagBarTheme;

