import Div from "@hellodarwin/core/lib/components/common/div";
import Button from "antd/es/button";
import Result from "antd/es/result";
import HeaderBarUser from "../layout/header-bar-user";
interface ErrorPageProps {
  title?: string;
  subTitle?: string;
  href?: string;
  buttonLabel?: string;
}

const ErrorPage = ({
  title = "Try again",
  subTitle = "Sorry, unable to reach servers at the moment",
  href = "/",
  buttonLabel = "Refresh",
}: ErrorPageProps) => {
  return (
    <>
      <Div flex="row" justify="flex-end" style={{ paddingRight: "16px" }}>
        <HeaderBarUser />
      </Div>
      <Result
        status="500"
        title={title}
        subTitle={subTitle}
        extra={
          <a href={href}>
            <Button>{buttonLabel}</Button>
          </a>
        }
      />
    </>
  );
};

export default ErrorPage;
