import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Expand = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77777 1.77777V9.53535M1.77777 1.77777H9.53535M1.77777 1.77777L10.8283 10.8283M1.77777 30.2222V22.4646M1.77777 30.2222H9.53535M1.77777 30.2222L10.8283 21.1717M30.2222 1.77777H22.4646M30.2222 1.77777V9.53535M30.2222 1.77777L21.1717 10.8283M30.2222 30.2222H22.4646M30.2222 30.2222V22.4646M30.2222 30.2222L21.1717 21.1717"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Expand;

