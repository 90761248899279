import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import React from "react";
import { AdminFormResponse } from "../../../../../features/entities/form-entities";
import { GrantPreview } from "../../../../../features/entities/grants-entities";
import getShortId from "../../../../../features/helpers/get-short-id";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import HdTag from "../../../../common/hd-tag";
import Typography from "../../../../common/typography";
import getFormTypeColor from "../../utils/get-form-type-color";
import getItemTranslatedLabel from "../../utils/get-item-translated-label";

interface FormBuilderHeaderProps {
  formResponse: AdminFormResponse;
  locale: string;
  openGrant: (grant: GrantPreview) => void;
}
const FormBuilderHeader = ({
  formResponse,
  locale,
  openGrant,
}: FormBuilderHeaderProps) => {
  const theme = useTheme();

  return (
    <Div flex="column" gap={16}>
      <div>
        <Typography.Title level={2} elementTheme="h4" textAlign="center">
          {getItemTranslatedLabel({
            locale,
            label_en: formResponse.title_en,
            label_fr: formResponse.title_fr,
            fallback: `Form ${getShortId(formResponse.form_id)}`,
          })}
        </Typography.Title>
        <Typography elementTheme="body1" textAlign="center">
          {getItemTranslatedLabel({
            locale,
            label_en: formResponse.subtitle_en,
            label_fr: formResponse.subtitle_fr,
            fallback: ``,
          })}
        </Typography>
      </div>

      <Div flex="row" gap={8} wrap="wrap" align="center" justify="center">
        {formResponse.grants &&
          React.Children.toArray(
            formResponse.grants.map((grant) => (
              <HdTag
                color={theme.colors.purple_2}
                text={grant.display_title}
                hoverable
                size="large"
                onClick={() => openGrant(grant)}
                icon={<NewWindow width={16} height={16} />}
                iconPosition="end"
              />
            ))
          )}
        {!!formResponse.form_type && (
          <HdTag
            color={getFormTypeColor(formResponse.form_type, theme)}
            text={formResponse.form_type}
            size="large"
          />
        )}
      </Div>
    </Div>
  );
};

export default FormBuilderHeader;

