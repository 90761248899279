import Div from "@hellodarwin/core/lib/components/common/div";
import { PreviewProps } from "@hellodarwin/core/lib/components/common/layout/preview-card";
import StepsManager from "@hellodarwin/core/lib/components/common/steps-manager";
import { StepsManagerColProps } from "@hellodarwin/core/lib/components/common/steps-manager/steps-manager-col";
import { ProgramGrantStatus } from "@hellodarwin/core/lib/features/entities";
import { getStringDateFromString } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { getProgramGrantStatusColor } from "@hellodarwin/core/lib/features/helpers/get-status-color";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Button from "antd/es/button";
import { useEffect, useMemo, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../app";
import CreateProgramForm from "../../components/programs/programs-action-modal/create-program-form";
import {
  getCompanyPrograms,
  selectProgramsByCompanyId,
} from "../../features/api/slices/programs-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type CompanyGrantsListProps = {
  companyId: string;
};
const CompanyGrantsList = ({ companyId }: CompanyGrantsListProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [visibleProgramForm, setVisibleProgramForm] = useState(false);

  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const programs = useAppSelector((state: RootState) =>
    selectProgramsByCompanyId(state, companyId)
  );

  useEffect(() => {
    if (companyId !== "") {
      dispatch(getCompanyPrograms({ api, companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleClose = () => {
    setVisibleProgramForm(false);
    dispatch(getCompanyPrograms({ api, companyId }));
  };

  const items: StepsManagerColProps[] = useMemo(() => {
    const createdItems: StepsManagerColProps[] = [];

    Object.values(ProgramGrantStatus).forEach((key, i) => {
      const colItems: PreviewProps[] = [];
      var colTotal = 0;
      for (const program of programs) {
        if (program.program_grant_status === key) {
          colTotal += !!program.program_accepted_amount
            ? program.program_accepted_amount
            : !!program.program_application_amount
              ? program.program_application_amount
              : !!program.program_targeted_amount
                ? program.program_targeted_amount
                : 0;
          colItems.push({
            title: `${program.program_name}`,
            infos: [
              {
                label: "Updated at ",
                value: !!program.program_updated_at
                  ? getStringDateFromString(
                      program.program_updated_at,
                      selectedLocale
                    )
                  : "",
                Icon: Timeline,
              },
            ],
            tag: {
              text: t(`programs|labels.${program.program_grant_status}`),
              color: getProgramGrantStatusColor(program.program_grant_status),
            },
            parentSlug: "programs",
            id: program.program_id,
            ctaText: t("programs|cta.seeStep"),
          });
        }
      }
      createdItems.push({
        label: t(`programs|labels.${key}`),
        tooltip: t(`programs|tooltips.${key}`),
        colTotal: colTotal,
        assignedKey: `${key}`,
        items: colItems,
      });
    });

    return createdItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs, selectedLocale]);

  return (
    <Div flex="column">
      <Button
        style={{ alignSelf: "flex-end", marginBottom: 16 }}
        icon={<AddNew height={24} width={24} />}
        onClick={() => setVisibleProgramForm(true)}
      />
      <StepsManager items={items} />
      <CreateProgramForm
        visible={visibleProgramForm}
        handleClose={handleClose}
        companyId={companyId}
      />
    </Div>
  );
};

export default CompanyGrantsList;

