import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import FormsList from "../../components/forms/forms-list";
import FormsSubmissionList from "../../components/forms/submissions/forms-submission-list";
import {
  selectActiveTab,
  setActiveTab,
} from "../../features/api/slices/forms-slice";

type FormsPageTab = "forms" | "forms_submissions";

const FormsPage = ({ defaultKey }: { defaultKey?: FormsPageTab }) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectActiveTab);
  const navigate = useNavigate();
  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab("forms"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!!defaultKey) {
      dispatch(setActiveTab(defaultKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKey]);

  const handleTabChange = (key: string) => {
    switch (key) {
      case "forms":
        navigate("/forms");
        break;
      case "forms_submissions":
        navigate("/forms/submissions");
        break;

      default:
        break;
    }
    dispatch(setActiveTab(key));
  };

  return (
    <PageLayout
      app="admin"
      title="Forms"
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Forms",
        },
      ]}
    >
      <Container
        tabs={[
          {
            label: "Forms",
            key: "forms",
            children: <FormsList />,
          },
          {
            label: "Submissions",
            key: "forms_submissions",
            children: <FormsSubmissionList type="all" entityId="" />,
          },
        ]}
        activeTab={activeTab}
        handleTabClick={handleTabChange}
        fullWidth
      />
    </PageLayout>
  );
};

export default FormsPage;

