import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  dollarFormat,
  getPriceInDollars,
} from "@hellodarwin/core/lib/features/helpers";
import Button from "antd/es/button";
import Card from "antd/es/card";
import { useAppDispatch } from "../../app";
import { updateCompanyBillingId } from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import ExternalLink from "../widgets/external-link";

type SubscriptionFormProps = {
  billingId: string;
  companyId?: string;
  creditBalance: number;
};

const SubscriptionForm = ({
  billingId,
  companyId,
  creditBalance,
}: SubscriptionFormProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const openSession = () => {
    (async () => {
      try {
        const session = await api.createBillingSession(billingId);
        window.location.href = session.url;
      } catch (e: any) {
        console.error(e);
      }
    })();
  };

  return (
    <Card
      style={{ padding: "1rem" }}
      title={
        <Typography elementTheme={"body3"}>
          Credit Balance: $
          {dollarFormat(Math.abs(getPriceInDollars(creditBalance)), "en")}
        </Typography>
      }
      extra={
        <ExternalLink
          id={billingId}
          title="Stripe Billing Customer ID"
          url={`https://dashboard.stripe.com/customers/${billingId}`}
          onEnd={(newValue) => {
            if (companyId)
              dispatch(
                updateCompanyBillingId({ api, companyId, billingId: newValue })
              );
          }}
        />
      }
    >
      <Div flex="row" justify="center" gap={10} style={{ paddingTop: "1rem" }}>
        <Button onClick={() => openSession()}>Manage Billing</Button>
      </Div>
    </Card>
  );
};

export default SubscriptionForm;

