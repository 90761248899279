import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Popconfirm from "antd/es/popconfirm";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import { FormBuilderItemActionsProps } from "../../types";
import FormItemEditButton from "./form-builder-item-edit-button";

const FormBuilderItemActions = ({
  formItemId,
  itemEntity,
  pages,
  functions,
}: FormBuilderItemActionsProps) => {
  const theme = useTheme();

  const handleFormItemDelete = () => {
    functions.deleteFormItem(formItemId);
  };

  return (
    <Div flex="column" gap={2} fitContent>
      <FormItemEditButton
        itemEntity={itemEntity}
        functions={functions}
        pages={pages}
      />
      <Popconfirm
        title="Delete the form Page"
        description="Are you sure to delete this item ?"
        onConfirm={handleFormItemDelete}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <div>
          <Button
            size="square"
            defaultStyle={theme.colors.purple_3}
            trailingIcon={<Delete width={12} height={12} />}
          />
        </div>
      </Popconfirm>
    </Div>
  );
};

export default FormBuilderItemActions;

