import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  Contact,
  RaiseHandParams,
} from "@hellodarwin/core/lib/features/entities";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import message from "antd/es/message";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import Switch from "antd/es/switch";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  fetchProjectMatches,
  selectAllMatchesById,
} from "../../features/api/slices/projects-slice";
import {
  fetchProviderById,
  selectSelectedProvider,
} from "../../features/api/slices/providers-slice";
import {
  fetchProviderContacts,
  selectProviderContacts,
} from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { validateUUID } from "../../features/utils";
import { missingDefaultPaymentError } from "../messages/missing-payment-method";
const DEFAULT_PAYMENT_METHOD_ERROR = 11_007;

const { Title } = Typography;

type RaiseHandFormProps = {
  projectId: string;
  leadPrice: number;
};

interface RaiseHandModalForm {
  price: number;
  raisehand_price: number;
  provider_id: string;
  hello_message: string;
  waive_rh_price: boolean;
}

const RaiseHandForm = ({ projectId, leadPrice }: RaiseHandFormProps) => {
  const [form] = Form.useForm();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [providerId, setProviderId] = useState<string>("");
  const provider = useAppSelector(selectSelectedProvider);
  const providerContacts = useAppSelector((state) =>
    selectProviderContacts(state, provider.company_id || "")
  );
  const [selectedProviderContact, setSelectedProviderContact] =
    useState<Contact | null>(null);

  const match = useAppSelector((state) =>
    selectAllMatchesById(state, providerId)
  );
  useEffect(() => {
    dispatch(fetchProjectMatches({ api, projectId: projectId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      price: match?.price || "",
      raisehand_price: match?.raise_hand_price || 0,
      provider_id: match?.provider_id || "",
      contact_id: match?.provider_contact_id || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  useEffect(() => {
    if (validateUUID(providerId))
      dispatch(fetchProviderById({ api, providerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    if (provider && provider.company_id)
      dispatch(
        fetchProviderContacts({ api, companyId: provider.company_id || "" })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const handleChange = (value: string) => {
    const contact = providerContacts.find(
      (contact) => contact.contact_id === value
    );
    if (contact) {
      setSelectedProviderContact(contact);
    }
  };

  const OnProviderInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProviderId(event.currentTarget.value);
  };

  const onFinish = (values: RaiseHandModalForm) => {
    (async () => {
      if (!match) return;
      setIsSaving(true);
      try {
        const params: RaiseHandParams = {
          projectId: projectId,

          providerId: values.provider_id,
          providerContactId: selectedProviderContact?.contact_id || "",
          price: values.price,
          raiseHandPrice: values.raisehand_price,
          helloMessage: values.hello_message,
          waiveRhPrice: values.waive_rh_price,
        };
        await api.raiseHand(params);
        await dispatch(fetchProjectMatches({ api, projectId: projectId }));
        setIsSaving(false);
        form.resetFields();
        message.success("Saved!");
      } catch (e: any) {
        setIsSaving(false);

        if (e.response.data.error_code === DEFAULT_PAYMENT_METHOD_ERROR) {
          return missingDefaultPaymentError();
        }

        notification.error({
          message: "Payment failed!",
          description: `${e.response.data.error}, error_code: ${e.response.data.error_code}`,
          placement: "bottomLeft",
        });
      }
    })();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"manual-charge"}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} elementTheme="h6">
            Manual Raise Hand
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Provider id"
            name="provider_id"
            rules={[{ required: true, message: "provider ID is required" }]}
          >
            <Input onChange={OnProviderInputChange} />
          </Form.Item>
          {!match && providerId && (
            <Typography>Can't find provider in matches</Typography>
          )}
        </Col>
        {match &&
          (!match.raised_hand_at ? (
            validateUUID(providerId) && providerContacts ? (
              <>
                <Col span={24}>
                  <Form.Item
                    label="Raisehand Price"
                    name="raisehand_price"
                    rules={[
                      {
                        required: true,
                        message: "raise hand price is required",
                      },
                      { type: "number" },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    label="Price"
                    name="price"
                    rules={[
                      { required: true, message: "lead price is required" },
                      { type: "number" },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Contact id"
                    name="contact_id"
                    rules={[{ required: true, message: "Contact is required" }]}
                  >
                    <Select
                      filterOption={false}
                      placeholder="Select a contact"
                      defaultActiveFirstOption={true}
                      onChange={handleChange}
                    >
                      {providerContacts.map((contact) => (
                        <Select.Option
                          key={contact.contact_id}
                          value={contact.contact_id}
                        >
                          {contact.name}/ {contact.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Hello message" name="hello_message">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={`Use free raisehand (provider currently has ${match.provider.available_raise_hands} left)`}
                    name="waive_rh_price"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ margin: 0 }} shouldUpdate={true}>
                    <Button htmlType="submit" loading={isSaving}>
                      Raise Hand
                    </Button>
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Typography>Can't find provider contacts</Typography>
            )
          ) : (
            <Typography>Hand already raised for this match</Typography>
          ))}
      </Row>
    </Form>
  );
};

export default RaiseHandForm;

