import Empty from "antd/es/empty";
import Form from "antd/es/form/Form";
import React, { useEffect, useMemo, useState } from "react";
import { AppType, GrantProject, GrantResult } from "../../../features/entities";
import { usePagination } from "../../../features/providers/pagination-provider";
import { useTranslation } from "../../../plugins/i18n";
import Container from "../../common/container";
import List from "../../common/List";
import GrantCard from "../card";
import FundingExplorerFilter, { FundingExplorerFilterProps } from "./filter";
import FilterProjects from "./filter/filter-projects";
import getFeaturedTags from "./filter/utils/get-featured-tags";
import FundingExplorerListLocked from "./funding-explorer-list-locked";

interface FundingExplorerProps
  extends Omit<
    FundingExplorerFilterProps,
    "handleProjectSelection" | "onTextQueryChange"
  > {
  setSelectedProjects?: React.Dispatch<React.SetStateAction<GrantProject[]>>;
  isLocked?: boolean;
  goToSettings: () => void;
  isLoading?: boolean;
  grants: GrantResult[];
  missingDefaultFields?: boolean;
  app: AppType;
}

const FundingExplorer = (props: FundingExplorerProps) => {
  const {
    setSelectedProjects,
    bestProjects,
    grantsProjectsLoading,
    initialQuerySearch,
    filterform,
    selectedProjects,
    isLocked,
    goToSettings,
    isLoading,
    grants,
    applyFilter,
    missingDefaultFields,
    company,
    app,
  } = props;

  const { activePage, pageSize, goToPage, getParam } = usePagination();

  const [pageHasChanged, setPageHasChanged] = useState(false);
  const { t } = useTranslation();

  const handleProjectSelection = (selectedProject: GrantProject) => {
    setSelectedProjects((prevSelectedProjects) => {
      const isSelected = prevSelectedProjects.some(
        (project) =>
          project.grant_project_id === selectedProject.grant_project_id
      );
      const newSelection = isSelected
        ? prevSelectedProjects.filter(
            (project) =>
              project.grant_project_id !== selectedProject.grant_project_id
          )
        : [...prevSelectedProjects, selectedProject];

      return newSelection;
    });
  };

  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );

  const onPageChange = async (page: number, pageSize: number) => {
    goToPage(page, pageSize);
    setPageHasChanged(true);
  };

  const isInitialCompanyValues = getParam("initialValues") === "true";

  useEffect(() => {
    if (!company?.company_id || app === "admin") return;

    if (!missingDefaultFields && !isInitialCompanyValues) {
      applyFilter({ type: "init" });
    } else {
      applyFilter({ type: "companyInit" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    applyFilter({ type: "filterChanged" });
  };

  useEffect(() => {
    if (pageHasChanged) {
      applyFilter();
      setPageHasChanged(false);
    }
  }, [activePage, pageSize, pageHasChanged]);

  return (
    <Form form={filterform} layout="vertical">
      <FundingExplorerFilter
        {...props}
        onTextQueryChange={onTextQueryChange}
        handleProjectSelection={handleProjectSelection}
      />

      <Container
        style={{ marginTop: 24, position: "relative", minHeight: 600 }}
      >
        <List
          renderEmpty={() => <Empty description={t("grants|noGrants")} />}
          loading={isLoading}
          dataSource={grants}
          renderItem={(grant) => (
            <GrantCard
              featuredTags={getFeaturedTags(grant, selectedProjects)}
              {...grant}
            />
          )}
          extra={
            <FundingExplorerListLocked
              isLocked={isLocked}
              goToSettings={goToSettings}
            />
          }
          pagination={{
            total: hasGrants ? grants?.[0]?.total_count : 0,
            currentPage: activePage,
            onPageChange,
            pageSize,
          }}
        />
      </Container>

      {!isLocked && (
        <FilterProjects
          {...props}
          onTextQueryChange={onTextQueryChange}
          handleProjectSelection={handleProjectSelection}
        />
      )}
    </Form>
  );
};

export default FundingExplorer;

