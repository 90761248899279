import SidebarPageLayout from "@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Empty from "antd/es/empty";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app";
import GinSingleGrantPage from "../../../components/gins/grant-single";
import { GinGrantSinglePageSection } from "../../../components/gins/grant-single/use-gin-grant-blocks";
import GinSidebarMenu from "../../../components/gins/sidebars/menu";
import GinRelatedContacts from "../../../components/gins/sidebars/related-contacts";
import RelatedGrantsList from "../../../components/gins/sidebars/related-grants";
import GinRelatedPrograms from "../../../components/gins/sidebars/related-programs";
import {
  fetchGinByGrantId,
  selectCurrentGin,
} from "../../../features/api/slices/gins-slice";
import { useNewAdminApi } from "../../../features/api/use-admin-api";

const SingleGinPage = () => {
  const params = useParams<{ id: string }>();

  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const [isAddAgentVisible, setAddAgentVisible] = useState(false);
  const onClose = () => {
    setAddAgentVisible(false);
  };

  const onOpen = () => {
    setAddAgentVisible(true);
  };
  const adminGin = useAppSelector(selectCurrentGin);
  useEffect(() => {
    if (params.id) {
      dispatch(fetchGinByGrantId({ api, grantId: params.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const title = useMemo(() => {
    if (!adminGin?.grant) return "Grant";

    return selectedLocale === "fr"
      ? adminGin?.grant?.grant_title_fr
      : adminGin?.grant?.grant_title_en;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminGin?.grant]);

  if (!params.id) return <Empty />;

  const sections: GinGrantSinglePageSection[] = [
    GinGrantSinglePageSection.BusinessAdmissibility,
    GinGrantSinglePageSection.ActivitiesAdmissibility,
    GinGrantSinglePageSection.ExpenseAdmissibility,
    GinGrantSinglePageSection.Files,
    GinGrantSinglePageSection.FinancialAssistance,
    GinGrantSinglePageSection.ProcessSummary,
    GinGrantSinglePageSection.ProductionStages,
  ];
  return (
    <SidebarPageLayout
      app="admin"
      title={title}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants Internal Notes",
          path: "/gin",
        },
        {
          breadcrumbName: title,
        },
      ]}
      leftSidebarBlocks={[
        { title: "Menu", content: <GinSidebarMenu menu_items={sections} /> },

        {
          content: <RelatedGrantsList grantId={params.id} />,
          title: t("gin_program|related_programs"),
        },
      ]}
      rightSidebarBlocks={[
        {
          content: (
            <GinRelatedContacts
              grantId={params.id}
              open={isAddAgentVisible}
              onClose={onClose}
            />
          ),
          title: t("gin_program|mandated_agents"),
          extra: (
            <AddNew size={16} onClick={onOpen} style={{ cursor: "pointer" }} />
          ),
        },
        {
          content: <GinRelatedPrograms grantId={params.id} />,
          title: t("gin_program|applications"),
        },
      ]}
      defaultNavCollapsed
      noFooter
    >
      {!!params.id && (
        <GinSingleGrantPage grantId={params.id} sections={sections} />
      )}
    </SidebarPageLayout>
  );
};

export default SingleGinPage;

