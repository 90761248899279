import Progress from "antd/es/progress";
import React from "react";
import { PreviewProps } from ".";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../button";
import HdTag from "../../hd-tag";
import Typography from "../../typography";
import { PreviewContainer } from "./styles";

interface PreviewRowProps {
  onPreviewClick?: () => void;
}
const PreviewRow = ({
  percentage,
  title,
  tag,
  infos,
  ctaText,
  onPreviewClick,
  style,
}: PreviewProps & PreviewRowProps) => {
  const theme = useTheme();
  return (
    <PreviewContainer
      style={{
        ...style,
        height: 85,
        padding: 0,
        paddingLeft: 24,
        paddingRight: 32,
        gap: 32,
      }}
      onClick={onPreviewClick}
      $isRow={true}
    >
      <Typography.Title
        level={3}
        elementTheme="subtitle2"
        noMargin
        fitContent
        lineClamp={2}
      >
        {title}
      </Typography.Title>
      <div>
        <HdTag color={tag.color} text={tag.text} />
      </div>
      {!!infos.length ? (
        React.Children.toArray(
          infos.map((i) =>
            !i.hide ? (
              <Typography elementTheme="caption" color={theme.colors.grey_1}>
                {i.value}
              </Typography>
            ) : (
              <div />
            )
          )
        )
      ) : (
        <div />
      )}
      {typeof percentage === "number" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 19,
          }}
        >
          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor={theme.colors.green_high_contrast}
            trailColor={theme.colors.green_4}
            style={{
              alignSelf: "center",
              marginInlineEnd: "0",

              margin: 0,
            }}
          />
          <Typography
            elementTheme="overline"
            bold
            color={theme.colors.grey_2}
            style={{
              lineHeight: 1,
              width: 42,
            }}
          >
            {percentage}%
          </Typography>
        </div>
      )}
      <div>
        <Button
          size="small"
          style={{ minWidth: 180, height: 32 }}
          defaultStyle={theme.colors.grey_1}
          onClick={onPreviewClick}
        >
          {ctaText}
        </Button>
      </div>
    </PreviewContainer>
  );
};

export default PreviewRow;

