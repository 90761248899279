import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const LeftArrow = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 16H16L30.5 16"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        className="arrow-tail"
        width={32}
      />
      <path
        d="M11 6L1.18182 16L11 26"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LeftArrow;

