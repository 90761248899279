import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Search from "@hellodarwin/icons/dist/icons/Search";
import { Input } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/use-debounce";

export interface GinFilterHeaderProps {
  applyFilter: (query: string) => void;
}

const GinFilterHeader = ({ applyFilter }: GinFilterHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");

  const SEARCH_DELAY_MS = 500;

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    applyFilter(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <Div flex="column" gap={8} tablet={{ gap: 24 }}>
      <Div flex="row" gap={8} tablet={{ flex: "column" }}>
        <Input
          placeholder={t(`grants|ginFilter.searchPlaceholder`)}
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            fontSize: 18,
            height: 45,
          }}
          prefix={
            <Search style={{ marginRight: 8 }} color={theme.colors.grey_3} />
          }
          suffix={
            searchTerm && (
              <Close
                style={{ cursor: "pointer" }}
                size={16}
                onClick={() => setSearchTerm("")}
                color={theme.colors.grey_3}
              />
            )
          }
        />
        <Button
          defaultStyle={theme.colors.purple_3}
          style={{ height: 45, flex: 1, borderRadius: 4, gap: 24 }}
        >
          {t(`grants|ginFilter.search`)}
        </Button>
      </Div>
    </Div>
  );
};
export default GinFilterHeader;

