import { useMemo } from "react";
import {
  EditableFormSubmissionResponse,
  EditSubmissionPageResponse,
} from "../../../../../features/entities/form-entities";
import { FormData, MappedSubmissionItems } from "../../types";
import {
  getFormItemConditions,
  getFormPageKeysToCheck,
  getFormPageSubmissionValues,
} from "./submission-page-validator";

interface MappedPages {
  [form_page_id: string]: EditSubmissionPageResponse;
}
const getSubmissionActivePages = ({
  formSubmission,
  formData,
  mappedSubmissionItems,
}: {
  formSubmission: EditableFormSubmissionResponse;
  formData: FormData;
  mappedSubmissionItems: MappedSubmissionItems;
}) => {
  const { form } = formSubmission;

  return useMemo(() => {
    // Filter out pages that are not "opening"
    const pages = form.pages.filter(
      (page) => !page.entity.page_type?.includes("opening")
    );

    // Map pages by their form_page_id for quick lookup
    const orderedPages: MappedPages = pages.reduce((acc, page) => {
      acc[page.entity.form_page_id] = page;
      return acc;
    }, {} as MappedPages);

    const activePagesIds: string[] = [];
    let currentPageId = pages[0]?.entity.form_page_id;
    let isLastPage = false;

    while (currentPageId && !isLastPage) {
      const currentPage = orderedPages[currentPageId];
      const currentPageIndex = pages.findIndex(
        (page) => page.entity.form_page_id === currentPageId
      );

      activePagesIds.push(currentPageId);

      const nextPage = pages[currentPageIndex + 1];
      let nextPageId = nextPage?.entity.form_page_id;

      // Determine keys and values of the current page
      const keysToCheck = getFormPageKeysToCheck(currentPage.entity);
      const pageValues = getFormPageSubmissionValues(currentPage, formData);

      // Evaluate conditions to determine next page
      keysToCheck.forEach((key) => {
        const value = formData[key];
        const conditionPageId = getFormItemConditions(
          value,
          nextPageId,
          mappedSubmissionItems[key]?.form_item
        );

        // Update the nextPageId based on conditions
        if (conditionPageId !== nextPageId) {
          nextPageId = conditionPageId;
        }
      });
      const alreadySeenThisPage = activePagesIds.includes(nextPageId);

      if (
        !pageValues.length ||
        !currentPage.groups.length ||
        alreadySeenThisPage
      ) {
        isLastPage = true;
      }
      currentPageId = nextPageId;
    }

    const activePages: EditSubmissionPageResponse[] = activePagesIds.map(
      (page_id) => orderedPages[page_id]
    );

    return activePages;
  }, [formSubmission, formData, mappedSubmissionItems]);
};
export default getSubmissionActivePages;

