import Div from "@hellodarwin/core/lib/components/common/div";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import SeeMoreTags from "@hellodarwin/core/lib/components/common/see-more/see-more-tags";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  GrantProjectTag,
  GrantTag,
  NewTag,
  TagType,
} from "@hellodarwin/core/lib/features/entities";
import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import message from "antd/es/message";
import Select from "antd/es/select";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  addGrantProjectTags,
  addGrantTags,
  clearAllOptions,
  clearSelectedTags,
  deleteGrantProjectTagWithChildren,
  deleteGrantTagWithChildren,
  fetchChildTags,
  selectActivityOptions,
  selectActivityTypeOptions,
  selectEligibleExpenseOptions,
  selectSectorOptions,
  selectSelectedTags,
  setSelectedTags,
} from "../../../features/api/slices/grant-tags-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import GrantTagsSearch from "./grant-tag-search";

interface GrantTagsDrawerProps {
  visible: boolean;
  onClose: () => void;
  currentTags: GrantProjectTag[] | GrantTag[];
  entityType: "GrantProject" | "Grant";
  entityId: string;
  locale: string;
}

const GrantTagsDrawer = ({
  visible,
  onClose,
  currentTags,
  entityType,
  entityId,
  locale,
}: GrantTagsDrawerProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const sectorOptions = useAppSelector(selectSectorOptions);
  const activityTypeOptions = useAppSelector(selectActivityTypeOptions);
  const activityOptions = useAppSelector(selectActivityOptions);
  const eligibleExpenseOptions = useAppSelector(selectEligibleExpenseOptions);
  const selectedTags = useAppSelector(selectSelectedTags);

  const [selectedSectors, setSelectedSectors] = useState<NewTag[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<NewTag[]>(
    []
  );
  const [selectedActivities, setSelectedActivities] = useState<NewTag[]>([]);
  const [selectedEligibleExpenses, setSelectedEligibleExpenses] = useState<
    NewTag[]
  >([]);

  // Fetch sector tags initially
  useEffect(() => {
    dispatch(
      fetchChildTags({ api, parentIds: [], locale, type: TagType.Sector })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch child tags dynamically based on selected parent tags
  useEffect(() => {
    const parentIds = selectedSectors?.map((sector) => sector.tag_id);
    dispatch(
      fetchChildTags({ api, parentIds, locale, type: TagType.ActivityType })
    );
    setSelectedActivityTypes(
      selectedActivityTypes.filter((activityType) =>
        parentIds.includes(activityType.parent_id)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectors, locale]);

  useEffect(() => {
    const parentIds = selectedActivityTypes?.map(
      (activityType) => activityType.tag_id
    );
    dispatch(
      fetchChildTags({ api, parentIds, locale, type: TagType.Activity })
    );
    setSelectedActivities(
      selectedActivities.filter((activity) =>
        parentIds.includes(activity.parent_id)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityTypes, locale]);

  useEffect(() => {
    const parentIds = selectedActivities?.map((activity) => activity.tag_id);
    dispatch(
      fetchChildTags({ api, parentIds, locale, type: TagType.EligibleExpense })
    );
    setSelectedEligibleExpenses(
      selectedEligibleExpenses.filter((eligibleExpense) =>
        parentIds.includes(eligibleExpense.parent_id)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, locale]);

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    type: TagType
  ) => {
    const options = getOptions(type);
    const newTags = values.map((value) => options[value]);

    setSelected(newTags);

    const updatedSelectedTags = [
      ...selectedTags.filter(
        (tag) => !newTags.some((newTag) => newTag.tag_id === tag.tag_id)
      ),
      ...newTags,
    ];

    dispatch(setSelectedTags(updatedSelectedTags));
  };

  const getOptions = (type: TagType) => {
    switch (type) {
      case TagType.Sector:
        return sectorOptions.entities;
      case TagType.ActivityType:
        return activityTypeOptions.entities;
      case TagType.Activity:
        return activityOptions.entities;
      case TagType.EligibleExpense:
        return eligibleExpenseOptions.entities;
      default:
        return {};
    }
  };

  const handleDeleteTag = async (tagId: string) => {
    try {
      let response;
      if (entityType === "Grant") {
        response = await dispatch(
          deleteGrantTagWithChildren({ api, grantId: entityId, tagId })
        ).unwrap();
      } else {
        response = await dispatch(
          deleteGrantProjectTagWithChildren({
            api,
            grantProjectId: entityId,
            tagId,
          })
        ).unwrap();
      }
      if (response) {
        message.success("Tag and its children deleted successfully");
      }
    } catch (err) {
      message.error("Error deleting tag");
    }
  };

  const handleSubmit = async (tag: NewTag) => {
    const searchResult = [];
    do {
      searchResult.push(tag);
      tag = tag.parent as NewTag;
    } while (tag?.parent?.tag_id);
    const tagsToAdd = searchResult.filter(
      (tag) =>
        !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id)
    );
    try {
      if (tagsToAdd.length > 0) {
        let response;
        if (entityType === "Grant") {
          response = await dispatch(
            addGrantTags({
              api,
              grantId: entityId,
              tags: tagsToAdd.map((tag) => tag.tag_id),
            })
          ).unwrap();
        } else {
          response = await dispatch(
            addGrantProjectTags({
              api,
              grantProjectId: entityId,
              newTags: tagsToAdd.map((tag) => tag.tag_id),
            })
          ).unwrap();
        }
        if (response) {
          dispatch(clearSelectedTags());
          dispatch(clearAllOptions());
          setSelectedSectors([]);
          setSelectedActivityTypes([]);
          setSelectedActivities([]);
          setSelectedEligibleExpenses([]);
          message.success("Tags added successfully");
        }
      }
    } catch (err) {
      message.error("Error adding tags");
    }
  };

  const handleConfirm = async () => {
    const tagsToAdd = selectedTags.filter(
      (tag) =>
        !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id)
    );
    try {
      let response;
      if (tagsToAdd.length > 0) {
        if (entityType === "Grant") {
          response = await dispatch(
            addGrantTags({
              api,
              grantId: entityId,
              tags: tagsToAdd.map((tag) => tag.tag_id),
            })
          ).unwrap();
        } else {
          response = await dispatch(
            addGrantProjectTags({
              api,
              grantProjectId: entityId,
              newTags: tagsToAdd.map((tag) => tag.tag_id),
            })
          ).unwrap();
        }
        if (response) {
          dispatch(clearSelectedTags());
          dispatch(clearAllOptions());
          setSelectedSectors([]);
          setSelectedActivityTypes([]);
          setSelectedActivities([]);
          setSelectedEligibleExpenses([]);
          message.success("Tags added successfully");
        }
      }
    } catch (err) {
      message.error("Error adding tags");
    }
  };

  return (
    <Drawer
      title="Edit Tags"
      placement="right"
      onClose={onClose}
      open={visible}
      size="large"
    >
      <Div flex="column" justify="space-between" fullHeight>
        <Div flex="column" gap={8}>
          <Div>
            <Typography elementTheme="body1">Global Search</Typography>
            <GrantTagsSearch locale={locale} handleSubmit={handleSubmit} />
          </Div>
          <Div>
            <Typography elementTheme="body1">Search By Type</Typography>
            <Grid sm={2} gutter={16}>
              {[
                TagType.Sector,
                TagType.ActivityType,
                TagType.Activity,
                TagType.EligibleExpense,
              ].map((type, idx) => (
                <Div key={idx}>
                  <Typography elementTheme="body3">
                    {type
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </Typography>
                  <Select
                    mode="multiple"
                    placeholder={`Select ${type
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}(s)`}
                    value={
                      type === TagType.Sector
                        ? selectedSectors.map((tag) => tag.tag_id)
                        : type === TagType.ActivityType
                          ? selectedActivityTypes.map((tag) => tag.tag_id)
                          : type === TagType.Activity
                            ? selectedActivities.map((tag) => tag.tag_id)
                            : selectedEligibleExpenses.map((tag) => tag.tag_id)
                    }
                    onChange={(values) =>
                      handleTagChange(
                        values,
                        type === TagType.Sector
                          ? setSelectedSectors
                          : type === TagType.ActivityType
                            ? setSelectedActivityTypes
                            : type === TagType.Activity
                              ? setSelectedActivities
                              : setSelectedEligibleExpenses,
                        type
                      )
                    }
                    options={Object.values(getOptions(type))?.map((option) => ({
                      label: option.content,
                      value: option.tag_id,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Div>
              ))}
            </Grid>
          </Div>
          <Div flex="row" gap={8} justify="center">
            <Button type="primary" onClick={handleConfirm}>
              Add Tags
            </Button>
            <Button
              onClick={() => {
                dispatch(clearSelectedTags());
                dispatch(clearAllOptions());
              }}
            >
              Cancel
            </Button>
          </Div>
        </Div>
        <Div flex="column" gap={8}>
          <Typography elementTheme="body2">
            {entityType === "Grant" ? "Grant" : "Grant Project"} Tags
          </Typography>
          <Div flex="row" gap={8} align="center" wrap="wrap">
            <SeeMoreTags
              limit={10}
              size="medium"
              position={"bottom"}
              content={currentTags.map((tag) => ({
                ...tag,
                onClose: () => handleDeleteTag(tag.tag_id),
              }))}
            />
          </Div>
        </Div>
      </Div>
    </Drawer>
  );
};

export default GrantTagsDrawer;

