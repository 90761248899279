import { Company } from "@hellodarwin/core/lib/features/entities";
import CompanyCard from "./company-card";

type CompaniesListProps = {
  companies: Company[];
};

const CompaniesList = ({ companies }: CompaniesListProps) => {
  return companies.map((company) => {
    return <CompanyCard key={company?.company_id!} company={company!} />;
  });
};

export default CompaniesList;
