import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const PauseProject = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5556 11.2593V20.7407M12.4444 20.7407V11.2593M30.2222 16C30.2222 17.8677 29.8544 19.7171 29.1396 21.4426C28.4249 23.1681 27.3773 24.736 26.0566 26.0566C24.736 27.3773 23.1681 28.4249 21.4426 29.1396C19.7171 29.8544 17.8677 30.2222 16 30.2222C14.1323 30.2222 12.2829 29.8544 10.5574 29.1396C8.83187 28.4249 7.26402 27.3773 5.94336 26.0566C4.62271 24.736 3.57511 23.1681 2.86037 21.4426C2.14564 19.7171 1.77777 17.8677 1.77777 16C1.77777 12.228 3.27618 8.61055 5.94336 5.94336C8.61055 3.27618 12.228 1.77777 16 1.77777C19.772 1.77777 23.3894 3.27618 26.0566 5.94336C28.7238 8.61055 30.2222 12.228 30.2222 16Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PauseProject;

