import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { GinProgram } from "./../../../../../core/src/features/entities/programs-entities";

import {
  AdminGinResult,
  AdminGrantResult,
  ApiError,
  ApiErrorInitialState,
  Contact,
  DocumentNeeded,
  FullGinApplication,
  GinApplication,
  GinApplicationCompany,
  GinApplicationProgram,
  GinInitialState,
  InitialStateFullGinApplication,
} from "@hellodarwin/core/lib/features/entities";

import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApi from "../admin-api";
import AdminApiClient from "../admin-api-client";

const gins = createEntityAdapter({
  selectId: (model: AdminGrantResult) => model.grant_id,
});

type StatusType =
  | "generic"
  | "singleGrant"
  | "applicationSingle"
  | "applicationContacts"
  | "applicationProgram"
  | "applicationCompany"
  | "programsList"
  | "relatedApplications";

type Status = {
  [key in StatusType]: "idle" | "pending";
};

export interface GinsState {
  status: Status;
  error: ApiError;
  ginGrants: EntityState<AdminGrantResult, string>;
  currentGin: AdminGinResult;
  selectedGinApplications: GinProgram[] | null;
  currentGinApplication: FullGinApplication;
  modalForm: {
    isVisible: boolean;
    applicationStatus: string;
  };
  modalMessage: {
    isVisible: boolean;
    isSuccessful: boolean;
    applicationStatus: string;
  };
  modalProgramContacts: {
    isVisible: boolean;
  };
}

const initialState: GinsState = {
  status: {
    generic: "idle",
    singleGrant: "pending",
    programsList: "pending",
    relatedApplications: "pending",
    applicationSingle: "pending",
    applicationCompany: "pending",
    applicationContacts: "pending",
    applicationProgram: "pending",
  },
  error: ApiErrorInitialState,
  ginGrants: gins.getInitialState(),
  currentGin: GinInitialState,
  selectedGinApplications: null,
  currentGinApplication: InitialStateFullGinApplication,
  modalForm: {
    isVisible: false,
    applicationStatus: "",
  },
  modalMessage: {
    isVisible: false,
    isSuccessful: false,
    applicationStatus: "",
  },
  modalProgramContacts: {
    isVisible: false,
  },
};

export const queryGinGrants = createAsyncThunk<
  AdminGrantResult[],
  { api: AdminApi; locale: string; query?: string },
  { rejectValue: ApiError }
>(
  "admin/queryGins",
  async (
    { api, locale, query }: { api: AdminApi; locale: string; query?: string },
    { rejectWithValue }
  ) => {
    try {
      const url = `/gin?locale=${locale}${query ? `&query=${query}` : ""}`;
      return (await api.get<AdminGrantResult[]>(url)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinByGrantId = createAsyncThunk<
  AdminGinResult,
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinByGrantId",
  async (
    { api, grantId }: { api: AdminApi; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await api.get<AdminGinResult>(`/gin/grant/${grantId}`)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplications = createAsyncThunk<
  GinProgram[],
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplications",
  async (
    { api, grantId }: { api: AdminApi; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await api.get<GinProgram[]>(`/gin/${grantId}/programs`)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplication = createAsyncThunk<
  GinApplication,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplication",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplication>(`/gin/application/${applicationId}`)
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationCompany = createAsyncThunk<
  GinApplicationCompany,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationCompany",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplicationCompany>(
          `/gin/application/${applicationId}/company`
        )
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationContacts = createAsyncThunk<
  Contact[],
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationContacts",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<Contact[]>(`/gin/application/${applicationId}/contacts`)
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGinApplicationContacts = createAsyncThunk<
  Contact[],
  { api: AdminApi; applicationId: string; contactIds: string[] },
  { rejectValue: ApiError }
>(
  "gins/updateGinApplicationContacts",
  async ({ api, applicationId, contactIds }, { rejectWithValue }) => {
    try {
      const response = await api.post<Contact[]>(
        `/gin/application/${applicationId}/contacts`,
        { contactIds }
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationProgram = createAsyncThunk<
  GinApplicationProgram,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationProgram",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplicationProgram>(
          `/gin/application/${applicationId}/program`
        )
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleApplicationModalForm = createAction<{
  isVisible: boolean;
  applicationStatus: string;
}>("admin/toggleProgramsModalForm");

export const toggleApplicationModalMessage = createAction<{
  isVisible: boolean;
  isSuccessful: boolean;
  applicationStatus: string;
}>("admin/toggleProgramsModalMessage");

export const toggleProgramContactsModal = createAction<{
  isVisible: boolean;
}>("admin/toggleContactModal");

export const updateGinApplication = createAsyncThunk<
  GinApplication,
  { api: AdminApi; updatedApplication: GinApplication },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateGinApplication",
  async (
    {
      api,
      updatedApplication,
    }: { api: AdminApi; updatedApplication: GinApplication },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put<GinApplication>(
        `/gin/application`,
        updatedApplication
      );
      return response.data;
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === "pending") return false;
    },
  }
);

export const createNewDocuments = createAsyncThunk<
  DocumentNeeded,
  { api: AdminApiClient; document: DocumentNeeded },
  { rejectValue: ApiError }
>(
  "admin/createNewDocument",
  async (
    { api, document }: { api: AdminApiClient; document: DocumentNeeded },
    { rejectWithValue }
  ) => {
    try {
      return await api.createNewDocuments(document);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSingleSelectDocument = createAsyncThunk<
  DocumentNeeded,
  { api: AdminApiClient; document: DocumentNeeded },
  { rejectValue: ApiError }
>(
  "admin/updateSingleSelectDocument",
  async (
    { api, document }: { api: AdminApiClient; document: DocumentNeeded },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateSingleSelectedDocument(document);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSingleSelectedDocument = createAsyncThunk<
  string,
  { api: AdminApiClient; document_id: string },
  { rejectValue: ApiError }
>(
  "admin/deleteSelectDocument",
  async (
    { api, document_id }: { api: AdminApiClient; document_id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteSingleSelectedDocument(document_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const ginsSlice = createSlice({
  name: "gins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryGinGrants.pending, (state) => {
      state.status.programsList = "pending";
    });
    builder.addCase(queryGinGrants.fulfilled, (state, { payload }) => {
      gins.setAll(state.ginGrants, payload ?? []);
      state.status.programsList = "idle";
    });
    builder.addCase(queryGinGrants.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.programsList = "idle";
    });
    builder.addCase(fetchGinByGrantId.pending, (state) => {
      state.status.singleGrant = "pending";
    });
    builder.addCase(fetchGinByGrantId.fulfilled, (state, { payload }) => {
      state.currentGin = payload;
      state.status.singleGrant = "idle";
    });
    builder.addCase(fetchGinByGrantId.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.singleGrant = "idle";
    });
    builder.addCase(fetchGinApplications.pending, (state) => {
      state.status.relatedApplications = "pending";
    });
    builder.addCase(fetchGinApplications.fulfilled, (state, { payload }) => {
      state.selectedGinApplications = payload;
      state.status.relatedApplications = "idle";
    });
    builder.addCase(fetchGinApplications.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.relatedApplications = "idle";
    });
    builder.addCase(fetchGinApplication.pending, (state) => {
      state.status.applicationSingle = "pending";
    });
    builder.addCase(fetchGinApplication.fulfilled, (state, { payload }) => {
      state.currentGinApplication.application = payload;
      state.status.applicationSingle = "idle";
    });
    builder.addCase(fetchGinApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.applicationSingle = "idle";
    });
    builder.addCase(
      updateGinApplicationContacts.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.contacts = payload;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(updateGinApplicationContacts.pending, (state) => {
      state.status.applicationContacts = "pending";
    });
    builder.addCase(
      updateGinApplicationContacts.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(fetchGinApplicationCompany.pending, (state) => {
      state.status.applicationCompany = "pending";
    });
    builder.addCase(
      fetchGinApplicationCompany.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.company = payload;
        state.status.applicationCompany = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationCompany.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationCompany = "idle";
      }
    );
    builder.addCase(fetchGinApplicationContacts.pending, (state) => {
      state.status.applicationContacts = "pending";
    });
    builder.addCase(
      fetchGinApplicationContacts.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.contacts = payload;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationContacts.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(fetchGinApplicationProgram.pending, (state) => {
      state.status.applicationProgram = "pending";
    });
    builder.addCase(
      fetchGinApplicationProgram.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.program = payload;
        state.status.applicationProgram = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationProgram.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationProgram = "idle";
      }
    );
    builder.addCase(toggleApplicationModalForm, (state, { payload }) => {
      state.modalForm = payload;
    });
    builder.addCase(toggleApplicationModalMessage, (state, { payload }) => {
      state.modalMessage = payload;
    });
    builder.addCase(toggleProgramContactsModal, (state, { payload }) => {
      state.modalProgramContacts = payload;
    });
    builder.addCase(updateGinApplication.pending, (state) => {
      state.status.applicationSingle = "pending";
    });
    builder.addCase(updateGinApplication.fulfilled, (state, { payload }) => {
      state.currentGinApplication.application = {
        ...state.currentGinApplication.application,
        ...payload,
      };
      state.status.applicationSingle = "idle";
    });
    builder.addCase(updateGinApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.applicationSingle = "idle";
    });
  },
});

export const selectGinsIsLoading = createSelector(
  (state: RootState, type?: StatusType) => ({
    status: state.gins.status,
    type,
  }),
  ({ status, type }) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === "pending");
    } else {
      return status[type] === "pending";
    }
  }
);

export const { selectAll: selectGinGrants } = gins.getSelectors(
  (state: RootState) => state.gins.ginGrants
);

export const selectCurrentGin = (state: RootState) => state.gins.currentGin;

export const selectGinApplication = (state: RootState) =>
  state.gins.currentGinApplication.application;
export const selectGinApplicationCompany = (state: RootState) =>
  state.gins.currentGinApplication.company;
export const selectGinApplicationContacts = (state: RootState) =>
  state.gins.currentGinApplication.contacts;
export const selectGinApplicationProgram = (state: RootState) =>
  state.gins.currentGinApplication.program;

export const selectSelectedGinApplications = (state: RootState) =>
  state.gins.selectedGinApplications;

export const selectGinModalForm = (state: RootState) => state.gins.modalForm;
export const selectGinModalMessage = (state: RootState) =>
  state.gins.modalMessage;
export const selectGinProgramContactsModal = (state: RootState) =>
  state.gins.modalProgramContacts;

export const ginsReducer = ginsSlice.reducer;

