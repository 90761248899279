import {
  GrantProvider,
  GrantProviders,
} from "@hellodarwin/core/lib/features/entities/grants-entities";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import { useState } from "react";
import { useAppDispatch } from "../../app";
import { createGrantProvider } from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type CreateGrantProviderModalProps = {
  visible: boolean;
  handleClose: () => void;
  handleSuccess: (grantProviderId: string) => void;
};

const CreateGrantProviderModal = ({
  visible,
  handleClose,
  handleSuccess,
}: CreateGrantProviderModalProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const [grantProvider, setGrantProvider] = useState<GrantProvider[]>([
    {
      grant_provider_id: "",
    },
    {
      grant_provider_id: "",
    },
  ]);

  const onSave = async () => {
    try {
      const grantProviderFormValues = form.getFieldsValue()!;

      if (grantProviderFormValues === undefined) {
        return;
      }

      const newGrantProvider: GrantProvider[] = [
        {
          grant_provider_id: grantProvider[0].grant_provider_id,
          locale: "en",
          title: grantProviderFormValues.title_en,
          slug: grantProviderFormValues.slug_en,
        },
        {
          grant_provider_id: grantProvider[1].grant_provider_id,
          locale: "fr",
          title: grantProviderFormValues.title_fr,
          slug: grantProviderFormValues.slug_fr,
        },
      ];

      const updatedGrantProvider: GrantProvider[] = {
        ...grantProvider,
        ...newGrantProvider,
      };

      const sendGrantProvider: GrantProviders = {
        grant_provider_en: updatedGrantProvider[0],
        grant_provider_fr: updatedGrantProvider[1],
      };

      const grantProviderResponse = (await dispatch(
        createGrantProvider({ api, grantProvider: sendGrantProvider })
      ).unwrap()) as unknown as any[];

      if ("grant_provider_id" in grantProviderResponse[0]) {
        message.success("Grant Provider created with success !");
        setGrantProvider([
          {
            grant_provider_id: "",
          },
          {
            grant_provider_id: "",
          },
        ]);
        form.resetFields();
        setIsSaving(false);
        handleSuccess(grantProviderResponse[0].grant_provider_id);
      }
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const onFinish = (values: any) => {
    (async () => {
      setIsSaving(true);
      try {
        await onSave();
        message.success("Saved!");
      } catch (e: any) {
        setIsSaving(false);
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  return (
    <Modal
      title="Create Grant Provider"
      afterClose={form.resetFields}
      open={visible}
      okText="Create"
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-grant-provider"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Title EN"}
              name={"title_en"}
              rules={[{ required: true, message: "input title is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Title FR"}
              name={"title_fr"}
              rules={[{ required: true, message: "input title is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Slug EN"}
              name={"slug_en"}
              rules={[
                {
                  required: true,
                  message: "input slug is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Slug FR"}
              name={"slug_fr"}
              rules={[
                {
                  required: true,
                  message: "input slug is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateGrantProviderModal;

