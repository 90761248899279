import CopyOutlined from "@ant-design/icons/CopyOutlined";
import ImportOutlined from "@ant-design/icons/ImportOutlined";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Button from "antd/es/button";
import List from "antd/es/list";
import Popover from "antd/es/popover";
import Skeleton from "antd/es/skeleton";
import Tooltip from "antd/es/tooltip";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchDirectoryMatches,
  selectDirectoryMatches,
  selectMatchesLoading,
} from "../../features/api/slices/matches-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { copyIntoClipboard } from "../clipboard";

type ReferenceWidgetProps = {
  id?: string;
};

const ReferenceWidget = ({ id }: ReferenceWidgetProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const loading = useAppSelector(selectMatchesLoading);
  const matches = useAppSelector((state) => selectDirectoryMatches(state));

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(fetchDirectoryMatches({ api, id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (matches.length <= 0) return <></>;
  return (
    <Popover
      title={"Website References"}
      content={
        <List
          dataSource={matches}
          size="small"
          itemLayout="horizontal"
          style={{ width: 500 }}
          renderItem={(p, index) => (
            <List.Item>
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  avatar={
                    <Div
                      onClick={() => copyIntoClipboard(p.provider_id)}
                      fitContent
                    >
                      <Tooltip
                        placement="bottom"
                        title={"Copy ID To Clipboard"}
                      >
                        <Button
                          style={{
                            width: 45,
                            height: 45,
                            border: 0,
                          }}
                          icon={<CopyOutlined />}
                        ></Button>
                      </Tooltip>
                    </Div>
                  }
                  title={
                    <Link to={"/providers/" + p.provider_id}>
                      <Typography elementTheme="body3">
                        {`${p.provider?.name} | ${p.provider?.email}`}
                      </Typography>
                    </Link>
                  }
                  description={
                    <Div flex="row" align="center" gap={10}>
                      <Typography elementTheme="overline">
                        {p.provider_id}
                      </Typography>
                    </Div>
                  }
                />
                <Div
                  onClick={() =>
                    window.open("/providers/" + p.provider_id, "_blank")
                  }
                  style={{ width: 60 }}
                >
                  <Button
                    style={{ width: 45, border: 0 }}
                    icon={<ImportOutlined />}
                  ></Button>
                </Div>
              </Skeleton>
            </List.Item>
          )}
        />
      }
    >
      <WarningOutlined style={{ fontSize: 20, color: "#fc3503" }} />
    </Popover>
  );
};

export default ReferenceWidget;

