import { API_URL } from "@hellodarwin/core/lib/api";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import { useMemo } from "react";
import AdminApi from "./admin-api";
import AdminApiClient from "./admin-api-client";

export const useAdminApi = () => {
  const { accessToken } = useLogin();
  return useMemo(() => new AdminApiClient(API_URL, accessToken), [accessToken]);
};

export const useNewAdminApi = () => {
  const { accessToken } = useLogin();
  return useMemo(() => new AdminApi(API_URL, accessToken), [accessToken]);
};
