import Div from "@hellodarwin/core/lib/components/common/div";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { useAppSelector } from "../../../../app";
import { selectGinApplication } from "../../../../features/api/slices/gins-slice";
import { GinBlockComponentProps } from "../../gin-single/gin-block";
import ApplicationTimelineCard from "./application-timeline-card";

const ApplicationTimeline = (props: GinBlockComponentProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const application = useAppSelector(selectGinApplication);

  if (!application.application_id) return <></>;
  return (
    <Div
      flex="row"
      gap={24}
      justify="space-between"
      wrap="wrap"
      align="center"
      style={{ position: "relative" }}
    >
      <div
        style={{
          borderBottom: `1px solid ${theme.colors.grey_4}`,
          position: "absolute",
          top: "50%",
          left: 0,
          width: "100%",
        }}
      />
      <ApplicationTimelineCard
        label={t("application_single|application_created_at")}
        date={application.application_created_at}
        hideAmount
      />
      <ApplicationTimelineCard
        label={t("application_single|application_ongoing_work_at")}
        date={application.application_requested_at}
        amount={application.application_targeted_amount}
        amountLabel={t("application_single|targeted_amount")}
      />
      <ApplicationTimelineCard
        label={t("application_single|application_submitted_at")}
        date={application.application_submitted_at}
        amount={application.application_submitted_amount}
        amountLabel={t("application_single|submitted_amount")}
      />
      <ApplicationTimelineCard
        application={application}
        label={
          application.application_rejected_at
            ? t("application_single|application_rejected_at")
            : application.application_completed_at
              ? t("application_single|application_accepted_at")
              : t("application_single|application_result_at")
        }
        date={
          application.application_rejected_at ??
          application.application_completed_at ??
          application.application_result_at
        }
        amount={application.application_accepted_amount}
        amountLabel={t("application_single|accepted_amount")}
      />
    </Div>
  );
};

export default ApplicationTimeline;

