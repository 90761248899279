import styled from "@hellodarwin/core/lib/plugins/styled";

export const SelectableContactContainer = styled.div`
  padding: 1rem;
  position: relative !important;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  &:hover {
    background: ${({ theme }) => theme.colors.beige_2};
  }
`;

