import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Design = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1819 20.7392C12.3175 18.6331 9.87774 17.6211 7.73076 18.4827C6.15538 19.1118 5.12372 20.6161 5.12372 22.2845C5.12372 23.9803 3.71564 25.3616 1.9869 25.3616C1.91719 25.3616 1.84749 25.3616 1.77778 25.3616C3.50651 28.3019 7.3404 29.3139 10.3378 27.6317C12.2896 26.5377 13.4886 24.5 13.4886 22.2982C13.4886 21.7512 13.377 21.2315 13.1819 20.7528V20.7392ZM13.1819 20.7392C14.8409 20.1785 16.4302 19.44 17.908 18.5237M10.8815 18.4964C11.4531 16.8689 12.206 15.3099 13.14 13.8603M17.908 18.5374C20.5569 16.8963 22.8154 14.7355 24.5441 12.1782L29.9534 4.23256C30.4413 3.50775 30.2462 2.53677 29.5212 2.04445C28.9775 1.68888 28.2804 1.68888 27.7367 2.04445L19.6367 7.35063C17.0297 9.04642 14.8269 11.2756 13.154 13.8603C15.2731 14.7902 16.9739 16.4587 17.9219 18.5374"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Design;

