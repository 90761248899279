import parse, {
  HTMLReactParserOptions,
} from "../../../../features/helpers/parse";

const getItemTranslatedLabel = ({
  label_en,
  label_fr,
  locale,
  fallback,
  parseOptions,
}: {
  label_fr: string | undefined;
  label_en: string | undefined;
  fallback: string;
  locale: string;
  parseOptions?: HTMLReactParserOptions;
}) => {
  if (locale === "en" && !!label_en?.length) {
    return !!parseOptions ? parse(label_en, parseOptions) : label_en;
  } else if (locale === "fr" && !!label_fr?.length) {
    return !!parseOptions ? parse(label_fr, parseOptions) : label_fr;
  } else {
    return fallback;
  }
};

export default getItemTranslatedLabel;

