import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const InformationCircle = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.035 15.035L15.0998 15.0034C15.3024 14.9022 15.5297 14.8611 15.755 14.8851C15.9802 14.9091 16.1938 14.9972 16.3705 15.1388C16.5472 15.2805 16.6796 15.4698 16.7521 15.6844C16.8245 15.8989 16.834 16.1298 16.7793 16.3496L15.6607 20.8304C15.6056 21.0503 15.6148 21.2814 15.6871 21.4962C15.7593 21.7111 15.8917 21.9007 16.0685 22.0426C16.2453 22.1844 16.4591 22.2726 16.6845 22.2967C16.9099 22.3207 17.1375 22.2796 17.3402 22.1782L17.405 22.145M30.44 16.22C30.44 18.0874 30.0722 19.9365 29.3576 21.6618C28.6429 23.387 27.5955 24.9546 26.2751 26.2751C24.9546 27.5955 23.387 28.6429 21.6618 29.3576C19.9365 30.0722 18.0874 30.44 16.22 30.44C14.3526 30.44 12.5035 30.0722 10.7782 29.3576C9.05299 28.6429 7.48539 27.5955 6.16494 26.2751C4.84449 24.9546 3.79705 23.387 3.08243 21.6618C2.36781 19.9365 2 18.0874 2 16.22C2 12.4486 3.49817 8.83171 6.16494 6.16494C8.83171 3.49817 12.4486 2 16.22 2C19.9914 2 23.6083 3.49817 26.2751 6.16494C28.9418 8.83171 30.44 12.4486 30.44 16.22ZM16.22 10.295H16.2326V10.3076H16.22V10.295Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InformationCircle;

