import { ReactNode } from "react";
import Loading from "../../../loading";
import Typography from "../../typography";
import {
  SidebarBlockContainer,
  SidebarBlockContent,
  SidebarBlockHeader,
} from "./styles";

export interface SidebarBlockConfig {
  title: ReactNode;
  content: ReactNode;
  extra?: ReactNode;
}

export interface SidebarBlockProps extends SidebarBlockConfig {
  isLoading?: boolean;
}

const SidebarBlock = ({
  isLoading,
  content,
  title,
  extra,
}: SidebarBlockProps) => {
  return (
    <SidebarBlockContainer>
      <SidebarBlockHeader>
        <Typography elementTheme="subtitle1">{title}</Typography>
        {extra && extra}
      </SidebarBlockHeader>
      <SidebarBlockContent>
        {isLoading ? <Loading /> : content}
      </SidebarBlockContent>
    </SidebarBlockContainer>
  );
};

export default SidebarBlock;

