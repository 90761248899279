import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Divider from "antd/es/divider";
import Tooltip from "antd/es/tooltip";
import { Grant } from "../../../features/entities/grants-entities";
import {
  AdminProfile,
  Profile,
} from "../../../features/entities/profile-entities";
import { Program } from "../../../features/entities/programs-entities";
import SingleGrantSections from "../../../features/enums/single-grant-sections";
import { dollarFormat } from "../../../features/helpers";
import { getStringDateFromString } from "../../../features/helpers/get-formatted-date";
import { getProgramGrantStatusColor } from "../../../features/helpers/get-status-color";
import useLocale from "../../../features/providers/locale-provider";
import { useTranslations } from "../../../features/providers/translations-provider";
import theme from "../../../theme";
import AdminCard from "../../admin/admin-card";
import Container from "../../common/container";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import InfoField from "../../common/infoField";
import Typography from "../../common/typography";
import SingleGrant from "../../grants/single";
import ProjectSingleTile from "../../project/single/project-content/project-single-tile";
import ProgramEmptyState from "./program-empty-state";

interface ProgramProps extends Program {
  admin?: AdminProfile;
  profile?: Profile;
  isLoading?: boolean;
  grant?: Grant;
}

export enum ProgramSingleTabs {
  GrantInfo = "grant",
}

const ProgramSingle = (program: ProgramProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();

  if (!program) return <></>;

  const { admin, grant, profile } = program;

  const Content = () => (
    <Container style={{ maxWidth: theme.caps.normal }}>
      <Div flex="column" gap={32}>
        <Div flex="column" gap={16}>
          <Tooltip title={grant?.grant_display_title || program.program_name}>
            <Typography.Title level={1} elementTheme="h4" noMargin>
              {grant?.grant_display_title || program.program_name}
            </Typography.Title>
          </Tooltip>
          <Div
            flex="row"
            gap={24}
            align="center"
            style={{ color: theme.colors.grey_2 }}
          >
            <HdTag
              text={t(`programs_labels|${program.program_grant_status}`)}
              color={getProgramGrantStatusColor(program.program_grant_status)}
            />
            <Divider
              type="vertical"
              style={{
                borderColor: theme.colors.grey_4,
                margin: 0,
                height: 18,
              }}
            />
            <InfoField
              Icon={Timeline}
              label=""
              value={getStringDateFromString(
                program.program_updated_at,
                selectedLocale
              )}
            />
            <Divider
              type="vertical"
              style={{
                borderColor: theme.colors.grey_4,
                margin: 0,
                height: 18,
              }}
            />
            <InfoField
              Icon={Budget}
              label=""
              value={
                !!program.program_accepted_amount
                  ? `${dollarFormat(
                      program.program_accepted_amount,
                      selectedLocale,
                      true
                    )}`
                  : !!program.program_application_amount
                    ? `${dollarFormat(
                        program.program_application_amount,
                        selectedLocale,
                        true
                      )}`
                    : !!program.program_targeted_amount
                      ? `${dollarFormat(
                          program.program_targeted_amount,
                          selectedLocale
                        )}`
                      : !!grant?.funding_max_amount
                        ? `${dollarFormat(
                            grant.funding_max_amount,
                            selectedLocale
                          )}`
                        : dollarFormat(0, selectedLocale, true)
              }
            />
          </Div>
        </Div>

        <SingleGrant
          grant={grant}
          sections={[
            SingleGrantSections.Summary,
            SingleGrantSections.Preview,
            SingleGrantSections.Projects,
            SingleGrantSections.Admissibility,
            SingleGrantSections.Criteria,
            SingleGrantSections.Register,
            SingleGrantSections.OtherInfo,
            SingleGrantSections.Documents,
            SingleGrantSections.Contact,
          ]}
        />

        {!!admin && !!profile && (
          <ProjectSingleTile>
            <AdminCard
              profile={profile}
              admin={admin}
              title={t("adminCard|adminTextProgram")}
              subtitle={t("adminCard|adminTextStep")}
            />
          </ProjectSingleTile>
        )}
      </Div>
    </Container>
  );

  return !program?.program_id ? <ProgramEmptyState /> : <Content />;
};

export default ProgramSingle;

