import {
  AdminFormResponse,
  FormGroupResponse,
  FormItemResponse,
  FormPageResponse,
  Forms,
  FormsConditions,
  FormsGroupsItem,
  FormsItemsTotal,
  FormsSelectOptions,
} from "@hellodarwin/core/lib/features/entities/form-entities";

class FormAdapter {
  InsertSingleFormItemOptions = (
    form: AdminFormResponse,
    formItemOption: FormsSelectOptions
  ) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            form_select_options:
              item.form_item_id === formItemOption.form_item_id
                ? [...(item.form_select_options ?? []), formItemOption].sort(
                    (option1, option2) =>
                      option1.display_order - option2.display_order
                  )
                : item.form_select_options ?? [],
          })),
        })),
      })),
    };
  };
  UpdateSingleFormItemOptions = (
    form: AdminFormResponse,
    formItemOption: FormsSelectOptions
  ) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            form_select_options: (item.form_select_options ?? [])
              .map((option) =>
                option.form_select_option_id ===
                formItemOption.form_select_option_id
                  ? formItemOption
                  : option
              )
              .sort(
                (option1, option2) =>
                  option1.display_order - option2.display_order
              ),
          })),
        })),
      })),
    };
  };
  CreateFormCondition = (
    form: AdminFormResponse,
    formCondition: FormsConditions
  ) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            form_item_conditions:
              item.form_item_id === formCondition.form_item_id
                ? [...(item.form_item_conditions ?? []), formCondition]
                : item.form_item_conditions ?? [],
          })),
        })),
      })),
    };
  };
  UpdateFormsConditions = (
    form: AdminFormResponse,
    formCondition: FormsConditions
  ) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            form_item_conditions: (item.form_item_conditions ?? [])
              .map((condition) =>
                condition.form_condition_id === formCondition.form_condition_id
                  ? formCondition
                  : condition
              )
              .filter((itm) => !itm.deleted_at),
          })),
        })),
      })),
    };
  };

  UpdateFormItemOptions = (
    form: AdminFormResponse,
    formItemOptions: FormsSelectOptions[]
  ) => {
    if (!formItemOptions?.length) return form;

    const options = formItemOptions.reduce(
      (o, option) => ({
        ...o,
        [option.form_item_id]: { ...(o[option.form_item_id] ?? []), option },
      }),
      {} as { [form_item_id: string]: FormsSelectOptions }
    );

    const optionsKeys = Object.keys(options);
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            form_select_options: optionsKeys.includes(item.form_item_id)
              ? options[item.form_item_id]
              : item.form_select_options ?? [],
          })),
        })),
      })),
    };
  };

  UpdateFormItem = (form: AdminFormResponse, formItem: FormItemResponse) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) =>
            item.form_item_id === formItem.form_item_id ? formItem : item
          ),
        })),
      })),
    };
  };
  UpdateFormItemOrder = (
    form: AdminFormResponse,
    formItemsOrders: FormsGroupsItem[]
  ) => {
    const initialMappedOrders: { [id: string]: number } = {};

    if (!formItemsOrders?.length) return form;

    const mappedOrders = formItemsOrders.reduce(
      (o, itemOrder) => ({
        ...o,
        [itemOrder.form_item_id]: itemOrder.forms_groups_items_order,
      }),
      initialMappedOrders
    );

    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.map((item) => ({
            ...item,
            forms_groups_items_order:
              mappedOrders[item.form_item_id] ?? item.forms_groups_items_order,
          })),
        })),
      })),
    };
  };

  InsertFormPage = (form: AdminFormResponse, formPage: FormPageResponse) => {
    return {
      ...form,
      pages: [...form.pages, formPage],
    };
  };
  InsertFormGroup = (
    form: AdminFormResponse,
    formGroup: FormGroupResponse
  ): AdminFormResponse => {
    return {
      ...form,
      pages: form.pages.map((page) =>
        formGroup.form_page_id === page.form_page_id
          ? { ...page, groups: [...page.groups, formGroup] }
          : page
      ),
    };
  };

  DeleteFormPage = (form: AdminFormResponse, formPageId: string) => {
    return {
      ...form,
      pages: form.pages.filter((group) => group.form_page_id !== formPageId),
    };
  };
  DeleteFormGroup = (form: AdminFormResponse, formGroupId: string) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.filter(
          (group) => group.form_group_id !== formGroupId
        ),
      })),
    };
  };
  DeleteFormItem = (form: AdminFormResponse, formItemId: string) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items: group.items.filter((itm) => itm.form_item_id !== formItemId),
        })),
      })),
    };
  };

  InsertFormItem = (
    form: AdminFormResponse,
    { form_item, form_group, form_group_item }: FormsItemsTotal
  ) => {
    const itemResponse: FormItemResponse = {
      form_item_id: form_item.form_item_id,
      key: form_item.key,
      content_en: form_item.content_en,
      content_fr: form_item.content_fr,
      heading_en: form_item.heading_en,
      heading_fr: form_item.heading_fr,
      type: form_item.type,
      field_type: form_item.field_type,
      required: form_item.required,
      disposition: form_item.disposition,
      form_select_options: form_item.formSelectOptions,
      forms_groups_items_id: form_group_item.form_item_id,
      forms_groups_items_order: form_group_item.forms_groups_items_order,
    };

    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) => ({
          ...group,
          items:
            form_group?.form_group_id === group.form_group_id
              ? [...(group.items ?? []), itemResponse]
              : group.items,
        })),
      })),
    };
  };

  UpdateFormPage = (form: AdminFormResponse, formPage: FormPageResponse) => {
    return {
      ...form,
      pages: form.pages.map((page) =>
        page.form_page_id === formPage.form_page_id ? formPage : page
      ),
    };
  };

  UpdateFormGroup = (form: AdminFormResponse, formGroup: FormGroupResponse) => {
    return {
      ...form,
      pages: form.pages.map((page) => ({
        ...page,
        groups: page.groups.map((group) =>
          group.form_group_id === formGroup.form_group_id ? formGroup : group
        ),
      })),
    };
  };

  ConvertToForms = (form: AdminFormResponse): Forms => {
    return {
      form_id: form.form_id,
      grants: form.grants ?? [],
      title_en: form.title_en ?? "",
      title_fr: form.title_fr ?? "",
      subtitle_en: form.subtitle_en ?? "",
      subtitle_fr: form.subtitle_fr ?? "",
      form_slug_en: form.form_slug_en ?? "",
      form_slug_fr: form.form_slug_fr ?? "",
      form_type: form.form_type ?? "",
      published_at: form.published_at,
    };
  };
}

export const createFormAdapter = () => new FormAdapter();

