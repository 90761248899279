import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import React from "react";

export interface CardInfoRowProps {
  Icon: IconNode;
  value: React.ReactNode;
}

const CardInfoRow = ({ Icon, value }: CardInfoRowProps) => {
  if (!value) return <></>;
  return (
    <Div flex="row" gap={8}>
      <Icon width={12} height={12} style={{ flexShrink: 0 }} />
      <Typography elementTheme="overline" ellipsis overflow>
        {value}
      </Typography>
    </Div>
  );
};

export default CardInfoRow;

