import Tooltip from "antd/es/tooltip";
import { GrantStatus } from "../../../features/entities/grants-entities";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import GrantStatusTag from "./grant-status-tag";

const GrantCardStatusList = ({
  application_status,
  featuredTags,
}: {
  application_status: GrantStatus;
  featuredTags: string[];
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Div flex="row" gap={8} wrap="wrap">
      {application_status === "closingSoon" && <GrantStatusTag status="open" />}
      <GrantStatusTag status={application_status} />

      {featuredTags &&
        featuredTags.map((featuredString) => (
          <Tooltip title={t("grant_card|idealFor") + featuredString}>
            <HdTag color={theme.colors.yellow_2} text={featuredString} />
          </Tooltip>
        ))}
    </Div>
  );
};
export default GrantCardStatusList;

