import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { TagType } from "../../../features/entities";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Collapse from "../Collapse";
import {
  CollapsePanelExtendedProps,
  CollapsePanelProps,
} from "../Collapse/CollapsePanel";
import Div from "../div";
import HdRecursiveTag from "../hd-recursive-tag";
import Typography from "../typography";

type SeeMoreTagsProps = {
  limit: number;
  content: {
    tag_id: string;
    content: string;
    tag_type: string;
    parent_id: string;
    onClose?: () => void;
  }[];
  size: "small" | "medium" | "large";
  position?: "top" | "bottom";
};

const SeeMoreTags = (props: SeeMoreTagsProps & PropsWithChildren) => {
  const { limit, content, size, position } = props;
  const theme = useTheme();
  const { t } = useTranslations();

  const [highlightedTags, setHighlightedTags] = useState<Set<string>>(
    new Set()
  );

  const contentLength = content.length;
  const remainingCount = contentLength - limit;

  const showMore = useMemo(
    () =>
      remainingCount <= 1
        ? t("actions|seeCountMoreSingular", {
            count: remainingCount,
          })
        : t("actions|seeCountMore", { count: remainingCount }),
    [remainingCount, t]
  );

  const showLess = t("actions|seeLess");

  const getTagColor = (category: string) => {
    let baseColor;
    switch (category) {
      case TagType.Sector:
        baseColor = theme.colors.orange_1;
        break;
      case TagType.ActivityType:
        baseColor = theme.colors.green_1;
        break;
      case TagType.Activity:
        baseColor = theme.colors.blue_1;
        break;
      default:
        baseColor = theme.colors.purple_3;
        break;
    }
    return baseColor;
  };

  const expandIcon = ({ isActive }: CollapsePanelExtendedProps) => (
    <Typography nowrap elementTheme="body2" color={theme.colors.grey_3}>
      <span style={{ marginRight: 8 }}>{isActive ? showLess : showMore}</span>
      <TabArrow width={8} height={8} down={!isActive} />
    </Typography>
  );

  const findParentTags = (tagId: string): Set<string> => {
    const highlighted = new Set<string>();
    const findParent = (id: string) => {
      const parent = content.find((tag) => tag.tag_id === id)?.parent_id;
      if (parent) {
        highlighted.add(parent);
        findParent(parent);
      }
    };
    findParent(tagId);
    return highlighted;
  };

  const handleMouseEnter = (tagId: string) => {
    const parents = findParentTags(tagId);
    setHighlightedTags(new Set([tagId, ...Array.from(parents)]));
  };

  const handleMouseLeave = () => {
    setHighlightedTags(new Set());
  };

  const collapseItems: CollapsePanelProps[] = useMemo(
    () => [
      {
        handleClickButtonOnly: true,
        id: "see-more-header",
        title: (
          <Div
            flex="row"
            gap={8}
            style={{ paddingBottom: 8 }}
            align="center"
            wrap="wrap"
          >
            {React.Children.toArray(
              content
                .slice(0, limit)
                .map((tag, index) => (
                  <HdRecursiveTag
                    key={`tag-${index}`}
                    text={tag.content}
                    size={size}
                    color={getTagColor(tag.tag_type)}
                    onMouseEnter={() => handleMouseEnter(tag.tag_id)}
                    onMouseLeave={handleMouseLeave}
                    isDimmed={
                      highlightedTags.size > 0 &&
                      !highlightedTags.has(tag.tag_id)
                    }
                    isHovered={highlightedTags.has(tag.tag_id)}
                    onClose={tag.onClose}
                    deleteConfirmation={true}
                  />
                ))
            )}
          </Div>
        ),
        children:
          contentLength > limit ? (
            <Div
              flex="row"
              gap={8}
              style={{ paddingBottom: 8 }}
              align="center"
              wrap="wrap"
            >
              {React.Children.toArray(
                content
                  .slice(limit)
                  .map((tag, index) => (
                    <HdRecursiveTag
                      key={`tag-${index + limit}`}
                      text={tag.content}
                      size={size}
                      color={getTagColor(tag.tag_type)}
                      onMouseEnter={() => handleMouseEnter(tag.tag_id)}
                      onMouseLeave={handleMouseLeave}
                      isDimmed={
                        highlightedTags.size > 0 &&
                        !highlightedTags.has(tag.tag_id)
                      }
                      isHovered={highlightedTags.has(tag.tag_id)}
                      onClose={tag.onClose}
                      deleteConfirmation={true}
                    />
                  ))
              )}
            </Div>
          ) : undefined,
      },
    ],
    [
      content,
      limit,
      contentLength,
      highlightedTags,
      size,
      handleMouseEnter,
      handleMouseLeave,
    ]
  );

  return (
    <Collapse
      expandIconPosition={position || "bottom"}
      ExpandIcon={expandIcon}
      ghost
      noPadding
      items={collapseItems}
    />
  );
};

export default SeeMoreTags;

