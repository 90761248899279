import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  Project,
  ReviewNotification,
} from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { getFormattedDate } from "@hellodarwin/core/lib/features/helpers";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Switch from "antd/es/switch";
import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  lazy,
  useEffect,
  useState,
} from "react";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../../pages/single-project-page";

const DatePicker = lazy(() => import("antd/es/date-picker"));

const { Title } = Typography;

type ReviewNotificationFormProps = {
  project: Project;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
};

export type ReviewNotificationFormValues = {
  scheduled_at: any;
  disable_review_notification: boolean;
};
const ReviewNotificationForm = ({
  project,
  setForms,
}: ReviewNotificationFormProps) => {
  const api = useAdminApi();
  const [form] = Form.useForm<ReviewNotificationFormValues>();
  const [notification, setReviewNotification] = useState<ReviewNotification>();

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      reviewNotificationForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    (async () => {
      if (
        !!project.project_id &&
        project.status !== ProjectStatus.PendingApproval
      ) {
        const reviewResults = await api.fetchReview(project.project_id);
        setReviewNotification(reviewResults);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.project_id]);

  const initialValues: ReviewNotificationFormValues = {
    scheduled_at: notification?.scheduled_at
      ? dayjs(notification?.scheduled_at)
      : null,
    disable_review_notification:
      notification?.disable_review_notifications_at !== null,
  };

  const handleSendReviewNotification = () => {
    (async () => {
      try {
        const review = await api.sendReviewNotification(project.project_id);
        setReviewNotification(review);
      } catch (err: any) {
        console.error(err);
      }
    })();
  };

  if (!notification) return <></>;

  return (
    <Card style={{ padding: "1rem" }}>
      <Title level={3}>Review Notification</Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        name="Review Notification"
        size="small"
      >
        <Div flex="column" gap={16}>
          <Div flex="row" gap={8}>
            <Suspense fallback={<Loading />}>
              <Form.Item
                style={{ flex: 2 }}
                label="Scheduled At"
                name="scheduled_at"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Suspense>
            <Form.Item
              style={{ flex: 1 }}
              label="Disable"
              name="disable_review_notification"
              valuePropName={"checked"}
            >
              <Switch />
            </Form.Item>

            {notification?.sent_at && (
              <Div style={{ flex: 1 }}>
                <Typography>Sent at</Typography>
                <Typography>
                  {getFormattedDate(notification?.sent_at)}
                </Typography>
              </Div>
            )}

            <Button onClick={handleSendReviewNotification}>Send</Button>
          </Div>
        </Div>
      </Form>
    </Card>
  );
};

export default ReviewNotificationForm;

