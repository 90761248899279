import Loading from "@hellodarwin/core/lib/components/loading";
import {
  getFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import List from "../../components/list/list";
import {
  fetchProviderReferredProjects,
  selectProviderReferredProjects,
  selectProvidersLoading,
} from "../../features/api/slices/providers-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { getProjectStatusColor } from "../../features/utils";

type ReferredProjectsListProps = {
  providerId: string;
};

const ReferredProjectsList = ({ providerId }: ReferredProjectsListProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProviderReferredProjects);
  const loading = useAppSelector(selectProvidersLoading);

  useEffect(() => {
    dispatch(fetchProviderReferredProjects({ api, providerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    "Project ID",
    "Category",
    "Status",
    "Estimated value",
    "Lead price",
    "Received credits",
    "Received Credits at",
  ];

  const getRows = () => {
    if (projects.length === 0) {
      return [];
    }
    return projects.map((p, index) => {
      return [
        <Tooltip placement="bottom" key={index} title="Project page">
          <Link to={"/projects/" + p.project_id}>
            {getShortId(p.project_id)}
          </Link>
        </Tooltip>,
        p.category,
        <Tag color={getProjectStatusColor(p.status)}>{p.status}</Tag>,
        `$${p.estimated_value || 0}`,
        `$${p.lead_price || 0}`,
        p.referral_credit_amount ? `$${p.referral_credit_amount}` : "n/a",
        p.referral_credit_applied_at
          ? `${getFormattedDate(p.referral_credit_applied_at)}`
          : "n/a",
      ];
    });
  };

  if (loading) return <Loading />;

  return <List headers={headers} rows={getRows()} onClick={() => null} />;
};

export default ReferredProjectsList;

