import styled from "../../../../plugins/styled";

export const GrantHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  padding: 24px 17px;
  padding-right: 40px;
  padding-bottom: 0;
  border-radius: 11px 11px 0px 0px;

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 40px;
  }
`;
export const GrantHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  display: flex;
  flex-direction: column;
  gap: 40px;

  border-radius: 11px 11px 0px 0px;

  a {
    color: inherit;
  }
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {

    gap: 0;x
  }
`;

export const GrantLogo = styled.img`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  border-radius: 4px;
  overflow: hidden;
`;

