import { Tablet } from "@hellodarwin/core/lib/components/common/Media";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import FormSumbissionViewer from "@hellodarwin/core/lib/components/forms/form-submission";
import {
  FormsSubmissionItem,
  TranslatedFormPageResponse,
} from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import message from "antd/es/message";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import FormCard from "../../components/forms/form-card";
import FormSubmissionCompanyCard from "../../components/forms/submissions/form-submission-company-card";
import FormSubmissionContactCard from "../../components/forms/submissions/form-submission-contact-card";
import FormSubmissionEligibilityCard from "../../components/forms/submissions/form-submission-eligibility-card";
import {
  createFormSubmissionItems,
  fetchSingleFormSubmission,
  fetchTranslatedFormByID,
  selectActiveFormSubmission,
  selectActiveTranslatedForm,
  updateFormSubmissionEligibilityStatus,
} from "../../features/api/slices/forms-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

interface TriggerInfo {
  form_item_id: string;
  type: string;
  values: string[];
}

interface TriggerGroups {
  form_item_id: string;
  triggers: TriggerInfo[];
}

interface TriggerStatePageInfo {
  page_number: number;
  triggerGroups: TriggerGroups[];
}

const FormSubmissionSinglePage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { formSubmissionId } = useParams<{
    formSubmissionId: string;
  }>();

  const formSubmission = useAppSelector(selectActiveFormSubmission);
  const formResponse = useAppSelector(selectActiveTranslatedForm);

  const filterStatePages = (pages: TranslatedFormPageResponse[]) => {
    const endPagesNumber = pages
      .filter((page) => page.page_type?.includes("end"))
      .map((page) => page.page_number);
    return pages.filter(
      (page) =>
        page.groups &&
        page.groups.some(
          (group) =>
            group.items &&
            group.items.some(
              (item) =>
                item.form_item_conditions &&
                item.form_item_conditions?.some((condition) =>
                  endPagesNumber.includes(condition.action_page ?? -1)
                )
            )
        )
    );
  };

  const parseTriggerStatePageInfo = (
    pages: TranslatedFormPageResponse[]
  ): TriggerStatePageInfo[] => {
    const triggerStatePages: TriggerStatePageInfo[] = [];
    for (let i = 0; i < pages.length; i++) {
      const group = pages[i].groups;
      for (let j = 0; j < group.length; j++) {
        const items = group[j].items;
        for (let k = 0; k < items.length; k++) {
          const conditions = items[k].form_item_conditions;
          if (conditions) {
            for (let l = 0; l < conditions.length; l++) {
              if (conditions[l].action_page) {
                const triggerInfo: TriggerInfo = {
                  form_item_id: items[k].form_item_id,
                  type: conditions[l].condition_type,
                  values: conditions[l].trigger_values.map(
                    (value) => value.trigger_value
                  ),
                };
                const triggerGroup: TriggerGroups = {
                  form_item_id: items[k].form_item_id,
                  triggers: [triggerInfo],
                };
                const triggerStatePage: TriggerStatePageInfo = {
                  page_number: conditions[l].action_page ?? -1,
                  triggerGroups: [triggerGroup],
                };
                triggerStatePages.push(triggerStatePage);
              }
            }
          }
        }
      }
    }
    return triggerStatePages;
  };

  const statePages = useMemo(() => {
    return formResponse.form_id ? filterStatePages(formResponse.pages) : [];
  }, [formResponse]);

  const parsedTriggerStatePageInfo = useMemo(() => {
    return statePages.length > 0 ? parseTriggerStatePageInfo(statePages) : [];
  }, [statePages]);

  useEffect(() => {
    (async () => {
      if (!!formSubmissionId) {
        dispatch(fetchSingleFormSubmission({ api, formSubmissionId }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formResponse.form_id === "" && formSubmission) {
      dispatch(
        fetchTranslatedFormByID({
          api,
          formId: formSubmission.form.entity.form_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission]);

  const handleBack = () => {
    navigate(-1);
  };

  const determineEligibilityStatus = (items: FormsSubmissionItem[]) => {
    let pageNumber;
    for (const item of items) {
      const triggersPages = parsedTriggerStatePageInfo.filter(
        (triggerStatePage) =>
          triggerStatePage.triggerGroups.some(
            (triggerGroup) => triggerGroup.form_item_id === item.form_item_id
          )
      );

      for (const triggerPage of triggersPages) {
        for (const triggerGroup of triggerPage?.triggerGroups ?? []) {
          for (const trigger of triggerGroup.triggers) {
            const values = trigger.values;
            switch (trigger.type) {
              case "equals":
                pageNumber = values.some((value) => value === item.value)
                  ? triggerPage?.page_number
                  : undefined;
                if (pageNumber) {
                  return pageNumberToEligibilityStatus(pageNumber);
                }
                break;
              case "contains":
                pageNumber = values.some((value) => item.value.includes(value))
                  ? triggerPage?.page_number
                  : undefined;
                if (pageNumber) {
                  return pageNumberToEligibilityStatus(pageNumber);
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }
  };

  const pageNumberToEligibilityStatus = (pageNumber?: number) => {
    if (pageNumber === undefined) return "not-eligible";
    const page = formResponse.pages.find(
      (page) => page.page_number === pageNumber
    );
    if (!page) return "not-eligible";
    return page.page_type?.includes("not-elligible")
      ? "not-eligible"
      : page.page_type?.includes("maybe-elligible")
        ? "maybe-eligible"
        : "eligible";
  };

  const handleSave = async (items: FormsSubmissionItem[]) => {
    try {
      await dispatch(createFormSubmissionItems({ api, items }));
      const newStatus = determineEligibilityStatus(items);
      if (formSubmissionId && newStatus) {
        await dispatch(
          updateFormSubmissionEligibilityStatus({
            api,
            formSubmissionId,
            status: newStatus,
          })
        );
      }

      message.success(
        `Updated Submission ${getShortId(
          formSubmission.submission.form_submission_id
        )} `
      );
    } catch (error) {
      message.error(
        `Could't update Submission ${getShortId(
          formSubmission.submission.form_submission_id
        )} `
      );
    }
  };

  if (!formSubmission) return <></>;

  return (
    <PageLayout
      app="admin"
      title={formSubmission?.form.entity.title_en}
      handleBack={handleBack}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Forms",
          path: "/forms",
        },
        {
          breadcrumbName: "Submissions",
          path: "/forms/submissions",
        },
        {
          breadcrumbName: formSubmission?.form.entity.title_en,
        },
      ]}
    >
      <Div flex="row" gap={54} xl={{ flex: "column-reverse" }}>
        <div
          style={{ width: theme.caps.small, maxWidth: "100%", flexShrink: 0 }}
        >
          <FormSumbissionViewer
            source="admin"
            page_name="test"
            formSubmission={formSubmission}
            handleSave={handleSave}
          />
        </div>
        <Tablet>
          <div>
            <Div
              flex="column"
              gap={16}
              xl={{
                flex: "row",
                align: "stretch",
                style: { position: "static" },
              }}
              style={{
                position: "sticky",
                top: theme.appearance.appHeaderHeight + 32,
              }}
            >
              <FormCard form={formSubmission.form.entity} />
              <FormSubmissionEligibilityCard
                status={formSubmission.submission.eligibility_state}
              />
              <FormSubmissionContactCard contact={formSubmission.contact} />

              <FormSubmissionCompanyCard company={formSubmission.company} />
            </Div>
          </div>
        </Tablet>
      </Div>
    </PageLayout>
  );
};

export default FormSubmissionSinglePage;

