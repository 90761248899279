import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import InputNumber from "antd/es/input-number";

const RenderInputNumber = ({
  label,
  name,
}: {
  label: string;
  name: string;
}) => {
  const { t } = useTranslations();
  return (
    <FormItem
      name={name}
      label={
        <Typography elementTheme="body2">
          {t(`application_single|${label}`)}
        </Typography>
      }
      rules={[
        { required: true, message: t("application_single|required_amount") },
      ]}
    >
      <InputNumber style={{ maxWidth: "180px", width: "100%" }} />
    </FormItem>
  );
};

export default RenderInputNumber;

