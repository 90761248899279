import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Modal from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import XCircle from "@hellodarwin/icons/dist/icons/XCircle";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../app";
import {
  selectGinModalMessage,
  toggleApplicationModalMessage,
} from "../../../../features/api/slices/gins-slice";

const ApplicationMessage = () => {
  const modalMessage = useAppSelector(selectGinModalMessage);
  const dispatch = useDispatch();
  const { t } = useTranslations();
  return (
    <Modal
      open={modalMessage.isVisible}
      handleCancel={() =>
        dispatch(
          toggleApplicationModalMessage({
            isVisible: false,
            isSuccessful: modalMessage.isSuccessful,
            applicationStatus: "",
          })
        )
      }
      styles={{
        content: {
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        },
      }}
      footer={
        <Button
          size="small"
          style={{ backgroundColor: theme.colors.black, justifySelf: "center" }}
          onClick={() =>
            dispatch(
              toggleApplicationModalMessage({
                isVisible: false,
                isSuccessful: modalMessage.isSuccessful,
                applicationStatus: "",
              })
            )
          }
        >
          {t("button|close")}
        </Button>
      }
    >
      <Div flex="column" gap={4} justifySelf="center" align="center">
        <Div
          flex="column"
          gap={16}
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          {modalMessage.isSuccessful ? (
            <>
              <Confirm color={theme.colors.green_1} />
              <Typography elementTheme="h6">
                {t("application_single|title_successful_message")}
              </Typography>
              <Typography elementTheme="body1" style={{ textAlign: "center" }}>
                {t("application_single|successful_message", {
                  application_status: t(
                    `programs_labels|${modalMessage.applicationStatus}`
                  ),
                })}
              </Typography>
            </>
          ) : (
            <>
              <XCircle color={theme.colors.red_1} />
              <Typography elementTheme="h6">
                {t("application_single|title_error_message")}
              </Typography>
              <Typography elementTheme="body1" style={{ textAlign: "center" }}>
                {t("application_single|error_message")}
              </Typography>
            </>
          )}
        </Div>
      </Div>
    </Modal>
  );
};

export default ApplicationMessage;

