import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import { Suspense, useState } from "react";
import { FiltersDropdownProps } from ".";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import { Tablet } from "../../../../common/Media";
import Typography from "../../../../common/typography";
import FormItem from "../../../../forms/form-layouts/form-item";
import Loading from "../../../../loading";
import { FiltersGrid } from "../styles";
import useGetOptions from "../utils/use-get-options";
import FilterDropdownActions from "./filter-dropdown-actions";
import FilterMultipleSelect from "./filter-multiple-select";
import SavingBlock from "./saving-block";

const FilterDropdownForm = (props: FiltersDropdownProps) => {
  const {
    filterform,
    financingType,
    industries,
    subIndustries,
    provinces,
    services,
    companySize,
    annualRevenue,
    applyFilter,

    TagsCollapse,
    setSelectedIndustries,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { statusOptions, filterOptions } = useGetOptions();

  const [needSaving, setNeedSaving] = useState(false);
  const handleSaveContainer = () => setNeedSaving(false);

  return (
    <Div flex="column" gap={8} tablet={{ gap: 24 }}>
      <Div
        flex="row"
        gap={8}
        justify="space-between"
        align="center"
        style={{ padding: "12px 32px" }}
        borderColor={theme.colors.grey_4}
        bordersSide="bottom"
        backgroundColor={theme.colors.white_1}
        tablet={{ borderColor: "", style: { padding: 0 } }}
      >
        <Tablet direction="up">
          <Typography elementTheme="subtitle2">
            {t(`grants|fundingExplorerFilter.advancedFilters`)}
          </Typography>
        </Tablet>
        <FilterDropdownActions {...props} />
      </Div>
      <Div style={{ padding: "12px 24px" }} tablet={{ style: { padding: 0 } }}>
        <FiltersGrid
          xl={3}
          lg={3}
          md={3}
          sm={1}
          xs={1}
          gutter={12}
          $needSaving={needSaving}
        >
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={filterOptions}
            label={t(`grants|fundingExplorerFilter.filterBy`)}
            name="filterBy"
            placeholder={t(`grants|fundingExplorerFilter.filterByPlaceholder`)}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={industries}
            label={t(`grants|fundingExplorerFilter.industry`)}
            name="industry"
            onChange={setSelectedIndustries}
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.industryPlaceholder`)}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={subIndustries}
            label={t(`grants|fundingExplorerFilter.industrySubsector`)}
            name="subindustry"
            isMultiple
            placeholder={t(
              `grants|fundingExplorerFilter.industrySubsectorPlaceholder`
            )}
            notFoundContent={
              <Div
                flex="column"
                align="center"
                justify="center"
                gap={8}
                style={{ padding: "8px" }}
              >
                <CancelProject height={24} width={24} />
                <Typography elementTheme="body1" bold textAlign="center">
                  {t(`grants|fundingExplorerFilter.notIndustrySelected`)}
                </Typography>
              </Div>
            }
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={financingType}
            label={t(`grants|fundingExplorerFilter.financingType`)}
            name="financingType"
            isMultiple
            placeholder={t(
              `grants|fundingExplorerFilter.financingTypePlaceholder`
            )}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={services}
            label={t(`grants|fundingExplorerFilter.services`)}
            name="service"
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.servicesPlaceholder`)}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={statusOptions}
            label={t(`grants|fundingExplorerFilter.status`)}
            name="status"
            isMultiple
            isStatusTag
            placeholder={t(`grants|fundingExplorerFilter.statusPlaceholder`)}
          />
          <FormItem
            name="closingDate"
            label={t(`grants|fundingExplorerFilter.closingDate`)}
            getValueFromEvent={(onChange) => (onChange ? dayjs(onChange) : "")}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
            style={{ width: "100%", margin: 0 }}
            layout="vertical"
          >
            <Suspense fallback={<Loading />}>
              <DatePicker
                size="large"
                picker="date"
                allowClear
                placeholder={t(
                  `grants|fundingExplorerFilter.closingDatePlaceholder`
                )}
                style={{ width: "100%", height: "32px" }}
                onChange={applyFilter}
              />
            </Suspense>
          </FormItem>

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={provinces}
            label={t(`grants|fundingExplorerFilter.region`)}
            name="region"
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.regionPlaceholder`)}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={annualRevenue}
            label={t(`grants|fundingExplorerFilter.annualRevenue`)}
            name="annualRevenue"
            isMultiple={false}
            placeholder={t(
              `grants|fundingExplorerFilter.annualRevenuePlaceholder`
            )}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={applyFilter}
            options={companySize}
            label={t(`grants|fundingExplorerFilter.companySize`)}
            name="companySize"
            isMultiple={false}
            placeholder={t(
              `grants|fundingExplorerFilter.companySizePlaceholder`
            )}
          />
          {needSaving && <SavingBlock handleClose={handleSaveContainer} />}
        </FiltersGrid>{" "}
      </Div>
      {TagsCollapse && (
        <Div
          flex="row"
          style={{
            color: theme.colors.grey_2,
            margin: 0,
            width: "100%",
          }}
          justify="space-between"
        >
          <TagsCollapse />
        </Div>
      )}
    </Div>
  );
};

export default FilterDropdownForm;

