import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import { Contact } from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Email from "@hellodarwin/icons/dist/icons/Email";
import Language from "@hellodarwin/icons/dist/icons/Language";
import Phone from "@hellodarwin/icons/dist/icons/Phone";
import Empty from "antd/es/empty";
import { useAppSelector } from "../../../../app";
import { selectGinsIsLoading } from "../../../../features/api/slices/gins-slice";
import CardLayout from "./card-layout";

interface ApplicationContactCardProps {
  programContacts: Contact[];
}

const ApplicationContactCard = ({
  programContacts,
}: ApplicationContactCardProps) => {
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, "applicationContacts")
  );
  const { t } = useTranslations();

  if (isLoading) return <Loading />;
  if (!programContacts || programContacts.length === 0) return <Empty />;

  return (
    <>
      {programContacts.map((contact) => (
        <Div style={{ marginBottom: 16 }}>
          <CardLayout
            key={contact.contact_id}
            title={contact.name}
            infos={[
              { Icon: Phone, value: contact.phone },
              { Icon: Email, value: contact.email },
              {
                Icon: Language,
                value: contact.preferred_language
                  ? t(`language|${contact.preferred_language}`)
                  : "",
              },
            ]}
          />
        </Div>
      ))}
    </>
  );
};

export default ApplicationContactCard;

