import Form from "antd/es/form";
import Select, { SelectProps } from "antd/es/select";
import React, { useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ItemsTypes } from "../../../../features/entities/form-entities";
import { FormListStyled } from "../styles";
import getTypeComponent from "../utils/get-type-component";
import getTypeItems from "../utils/get-type-items";

const FormItemsList = ({ locale }: { locale: string }) => {
  const [activeType, setActiveType] = useState<ItemsTypes>(ItemsTypes.main);

  const options: SelectProps["options"] = useMemo(
    () =>
      (Object.keys(ItemsTypes) as Array<keyof typeof ItemsTypes>).map(
        (key) => ({
          label: ItemsTypes[key],
          value: ItemsTypes[key],
        })
      ),
    [ItemsTypes]
  );

  const droppableId = useMemo(
    () => `controls_droppable_${activeType}`,
    [activeType]
  );
  const activeType_items = useMemo(() => {
    return getTypeItems(activeType);
  }, [activeType]);

  const ActiveType_Component = useMemo(() => {
    return getTypeComponent(activeType);
  }, [activeType]);

  return (
    <FormListStyled>
      <Select options={options} value={activeType} onChange={setActiveType} />
      <Form layout="vertical">
        <div>
          <Droppable droppableId={droppableId} type={activeType}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {React.Children.toArray(
                  activeType_items.map(({ key }, index) => (
                    <Draggable
                      key={`control_draggable_${key}`}
                      draggableId={key}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ActiveType_Component
                            type={key as any}
                            locale={locale}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </Form>
    </FormListStyled>
  );
};

export default FormItemsList;

