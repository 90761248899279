import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import { AdminGinContactResponse } from "@hellodarwin/core/lib/features/entities/gin-entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Drawer from "antd/es/drawer";
import Select from "antd/es/select";
import React from "react";
import { Link } from "react-router-dom";
import { ContactCard } from "../../../pages/gin/single-grant/contact-card";

interface GinContactDrawerProps {
  isAddAgentVisible: boolean;
  onClose: () => void;
  providers: { label: string | undefined; value: string }[];
  selectedProvider: string;
  setSelectedProvider: (value: string) => void;
  ginContacts: AdminGinContactResponse[];
  isLoading: boolean;
  grantId: string;
}

const GinContactDrawer: React.FC<GinContactDrawerProps> = ({
  isAddAgentVisible,
  onClose,
  providers,
  selectedProvider,
  setSelectedProvider,
  ginContacts,
  isLoading,
  grantId,
}) => {
  const { t } = useTranslations();
  const selectedProviderLabel = providers.find(
    (provider) => provider.value === selectedProvider
  )?.label;

  return (
    <Drawer
      size="default"
      closable={true}
      onClose={onClose}
      open={isAddAgentVisible}
      title={t("gin_program|mandated_agents")}
      extra={
        !!selectedProvider && (
          <Link
            target="_blank"
            to={`/grants/providers/${selectedProvider}/contacts?grantId=${grantId}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <AddNew size={16} color={theme.colors.grey_2} />
          </Link>
        )
      }
    >
      <Div flex="column" gap={8}>
        <Select
          placeholder="Choose a mandatary"
          size="large"
          onChange={setSelectedProvider}
          options={providers}
          value={selectedProvider}
          style={{ width: "100%" }}
        />

        <Div flex="column" gap={8} overflow="auto">
          {isLoading ? (
            <Loading />
          ) : (
            (() => {
              const filteredContacts = ginContacts?.filter(
                (contact) => contact.grant_provider_id === selectedProvider
              );

              return (
                <>
                  {filteredContacts && filteredContacts.length > 0 ? (
                    filteredContacts.map((contact, index) => (
                      <ContactCard
                        key={index}
                        contact={contact}
                        grantId={grantId}
                      />
                    ))
                  ) : (
                    <Typography
                      elementTheme="subtitle2"
                      style={{ textAlign: "center" }}
                    >
                      {t("gin_program|no_mandated_agents", {
                        companyName: selectedProviderLabel || "",
                      })}
                    </Typography>
                  )}
                </>
              );
            })()
          )}
        </Div>
      </Div>
    </Drawer>
  );
};

export default GinContactDrawer;

