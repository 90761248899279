import {
  ApiError,
  ApiErrorInitialState,
  Portfolio,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export const queryProviderPortfolios = createAsyncThunk<
  Portfolio[],
  { api: AdminApiClient; page: number; limit: number },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/queryProviderPortfolios",
  async (
    { api, page, limit }: { api: AdminApiClient; page: number; limit: number },
    { rejectWithValue }
  ) => {
    try {
      return await api.queryProviderPortfolios(page, limit);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { portfolios } = getState();
      if (portfolios.status === "pending") return false;
    },
  }
);

export const updateProviderPortfolioCuratedAt = createAsyncThunk<
  Portfolio,
  { api: AdminApiClient; portfolioId: string; request: { curated: boolean } },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateProviderPortfolioCuratedAt",
  async (
    {
      api,
      portfolioId,
      request,
    }: {
      api: AdminApiClient;
      portfolioId: string;
      request: { curated: boolean };
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateProviderPortfolioCuratedAt(portfolioId, request);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { portfolios } = getState();
      if (portfolios.status === "pending") return false;
    },
  }
);

const providerPortfolioAdapter = createEntityAdapter({
  selectId: (model: Portfolio) => model?.provider_portfolio_id || "",
});

export interface ProvidersState {
  status: "idle" | "pending";
  error: ApiError;
  providerPortfolios: EntityState<Portfolio, string>;
}

const initialState: ProvidersState = {
  status: "idle",
  error: ApiErrorInitialState,
  providerPortfolios: providerPortfolioAdapter.getInitialState(),
};

const portfoliosSlice = createSlice({
  name: "portfolios",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryProviderPortfolios.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(queryProviderPortfolios.fulfilled, (state, { payload }) => {
      providerPortfolioAdapter.addMany(state.providerPortfolios, payload);
      state.status = "idle";
    });
    builder.addCase(queryProviderPortfolios.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateProviderPortfolioCuratedAt.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      updateProviderPortfolioCuratedAt.fulfilled,
      (state, { payload }) => {
        providerPortfolioAdapter.updateOne(state.providerPortfolios, {
          id: payload?.provider_portfolio_id || "",
          changes: payload,
        });
        state.status = "idle";
      }
    );
    builder.addCase(
      updateProviderPortfolioCuratedAt.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = "idle";
      }
    );
  },
});

export const {
  selectAll: selectProviderPortfolios,
  selectById: selectProviderPortfolioById,
} = providerPortfolioAdapter.getSelectors(
  (state: RootState) => state.portfolios.providerPortfolios
);

export const selectPortfoliosLoading = (state: RootState) =>
  state.providers.status === "pending";

export const portfoliosReducer = portfoliosSlice.reducer;
