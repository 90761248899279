import Divider from "antd/es/divider";
import { FormGroupType, FormLayoutProps } from "..";
import theme from "../../../../../theme";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

const FormGroupLayoutContent = ({ type }: { type: FormGroupType }) => {
  switch (type) {
    case "group":
      return (
        <Divider
          dashed
          style={{ borderColor: theme.colors.grey_2, minWidth: "auto" }}
        >
          GROUP
        </Divider>
      );
    case "page":
      return (
        <Divider
          dashed
          style={{ borderColor: theme.colors.grey_2, minWidth: "auto" }}
        >
          New Page
        </Divider>
      );

    default:
      return <></>;
  }
};

const FormGroupLayout = ({ type, isSimple }: FormLayoutProps) => {
  return isSimple ? (
    <FormGroupLayoutContent type={type as FormGroupType} />
  ) : (
    <Div flex="column" gap={8} overflow="hidden">
      <Typography
        textTransform="capitalize"
        elementTheme="body3"
        style={{ fontWeight: 600 }}
      >
        {type}
      </Typography>

      <FormGroupLayoutContent type={type as FormGroupType} />
    </Div>
  );
};

export default FormGroupLayout;

