import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  AdminTags,
  ApiError,
  ApiErrorInitialState,
  Industry,
  Tag,
} from "@hellodarwin/core/lib/features/entities";
import { showErrorNotification } from "../../utils";

import { RootState } from "../../../app/app-store";
import { createTagAdapter } from "../adapters/tag-adapter";
import AdminApiClient from "../admin-api-client";
import {
  deselectProjectServiceTag,
  fetchProjectTags,
  selectProjectServiceTag,
  toggleProjectCategoryTag,
} from "./projects-slice";
import {
  deselectProviderServiceTag,
  fetchProviderTags,
  handleServiceBudgetChange,
  selectProviderServiceTag,
  toggleProviderCategoryTag,
} from "./providers-slice";

export const fetchTags = createAsyncThunk<
  Tag[],
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  "admin/fetchTags",
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchTags();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchIndustries = createAsyncThunk<
  Industry[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchIndustries",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchIndustries(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleSpecialtyTag = createAction<string>(
  "admin/toggleSpecialtyTag"
);

export const toggleIndustryTag = createAction<string>(
  "admin/toggleIndustryTag"
);

const tagAdapter = createTagAdapter();

export interface TagsState {
  status: "idle" | "pending";
  entityStatus: "idle" | "pending";
  error: ApiError;
  tags: AdminTags;
  industries: Industry[];
}

const initialState: TagsState = {
  status: "idle",
  entityStatus: "idle",
  error: ApiErrorInitialState,
  tags: tagAdapter.getInitialState(),
  industries: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      state.tags = tagAdapter.setAll(payload);
      state.status = "idle";
    });
    builder.addCase(fetchTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchIndustries.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchIndustries.fulfilled, (state, { payload }) => {
      state.industries = payload;
      state.status = "idle";
    });
    builder.addCase(fetchIndustries.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchProjectTags.pending, (state) => {
      state.entityStatus = "pending";
    });
    builder.addCase(fetchProjectTags.fulfilled, (state, { payload }) => {
      state.tags = tagAdapter.updateAll(state.tags, payload);
      state.entityStatus = "idle";
    });
    builder.addCase(fetchProjectTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.entityStatus = "idle";
    });
    builder.addCase(fetchProviderTags.pending, (state) => {
      state.entityStatus = "pending";
    });
    builder.addCase(fetchProviderTags.fulfilled, (state, { payload }) => {
      if (payload != null) {
        state.tags = tagAdapter.updateAll(state.tags, payload);
      }
      state.entityStatus = "idle";
    });
    builder.addCase(fetchProviderTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.entityStatus = "idle";
    });
    builder.addCase(toggleProjectCategoryTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleProjectCategoryTag(state.tags, payload);
    });
    builder.addCase(selectProjectServiceTag, (state, { payload }) => {
      state.tags = tagAdapter.selectProjectServiceTag(state.tags, payload);
    });
    builder.addCase(deselectProjectServiceTag, (state, { payload }) => {
      state.tags = tagAdapter.deselectProjectServiceTag(state.tags, payload);
    });
    builder.addCase(toggleProviderCategoryTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleProviderCategoryTag(state.tags, payload);
    });
    builder.addCase(selectProviderServiceTag, (state, { payload }) => {
      state.tags = tagAdapter.selectProviderServiceTag(state.tags, payload);
    });

    builder.addCase(deselectProviderServiceTag, (state, { payload }) => {
      state.tags = tagAdapter.deselectProjectServiceTag(state.tags, payload);
      state.status = "pending";
    });
    builder.addCase(toggleSpecialtyTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleSpecialtyTag(state.tags, payload);
    });

    builder.addCase(toggleIndustryTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleIndustryTag(state.tags, payload);
    });
    builder.addCase(handleServiceBudgetChange, (state, { payload }) => {
      state.tags = tagAdapter.handleServiceBudgetChange(
        state.tags,
        payload.service,
        payload.minBudget,
        payload.maxBudget
      );
    });
  },
});

export const selectAllTags = createSelector(
  (state: RootState) => state.tags.tags,
  (tags) => tagAdapter.selectTags(tags)
);

export const selectIndustriesSectors = createSelector(
  [(state: RootState) => state.tags.industries],
  (industries) =>
    industries
      .filter((item) => item.level === 1)
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      })
);

export const selectAllIndustriesSubsectors = createSelector(
  [(state: RootState) => state.tags.industries],
  (industries) =>
    industries
      .filter((item) => item.level === 2)
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      })
);

export const selectIndustriesSubsectors = createSelector(
  [
    (state: RootState, _) => state.tags.industries,
    (_, sectors: string[]) => sectors,
  ],
  (industries, sectors) =>
    industries
      .filter(
        (item: Industry) =>
          item.level === 2 && sectors.some((sector) => sector === item.parent)
      )
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      })
      .sort((a, b) => a.value.localeCompare(b.value))
);

export const selectTagsIsLoading = (state: RootState) =>
  state.tags.status === "pending";
export const selectEntityTagsIsLoading = (state: RootState) =>
  state.tags.entityStatus === "pending";

export const tagsReducer = tagsSlice.reducer;

