import { CSSProperties, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTheme } from "../../../../plugins/styled";
import PreviewRow from "./PreviewRow";
import PreviewTile from "./PreviewTile";

interface PreviewCardProps {
  isRow?: boolean;
}

export interface InfoRowProps {
  label: string;
  value: string;
  Icon: (props: {
    width?: string | number;
    height?: string | number;
    style?: CSSProperties;
  }) => ReactNode;
  hide?: boolean;
}

export interface PreviewProps {
  percentage?: number;
  title: string;
  tag: { color: string; text: string };
  ctaText: string;
  infos: InfoRowProps[];
  parentSlug: string;
  id: string;
  style?: CSSProperties;
}

const PreviewCard = ({
  isRow,
  style,
  ...data
}: PreviewCardProps & PreviewProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isXl = useMediaQuery({ maxWidth: theme.breakpoints.xl });
  const onPreviewClick = () => {
    navigate(`/${data.parentSlug}/${data.id}`);
  };

  return isRow && !isXl ? (
    <PreviewRow style={style} {...data} onPreviewClick={onPreviewClick} />
  ) : (
    <PreviewTile style={style} {...data} onPreviewClick={onPreviewClick} />
  );
};

export default PreviewCard;

