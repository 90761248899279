import Tooltip from "antd/es/tooltip";
import React, { CSSProperties } from "react";
import CountUp from "react-countup";
import { useTranslation } from "../../../plugins/i18n";
import PreviewCard, { PreviewProps } from "../layout/preview-card";
import Typography from "../typography";
import {
  StepsManagerColContainer,
  StepsManagerColContent,
  StepsManagerColFooter,
  StepsManagerColHeader,
} from "./styles";

export interface StepsManagerColProps {
  label: string;
  tooltip: string;
  assignedKey: string;
  colTotal: number;
  items: PreviewProps[];
  styles?: {
    col?: CSSProperties;
    item?: CSSProperties;
  };
}

const StepsManagerCol = ({
  label,
  tooltip,
  assignedKey,
  colTotal,
  items,
  styles,
}: StepsManagerColProps) => {
  const { t } = useTranslation();
  return (
    <StepsManagerColContainer id={assignedKey}>
      <StepsManagerColHeader>
        <Tooltip placement="top" title={tooltip}>
          <div>
            <Typography elementTheme="body2" medium ellipsis>
              {label}
            </Typography>
          </div>
        </Tooltip>
      </StepsManagerColHeader>
      <StepsManagerColContent>
        {React.Children.toArray(
          items.map((itm) => (
            <PreviewCard style={{ ...styles?.item, flexShrink: 0 }} {...itm} />
          ))
        )}
      </StepsManagerColContent>
      <StepsManagerColFooter>
        <Typography textAlign="center">
          {"Total "}
          <b>
            <CountUp
              end={colTotal as number}
              separator={t(`programs|totals.seperator`)}
              prefix={t(`programs|totals.prefix`)}
              suffix={t(`programs|totals.suffix`)}
              duration={1}
            />
          </b>
        </Typography>
      </StepsManagerColFooter>
    </StepsManagerColContainer>
  );
};

export default StepsManagerCol;

