import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Chat = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60001 17.6148C1.60001 19.9779 3.25859 22.0367 5.59804 22.3808C7.20345 22.6171 8.82511 22.7988 10.4615 22.9258V29.7846L16.4815 23.7647C16.8897 23.3591 17.4377 23.125 18.0131 23.1104C20.8213 23.0327 23.6225 22.7891 26.402 22.3808C28.7414 22.0367 30.4 19.9793 30.4 17.6133V8.72519C30.4 6.35915 28.7414 4.3018 26.402 3.95768C22.9577 3.45215 19.4812 3.19892 16 3.20002C12.4672 3.20002 8.99348 3.45848 5.59804 3.95768C3.25859 4.3018 1.60001 6.36063 1.60001 8.72519V17.6133V17.6148Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chat;

