import { LogoProps } from "@hellodarwin/icons/dist/logo-entities";
import { ReactNode } from "react";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

const InfoTile = ({
  value,
  Icon,
}: {
  value?: string;
  Icon: (props: LogoProps) => ReactNode;
}) => {
  if (!value?.length) return <></>;
  return (
    <Div flex="row" gap={24} align="center">
      <Icon width={18} height={18} style={{ flexGrow: 0, flexShrink: 0 }} />
      <Typography>{value}</Typography>
    </Div>
  );
};

export default InfoTile;
