import SelectProvince from "@hellodarwin/core/lib/components/forms/form-groups/province-select";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminApi } from "../../../features/api/use-admin-api";

type CreateProviderFormProps = {
  visible: boolean;
  handleClose: () => void;
};

const CreateProviderForm = ({
  visible,
  handleClose,
}: CreateProviderFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const api = useAdminApi();

  const onFinish = (values: any) => {
    (async () => {
      setIsSaving(true);
      try {
        const provider = await api.createBasicProvider(values);
        setIsSaving(false);
        handleClose();
        navigate("/providers/" + provider.provider_id);
        message.success("Saved!");
      } catch (e: any) {
        setIsSaving(false);
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  return (
    <Modal
      title="Create Provider"
      afterClose={form.resetFields}
      open={visible}
      okText="Create"
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-provider"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "input name is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "input email is required",
                },
                {
                  type: "email",
                  message: "must be a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SelectProvince isRequired />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProviderForm;

