import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import FundingExplorerListPageLayout from "@hellodarwin/core/lib/components/grants/funding-explorer";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/types";
import FundingExplorerCompanyInfo from "@hellodarwin/core/lib/components/grants/funding-explorer/funding-explorer-company-info";
import useApplyFundingExplorerFilter from "@hellodarwin/core/lib/components/grants/funding-explorer/hooks/apply-filters";
import {
  Company,
  GrantProject,
  NewTag,
  TagType,
} from "@hellodarwin/core/lib/features/entities";
import AdminQueryFundingExplorerProps from "@hellodarwin/core/lib/features/entities/admin-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { ThemeProvider } from "@hellodarwin/core/lib/plugins/styled";
import theme from "@hellodarwin/core/lib/theme";
import getAntdTheme from "@hellodarwin/core/lib/theme/antd-theme";
import Collapse from "antd/es/collapse/Collapse";
import ConfigProvider from "antd/es/config-provider";
import Form from "antd/es/form";
import Select from "antd/es/select";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchGrantProjects,
  selectBestGrantProjects,
  selectGrantProjectsLoading,
} from "../../features/api/slices/grant-projects-slice";
import {
  fetchChildTags,
  selectActivityOptions,
  selectActivityTypeOptions,
  selectEligibleExpenseOptions,
  selectGrantTagsLoading,
  selectSectorOptions,
} from "../../features/api/slices/grant-tags-slice";
import {
  fetchGrantFinancingType,
  fetchGrantService,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectGrantFinancingType,
  selectGrantService,
  selectGrantsIsLoading,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
  selectTagsIsLoading,
} from "../../features/api/slices/tags-slice";
import { useAdminApi, useNewAdminApi } from "../../features/api/use-admin-api";

const FundingExplorer = () => {
  const dispatch = useAppDispatch();
  const newApi = useNewAdminApi();
  const api = useAdminApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );
  const isGrantsLoading = useAppSelector(selectGrantsIsLoading);
  const isGrantsProjectsLoading = useAppSelector(selectGrantProjectsLoading);
  const isGrantsTagsLoading = useAppSelector(selectGrantTagsLoading);
  const isTagsLoading = useAppSelector(selectTagsIsLoading);

  const isLoading = isGrantsLoading || isTagsLoading || isGrantsTagsLoading;

  const provinces = useAppSelector(selectProvinces);
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [company, setCompany] = useState<Company>();
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, selectedIndustries || [])
  );
  const services = useAppSelector(selectGrantService);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const sectorOptions = useAppSelector(selectSectorOptions);
  const activityTypeOptions = useAppSelector(selectActivityTypeOptions);
  const activityOptions = useAppSelector(selectActivityOptions);
  const eligibleExpenseOptions = useAppSelector(selectEligibleExpenseOptions);
  const [selectedSectors, setSelectedSectors] = useState<NewTag[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<NewTag[]>(
    []
  );
  const [selectedActivities, setSelectedActivities] = useState<NewTag[]>([]);
  const [selectedEligibleExpenses, setSelectedEligibleExpenses] = useState<
    NewTag[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<GrantProject[]>([]);

  const { activePage, pageSize, addParam, getParam, removeParam } =
    usePagination();

  const grantIds = useMemo(
    () => grants?.map((grant) => grant.grant_id),
    [grants]
  );

  useEffect(() => {
    if (grantIds?.length === 0 || !selectedLocale) return;
    dispatch(
      fetchGrantProjects({ api: newApi, locale: selectedLocale, grantIds })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantIds, selectedLocale]);

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));

    dispatch(
      fetchChildTags({
        api,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Sector,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  useEffect(() => {
    if (selectedSectors?.length > 0) {
      const parentIds = selectedSectors.map((sector) => sector.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.ActivityType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectors, selectedLocale]);

  useEffect(() => {
    if (selectedActivityTypes?.length > 0) {
      const parentIds = selectedActivityTypes.map((type) => type.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.Activity,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityTypes, selectedLocale]);

  useEffect(() => {
    if (selectedActivities?.length > 0) {
      const parentIds = selectedActivities.map((activity) => activity.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.EligibleExpense,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, selectedLocale]);

  const fetch = async () => {
    try {
      const filterValues = filterform.getFieldsValue();
      const selectedProjectsIds = selectedProjects.map(
        (project) => project.grant_project_id
      );

      const queryProps: AdminQueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        selectedProjects: selectedProjectsIds,
        tagSectors: selectedSectors.map((tag) => tag?.tag_id),
        tagActivityTypes: selectedActivityTypes.map((tag) => tag?.tag_id),
        tagActivities: selectedActivities.map((tag) => tag?.tag_id),
        tagEligibleExpenses: selectedEligibleExpenses.map((tag) => tag?.tag_id),
        ...filterValues,
      };

      dispatch(queryFundingExplorer({ api, ...queryProps }));
      queryProps.textQuery
        ? addParam("search", queryProps.textQuery)
        : removeParam("search");
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    options: { [key: string]: NewTag }
  ) => {
    const newTags = values.map((value) => options[value]);
    setSelected(newTags);
  };

  useEffect(() => {
    if (company) {
      applyFilter({ type: "companyInit" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const removeCompany = () => {
    setCompany(undefined);
    applyFilter({ type: "reset" });
  };

  const applyFilter = useApplyFundingExplorerFilter({
    fetch,
    form: filterform,
    selectedIndustries,
    setSelectedIndustries,
    setSelectedProjects,
    company,
  });

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSectors,
    selectedActivityTypes,
    selectedActivities,
    selectedEligibleExpenses,
  ]);

  const TagsCollapse = () => {
    return (
      <Collapse ghost style={{ width: "100%" }}>
        <Collapse.Panel header={"Tag Filters"} key="1">
          <Div flex="row" gap={8}>
            {[
              TagType.Sector,
              TagType.ActivityType,
              TagType.Activity,
              TagType.EligibleExpense,
            ].map((type, idx) => (
              <Div key={idx}>
                <Typography elementTheme="body3">
                  {type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
                <Select
                  mode="multiple"
                  placeholder={`Select ${type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}(s)`}
                  style={{ width: "100%" }}
                  value={
                    type === TagType.Sector
                      ? selectedSectors.map((tag) => tag.tag_id)
                      : type === TagType.ActivityType
                        ? selectedActivityTypes.map((tag) => tag.tag_id)
                        : type === TagType.Activity
                          ? selectedActivities.map((tag) => tag.tag_id)
                          : selectedEligibleExpenses.map((tag) => tag.tag_id)
                  }
                  onChange={(values) =>
                    handleTagChange(
                      values,
                      type === TagType.Sector
                        ? setSelectedSectors
                        : type === TagType.ActivityType
                          ? setSelectedActivityTypes
                          : type === TagType.Activity
                            ? setSelectedActivities
                            : setSelectedEligibleExpenses,
                      type === TagType.Sector
                        ? sectorOptions.entities
                        : type === TagType.ActivityType
                          ? activityTypeOptions.entities
                          : type === TagType.Activity
                            ? activityOptions.entities
                            : eligibleExpenseOptions.entities
                    )
                  }
                  options={Object.values(
                    type === TagType.Sector
                      ? sectorOptions.entities
                      : type === TagType.ActivityType
                        ? activityTypeOptions.entities
                        : type === TagType.Activity
                          ? activityOptions.entities
                          : eligibleExpenseOptions.entities
                  )?.map((option) => ({
                    label: option.content,
                    value: option.tag_id,
                  }))}
                />
              </Div>
            ))}
          </Div>
        </Collapse.Panel>
      </Collapse>
    );
  };

  const antdTheme = getAntdTheme(theme);

  return (
    <PageLayout
      app="admin"
      title={"Funding Explorer"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Funding Explorer",
        },
      ]}
      actions={
        <Div flex="row" fitContent>
          <Typography style={{ paddingRight: "8px" }} nowrap>
            {t(`grants|results`)}
          </Typography>
          <Typography bold nowrap>
            {t(`grants|nbGrants`, {
              nb: hasGrants ? grants?.[0]?.total_count : 0,
            })}
          </Typography>
        </Div>
      }
    >
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <FundingExplorerListPageLayout
            app="admin"
            initialQuerySearch={getParam("search")}
            afterFilters={
              !!company && (
                <FundingExplorerCompanyInfo
                  company={company}
                  removeCompany={removeCompany}
                  provinces={provinces}
                  companySize={companySize}
                  annualRevenue={annualRevenue}
                  industries={industries}
                  subIndustries={subindustries}
                />
              )
            }
            company={company}
            filterform={filterform}
            financingType={financingType}
            industries={industries}
            subIndustries={subindustries}
            provinces={provinces}
            services={services}
            companySize={companySize}
            annualRevenue={annualRevenue}
            applyFilter={applyFilter}
            selectedIndustries={selectedIndustries}
            setSelectedIndustries={setSelectedIndustries}
            TagsCollapse={TagsCollapse}
            bestProjects={bestProjects}
            grantsProjectsLoading={isGrantsProjectsLoading}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            goToSettings={() => {}}
            grants={grants}
            isLoading={isLoading}
          />
        </ConfigProvider>
      </ThemeProvider>
    </PageLayout>
  );
};

export default FundingExplorer;

