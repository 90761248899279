import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";

type AdminTagProps = {
  text?: string;
  tooltip?: string;
  color?: string;
  onClick?: () => void;
};

const AdminTag = ({ text, tooltip, color, onClick }: AdminTagProps) => {
  return (
    <Tooltip color={color} title={tooltip}>
      <Tag
        color={color}
        style={{
          padding: "0.2rem 1rem",
          fontSize: "14px",
        }}
        onClick={onClick}
      >
        {text}
      </Tag>
    </Tooltip>
  );
};

export default AdminTag;

