import Checkmark from "@hellodarwin/icons/dist/icons/Checkmark";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

type ReviewConfirmProps = {
  handleClose: () => void;
  isActive?: boolean;
};

const ReviewConfirm = ({ handleClose, isActive }: ReviewConfirmProps) => {
  const theme = useTheme();

  if (!isActive) return <></>;
  const { t } = useTranslations();
  return (
    <Div
      flex="column"
      align="center"
      gap={24}
      className="fade-in"
      style={{ padding: "24px 156px" }}
    >
      <Checkmark
        style={{ color: theme.colors.green_1, flexShrink: 0 }}
        width={64}
        height={64}
      />

      <Div flex="column" gap={16} align="center">
        <Typography.Title level={1} elementTheme="h5" textAlign="center">
          {t("reviews|newReviewThankYouScreenHeader")}
        </Typography.Title>
        <Typography textAlign="center">
          {t("reviews|newReviewThankYouScreenDescription")}
        </Typography>
      </Div>
      <Button
        defaultStyle={theme.colors.purple_1}
        size={"medium"}
        onClick={handleClose}
      >
        {t("reviews|newReviewThankYouScreenClose")}
      </Button>
    </Div>
  );
};

export default ReviewConfirm;

