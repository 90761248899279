import Button from "antd/es/button";
import Popover from "antd/es/popover";
import { ReactNode, useState } from "react";
type QuickActionProps = {
  content: ReactNode;
  title: string;
  icon: ReactNode;
};

const QuickAction = ({ content, title, icon }: QuickActionProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  return (
    <Popover
      onOpenChange={handleVisibleChange}
      placement="topRight"
      content={content}
      trigger="click"
      overlayStyle={{ width: 500 }}
    >
      <Button icon={icon} style={{ border: 0 }} onClick={() => null}>
        {title}
      </Button>
    </Popover>
  );
};

export default QuickAction;

