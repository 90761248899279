import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Key = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.641 5.83955C22.8379 5.83955 23.9858 6.31514 24.8321 7.16168C25.6784 8.00822 26.1539 9.15638 26.1539 10.3536M30.6667 10.3536C30.6669 11.6703 30.3791 12.9712 29.8236 14.165C29.268 15.3587 28.4581 16.4165 27.4507 17.264C26.4432 18.1116 25.2626 18.7285 23.9916 19.0714C22.7206 19.4143 21.39 19.475 20.0931 19.2492C19.2462 19.1032 18.3497 19.2883 17.742 19.8962L13.7436 23.8956H10.359V27.2811H6.97437V30.6666H1.33334V26.4265C1.33334 25.5282 1.68986 24.666 2.32466 24.0325L12.1009 14.2537C12.7087 13.6458 12.8937 12.749 12.7478 11.9019C12.5345 10.6698 12.5793 9.40683 12.8796 8.19302C13.1798 6.9792 13.7289 5.84101 14.4919 4.85057C15.255 3.86013 16.2154 3.03905 17.3124 2.43935C18.4093 1.83965 19.6188 1.47443 20.8643 1.36681C22.1097 1.2592 23.3639 1.41154 24.5474 1.81419C25.7309 2.21685 26.8179 2.86102 27.7395 3.7059C28.661 4.55078 29.3971 5.57793 29.901 6.72226C30.4049 7.8666 30.6656 9.10316 30.6667 10.3536Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Key;

