import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import {
  ChatType,
  HdChatPromptRequest,
} from "@hellodarwin/core/lib/features/entities";
import Drawer from "antd/es/drawer";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  createNewPrompt,
  selectPromptsLoading,
  updatePrompt,
} from "../../features/api/slices/hd-chat-prompts-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";

const { TextArea } = Input;

interface PromptDrawerProps {
  prompt: HdChatPromptRequest;
  visible: boolean;
  onClose: () => void;
}
export type PromptFormValues = {
  type: ChatType;
  name: string;
  content_fr: string;
  content_en: string;
};

const CreateUpdatePromptDrawer = ({
  prompt,
  visible,
  onClose,
}: PromptDrawerProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [form] = Form.useForm<PromptFormValues>();
  const [selectedType, setSelectedType] = useState<ChatType>(
    ChatType.GrantApplication
  );
  const loading = useAppSelector(selectPromptsLoading);
  const initialValues: PromptFormValues = useMemo(() => {
    return {
      type: prompt.type || ChatType.GrantApplication,
      name: prompt.name || "",
      content_fr: prompt.content_fr || "",
      content_en: prompt.content_en || "",
    };
  }, [prompt]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setSelectedType(initialValues.type);
  }, [form, initialValues]);

  const handleTypeChange = (type: ChatType) => {
    setSelectedType(type);
    form.setFieldsValue({ type });
  };

  const handleNewPromptSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          const promptActionResponse = await dispatch(
            createNewPrompt({
              api: api,
              prompt: form.getFieldsValue(),
            })
          ).unwrap();
          if (promptActionResponse) {
            onClose();
            form.resetFields();
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePromptUpdate = async () => {
    if (!prompt.id) {
      return;
    }
    form
      .validateFields()
      .then(async () => {
        try {
          const promptActionResponse = await dispatch(
            updatePrompt({
              api: api,
              prompt: {
                ...form.getFieldsValue(),
                created_at: prompt.created_at,
                updated_at: prompt.updated_at,
                type: selectedType,
                id: prompt.id,
              },
            })
          ).unwrap();
          if (promptActionResponse) {
            onClose();
            form.resetFields();
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Drawer
      title={prompt.id ? "Update Prompt" : "Add New Prompt"}
      size="large"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      extra={
        prompt.id ? (
          <Button
            size="small"
            onClick={handlePromptUpdate}
            loading={loading}
            defaultStyle={theme.colors.purple_1}
          >
            Update
          </Button>
        ) : (
          <Button
            size="small"
            onClick={handleNewPromptSubmit}
            loading={loading}
            defaultStyle={theme.colors.purple_1}
          >
            Submit
          </Button>
        )
      }
    >
      <Div flex="column" gap={16}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="prompts"
        >
          <Form.Item
            label={"Type"}
            name={"type"}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please add type"));
                },
              }),
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              onChange={handleTypeChange}
              placeholder="Select type"
              style={{ width: "100%" }}
            >
              {Object.entries(ChatType).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Prompt Name"}
            name={"name"}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please add prompt name"));
                },
              }),
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input
              onChange={(e) => form.setFieldValue("name", e.target.value)}
              placeholder="Enter prompt name"
            />
          </Form.Item>
          <Form.Item
            label={"Content FR"}
            name={"content_fr"}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Please add french content"));
                },
              }),
              {
                required: true,
                message: "",
              },
            ]}
          >
            <TextArea
              onChange={(e) => form.setFieldValue("content_fr", e.target.value)}
              rows={4}
              placeholder="Enter new prompt here..."
              style={{ padding: 16, width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label={"Content EN"}
            name={"content_en"}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please add english content")
                  );
                },
              }),
              {
                required: true,
                message: "",
              },
            ]}
          >
            <TextArea
              onChange={(e) => form.setFieldValue("content_en", e.target.value)}
              rows={4}
              placeholder="Enter new prompt here..."
              style={{ padding: 16, width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Div>
    </Drawer>
  );
};

export default CreateUpdatePromptDrawer;

