import { ItemsTypes } from "../../../../features/entities/form-entities";
import {
  FORM_GROUPS_TYPES,
  FORM_ITEMS_TYPES,
  FORM_MAIN_TYPES,
} from "./form-items-type";

const getTypeItems = (activeType: ItemsTypes) => {
  switch (activeType) {
    case ItemsTypes.main:
      return FORM_MAIN_TYPES;
    case ItemsTypes.items:
      return FORM_ITEMS_TYPES;
    case ItemsTypes.groups:
      return FORM_GROUPS_TYPES;
    default:
      return [];
  }
};

export default getTypeItems;

