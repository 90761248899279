import React, { ReactNode } from "react";

import "./action-bar.scss";
export interface ActionBarProps {
  children: React.ReactNode;
  quickActions: ReactNode[];
}

const ActionBar = ({ children, quickActions }: ActionBarProps) => {
  return (
    <div className="action-bar">
      <div className="action-bar-title">
        <div className="action-bar-quick-action">
          {quickActions.map((a, index) => {
            return <div key={index}>{a}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default ActionBar;

