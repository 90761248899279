import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Stats = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.96581 3.55554V20.2222C3.96581 22.0592 5.43908 23.5555 7.24786 23.5555H10.5299M3.96581 3.55554H1.77777M3.96581 3.55554H28.0342M10.5299 23.5555H21.4701M10.5299 23.5555L9.07122 28M28.0342 3.55554H30.2222M28.0342 3.55554V20.2222C28.0342 22.0592 26.5609 23.5555 24.7521 23.5555H21.4701M21.4701 23.5555L22.9288 28M9.07122 28H22.9288M9.07122 28L8.34187 30.2222M22.9288 28L23.6581 30.2222M11.6239 15.7778V18M16 12.4444V18M20.3761 9.1111V18"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Stats;

