import { ItemsTypes } from "../../../../features/entities/form-entities";
import FormGroupLayout from "../layout/groups";
import FormItemLayout from "../layout/items";

const getTypeComponent = (activeType: ItemsTypes) => {
  switch (activeType) {
    case ItemsTypes.main:
    case ItemsTypes.groups:
      return FormGroupLayout;
    case ItemsTypes.items:
      return FormItemLayout;

    default:
      return FormItemLayout;
  }
};

export default getTypeComponent;

