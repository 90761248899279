import { useMemo } from "react";
import { EditableFormSubmissionResponse } from "../../../../../features/entities";
import { FormData, InitialFormData } from "../../types";

const getFormSubmissionData = ({
  formSubmission,
}: {
  formSubmission: EditableFormSubmissionResponse;
}): FormData => {
  const { all_items } = formSubmission;

  return useMemo(() => {
    if (!all_items.length) return InitialFormData;

    return all_items.reduce(
      (o, itm) => ({
        ...o,
        [itm.form_item.form_item_id]: itm.forms_submissions_item.value,
      }),
      InitialFormData
    );
  }, [all_items]);
};

export default getFormSubmissionData;

