import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { itemDescriptionOptions } from "@hellodarwin/core/lib/components/forms/form-builder/utils/form-parse-options";
import SummaryBox from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/SummaryBox";
import { Step } from "@hellodarwin/core/lib/features/entities";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import theme from "@hellodarwin/core/lib/theme";
import { CustomUl } from "@hellodarwin/core/lib/theme/list-style";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import TeamSize from "@hellodarwin/icons/dist/icons/TeamSize";
import XOutlined from "@hellodarwin/icons/dist/icons/XOutlined";
import Divider from "antd/es/divider";
import { SelectProps } from "antd/es/select";
import React from "react";
import {
  GinAssignmentStepOption,
  GinMediumStepOption,
  GinTypeStepOption,
} from "../utils/gin-select-option";
import { GinStepCardSummary } from "./styles";

const GinStepCardContent = ({ step }: { step: Step }) => {
  const getLabelByValue = (value: string, options: SelectProps["options"]) => {
    const option = options?.find((opt) => opt.value === value);
    return option ? option.label : "";
  };

  return (
    <>
      <GinStepCardSummary>
        <Div
          gap={24}
          style={{
            display: "flex",
            alignItems: "flex-start",
            alignSelf: "stretch",
            padding: "0px 24px",
            borderBottom: "1px black",
          }}
        >
          <SummaryBox label="STEP ASSIGNMENT" Icon={TeamSize}>
            <Typography
              color={theme.colors.black}
              elementTheme="body3"
              style={{ paddingLeft: "22px" }}
            >
              {getLabelByValue(step.assignment, GinAssignmentStepOption)}
            </Typography>
          </SummaryBox>
          <SummaryBox label="MEDIUM" Icon={Budget}>
            <Typography
              color={theme.colors.black}
              elementTheme="body3"
              style={{ paddingLeft: "22px" }}
            >
              {getLabelByValue(step.medium, GinMediumStepOption)}
            </Typography>
          </SummaryBox>
          <SummaryBox label="STEP TYPE" Icon={XOutlined}>
            <Typography
              color={theme.colors.black}
              elementTheme="body3"
              style={{ paddingLeft: "22px" }}
            >
              {getLabelByValue(step.type, GinTypeStepOption)}
            </Typography>
          </SummaryBox>
        </Div>
      </GinStepCardSummary>
      <Divider
        style={{
          borderColor: theme.colors.grey_4,
          margin: "0px",
        }}
      />
      <Div
        gap={12}
        style={{
          display: "flex",
          padding: "12px 24px",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Div flex="column" gap={4}>
          <Typography
            textTransform="uppercase"
            color={theme.colors.grey_2}
            elementTheme="overline"
          >
            {"description"}
          </Typography>
          <Typography color={theme.colors.black} elementTheme="body2">
            {parse(step.description || "", itemDescriptionOptions)}
          </Typography>
        </Div>
        <Div flex="column" gap={4}>
          <Typography color={theme.colors.grey_2} elementTheme="overline">
            {"File"}
          </Typography>
          {step.document_needed && (
            <CustomUl style={{ fontSize: "14px" }}>
              {React.Children.toArray(
                step.document_needed.map((elem) => <li>{elem.name}</li>)
              )}
            </CustomUl>
          )}
        </Div>
      </Div>
    </>
  );
};

export default GinStepCardContent;

