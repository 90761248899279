import Badge from "antd/es/badge";
import Tooltip from "antd/es/tooltip";

import { ReactNode } from "react";
import theme from "../../theme";

import "./block-button.scss";

type ActionButtonProps = {
  icon: ReactNode;
  onClick: () => void;
  tooltip: string;
  active?: boolean;
  label?: string;
  notification?: {
    count?: number;
    problem?: boolean;
  };
};

const BlockButton = ({
  icon,
  onClick,
  tooltip,
  active,
  notification,
  label,
}: ActionButtonProps) => {
  const className = active ? "quick-action active" : "quick-action";
  return (
    <Tooltip placement="bottom" title={tooltip}>
      <Badge
        count={notification?.count}
        color={theme.colors.purple_1}
        status={notification?.problem ? "error" : "default"}
      >
        <div onClick={onClick} className={className}>
          {icon}
          {label && <div>{label}</div>}
        </div>
      </Badge>
    </Tooltip>
  );
};

export default BlockButton;

