import {
  ApiError,
  ApiErrorInitialState,
  Step,
} from "@hellodarwin/core/lib/features/entities";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

//Possiblement a changer
const stepsAdapter = createEntityAdapter({
  selectId: (model: Step) => model.step_id,
});

export interface GinStepsState {
  status: "idle" | "pending";
  error: ApiError;
  ginSteps: EntityState<Step, string>;
}

const initialState: GinStepsState = {
  status: "pending",
  error: ApiErrorInitialState,
  ginSteps: stepsAdapter.getInitialState(),
};

export const updateSingleSelectedStep = createAsyncThunk<
  { old_id: string; updatedStep: Step },
  { api: AdminApiClient; step: Step },
  { rejectValue: ApiError }
>(
  "admin/updateSingleSelectedStep",
  async (
    { api, step }: { api: AdminApiClient; step: Step },
    { rejectWithValue }
  ) => {
    try {
      const updatedStep = await api.updateSingleSelectedStep(step);
      return { old_id: step.step_id, updatedStep };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNewSteps = createAsyncThunk<
  Step,
  { api: AdminApiClient; grantId: string; step: Step },
  { rejectValue: ApiError }
>(
  "admin/createNewStep",
  async (
    {
      api,
      grantId,
      step,
    }: { api: AdminApiClient; grantId: string; step: Step },
    { rejectWithValue }
  ) => {
    try {
      return await api.createNewStep(grantId, step);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSingleSelectedStep = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grant_id: string; step_id: string },
  { rejectValue: ApiError }
>(
  "admin/deleteSelectStep",
  async (
    {
      api,
      grant_id,
      step_id,
    }: { api: AdminApiClient; grant_id: string; step_id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteSingleSelectedStep(grant_id, step_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinsSteps = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grant_id: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinsSteps",
  async (
    { api, grant_id }: { api: AdminApiClient; grant_id: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGinsSteps(grant_id);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStepsOrder = createAsyncThunk<
  Step[],
  { api: AdminApiClient; grantId: string; steps: Step[] },
  { rejectValue: ApiError }
>(
  "admin/updateStepsOrder",
  async (
    {
      api,
      grantId,
      steps,
    }: { api: AdminApiClient; grantId: string; steps: Step[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateStepsOrder(grantId, steps);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const ginsStepsSlice = createSlice({
  name: "ginsStep",
  initialState,
  reducers: {
    clearSteps: (state) => {
      stepsAdapter.removeAll(state.ginSteps);
      state.status = "pending";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewSteps.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(createNewSteps.fulfilled, (state, { payload }) => {
      stepsAdapter.upsertOne(state.ginSteps, payload);
      state.status = "idle";
    });
    builder.addCase(createNewSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGinsSteps.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGinsSteps.fulfilled, (state, { payload }) => {
      stepsAdapter.upsertMany(state.ginSteps, payload ? payload : []);
      state.status = "idle";
    });
    builder.addCase(fetchGinsSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateSingleSelectedStep.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      updateSingleSelectedStep.fulfilled,
      (state, { payload }) => {
        const ids = state.ginSteps.ids as string[];
        const index = ids.indexOf(payload.old_id);

        if (index !== -1) {
          state.ginSteps.entities[payload.updatedStep.step_id] =
            payload.updatedStep;

          if (payload.old_id !== payload.updatedStep.step_id) {
            state.ginSteps.ids[index] = payload.updatedStep.step_id;
            delete state.ginSteps.entities[payload.old_id];
          }
        }
        state.status = "idle";
      }
    );
    builder.addCase(updateSingleSelectedStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteSingleSelectedStep.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      deleteSingleSelectedStep.fulfilled,
      (state, { payload }) => {
        stepsAdapter.setAll(state.ginSteps, payload ? payload : []);
        state.status = "idle";
      }
    );
    builder.addCase(deleteSingleSelectedStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateStepsOrder.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateStepsOrder.fulfilled, (state, { payload }) => {
      stepsAdapter.setAll(state.ginSteps, payload ? payload : []);
      state.status = "idle";
    });
    builder.addCase(updateStepsOrder.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});
export const selectStepsIsLoading = (state: RootState) =>
  state.ginSteps.status === "pending";

export const { selectAll: selectAllSteps } = stepsAdapter.getSelectors(
  (state: RootState) => state.ginSteps.ginSteps
);

export const { clearSteps } = ginsStepsSlice.actions;

export const ginStepsReducer = ginsStepsSlice.reducer;

