import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  HdChat,
  HdChatSubQuestion,
} from "@hellodarwin/core/lib/features/entities";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Refresh from "@hellodarwin/icons/dist/icons/Refresh";
import RestartProject from "@hellodarwin/icons/dist/icons/RestartProject";
import Carousel, { CarouselRef } from "antd/es/carousel";
import Input from "antd/es/input";
import Popconfirm from "antd/es/popconfirm";
import Select from "antd/es/select";
import Skeleton from "antd/es/skeleton";
import Title from "antd/es/typography/Title";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useAppDispatch } from "../../app";
import {
  generateSubQuestionAnswer,
  removeSubQuestionFromChat,
  updateSubQuestion as updateSubQuestionSlice,
} from "../../features/api/slices/hd-chat-questions-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";

const { TextArea } = Input;

type SubQuestionContextProps = {
  questionId: string;
  chat: HdChat;
  questionIndex: number;
  subQuestionIndex: number;
  subQuestion: HdChatSubQuestion;
  setSelectedAnswers: Dispatch<SetStateAction<Map<any, any>>>;
};

const SubQuestionContext = ({
  chat,
  questionIndex,
  subQuestionIndex,
  questionId,
  subQuestion,
  setSelectedAnswers,
}: SubQuestionContextProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const [subAnswerIndex, setSubAnswerIndex] = useState<number>(
    subQuestion.answers?.length - 1 || 0
  );

  const [loadingStates, setLoadingStates] = useState<Set<string>>(new Set());
  const subAnswerCarouselRef = useRef<CarouselRef>(null);

  const navigateCarousels = (direction: "next" | "prev") => {
    const newIndex =
      direction === "next"
        ? subAnswerIndex === subQuestion?.answers?.length - 1
          ? 0
          : subAnswerIndex + 1
        : subAnswerIndex === 0
          ? subQuestion?.answers?.length - 1
          : subAnswerIndex - 1;
    setSubAnswerIndex(newIndex);
    subAnswerCarouselRef.current?.goTo(newIndex);
  };

  const handleCarouselChange = (currentSlide: number) => {
    if (subAnswerIndex !== currentSlide) {
      setSubAnswerIndex(currentSlide);
      subAnswerCarouselRef.current?.goTo(currentSlide);
    }
  };

  const getLoadingKey = (questionId: string, subQuestionId?: string) =>
    subQuestionId ? `${questionId}-${subQuestionId}` : questionId;

  const updateLoadingState = (key: string, isLoading: boolean) => {
    setLoadingStates((prev) => {
      const newLoadingStates = new Set(prev);
      if (isLoading) {
        newLoadingStates.add(key);
      } else {
        newLoadingStates.delete(key);
      }
      return newLoadingStates;
    });
  };

  const isLoading = (key: string) => loadingStates.has(key);

  const handleSubQuestionAnswerGeneration = async (
    subQuestion: HdChatSubQuestion
  ) => {
    const key = getLoadingKey(questionId, subQuestion.id);
    updateLoadingState(key, true);

    try {
      await dispatch(
        generateSubQuestionAnswer({
          api,
          chatId: chat.id,
          subQuestion: {
            ...subQuestion,
            content: subQuestion.content,
          },
          questionId: questionId,
        })
      ).unwrap();

      const newSubAnswerIndex = subQuestion.answers?.length || 0;
      handleCarouselChange(newSubAnswerIndex);
    } catch (error) {
      console.error("Error generating sub-question answer:", error);
    } finally {
      updateLoadingState(key, false);
    }
  };

  const removeSubQuestion = (
    questionId: string,
    subQuestion: HdChatSubQuestion
  ) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const questionFullIndex = `${questionIndex + 1}.${subQuestionIndex + 1}`;
      const newSelection = new Map(prevSelectedAnswers);
      if (newSelection.has(questionFullIndex)) {
        newSelection.delete(questionFullIndex);
      }
      return newSelection;
    });
    dispatch(
      removeSubQuestionFromChat({
        api,
        chatId: chat.id,
        questionId: questionId,
        subQuestion: subQuestion,
      })
    );
  };

  const updateSubQuestion = (
    subQuestion: HdChatSubQuestion,
    newContent: string
  ) => {
    const updatedSubQuestion = { ...subQuestion, content: newContent };
    dispatch(
      updateSubQuestionSlice({
        api,
        chatId: chat.id,
        questionId: questionId,
        subQuestion: updatedSubQuestion,
      })
    );
  };

  const SkeletonTextArea = (rows: number, minHeight: number) => {
    return (
      <Skeleton
        paragraph={{ rows: rows, width: "100%" }}
        style={{
          backgroundColor: theme.colors.white_1,
          padding: 10,
          border: "1px solid",
          borderColor: theme.colors.grey_4,
          borderRadius: 5,
          minHeight: minHeight,
        }}
        active
      ></Skeleton>
    );
  };

  const toggleSelectedSubAnswers = (
    questionFullIndex: string,
    subAnswerIndex: string
  ) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const newSelection = new Map(prevSelectedAnswers);
      if (newSelection.has(questionFullIndex)) {
        newSelection.delete(questionFullIndex);
      }
      if (subAnswerIndex !== "none") {
        newSelection.set(
          questionFullIndex,
          subQuestion.answers[parseInt(subAnswerIndex)].id
        );
      }
      return newSelection;
    });
  };

  return (
    <>
      <Div flex="column" gap={16}>
        <Div flex="row" align="center" justify="space-between" gap={24}>
          <Div flex="row" gap={16} style={{ paddingLeft: 8 }}>
            <Title
              level={5}
              editable={{
                onChange: (newText) => updateSubQuestion(subQuestion, newText),
                autoSize: { minRows: 1 },
              }}
              style={{ margin: 0 }}
            >
              {subQuestion.content}
            </Title>
          </Div>
          <Div flex="row" gap={8} fitContent style={{ paddingRight: 8 }}>
            <Button
              headingIcon={
                subQuestion.answers ? (
                  <Refresh width={24} height={24} />
                ) : (
                  <RestartProject width={24} height={24} />
                )
              }
              onClick={() => handleSubQuestionAnswerGeneration(subQuestion)}
              size="auto"
              style={{ height: "fit-content", padding: 8 }}
              defaultStyle={theme.colors.purple_1}
            ></Button>
            <Popconfirm
              title="Delete the sub-question"
              description="Are you sure to delete this sub-question?"
              onConfirm={() => removeSubQuestion(questionId, subQuestion)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                headingIcon={<Delete width={24} height={24} />}
                size="auto"
                style={{ height: "fit-content", padding: 8 }}
                defaultStyle={theme.colors.red_1}
              ></Button>
            </Popconfirm>
          </Div>
        </Div>
        {isLoading(getLoadingKey(questionId, subQuestion.id)) ? (
          SkeletonTextArea(3, 200)
        ) : (
          <Carousel ref={subAnswerCarouselRef} draggable={false} dots={false}>
            {subQuestion.answers?.map((subAnswer, subAnswerIndex) => (
              <Div
                flex="column"
                key={subAnswerIndex}
                gap={8}
                alignSelf="end"
                style={{ width: "calc(100% - 50px)" }}
              >
                <Div>
                  <TextArea
                    style={{ minHeight: 200 }}
                    value={
                      subQuestion.answers?.length > 0
                        ? subQuestion.answers[subAnswerIndex].answer
                        : ""
                    }
                    color={theme.colors.grey_3}
                    rows={4}
                    placeholder="Answer will appear here..."
                  />
                </Div>
              </Div>
            ))}
            {!subQuestion.answers && (
              <Div flex="column" style={{ width: "100%" }}>
                <TextArea
                  value={"Answer will appear here..."}
                  style={{ minHeight: 200 }}
                  color={theme.colors.grey_3}
                  rows={4}
                  placeholder="Answer will appear here..."
                />
              </Div>
            )}
          </Carousel>
        )}
        {!!subQuestion.answers && (
          <Div
            flex="row"
            justify="center"
            align="center"
            gap={16}
            style={{ paddingLeft: 8 }}
          >
            {subQuestion.answers?.length > 0 && (
              <Div flex="row" justify="left" align="left" gap={8}>
                <Typography>Sub answer selected for export: </Typography>
                <Select
                  filterOption={false}
                  defaultValue={"none"}
                  onChange={(value) => {
                    toggleSelectedSubAnswers(
                      `${questionIndex + 1}.${subQuestionIndex + 1}`,
                      value
                    );
                  }}
                  style={{ minWidth: 80 }}
                >
                  <Select.Option key={0} value={"none"}>
                    None
                  </Select.Option>
                  {subQuestion.answers?.map((subAnswer, index) => (
                    <Select.Option key={index + 1} value={index}>
                      {index + 1}
                    </Select.Option>
                  ))}
                </Select>
              </Div>
            )}
            {subQuestion.answers?.length > 1 && (
              <>
                <Button
                  size="auto"
                  style={{
                    width: 32,
                    height: 32,
                    padding: 10,
                  }}
                  onClick={() => navigateCarousels("prev")}
                  withArrowLeft
                ></Button>
                {subAnswerIndex + 1}/{subQuestion.answers?.length}
                <Button
                  size="auto"
                  style={{
                    width: 32,
                    height: 32,
                    padding: 10,
                  }}
                  onClick={() => navigateCarousels("next")}
                  withArrowRight
                ></Button>
              </>
            )}
          </Div>
        )}
      </Div>
    </>
  );
};

export default SubQuestionContext;

