import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchRelatedGrants,
  selectRelatedGrants,
  selectRelatedGrantsIsLoading,
} from "../../../features/api/slices/related-grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { RelatedLink } from "./cards/related-link";

interface RelatedGrantsListProps {
  grantId: string;
}

const RelatedGrantsList = ({ grantId }: RelatedGrantsListProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();

  const relatedGrants = useAppSelector(selectRelatedGrants);
  const isLoading = useAppSelector(selectRelatedGrantsIsLoading);

  useEffect(() => {
    dispatch(fetchRelatedGrants({ api, grantId, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantId]);

  return (
    <Div flex="column" gap={8}>
      {isLoading ? (
        <Div style={{ height: "fit-content" }}>
          <Loading />
        </Div>
      ) : (
        relatedGrants?.map((grant, index) => (
          <RelatedLink
            key={index}
            label={grant.display_title}
            to={`/gin/${grant.grant_id}`}
          />
        ))
      )}
    </Div>
  );
};

export default RelatedGrantsList;

