import { Tag as HDTag } from "@hellodarwin/core/lib/features/entities";
import { createContext, useContext } from "react";

interface ServiceTagsContextState {
  projectTags: HDTag[];
  setProjectTags: (tags: HDTag[]) => void;
  providerTags: HDTag[];
  setProviderTags: (tags: HDTag[]) => void;
  hasProjectServiceTag: boolean;
  hasProviderServiceTag: boolean;
}

export const ServiceTagsContext = createContext<ServiceTagsContextState>({
  projectTags: [],
  setProjectTags: () => null,
  providerTags: [],
  setProviderTags: () => null,
  hasProjectServiceTag: false,
  hasProviderServiceTag: false,
});

export const useServiceTags = (): ServiceTagsContextState =>
  useContext(ServiceTagsContext);
