import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { AdminSelectedTags } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Card from "antd/es/card";
import Select, { SelectProps } from "antd/es/select";
import Skeleton from "antd/es/skeleton";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  deselectProjectServiceTag,
  selectProjectServiceTag,
  toggleProjectCategoryTag,
} from "../../features/api/slices/projects-slice";
import {
  deselectProviderServiceTag,
  handleServiceBudgetChange,
  selectProviderServiceTag,
  toggleProviderCategoryTag,
} from "../../features/api/slices/providers-slice";
import {
  selectEntityTagsIsLoading,
  selectTagsIsLoading,
  toggleIndustryTag,
  toggleSpecialtyTag,
} from "../../features/api/slices/tags-slice";
import ServiceBudgetSelectionRow from "./service-budget-selection-row";
import TagsSelectMenu from "./tags-select-menu";

export enum EntityType {
  Project = "project",
  Provider = "provider",
}

type TagsFormProps = {
  entityType: EntityType;
  tags: AdminSelectedTags;
};

const TagsForm = ({ entityType, tags }: TagsFormProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const tagsLoading = useAppSelector(selectTagsIsLoading);
  const entityTagsLoading = useAppSelector(selectEntityTagsIsLoading);

  const toggleCategoryTag = (category: string) => {
    switch (entityType) {
      case EntityType.Project:
        dispatch(toggleProjectCategoryTag(category));
        break;
      case EntityType.Provider:
        dispatch(toggleProviderCategoryTag(category));
        break;
      default:
        break;
    }
  };

  const selectServiceTag = (service: string) => {
    switch (entityType) {
      case EntityType.Project:
        dispatch(selectProjectServiceTag(service));
        break;
      case EntityType.Provider:
        dispatch(selectProviderServiceTag(service));
        break;
      default:
        break;
    }
  };

  const deselectServiceTag = (service: string) => {
    switch (entityType) {
      case EntityType.Project:
        dispatch(deselectProjectServiceTag(service));
        break;
      case EntityType.Provider:
        dispatch(deselectProviderServiceTag(service));
        break;
      default:
        break;
    }
  };

  const toggleSpecialty = (specialty: string) => {
    dispatch(toggleSpecialtyTag(specialty));
  };

  const toggleIndustry = (industry: string) => {
    dispatch(toggleIndustryTag(industry));
  };

  const handleBudgetChange = (
    service: string,
    minBudget: number,
    maxBudget: number
  ) => {
    dispatch(
      handleServiceBudgetChange({
        service: service,
        minBudget: minBudget,
        maxBudget: maxBudget,
      })
    );
  };

  const serviceTagsSelectPropsValues: SelectProps<string[]> = {
    style: { marginBottom: "1rem", width: "100%" },
    showSearch: true,
    mode: entityType === EntityType.Provider ? "multiple" : undefined,
    placeholder: "Select a service",
    value: tags?.selectedServices.map((service) => {
      return service.tag;
    }),
    onSelect: selectServiceTag,
    onDeselect: deselectServiceTag,
  };

  const specialtyTagsSelectPropsValues: SelectProps<string[]> = {
    style: { marginBottom: "1rem", width: "100%" },
    showSearch: true,
    mode: "multiple",
    placeholder: "Select specialties",
    value: tags?.selectedSpecialties.map((specialty) => {
      return specialty.tag;
    }),
    onSelect: (value: string) => toggleSpecialty(value),
    onDeselect: (value: string) => toggleSpecialty(value),
    defaultValue: tags?.selectedSpecialties.map((tag) => tag.tag),
  };

  if (tagsLoading || entityTagsLoading) {
    return (
      <Card>
        <Skeleton paragraph={{ rows: 2 }} active />
      </Card>
    );
  }

  return (
    <Card style={{ padding: "1rem" }}>
      <Div>
        <TagsSelectMenu
          heading={"Industry"}
          selectTag={toggleIndustry}
          deselectTag={toggleIndustry}
          selectedTags={tags?.selectedIndustry}
          tagOptions={tags?.industries}
        />
        <TagsSelectMenu
          heading={"Service Category"}
          multipleSelect={entityType === EntityType.Provider}
          selectTag={toggleCategoryTag}
          deselectTag={toggleCategoryTag}
          selectedTags={tags?.selectedCategories.map((category) => {
            return category.label;
          })}
          tagOptions={tags?.categories.map((category) => {
            return { label: category.label, tag: category.label };
          })}
        />
        <Typography elementTheme="caption">Service</Typography>
        <Select {...serviceTagsSelectPropsValues}>
          {tags?.selectedCategories.map((category) => (
            <Select.OptGroup
              key={category.tag}
              value={category.tag}
              label={t(`tags|${category.label}`)}
            >
              {category.services.map((service) => (
                <Select.Option key={service.tag} value={service.tag}>
                  {t(`tags|${service.label}`)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
        {entityType === EntityType.Provider &&
          tags?.selectedServices.map((service) => {
            return (
              <ServiceBudgetSelectionRow
                service={service}
                key={service.tag}
                handleBudgetChange={handleBudgetChange}
              />
            );
          })}
        <Typography elementTheme="caption">Specialty</Typography>
        <Select {...specialtyTagsSelectPropsValues}>
          {tags?.selectedCategories.map((category) => (
            <Select.OptGroup
              key={category.tag}
              value={category.tag}
              label={t(`tags|${category.label}`)}
            >
              {category.specialties.map((specialty) => (
                <Select.Option key={specialty.tag} value={specialty.tag}>
                  {t(`tags|${specialty.label}`)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Div>
    </Card>
  );
};

export default TagsForm;

