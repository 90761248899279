import Typography from "@hellodarwin/core/lib/components/common/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import { useState } from "react";
import { useAppDispatch } from "../../app/index";
import { fetchProjectMatches } from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

const { Title } = Typography;

type ManualMatchFormProps = {
  projectId: string;
};

interface ChargeForm {
  provider_id: string;
}

const ManualMatchForm = ({ projectId }: ManualMatchFormProps) => {
  const [form] = Form.useForm();
  const api = useAdminApi();
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: ChargeForm) => {
    (async () => {
      setIsSaving(true);
      try {
        await api.manualMatch(projectId, values.provider_id);
        await dispatch(fetchProjectMatches({ api, projectId: projectId }));
        form.resetFields();
        message.success("Saved!");
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    })();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"manual-match"}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} elementTheme="h6">
            Manual Match
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Provider id"
            name="provider_id"
            rules={[{ required: true, message: "Provider is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item shouldUpdate={true}>
            {() => (
              <Button block type="primary" htmlType="submit" loading={isSaving}>
                Match
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManualMatchForm;

