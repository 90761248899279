import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  ChatType,
  HdChat,
  HdChatTemplate,
} from "@hellodarwin/core/lib/features/entities";
import Drawer from "antd/es/drawer";
import List from "antd/es/list";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { selectChatTemplate } from "../../features/api/slices/hd-chats-slice";
import {
  fetchAllTemplates,
  selectAllTemplates,
  selectTemplatesLoading,
} from "../../features/api/slices/templates-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import Select from "antd/es/select";

interface TemplateDrawerProps {
  chat: HdChat;
  visible: boolean;
  onClose: () => void;
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const ChatTemplateDrawer = ({
  chat,
  visible,
  onClose,
}: TemplateDrawerProps) => {
  const dispatch = useAppDispatch();

  const api = useAdminApi();
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const templates = useAppSelector((state) => selectAllTemplates(state));
  const loading = useAppSelector(selectTemplatesLoading);
  const [selectedChatType, setSelectedChatType] = useState<ChatType>(ChatType.GrantApplication);
  const handleSelectedTemplate = async (templateId: string) => {
    const template = await dispatch(
      selectChatTemplate({
        api,
        chatId: chat?.id,
        templateId: templateId,
        locale: chat.locale || "",
      })
    ).unwrap();
    if (template) {
      onClose();
    }
  };
  useEffect(() => {
    if (!chat.locale) return;
    dispatch(
      fetchAllTemplates({ api, page: DEFAULT_PAGE, size: DEFAULT_LIMIT, type: selectedChatType })
    );
  }, [dispatch, api, chat, selectedChatType]);

  return (
    <Drawer
      title="Select Chat Template"
      size="large"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      extra={
        <Select defaultValue={selectedChatType} style={{ width: 175 }} onChange={setSelectedChatType}>
          {Object.values(ChatType).map((type) => (
            <Select.Option key={type} value={type}>
              {type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
            </Select.Option>
          ))}
        </Select>
      }
    >
      <List
        dataSource={templates}
        bordered={true}
        loading={loading}
        style={{ borderRadius: 0, height: '85vh', overflow: "auto" }}
        renderItem={(c: HdChatTemplate, index: number) => (
          <List.Item
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{
              cursor: "pointer",
              backgroundColor:
                hoveredIndex === index
                  ? theme.colors.purple_4
                  : theme.colors.transparent,
            }}
          >
            <Div
              key={c.id}
              onClick={() => handleSelectedTemplate(c.id)}
              style={{
                width: "100%",
                color: theme.colors.purple_1,
                borderColor: theme.colors.purple_1,
                borderRadius: 5,
                marginTop: 8
              }}
            >
              <Div flex="row" justify="space-between">
                <Typography.Paragraph elementTheme="h5" medium ellipsis>
                  {c.name}
                </Typography.Paragraph>
                <Typography.Paragraph elementTheme="subtitle1" medium>
                  Type: {c.type}
                </Typography.Paragraph>
              </Div>
              {c.content_en && (
                <Typography.Paragraph elementTheme="body3" ellipsis style={{ maxHeight: 50, overflow: "auto" }}>
                  {c.content_en}
                </Typography.Paragraph>
              )}
            </Div>
          </List.Item>
        )}
      />
    </Drawer >
  );
};

export default ChatTemplateDrawer;

