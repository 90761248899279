import Doc from "@hellodarwin/icons/dist/icons/Doc";
import Download from "@hellodarwin/icons/dist/icons/Download";
import Link from "@hellodarwin/icons/dist/icons/Link";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

type DocumentType = "link" | "pdf" | "xls";
interface DocumentTileProps {
  label: string;
  link: string;
  type: DocumentType;
  size?: string;
}

const DocumentTile = ({ label, link, type, size }: DocumentTileProps) => {
  const theme = useTheme();
  const Icon = type === "link" ? Link : Doc;
  const ButtonIcon = type === "link" ? NewWindow : Download;

  const { t } = useTranslations();
  return (
    <Div flex="row" gap={24} align="center" tablet={{ flex: "column" }}>
      <div
        style={{
          width: 77,
          height: 77,
          flexShrink: 0,
          background: theme.colors.purple_4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon width={24} height={24} />
      </div>
      <div style={{ width: "100%", padding: "14px 0" }}>
        <Typography.Title level={3} elementTheme="subtitle2" noMargin>
          {label}
        </Typography.Title>
      </div>
      <div>
        <a
          href={link}
          download={type !== "link"}
          target="_blank"
          rel="nofollow"
        >
          <Button
            fitContent
            size="small"
            defaultStyle={theme.colors.transparent}
            trailingIcon={<ButtonIcon width={13} height={13} />}
          >
            {type === "link"
              ? t("grant_single|openLink")
              : t("grant_single|download")}
          </Button>
        </a>
      </div>
    </Div>
  );
};

export default DocumentTile;

