import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
  GrantPreview,
} from "@hellodarwin/core/lib/features/entities";

import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export interface RelatedGrantState {
  status: "idle" | "pending";
  error: ApiError;
  relatedGrants: GrantPreview[];
}

const initialState: RelatedGrantState = {
  status: "idle",
  error: ApiErrorInitialState,
  relatedGrants: [],
};

export const fetchRelatedGrants = createAsyncThunk<
  GrantPreview[],
  { api: AdminApiClient; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "admin/fetchRelatedGrants",
  async (
    {
      api,
      grantId,
      locale,
    }: { api: AdminApiClient; grantId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchRelatedGrants(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const relatedGrantsSlice = createSlice({
  name: "relatedGrants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRelatedGrants.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchRelatedGrants.fulfilled, (state, action) => {
      state.status = "idle";
      state.relatedGrants = action.payload;
    });
    builder.addCase(fetchRelatedGrants.rejected, (state, action) => {
      state.status = "idle";
      state.error = action.payload as ApiError;
    });
  },
});

export const selectRelatedGrantsIsLoading = (state: RootState) =>
  state.relatedGrants.status === "pending";
export const selectRelatedGrants = (state: RootState) =>
  state.relatedGrants.relatedGrants;

export const relatedGrantsReducer = relatedGrantsSlice.reducer;

