import Industry from "@hellodarwin/icons/dist/icons/Industry";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Checkbox from "antd/es/checkbox";
import { FormInstance } from "antd/es/form/Form";
import { SelectProps } from "antd/es/select";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import FormItem from "../../../forms/form-layouts/form-item";
import { ApplyFundingExplorerFilterFunction } from "../hooks/apply-filters";
import { FilterSelect, FilterSelectDropdown } from "./styles";
import { FundingExplorerFilterValues } from "./types";

export interface IndustriesSelectorProps {
  industries: SelectProps["options"];
  setSelectedIndustries: (values: string[]) => void;
  applyFilter: ApplyFundingExplorerFilterFunction;
  selectedIndustries: string[];
  filterform: FormInstance<FundingExplorerFilterValues>;
}

const rangeBetween = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

const IndustriesSelector = ({
  industries,
  setSelectedIndustries,
  applyFilter,
  selectedIndustries,
  filterform,
}: IndustriesSelectorProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [hasIndustryChanged, setIndustryHasChanged] = useState(false);
  const [hasSubindustryChanged, setSubindustryHasChanged] = useState(false);

  const handleSubindustryOnChange = useCallback(() => {
    const subindustry: string[] = filterform.getFieldValue("subindustry");

    const filteredSubindustry = subindustry?.filter((si) =>
      selectedIndustries.find((i) => {
        const industries = i.split("-");
        if (industries.length > 1) {
          const range = rangeBetween(
            parseInt(industries[0]),
            parseInt(industries[1])
          );
          return range.some((index) => si.startsWith(`${index}`));
        }
        return si.startsWith(i);
      })
    );
    filterform.setFieldValue("subindustry", filteredSubindustry);
    setSubindustryHasChanged(true);
  }, [filterform, selectedIndustries]);

  // Update industries and subindustries
  useEffect(() => {
    filterform.setFieldValue("industry", selectedIndustries);
    handleSubindustryOnChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndustries]);

  // Apply filter only when subindustries change, using debounced handler
  useEffect(() => {
    if (hasIndustryChanged && hasSubindustryChanged) {
      applyFilter({ type: "filterChanged" });
      setSubindustryHasChanged(false);
      setIndustryHasChanged(false);
    }
  }, [applyFilter, hasSubindustryChanged]);

  const dropdownStyle: CSSProperties = useMemo(
    () => ({
      padding: 0,
      borderRadius: 0,
      border: `1px solid ${theme.colors.primary}`,
    }),
    [theme]
  );

  const dropdownRender: SelectProps["dropdownRender"] = useCallback(
    (el) => <FilterSelectDropdown>{el}</FilterSelectDropdown>,
    []
  );

  const optionRender: SelectProps["optionRender"] = useCallback(
    (option) => {
      return (
        <Div
          flex="row"
          gap={8}
          align="center"
          borderColor={theme.colors.grey_4}
          bordersSide="bottom"
          justify="space-between"
          style={{ padding: "12px 16px" }}
        >
          <Typography
            elementTheme="body3"
            color={theme.colors.primary}
            ellipsis
            overflow
            nowrap
          >
            {option.label}
          </Typography>
          <Checkbox
            type="square"
            checked={selectedIndustries.includes(option.value.toString())}
          />
        </Div>
      );
    },
    [selectedIndustries, theme]
  );

  const handleChange = (selectedIndustries: string[]) => {
    setSelectedIndustries(selectedIndustries);
    setIndustryHasChanged(true);
  };
  return (
    <FormItem name="industry" style={{ flex: 1, margin: 0 }}>
      <FilterSelect
        mode="multiple"
        dropdownRender={dropdownRender}
        style={{
          width: 340,
          maxWidth: "100%",
          height: 45,
        }}
        onChange={handleChange}
        filterOption={(input, option) =>
          typeof option.label === "string"
            ? option?.label?.toLowerCase().indexOf(input?.toLowerCase()) !== -1
            : undefined
        }
        suffixIcon={
          <Div flex="row" gap={32} align="center" justify="space-between">
            <Div flex="row" gap={8} align="center">
              <Industry width={20} height={20} />
              <Typography style={{ fontSize: 15 }}>
                {t(`grants|fundingExplorerFilter.industryMainPlaceholder`)}
              </Typography>
            </Div>
            <TabArrow down width={12} height={12} />
          </Div>
        }
        value={selectedIndustries}
        size="large"
        options={industries}
        dropdownStyle={dropdownStyle}
        optionRender={optionRender}
        menuItemSelectedIcon={<></>}
      />
    </FormItem>
  );
};

export default IndustriesSelector;

