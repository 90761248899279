import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Mobile = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5778 1.77777H11.7333C10.9789 1.77777 10.2554 2.09886 9.722 2.6704C9.18857 3.24194 8.88889 4.01711 8.88889 4.82539V27.1746C8.88889 27.9829 9.18857 28.7581 9.722 29.3296C10.2554 29.9011 10.9789 30.2222 11.7333 30.2222H21.2148C21.9692 30.2222 22.6927 29.9011 23.2261 29.3296C23.7596 28.7581 24.0593 27.9829 24.0593 27.1746V4.82539C24.0593 4.01711 23.7596 3.24194 23.2261 2.6704C22.6927 2.09886 21.9692 1.77777 21.2148 1.77777H18.3704M14.5778 1.77777V3.80952H18.3704V1.77777M14.5778 1.77777H18.3704M14.5778 27.1746H18.3704"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mobile;

