import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import Checkbox, { CheckboxOptionType } from "antd/es/checkbox";
import Radio from "antd/es/radio";
import Rate from "antd/es/rate";
import Select, { DefaultOptionType, SelectProps } from "antd/es/select";
import TextArea from "rc-textarea";
import { useTheme } from "styled-components";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import Div from "../../../common/div";
import FormItem from "../../../forms/form-layouts/form-item";
import FormLabel from "../../../forms/form-layouts/form-label";
import validateAnyType from "../../../forms/utils/validate-any-type";
interface ReviewStepCardProps {
  label: string;
  extra?: string;
  name: string;
  placeholder?: string;
  error?: string;
  type: "checkbox" | "radio" | "rating" | "textarea" | "select" | "rating";
  options?: CheckboxOptionType[] | SelectProps["options"];
}

const ReviewStepCard = ({
  extra,
  label,
  name,
  type,
  error,
  placeholder,
  options,
}: ReviewStepCardProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  return (
    <div>
      {type === "rating" ? (
        <Div flex="row" tablet={{ flex: "column" }}>
          <div style={{ flex: 1 }}>
            <FormLabel label={label} extra={extra} />
          </div>
          <FormItem
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            name={name}
            rules={[
              () => ({
                validator: validateAnyType({
                  message: error,
                  isRequired: true,
                }),
              }),
            ]}
          >
            <Rate
              character={(props) => {
                return !!props.value &&
                  props.value >= (props?.index || 0) + 1 ? (
                  <RatingFill
                    color={theme.colors.yellow_star}
                    size={isTablet ? 30 : 37}
                  />
                ) : (
                  <RatingOutline
                    color={theme.colors.grey_3}
                    size={isTablet ? 30 : 37}
                  />
                );
              }}
            />
          </FormItem>
        </Div>
      ) : (
        <FormItem
          name={name}
          label={<FormLabel label={label} extra={extra} />}
          rules={[
            () => ({
              validator: validateAnyType({
                message: error,
                isRequired: true,
              }),
            }),
          ]}
        >
          {type === "select" ? (
            <Select
              style={{ width: "100%" }}
              placeholder={placeholder}
              options={options as DefaultOptionType[]}
            />
          ) : type === "textarea" ? (
            <TextArea
              placeholder={placeholder}
              style={{ minHeight: 150, width: "100%" }}
            />
          ) : type === "radio" ? (
            <Radio.Group
              style={{
                display: isTablet ? "flex" : "",
                justifyContent: isTablet ? "center" : "",
              }}
              options={options as CheckboxOptionType[]}
            />
          ) : (
            type === "checkbox" && (
              <Checkbox.Group
                style={{
                  display: isTablet ? "flex" : "",
                  justifyContent: isTablet ? "center" : "",
                }}
                options={options as CheckboxOptionType[]}
              />
            )
          )}
        </FormItem>
      )}
    </div>
  );
};
export default ReviewStepCard;

