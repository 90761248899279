import styled from "../../../../../../plugins/styled";

export const FormEditListItem = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  &:hover {
    transform: scale(1.01);
  }
`;

