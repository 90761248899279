import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Telecom = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.792 20.082C10.7823 19.074 10.2151 17.707 10.2151 16.2817C10.2151 14.8563 10.7823 13.4893 11.792 12.4814M19.408 12.4814C20.4177 13.4893 20.9849 14.8563 20.9849 16.2817C20.9849 17.707 20.4177 19.074 19.408 20.082M8.74647 23.1233C7.84644 22.225 7.1325 21.1585 6.64541 19.9847C6.15832 18.8109 5.90761 17.5529 5.90761 16.2824C5.90761 15.0119 6.15832 13.7538 6.64541 12.5801C7.1325 11.4063 7.84644 10.3398 8.74647 9.44141M22.4535 9.44141C23.3536 10.3398 24.0675 11.4063 24.5546 12.5801C25.0417 13.7538 25.2924 15.0119 25.2924 16.2824C25.2924 17.5529 25.0417 18.8109 24.5546 19.9847C24.0675 21.1585 23.3536 22.225 22.4535 23.1233M5.70093 26.1633C0.233032 20.7054 0.233032 11.8593 5.70093 6.40002M25.4991 6.40002C30.967 11.8579 30.967 20.7054 25.4991 26.1647M15.6 16.2824H15.6115V16.2924H15.6V16.2824ZM16.1385 16.2824C16.1385 16.4249 16.0817 16.5616 15.9808 16.6624C15.8798 16.7632 15.7428 16.8199 15.6 16.8199C15.4572 16.8199 15.3202 16.7632 15.2193 16.6624C15.1183 16.5616 15.0615 16.4249 15.0615 16.2824C15.0615 16.1398 15.1183 16.0031 15.2193 15.9023C15.3202 15.8015 15.4572 15.7449 15.6 15.7449C15.7428 15.7449 15.8798 15.8015 15.9808 15.9023C16.0817 16.0031 16.1385 16.1398 16.1385 16.2824Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Telecom;

