import { Tag as HDTag } from "@hellodarwin/core/lib/features/entities";
import React, { useState } from "react";
import { ServiceTagsContext } from "./service-tags-context";

type ServiceTagsProps = {
  children?: React.ReactNode;
};

const ServiceTagsProvider = ({ children }: ServiceTagsProps) => {
  const [providerTags, setProviderTags] = useState<HDTag[]>([]);
  const [projectTags, setProjectTags] = useState<HDTag[]>([]);

  const checkForServiceTag = (tags: HDTag[]): boolean => {
    return tags.some((tagObject) => {
      const tag = tagObject.tag;
      return tag.includes("services");
    });
  };

  const value = {
    projectTags: projectTags,
    setProjectTags: setProjectTags,
    providerTags: providerTags,
    setProviderTags: setProviderTags,
    hasProjectServiceTag: checkForServiceTag(projectTags),
    hasProviderServiceTag: checkForServiceTag(providerTags),
  };

  return (
    <ServiceTagsContext.Provider value={value}>
      {children}
    </ServiceTagsContext.Provider>
  );
};

export default ServiceTagsProvider;
