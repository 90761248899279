import { ReactNode, useState } from "react";

import "./list.scss";

type ListProps = {
  headers: string[];
  rows: (string | ReactNode | undefined)[][];
  onClick: (index: number, eventDetail: number) => void;
};

const List = ({ headers, rows, onClick }: ListProps) => {
  const [activeRow, setActiveRow] = useState(0);

  return (
    <table className="hd-table">
      <thead className="hd-table-header">
        <tr>
          {headers.map((h) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((c, i) => (
          <tr
            className={`hd-table-row ${activeRow === i ? "active" : ""}`}
            onClick={(e) => {
              onClick(i, e.detail);
              setActiveRow(i);
            }}
            key={i}
          >
            {c.map((str, i) => (
              <td key={i}>{str}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default List;

