import TeamOutlined from "@ant-design/icons/TeamOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Company, Contact } from "@hellodarwin/core/lib/features/entities";
import { getFormattedDate } from "@hellodarwin/core/lib/features/helpers";
import Card from "antd/es/card";
import Skeleton from "antd/es/skeleton";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  fetchCompanyQuickInfos,
  selectCompanyCardIsLoading,
  selectCompanyHeaderById,
} from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
const { Title } = Typography;

type CompanyCardProps = {
  company: Company;
  contact?: Contact;
  className?: string;
  isCompanyPage?: boolean;
};

const CompanyCard = ({
  company,
  contact,
  className,
  isCompanyPage,
}: CompanyCardProps) => {
  const navigate = useNavigate();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const quickInfos = useAppSelector((state) =>
    selectCompanyHeaderById(state, company.company_id)
  );
  const loading = useAppSelector(selectCompanyCardIsLoading);

  useEffect(() => {
    if (company.company_id)
      dispatch(fetchCompanyQuickInfos({ api, companyId: company.company_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.company_id]);

  const accessCompany = () => {
    navigate(`/companies/${company.company_id}`);
  };

  if (loading)
    return (
      <Card style={{ padding: "1rem" }}>
        <Skeleton paragraph={{ rows: 2 }} active></Skeleton>
      </Card>
    );
  return (
    <Card
      hoverable
      className={`client-card ${className || ""}${
        isCompanyPage ? " on-client-page" : ""
      }`}
      onDoubleClick={accessCompany}
      bordered
      style={{ width: "100% " }}
    >
      <Div flex="row" align="center" justify="space-between">
        <Title level={4} elementTheme="h6">
          {company.name}
        </Title>
      </Div>
      <Div justify="space-between" tablet={{ flex: "column" }} flex="row">
        <Typography textAlign={"left"}>
          {quickInfos && (
            <Div flex={"row"} gap={8} tablet={{ flex: "column" }}>
              <Typography elementTheme="caption">
                Number of submitted projects
              </Typography>
              <Typography elementTheme="caption" bold>
                {quickInfos.projects_count}
              </Typography>
            </Div>
          )}
          {company.created_at && (
            <Div flex={"row"} gap={8} tablet={{ flex: "column" }}>
              <Typography elementTheme="caption">Client since</Typography>
              <Typography elementTheme="caption" bold>
                {getFormattedDate(company.created_at)}
              </Typography>
            </Div>
          )}
          {quickInfos && (
            <Div flex={"row"} gap={8} tablet={{ flex: "column" }}>
              <Typography elementTheme="caption">
                Last activated project
              </Typography>
              <Typography elementTheme="caption" bold>
                {quickInfos.latest_activated_project}
              </Typography>
            </Div>
          )}
          {contact?.last_login_at && (
            <Div flex={"row"} gap={8} tablet={{ flex: "column" }}>
              <Typography elementTheme="caption">Last login</Typography>
              <Typography elementTheme="caption" bold>
                {getFormattedDate(contact?.last_login_at)}
              </Typography>
            </Div>
          )}
        </Typography>
        <Typography textAlign="right">
          <Div
            flex={"row"}
            gap={8}
            justify={"flex-end"}
            tablet={{ flex: "column" }}
          >
            <Typography elementTheme="caption">Contact phone</Typography>
            <Typography elementTheme="caption">
              <a href={`tel:${company.phone}`}>{company.phone}</a>
            </Typography>
          </Div>
          <Div
            flex={"row"}
            gap={8}
            justify={"flex-end"}
            tablet={{ flex: "column" }}
          >
            <Typography elementTheme="caption" ellipsis>
              Company email
            </Typography>
            <Typography elementTheme="caption" bold ellipsis>
              {company.email}
            </Typography>
          </Div>
          <Div
            flex={"row"}
            gap={8}
            justify={"flex-end"}
            tablet={{ flex: "column" }}
          >
            {company.size && (
              <>
                {" "}
                <TeamOutlined />
                <Typography elementTheme="caption" bold>
                  {company.size}
                </Typography>
              </>
            )}
            <Typography elementTheme="caption">Website</Typography>
            <Typography elementTheme="caption" bold>
              {company.website}
            </Typography>
          </Div>
          {company.industry && (
            <Div flex={"row"} gap={8} justify={"flex-end"}>
              <Typography elementTheme="caption">Company industry</Typography>
              <Typography elementTheme="caption" bold>
                {company.industry}
              </Typography>
            </Div>
          )}
        </Typography>
      </Div>
    </Card>
  );
};

export default CompanyCard;

