import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  SummaryBoxContainer,
  SummaryBoxContent,
} from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/styles";
import SummaryBox from "@hellodarwin/core/lib/components/grants/single/grant-sections/summary/SummaryBox";
import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const GinGrantSummaryWrapper = styled.div`
  display: flex;
  padding: 24px 48px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 54px;
  flex-wrap: wrap;

  ${SummaryBoxContainer} {
    border-right: none;
    padding: 0px;
    min-width: 300px;
    gap: 6px;
  }

  ${SummaryBoxContent} {
    padding-inline-start: 24px;
  }

  ul {
    margin: 0;
    padding-inline-start: 0px;
  }
`;
export interface GinSummaryBlock extends PropsWithChildren {
  label: string;
  Icon: IconNode;
}
export interface GinSummaryProps {
  blocks: GinSummaryBlock[];
}
const GinGrantSummary = ({ blocks }: GinSummaryProps) => {
  return (
    <GinGrantSummaryWrapper>
      {blocks.map((block) => (
        <SummaryBox
          {...block}
          children={
            <Typography elementTheme="body2">{block.children}</Typography>
          }
        />
      ))}
    </GinGrantSummaryWrapper>
  );
};

export default GinGrantSummary;

