import styled from "../../../plugins/styled";

export const HubspotMeetingContainer = styled.div`
  width: 325px;
  height: 549px;

  border: 1px solid ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  iframe {
    width: 333px;
    margin-top: -103px;
    margin-left: -6px;
  }
`;

