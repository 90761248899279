import CopyOutlined from "@ant-design/icons/CopyOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import Tooltip from "antd/es/tooltip";
import { Link } from "react-router-dom";
import Clipboard from "../../components/clipboard";
import { copyIntoClipboard } from "../clipboard";

type ProjectMatchPreviewProps = {
  match: Match;
};
const ProjectMatchPreview = ({ match }: ProjectMatchPreviewProps) => {
  const items = [
    {
      label: "Company Name",
      text: (
        <Tooltip placement={"bottom"} title={"Go to provider page"}>
          <Link to={`/providers/${match.provider_id}`}>
            {match.provider.name}
          </Link>
        </Tooltip>
      ),
    },
    { label: "Company Email", text: match.provider.email },
    {
      label: "Provider ID",
      text: (
        <Div flex={"row"} align={"center"}>
          <Clipboard
            copy={match.provider_id}
            title={getShortId(match.provider_id)}
          ></Clipboard>
          <Tooltip
            placement={"bottom"}
            title={"Copy to clipboard"}
            style={{ marginLeft: "0.5rem" }}
          >
            <CopyOutlined
              onClick={() => copyIntoClipboard(match.provider_id)}
            />
          </Tooltip>
        </Div>
      ),
    },
    {
      label: "Contact Name",
      text: match?.provider_contact_name,
    },
    {
      label: "Contact Email",
      text: match?.provider_contact_email,
    },
    { label: "Contact Phone", text: match.provider_contact_phone },
  ];

  return (
    <div>
      {items.map((item, i) => (
        <div key={`item-${i}`} className="hd-preview-item match-preview-item">
          <div className="match-preview-item-label">{item.label}</div>
          <div>{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectMatchPreview;

