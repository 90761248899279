import Div from "@hellodarwin/core/lib/components/common/div";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import AddressAutocompleteInput from "@hellodarwin/core/lib/components/forms/form-groups/adress-autocomplete-input";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import validateWebsite from "@hellodarwin/core/lib/components/forms/utils/validate-website";
import {
  AdminProjectRequest,
  Company,
  Project,
} from "@hellodarwin/core/lib/features/entities";
import {
  isDev,
  selectInputEmail,
} from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/Form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import { MenuProps } from "antd/es/menu";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../app";
import { updateCompany } from "../../features/api/slices/companies-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../features/api/slices/tags-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../../pages/single-project-page";
import ExternalLink from "../widgets/external-link";
import AssignCompanyModal from "./assign-company-modal";
const { TextArea } = Input;

type CompanyFormProps = {
  company?: Company;
  project?: Project;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  isProgram?: boolean;
  companyForm?: FormInstance<CompanyFormValues>;
};

export type CompanyFormValues = {
  name: string;
  size: string;
  annual_revenue: string;
  province: string;
  postal_code: string;
  country: string;
  isCanada: boolean;
  comments: string;
  website: string;
  business_number: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  lng: number;
  lat: number;
  industry_sector: string[];
  industry_subsector: string[];
};

const CompanyForm = ({
  company,
  setForms,
  project,
  isProgram,
}: CompanyFormProps) => {
  const [form] = Form.useForm<CompanyFormValues>();
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const industrySectors = useAppSelector(selectIndustriesSectors);
  const industrySubsectors = useAppSelector((state: RootState) =>
    selectIndustriesSubsectors(state, company?.industry_sector || [])
  );

  useEffect(() => {
    dispatch(fetchIndustries({ api, locale: "en" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      companyForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    if (company) form.setFieldsValue(company);
  }, [form, company]);

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const initialValues: CompanyFormValues = {
    name: company?.name || "",
    size: company?.size || "",
    annual_revenue: company?.annual_revenue || "",
    province: company?.province || "",
    country: company?.country || "",
    isCanada: company?.country === "Canada" || company?.country === "",
    comments: company?.comments || "",
    website: company?.website || "",
    business_number: company?.business_number || "",
    email: company?.email || "",
    phone: company?.phone || "",
    address: company?.address || "",
    postal_code: company?.postal_code || "",
    city: company?.city || "",
    lat: company?.lat || 0,
    lng: company?.lng || 0,
    industry_sector: company?.industry_sector || [],
    industry_subsector: company?.industry_subsector || [],
  };

  const reassignCompany = (company: Company) => {
    if (project?.project_id) {
      Modal.confirm({
        title: t("project|company.assign"),
        content: t("project|company.confirmAssign"),
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          const company_id = company.company_id;
          return new Promise((resolve, reject) => {
            const request: AdminProjectRequest = {
              company_id,
              description: project.description,
              tags: project.tags,
              project_id: project.project_id,
            };

            api
              .updateProject(request)
              .then(() => {
                notification.success({
                  message: t("project|company.successAssign"),
                  placement: "topLeft",
                  duration: 0,
                });
                resolve("Suppression");
                setAssignModalOpen(false);
              })
              .catch((e: Error) => reject(e));
          }).catch(() => console.error("Oops errors!"));
        },
      });
    } else {
      Modal.error({
        title: "No project related to this company.",
      });
      setAssignModalOpen(false);
    }
  };

  const toggleDeleteCompany = () => {
    Modal.confirm({
      title: t("project|company.delete", { company_name: company?.name }),
      content: t("project|company.confirmDelete"),
      okText: t("button.confirm"),
      cancelText: t("button.cancel"),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk() {
        return new Promise((resolve, reject) => {
          api
            .deleteCompany(company?.company_id || "")
            .then(() => {
              notification.success({
                message: t("project|company.successDelete"),
                placement: "topLeft",
                duration: 0,
              });
              resolve("Suppression");
            })
            .catch((e: Error) => reject(e));
        }).catch(() => console.error("Oops errors!"));
      },
    });
  };

  const actionMenuItems: MenuProps["items"] = [
    {
      label: t("project|company.assign"),
      key: 2,
      onClick: () => setAssignModalOpen(true),
    },
    {
      label: t("project|company.delete", { company_name: company?.name }),
      key: 1,
      onClick: toggleDeleteCompany,
    },
  ];

  const onSave = async () => {
    if (company) {
      try {
        const companyFormValues = form?.getFieldsValue()!;

        if (companyFormValues === undefined) {
          return;
        }

        const updatedCompany: Company = {
          ...company,
          ...companyFormValues,
        };

        await dispatch(updateCompany({ api, company: updatedCompany }));

        message.success("Saved!");
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    } else {
      message.error("No company selected!");
    }
  };

  return (
    <>
      <Card style={{ padding: "1rem" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="company"
        >
          <Div flex="column" gap={8}>
            <Div flex="row" justify="space-between" align="center">
              <Div flex="row" justify="left" align="left" gap={8}>
                <Typography.Title
                  level={4}
                  elementTheme="h6"
                  copyable={{
                    successMessage: [company?.company_id, "company id copied"],
                    text: company?.company_id,
                  }}
                >
                  <a
                    href={
                      isProgram
                        ? `/companies/${company?.company_id}/grants`
                        : `/companies/${company?.company_id}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Company
                  </a>
                </Typography.Title>
                {company && (
                  <ExternalLink
                    id={company.crm_id}
                    title={"Hubspot ID"}
                    url={company.hubspot_url}
                    onEnd={(newValue) => {
                      dispatch(
                        updateCompany({
                          api,
                          company: {
                            ...company,
                            ...{ crm_id: newValue },
                          },
                        })
                      );
                    }}
                  />
                )}
                {isDev && (
                  <>
                    <Dropdown items={actionMenuItems} cta={{ size: 24 }} />
                  </>
                )}
              </Div>
              <Div flex="row" justify="right" align="right">
                <Button onClick={onSave} size="small">
                  Save
                </Button>
              </Div>
            </Div>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please add a name for the company",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Website"
                  name="website"
                  rules={[
                    {
                      required: true,
                      validator: validateWebsite({
                        message: t(
                          "profile|profileForm.company.validation.website"
                        ),
                        isAdmin: true,
                        isRequired: true,
                      }),
                      message: "Please add a valid website url",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Company Size"
                  name="size"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company size",
                    },
                  ]}
                >
                  <Select placeholder="Select option">
                    {companySize.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Annual revenue"
                  name="annual_revenue"
                  rules={[
                    {
                      required: true,
                      message: "Please add an annual revenue",
                    },
                  ]}
                >
                  <Select placeholder="Select option">
                    {annualRevenue.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <AddressAutocompleteInput form={form} showManualAddress />
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please add an email",
                    },
                  ]}
                >
                  <Input onMouseDown={(e) => selectInputEmail(e)} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Comments" name="comments">
              <TextArea rows={company?.comments ? 4 : 1} autoSize />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Business number" name="business_number">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone" name="phone">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Industry Sector" name="industry_sector">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    options={industrySectors}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Industry Subsector" name="industry_subsector">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    options={industrySubsectors}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Div>
        </Form>
      </Card>
      <AssignCompanyModal
        open={assignModalOpen}
        handleClose={() => setAssignModalOpen(false)}
        handleSubmit={reassignCompany}
      />
    </>
  );
};

export default CompanyForm;

