import { useTranslation } from "react-i18next";
import { FiltersDropdownProps } from ".";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import { Tablet } from "../../../../common/Media";

const FilterDropdownActions = ({
  applyFilter,
  handleClose,
  company,
}: FiltersDropdownProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const resetFilter = () => {
    applyFilter({
      type: "reset",
    });
  };

  const resetFilterWithCompany = () => {
    applyFilter({
      type: "companyInit",
    });
  };

  return (
    <Div
      flex="row"
      gap={8}
      fitContent
      tablet={{ flex: "column", style: { paddingInline: 6, width: "100%" } }}
    >
      {!!company && (
        <Button
          onClick={resetFilterWithCompany}
          size="small"
          style={{ borderRadius: 4, minWidth: 0 }}
          defaultStyle={theme.colors.white_1}
          tablet={{ style: { width: "100%", justifyContent: "flex-start" } }}
        >
          {t(`grants|fundingExplorerFilter.resetFilterWithCompany`)}
        </Button>
      )}

      <Button
        onClick={resetFilter}
        size="small"
        style={{ borderRadius: 4, minWidth: 0 }}
        defaultStyle={theme.colors.white_1}
        tablet={{ style: { width: "100%", justifyContent: "flex-start" } }}
      >
        {t(`grants|fundingExplorerFilter.resetFilter`)}
      </Button>
      <Tablet direction="up">
        <Button
          onClick={handleClose}
          size="small"
          style={{ borderRadius: 4, minWidth: 0 }}
          defaultStyle={theme.colors.primary}
          tablet={{ style: { width: "100%", justifyContent: "flex-start" } }}
        >
          {t(`grants|fundingExplorerFilter.closeFilters`)}
        </Button>
      </Tablet>
    </Div>
  );
};

export default FilterDropdownActions;

