import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Pagination from "antd/es/pagination";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/app-hooks";
import CompaniesList from "../components/companies/companies-list";
import {
  queryCompanies,
  selectAllCompanies,
  selectError,
} from "../features/api/slices/companies-slice";
import { useAdminApi } from "../features/api/use-admin-api";
import ErrorPage from "./error-page";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

const CompaniesPage = () => {
  const dispatch = useAppDispatch();

  const error = useAppSelector(selectError);
  const companies = useAppSelector(selectAllCompanies);
  const api = useAdminApi();

  const onPageChange = (page: number, limit: number) => {
    dispatch(queryCompanies({ api, page, limit, query: "" }));
  };

  useEffect(() => {
    dispatch(
      queryCompanies({
        api,
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
        query: "",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <PageLayout
      app="admin"
      title={"Companies"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Companies",
        },
      ]}
    >
      <>
        <Div flex="column" gap={16}>
          <CompaniesList companies={companies}></CompaniesList>
        </Div>
        <Div flex="row" justify="flex-end">
          <Pagination
            showSizeChanger
            onChange={(page, size) => onPageChange(page, size ?? DEFAULT_LIMIT)}
          />
        </Div>
      </>
    </PageLayout>
  );
};

export default CompaniesPage;

