import { SelectProps } from "antd/es/select";

export const GinAssignmentStepOption: SelectProps["options"] = [
  { label: "Internal", value: "internal" },
  { label: "Client", value: "client" },
  { label: "Both", value: "both" },
];

export const GinMediumStepOption: SelectProps["options"] = [
  { label: "Meeting", value: "meeting" },
  { label: "Offline", value: "offline" },
];

export const GinTypeStepOption: SelectProps["options"] = [
  { label: "Eligibility validation", value: "eligibility_validation" },
  { label: "Information requested", value: "information_requested" },
  { label: "Form submission", value: "form_submission" },
  { label: "Application", value: "application" },
];

