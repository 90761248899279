import { DefaultOptionType } from "antd/es/select";
import { TranslatedFormsSelectOptions } from "../../../features/entities/form-entities";

export const getSelectOptions = <T,>(
  formsSelectOptions: TranslatedFormsSelectOptions[],
  locale: string
): DefaultOptionType[] => {
  return formsSelectOptions.map((option) => ({
    label: option.label,
    value: option.value,
  }));
};

