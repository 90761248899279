import { FormInstance } from "antd/es/form/hooks/useForm";
import { useCallback } from "react";
import { usePagination } from "../../../../features/providers/pagination-provider";

interface UsePrepareFundingExplorerURLProps {
  form: FormInstance;
  selectedIndustries: string[];
}
const usePrepareFundingExplorerURL = ({
  form,
  selectedIndustries,
}: UsePrepareFundingExplorerURLProps) => {
  const { goToPage, addParam, removeParam } = usePagination();

  return useCallback(
    (page: number) => {
      const filterValues = form.getFieldsValue();

      if (filterValues) {
        const {
          filterBy,
          industry,
          subindustry,
          financingType,
          status,
          closingDate,
          region,
          service,
          companySize,
          annualRevenue,
        } = filterValues;

        const handleParam = (key: string, value: any) => {
          if (value && value.length > 0) {
            addParam(key, Array.isArray(value) ? value.join(",") : value);
          } else {
            removeParam(key);
          }
        };

        handleParam("filterBy", filterBy);
        handleParam("industry", industry);
        handleParam("subindustry", subindustry);
        handleParam("financingType", financingType);
        handleParam("status", status);
        handleParam("closingDate", closingDate);
        handleParam("region", region);
        handleParam("service", service);
        handleParam("companySize", companySize);
        handleParam("annualRevenue", annualRevenue);
      }
      goToPage(page);
    },
    [addParam, goToPage, removeParam, form, selectedIndustries]
  );
};

export default usePrepareFundingExplorerURL;

