import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { ServiceTag } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import InputNumber, { InputNumberProps } from "antd/es/input-number";

type ServiceBudgetSelectionRowProps = {
  service: ServiceTag;
  handleBudgetChange: (
    service: string,
    minBudget: number,
    maxBudget: number
  ) => void;
};

const ServiceBudgetSelectionRow = ({
  service,
  handleBudgetChange,
}: ServiceBudgetSelectionRowProps) => {
  const { t } = useTranslation();

  const minBudgetInputPropsValues: InputNumberProps = {
    defaultValue: 0,
    value: service.min_budget,
    onChange: (e) =>
      handleBudgetChange(service.tag, Number(e), service.max_budget),
    formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    min: 0,
  };

  const maxBudgetInputPropsValues: InputNumberProps = {
    defaultValue: 0,
    value: service.max_budget,
    onChange: (e) =>
      handleBudgetChange(service.tag, service.min_budget, Number(e)),
    formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    min: 0,
  };

  return (
    <Div flex={"row"} align={"center"} justify={"space-between"}>
      <Div flex={"column"}>
        <Typography elementTheme="caption">
          {t(`tags|${service.category}`)}
        </Typography>
        <Typography elementTheme="caption">
          {t(`tags|${service.label}`)}
        </Typography>
      </Div>
      <Div fitContent flex={"column"}>
        <InputNumber {...minBudgetInputPropsValues} />
        <InputNumber {...maxBudgetInputPropsValues} />
      </Div>
    </Div>
  );
};

export default ServiceBudgetSelectionRow;

