import Button from "antd/es/button";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import InputNumber from "antd/es/input-number";
import message from "antd/es/message";
import notification from "antd/es/notification";
import Tooltip from "antd/es/tooltip";
import { useMemo, useState } from "react";

import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { useAdminApi } from "../../features/api/use-admin-api";
import { missingDefaultPaymentError } from "../messages/missing-payment-method";

const DEFAULT_PAYMENT_METHOD_ERROR = 11_007;

const { Title } = Typography;

type ManualReferralCreditsProps = {
  projectId: string;
  leadPrice: number;
  onSave: (project: Project) => void;
};

interface ChargeForm {
  price: number;
}

const ManualReferralCredits = ({
  projectId,
  leadPrice,
  onSave,
}: ManualReferralCreditsProps) => {
  const [form] = Form.useForm();

  const api = useAdminApi();
  const [isSaving, setIsSaving] = useState(false);

  const onFinish = (values: ChargeForm) => {
    (async () => {
      setIsSaving(true);
      try {
        const project = await api.referralCreditProvider(projectId, values);
        onSave(project);
        setIsSaving(false);
        form.resetFields();
        message.success("Saved!");
      } catch (e: any) {
        setIsSaving(false);
        if (e.response.data.error_code === DEFAULT_PAYMENT_METHOD_ERROR) {
          return missingDefaultPaymentError();
        }
        notification.error({
          message: "Payment failed!",
          description: `${e.response.data.error}, error_code: ${e.response.data.error_code}`,
          placement: "bottomLeft",
        });
      }
    })();
  };

  const initialValues: ChargeForm = useMemo(() => {
    return {
      price: leadPrice,
    };
  }, [leadPrice]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={"manual-referral-credits"}
      initialValues={initialValues}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Tooltip placement="bottom" title="With love, Raphael">
            <Title level={4} elementTheme="h6">
              Manual Referral Credits
            </Title>
          </Tooltip>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Price"
            name="price"
            rules={[
              { required: true, message: "input price is required" },
              { type: "number" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item style={{ margin: 0 }} shouldUpdate={true}>
            {() => (
              <Button block type="primary" htmlType="submit" loading={isSaving}>
                Apply
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManualReferralCredits;

