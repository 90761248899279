import { GinApplication } from "@hellodarwin/core/lib/features/entities";
import Form, { FormInstance } from "antd/es/form";
import RenderSelectDate from "./application-form-select-date";

const CompletedToSubmitForm = ({
  form,
  application,
}: {
  form: FormInstance<GinApplication>;
  application: GinApplication;
}) => {
  return (
    <Form form={form} layout="vertical" initialValues={application}>
      <RenderSelectDate
        label="project_end_at"
        name="application_project_end"
        defaultToday={false}
      />
    </Form>
  );
};

export default CompletedToSubmitForm;

