import {
  ApiError,
  ApiErrorInitialState,
  GinHistory,
} from "@hellodarwin/core/lib/features/entities";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import AdminApiClient from "../admin-api-client";

export interface GinHistoryState {
  status: "idle" | "pending";
  error: ApiError;
  history: GinHistory[];
}

const initialState: GinHistoryState = {
  status: "idle",
  error: ApiErrorInitialState,
  history: [],
};

export const fetchGinHistory = createAsyncThunk<
  GinHistory[],
  { api: AdminApiClient; grantId: string; section: string },
  { rejectValue: ApiError }
>(
  "ginHistory/fetchGinHistory",
  async ({ api, grantId, section }, { rejectWithValue }) => {
    try {
      return await api.getGinHistory(grantId, section);
    } catch (err: any) {
      return rejectWithValue(err.response.data as ApiError);
    }
  }
);

const ginHistorySlice = createSlice({
  name: "ginHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGinHistory.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        fetchGinHistory.fulfilled,
        (state, action: PayloadAction<GinHistory[]>) => {
          state.status = "idle";
          state.history = action.payload;
        }
      )
      .addCase(fetchGinHistory.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload as ApiError;
      });
  },
});

export const selectGinHistory = (state: RootState) => state.ginHistory.history;
export const selectGinHistoryStatus = (state: RootState) =>
  state.ginHistory.status;
export const selectGinHistoryError = (state: RootState) =>
  state.ginHistory.error;

export const ginHistoryReducer = ginHistorySlice.reducer;

