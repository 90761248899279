import { GinApplication, Grant } from "@hellodarwin/core/lib/features/entities";
import {
  dollarFormat,
  getShortId,
  getStringDate,
} from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Grants from "@hellodarwin/icons/dist/icons/Grants";
import HubspotLogo from "@hellodarwin/icons/dist/icons/HubspotLogo";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import Key from "@hellodarwin/icons/dist/icons/Key";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Clipboard from "../../clipboard";

const useGinApplicationSummary = (
  application?: GinApplication,
  grant?: Grant
) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  return useMemo(() => {
    if (!application) return [];
    const {
      application_targeted_amount,
      application_submitted_amount,
      application_program_grant_id,
      application_company_id,
      application_contact_name,
      application_hubspot_url,
      application_accepted_amount,
      application_program_name,
      application_id,
    } = application;
    const { grant_timeline } = grant ?? {};

    return [
      {
        label: t("application_single|program_name"),
        Icon: Grants,
        children: (
          <Link to={`/gin/${application_program_grant_id}`}>
            {application_program_name}
          </Link>
        ),
      },
      {
        /* TODO change when added in contenful */
        label: t("application_single|application_manager"),
        Icon: Budget,
        children: (
          <Link to={`/companies/${application_company_id}/users`}>
            {application_contact_name}
          </Link>
        ),
      },
      {
        label: t("grant_single|deadlines"),
        Icon: Timeline,
        children: (
          <ul>
            {!!grant_timeline && !!grant_timeline[0]?.opened_at && (
              <li>
                {t("grant_single|openingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].opened_at),
                  selectedLocale
                )}
              </li>
            )}
            {!!grant_timeline && !!grant_timeline[0]?.closed_at && (
              <li>
                {t("grant_single|closingDate")}
                {getStringDate(
                  new Date(grant_timeline[0].closed_at),
                  selectedLocale
                )}
              </li>
            )}
            {(!grant_timeline ||
              (!grant_timeline[0]?.opened_at &&
                !grant_timeline[0]?.closed_at)) && (
              <li>{t("grant_single|timelineUnspecified")}</li>
            )}
          </ul>
        ),
      },
      {
        label: t("application_single|amount"),
        Icon: Invoices,
        children: dollarFormat(
          application_accepted_amount ??
            application_submitted_amount ??
            application_targeted_amount ??
            0,
          selectedLocale,
          true
        ),
      },
      {
        label: t("application_single|hubspot_link"),
        Icon: HubspotLogo,
        children: (
          <a
            href={application_hubspot_url}
            target="_blank"
            style={{ wordBreak: "break-all" }}
            rel="noreferrer"
          >
            {application_hubspot_url}
          </a>
        ),
      },
      {
        label: t("application_single|application_id"),
        Icon: Key,
        children: (
          <Clipboard title={getShortId(application_id)} copy={application_id} />
        ),
      },
    ];
  }, [application, selectedLocale, t, grant]);
};
export default useGinApplicationSummary;

