import Div from "@hellodarwin/core/lib/components/common/div";
import { GinApplication } from "@hellodarwin/core/lib/features/entities";
import {
  dollarFormat,
  getStringDate,
} from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Budget from "@hellodarwin/icons/dist/icons/Budget";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import CardInfoRowWithLabel from "../../sidebars/cards/card-info-row-with-label";

interface ApplicationTimelineCardProps {
  label: string;
  date: Date;
  amountLabel?: string;
  amount?: number;
  hideAmount?: boolean;
  application?: GinApplication;
}

const ApplicationTimelineCard = ({
  label,
  date,
  amount,
  amountLabel,
  hideAmount,
  application,
}: ApplicationTimelineCardProps) => {
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Div
      style={{
        padding: 10,
        flex: 1,
        width: 208,
        height: "fit-content",
        position: "relative",
        zIndex: 1,
        borderWidth:
          !!application?.application_status &&
          ["refused", "accepted"].includes(application.application_status)
            ? 2
            : 1,
      }}
      flex="column"
      gap={12}
      borderColor={
        application?.application_status === "accepted"
          ? theme.colors.green_1
          : application?.application_status === "refused"
            ? theme.colors.red_1
            : theme.colors.primary
      }
      backgroundColor={theme.colors.white_1}
    >
      <CardInfoRowWithLabel
        label={label}
        Icon={Timeline}
        value={!date ? "-" : getStringDate(date, selectedLocale)}
      />
      {!hideAmount && (
        <CardInfoRowWithLabel
          label={
            application?.application_refusal_reason
              ? t("application_single|refused_reason")
              : amountLabel ?? t("application_single|amount")
          }
          Icon={
            application?.application_refusal_reason ? CancelProject : Budget
          }
          value={
            application?.application_refusal_reason
              ? application.application_refusal_reason
              : !amount
                ? "-"
                : dollarFormat(amount, selectedLocale, true)
          }
        />
      )}
    </Div>
  );
};

export default ApplicationTimelineCard;

