import parse from "../../../../../features/helpers/parse";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

interface AdmissibilitySectionTileProps {
  label: string;
  content: string;
}

const AdmissibilitySectionTile = ({
  label,
  content,
}: AdmissibilitySectionTileProps) => {
  return (
    <Div flex="column" gap={16} align="flex-start">
      <div style={{ width: "100%", paddingTop: "24px" }}>
        <Typography.Title level={2} elementTheme="h6" noMargin>
          {label}
        </Typography.Title>
      </div>
      <Div>{parse(content)}</Div>
    </Div>
  );
};

export default AdmissibilitySectionTile;
