import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import Checkmark from "@hellodarwin/icons/dist/icons/Checkmark";
import Warning from "@hellodarwin/icons/dist/icons/Warning";
import XOutlined from "@hellodarwin/icons/dist/icons/XOutlined";
import { useMemo } from "react";
import { useTheme } from "../../../../plugins/styled";

const useEndPageIcon = (page_type: string): [IconNode, string] => {
  const theme = useTheme();

  return useMemo(
    () =>
      page_type === "not-elligible-end"
        ? [XOutlined, theme.colors.red_1]
        : page_type === "maybe-elligible-end"
          ? [Warning, theme.colors.yellow_2]
          : [Checkmark, theme.colors.green_1],
    [page_type]
  );
};

export default useEndPageIcon;

