import Checkbox from "antd/es/checkbox";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Select from "antd/es/select";
import Space from "antd/es/space";
import React from "react";
import { FormLayoutProps } from "..";
import parse from "../../../../../features/helpers/parse";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import FormItem from "../../../form-layouts/form-item";
import FormLabel from "../../../form-layouts/form-label";
import { DragDropContainer } from "../../styles";
import { itemDescriptionOptions } from "../../utils/form-parse-options";
import getItemTranslatedLabel from "../../utils/get-item-translated-label";
import { getSelectOptions } from "../../utils/get-select-options";

const FormItemLayoutContent = ({ type, entity, locale }: FormLayoutProps) => {
  switch (type) {
    case "datetime":
      return <Input type="date" />;
    case "input":
    case "shortText":
      return <Input />;
    case "text":
      return <TextArea />;
    case "select":
      const select_options = !!entity?.form_select_options
        ? getSelectOptions(entity.form_select_options, locale)
        : [];
      return (
        <Select value={select_options?.[0]?.value} options={select_options} />
      );
    case "radio":
      const radio_options = !!entity?.form_select_options
        ? getSelectOptions(entity.form_select_options, locale)
        : [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ];
      return (
        <Radio.Group>
          <Space
            direction={
              entity?.disposition === "vertical" ? "vertical" : "horizontal"
            }
          >
            {React.Children.toArray(
              radio_options.map((option) => (
                <Radio value={option.value}>{option.label}</Radio>
              ))
            )}
          </Space>
        </Radio.Group>
      );
    case "checkbox":
      const checkbox_options = !!entity?.form_select_options
        ? getSelectOptions(entity.form_select_options, locale)
        : [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ];
      return (
        <Checkbox.Group>
          <Space
            direction={
              entity?.disposition === "vertical" ? "vertical" : "horizontal"
            }
          >
            {React.Children.toArray(
              checkbox_options.map((option) => (
                <Checkbox value={option.value}>{option.label}</Checkbox>
              ))
            )}
          </Space>
        </Checkbox.Group>
      );

    default:
      return <></>;
  }
};

const FormItemLayout = (props: FormLayoutProps) => {
  const { type, isSimple, entity, locale } = props;

  return isSimple ? (
    <FormItem
      label={
        <FormLabel
          label={getItemTranslatedLabel({
            label_fr: entity.content_fr,
            label_en: entity.content_en,
            fallback: entity.field_type,
            locale,
          })}
          extra={getItemTranslatedLabel({
            label_fr: entity.heading_fr,
            label_en: entity.heading_en,
            fallback: undefined,
            locale,
            parseOptions: itemDescriptionOptions,
          })}
        />
      }
      style={{ width: "100%" }}
    >
      <FormItemLayoutContent {...props} />
    </FormItem>
  ) : (
    <Div flex="column" gap={8}>
      <Typography
        textTransform="capitalize"
        elementTheme="body3"
        style={{ fontWeight: 600 }}
      >
        {type}
      </Typography>
      <DragDropContainer $isMovable>
        <FormItem
          label={
            <FormLabel
              label={
                !!entity?.content_fr && locale === "fr"
                  ? entity.content_en
                  : !!entity?.content_en && locale === "en"
                    ? entity.content_en
                    : type?.toUpperCase()
              }
              extra={
                !!entity?.heading_fr && locale === "fr"
                  ? parse(entity.heading_fr, itemDescriptionOptions)
                  : !!entity?.heading_en && locale === "en"
                    ? parse(entity.heading_en, itemDescriptionOptions)
                    : undefined
              }
            />
          }
          style={{ width: "100%" }}
        >
          <FormItemLayoutContent {...props} />
        </FormItem>
      </DragDropContainer>
    </Div>
  );
};

export default FormItemLayout;

