import Div from "@hellodarwin/core/lib/components/common/div";
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from "@hellodarwin/core/lib/features/entities";
import { selectInputEmail } from "@hellodarwin/core/lib/features/helpers";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import type { SelectProps } from "antd/es/select";
import Select from "antd/es/select";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchGrantTags,
  generateGrantTags,
  selectGrantTags,
} from "../../../features/api/slices/grant-tags-slice";
import {
  fetchGrantFinancingType,
  fetchGrantForProfit,
  fetchGrantProviders,
  fetchGrantService,
  fetchProvinces,
  generateSingleGrantContentInfo,
  selectGrantFinancingType,
  selectGrantForProfit,
  selectGrantProviders,
  selectGrantService,
  selectProvinces,
  setLoadingSection,
} from "../../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectAllIndustriesSubsectors,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../../features/api/slices/tags-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { grantPromptPropertiesTitles } from "../../../pages/single-grant-page";
import theme from "../../../theme";
import GrantPromptButtons from "../grant-prompt-buttons";
import GrantPromptPropertySection from "../grant-prompt-property-section";
import { transformSectionIntoLabel } from "./grant-form";
import GrantTagsDrawer from "./grant-tags-drawer";

type GrantFormPropertiesProps = {
  grant?: AdminGrantResult;
  onSave?: () => Promise<void>;
  setSelectedPrompt: Dispatch<SetStateAction<HdChatPromptRequest>>;
  setAddNewPromptDrawer: Dispatch<SetStateAction<boolean>>;
  form: any;
  prompts?: HdChatPromptRequest[];
  isGeneratingRemaining?: boolean;
  isGeneratingAll?: boolean;
};

const GrantFormProperties = ({
  grant,
  onSave,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  form,
  prompts,
  isGeneratingRemaining,
  isGeneratingAll,
}: GrantFormPropertiesProps) => {
  const [selectedProperty, setSelectedProperty] = useState("all");
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const financingType = useAppSelector(selectGrantFinancingType);
  const grantsProviders = useAppSelector(selectGrantProviders);
  const industries = useAppSelector(selectIndustriesSectors);
  const [industrySectors, setIndustrySectors] = useState<string[]>(
    grant?.industry_sectors || []
  );
  const industrySubsectors = useAppSelector((state: RootState) =>
    selectIndustriesSubsectors(state, industrySectors)
  );
  const provinces = useAppSelector(selectProvinces);
  const service = useAppSelector(selectGrantService);
  const tags = useAppSelector(selectGrantTags);
  const forProfitTypes = useAppSelector(selectGrantForProfit);
  const options: SelectProps["options"] = grantPromptPropertiesTitles.map(
    (property) => {
      return {
        label: transformSectionIntoLabel(property),
        value: property,
      };
    }
  );
  options.unshift({ label: "All", value: "all" });
  const [canSave, setCanSave] = useState<boolean>(false);
  const allIndustrySubsectors = useAppSelector(selectAllIndustriesSubsectors);

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: "en" }));
    dispatch(
      fetchGrantProviders({
        api,
        locale: "en",
        page: 1,
        limit: 1000,
        query: "",
      })
    );
    dispatch(fetchIndustries({ api, locale: "en" }));
    dispatch(fetchProvinces({ api, locale: "en" }));
    dispatch(fetchGrantService({ api, locale: "en" }));
    dispatch(fetchGrantForProfit({ api, locale: "en" }));
    dispatch(
      fetchGrantTags({ api, grantId: grant?.grant_id || "", locale: "en" })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    if (isGeneratingRemaining) {
      generateRemainingProperties();
    }
    if (isGeneratingAll) {
      generateAllGrantProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingRemaining, isGeneratingAll]);

  const fetchIndustrySubsectors = () => {
    const industrySectors = form.getFieldValue("industry_sectors");
    setIndustrySectors(industrySectors);
  };

  const enableSave = () => {
    if (!canSave) {
      setCanSave(true);
    }
  };

  const generateRemainingProperties = async () => {
    setCanSave(false);
    var promiseArray = [];
    for (let property of grantPromptPropertiesTitles) {
      const formValue = form.getFieldValue(property);
      if (
        (formValue === "" ||
          formValue === null ||
          formValue === undefined ||
          formValue.length === 0) &&
        property !== "industry_subsectors" &&
        property !== "providers"
      ) {
        promiseArray.push(generateProperty(property));
      }
    }
    await Promise.all(promiseArray).then(async () => {
      const formValue = form.getFieldValue("industry_subsectors");
      if (
        formValue === "" ||
        formValue === null ||
        formValue === undefined ||
        formValue.length === 0
      ) {
        await generateProperty("industry_subsectors");
      }
      setCanSave(true);
      message.success(`All properties have been generated.`);
    });
  };

  const generateAllGrantProperties = async () => {
    setCanSave(false);
    var promiseArray = [];
    for (let property of grantPromptPropertiesTitles) {
      if (property !== "industry_subsectors" && property !== "providers") {
        promiseArray.push(generateProperty(property));
      }
    }
    dispatch(setLoadingSection("industry_subsectors"));
    await Promise.all(promiseArray).then(async () => {
      await generateProperty("industry_subsectors");
      setCanSave(true);
      message.success(`All properties have been generated.`);
    });
  };

  const generateProperty = async (currentProperty: string) => {
    try {
      let industrySectorsIDs = null;
      if (
        selectedProperty === "industry_subsectors" ||
        currentProperty === "industry_subsectors"
      ) {
        industrySectorsIDs = form.getFieldValue("industry_sectors");
      }

      const propertyResponse = await dispatch(
        generateSingleGrantContentInfo({
          api,
          grantId: grant!.grant_id,
          sectionName: currentProperty ?? selectedProperty,
          industrySectorsIDs,
        })
      ).unwrap();
      const titleLabel = transformSectionIntoLabel(
        currentProperty ?? selectedProperty
      );
      if (propertyResponse === "") {
        message.info(
          `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`
        );
      } else {
        handlePropertyResponse(
          currentProperty ?? selectedProperty,
          propertyResponse
        );
        if (selectedProperty !== "all" && !isGeneratingRemaining) {
          message.success(
            `Section ${titleLabel} generated! Don't forget to save.`
          );
        }
      }
    } catch (e: any) {
      message.error("Error !");
      message.error(e.cause);
    } finally {
      setCanSave(true);
    }
  };

  const genGrantTags = async () => {
    try {
      await dispatch(generateGrantTags({ api, grantId: grant!.grant_id }));
    } catch (e: any) {
      message.error("Error !");
      message.error(e.cause);
    } finally {
      message.success(`Tags generated! Don't forget to save.`);
      setCanSave(true);
    }
  };

  const handlePropertyResponse = (
    property: string,
    propertyResponse: string
  ) => {
    switch (property) {
      case "application_email_address":
        if (propertyResponse.includes("@")) {
          form.setFieldValue(property, propertyResponse);
        } else {
          form.setFieldValue(property, null);
        }
        break;
      case "application_phone_number":
        if (!propertyResponse.toLowerCase().includes("none")) {
          form.setFieldValue(property, propertyResponse);
        } else {
          form.setFieldValue(property, null);
        }
        break;
      case "funding_max_amount":
      case "funding_min_amount":
      case "total_funding_available":
      case "needed_investment":
      case "percentage_funding":
      case "applicants_min_revenue_past_year":
      case "applicants_max_revenue_past_year":
      case "applicants_min_count_employee":
      case "applicants_max_count_employee":
        propertyResponse = propertyResponse.replace(/\D/g, "");
        if (parseInt(propertyResponse) > 0) {
          form.setFieldValue(property, parseInt(propertyResponse));
        } else {
          form.setFieldValue(property, null);
        }
        break;
      case "size":
        propertyResponse = propertyResponse.toLowerCase();
        if (
          (propertyResponse.includes("large") ||
            propertyResponse.includes("grande")) &&
          (propertyResponse.includes("medium") ||
            propertyResponse.includes("moyenne")) &&
          (propertyResponse.includes("small") ||
            propertyResponse.includes("petite"))
        ) {
          form.setFieldValue(property, null);
        } else if (
          propertyResponse.includes("large") ||
          propertyResponse.includes("grande")
        ) {
          form.setFieldValue(property, "large");
        } else if (
          propertyResponse.includes("medium") ||
          propertyResponse.includes("moyenne")
        ) {
          form.setFieldValue(property, "medium");
        } else if (
          propertyResponse.includes("small") ||
          propertyResponse.includes("petite")
        ) {
          form.setFieldValue(property, "small");
        } else {
          form.setFieldValue(property, null);
        }
        break;
      case "city":
        if (
          propertyResponse.toLowerCase().includes("none") ||
          propertyResponse.length <= 2
        ) {
          form.setFieldValue(property, null);
        } else {
          form.setFieldValue(property, propertyResponse);
        }
        break;
      case "region":
        if (
          propertyResponse.toLowerCase().includes("none") ||
          propertyResponse.length <= 2
        ) {
          form.setFieldValue(property, null);
        } else {
          form.setFieldValue(property, propertyResponse);
        }
        break;
      case "grant_created_at":
        if (
          propertyResponse.toLowerCase().includes("none") ||
          propertyResponse.length <= 2 ||
          propertyResponse === null
        ) {
          form.setFieldValue(property, Date.now());
        } else if (Date.parse(propertyResponse)) {
          form.setFieldValue(property, new Date(propertyResponse));
        }
        break;
      case "grant_deadline":
        if (propertyResponse.toLowerCase().includes("none")) {
          form.setFieldValue(property, null);
        } else if (Date.parse(propertyResponse)) {
          form.setFieldValue(property, new Date(propertyResponse));
        }
        break;
      case "financing_type":
      case "providers":
      case "service":
      case "province":
      case "industry_sectors":
      case "industry_subsectors":
      case "for_profit":
        let options =
          property === "financing_type"
            ? financingType
            : property === "providers"
              ? grantsProviders
              : property === "service"
                ? service
                : property === "province"
                  ? provinces
                  : property === "industry_sectors"
                    ? industries
                    : property === "industry_subsectors"
                      ? allIndustrySubsectors
                      : forProfitTypes;
        let selectedLabels = [];
        let optionsCopy = options!.map((option) => {
          return option;
        });
        for (let option of optionsCopy) {
          let optionLabel = option.label;
          if (
            property === "industry_sectors" ||
            property === "industry_subsectors"
          ) {
            let index = option.label!.indexOf(" ");
            index = option.label!.indexOf(" ", index + 1);
            optionLabel = option.label!.substring(index + 1);
          }
          if (
            optionLabel !== "" &&
            propertyResponse.toLowerCase().includes(optionLabel!.toLowerCase())
          ) {
            selectedLabels.push(option.value);
          }
        }
        if (selectedLabels.length > 0) {
          form.setFieldValue(
            property,
            property === "province" ? selectedLabels[0] : selectedLabels
          );
          if (property === "industry_sectors" && setIndustrySectors) {
            setIndustrySectors(selectedLabels);
          }
        }

        break;

      default:
        break;
    }
  };

  return (
    <>
      <Div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "35%",
          justifyContent: "space-between",
        }}
      >
        <Div style={{ width: "60%" }}>
          <Select
            style={{ width: "100%" }}
            value={selectedProperty}
            onChange={setSelectedProperty}
            options={options}
          />
        </Div>
        <GrantPromptButtons
          section={selectedProperty}
          setSelectedPrompt={setSelectedPrompt}
          setAddNewPromptDrawer={setAddNewPromptDrawer}
          onSave={onSave}
          grant={grant}
          promptButtonDisabled={
            selectedProperty === "all" || selectedProperty === "tags"
          }
          prompts={prompts}
          canSave={canSave}
          setCanSave={setCanSave}
          generateContent={() =>
            selectedProperty === "all"
              ? generateAllGrantProperties()
              : selectedProperty === "tags"
                ? genGrantTags()
                : generateProperty(selectedProperty)
          }
        />
      </Div>

      <Row gutter={12}>
        <Col span={8}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Fincancing Type"
            section="financing_type"
            isMultipleSelect={true}
            financingType={financingType}
          />
        </Col>
        <Col span={12}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Providers"
            section="providers"
            isMultipleSelect={true}
            grantsProviders={grantsProviders}
          />
        </Col>
        <Col span={12}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Service"
            section="service"
            isMultipleSelect={true}
            service={service}
          />
        </Col>
        <Col span={12}>
          <GrantPromptPropertySection
            form={form}
            label="Tags"
            section="Tags"
            isTags={true}
            tags={tags}
            openTagDrawer={openDrawer}
            generateTags={genGrantTags}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Funding Maximum Amount"
            section="funding_max_amount"
            isDollarInput={true}
          />
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Total Funding Available"
            section="total_funding_available"
            isDollarInput={true}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Funding Minimum Amount"
            section="funding_min_amount"
            isDollarInput={true}
          />
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Needed Investment"
            section="needed_investment"
            isDollarInput={true}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Percentage of funding"
            section="percentage_funding"
            isNumberInput={true}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            industrySubsectors={industrySubsectors}
            fetchIndustrySubsectors={fetchIndustrySubsectors}
            label="Industry sectors"
            section="industry_sectors"
            isMultipleSelect={true}
            industries={industries}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            industrySubsectors={industrySubsectors}
            fetchIndustrySubsectors={fetchIndustrySubsectors}
            label="Industry subsectors"
            section="industry_subsectors"
            isMultipleSelect={true}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="For profit"
            section="for_profit"
            isMultipleSelect={true}
            forProfitTypes={forProfitTypes}
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Minimum Revenue"
            section="applicants_min_revenue_past_year"
            isDollarInput={true}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Maximum Revenue"
            section="applicants_max_revenue_past_year"
            isDollarInput={true}
          />
        </Col>
        <Col span={4}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Minimum Number of Employees"
            section="applicants_min_count_employee"
            isNumberInput={true}
          />
        </Col>
        <Col span={4}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Maximum Number of Employees"
            section="applicants_max_count_employee"
            isNumberInput={true}
          />
        </Col>
        <Col span={4}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Size"
            section="size"
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="City"
            section="city"
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Region"
            section="region"
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Province"
            section="province"
            isSimpleSelect={true}
            provinces={provinces}
          />
        </Col>
        <Col span={6}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Country"
            section="country"
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item label="Official Documents" name="official_documents">
            <Input onMouseDown={(e) => selectInputEmail(e)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Email"
            section="application_email_address"
            onMouseDown={(e) => selectInputEmail(e)}
          />
        </Col>
        <Col span={12}>
          <GrantPromptPropertySection
            onSave={enableSave}
            form={form}
            label="Phone"
            section="application_phone_number"
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Row align={"middle"}>
            <Form.Item
              label="Youtube Link EN"
              name="grant_youtube_url_en"
              style={
                grant!.grant_youtube_url_en
                  ? { width: "90%" }
                  : { width: "100%" }
              }
            >
              <Input />
            </Form.Item>
            {grant!.grant_youtube_url_en && (
              <Button
                onClick={() =>
                  window.open(
                    grant!.grant_youtube_url_en?.includes("https://")
                      ? grant!.grant_youtube_url_en
                      : "https://" + grant!.grant_youtube_url_en,
                    "_blank"
                  )
                }
                ghost
                icon={
                  <NewWindow
                    style={{ color: theme.colors.purple_1 }}
                    width={16}
                    height={16}
                  />
                }
                type="text"
              />
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row align={"middle"}>
            <Form.Item
              label="Youtube Link FR"
              name="grant_youtube_url_fr"
              style={
                grant!.grant_youtube_url_fr
                  ? { width: "90%" }
                  : { width: "100%" }
              }
            >
              <Input />
            </Form.Item>
            {grant!.grant_youtube_url_fr && (
              <Button
                onClick={() =>
                  window.open(
                    grant!.grant_youtube_url_fr?.includes("https://")
                      ? grant!.grant_youtube_url_fr
                      : "https://" + grant!.grant_youtube_url_fr,
                    "_blank"
                  )
                }
                ghost
                icon={
                  <NewWindow
                    style={{ color: theme.colors.purple_1 }}
                    width={16}
                    height={16}
                  />
                }
                type="text"
              />
            )}
          </Row>
        </Col>
      </Row>
      <GrantTagsDrawer
        visible={isDrawerVisible}
        onClose={closeDrawer}
        currentTags={tags || []}
        entityType="Grant"
        entityId={grant?.grant_id || ""}
        locale={"en"}
      />
    </>
  );
};

export default GrantFormProperties;

