import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import { HdChatPromptRequest } from "@hellodarwin/core/lib/features/entities";
import {
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { MenuProps } from "antd/es/menu/menu";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  deletePrompt,
  fetchAllPrompts,
  selectAllPrompts,
  selectPromptsLoading,
  selectPromptsPagination,
} from "../../features/api/slices/hd-chat-prompts-slice";
import { selectSelectedChatType } from "../../features/api/slices/hd-chats-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import CreateUpdatePromptDrawer from "./create-update-prompt-drawer";

const PromptsTab = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 10;
  const chatType = useAppSelector(selectSelectedChatType);

  useEffect(() => {
    dispatch(
      fetchAllPrompts({
        api,
        page: DEFAULT_PAGE,
        size: DEFAULT_LIMIT,
        type: chatType,
      })
    );
  }, [dispatch, api, chatType]);

  const loading = useAppSelector(selectPromptsLoading);
  const pagination = useAppSelector(selectPromptsPagination);
  const prompts = useAppSelector(selectAllPrompts);
  const [currentPage, setCurrentPage] = useState(pagination.page);
  const [pageSize, setPageSize] = useState(pagination.size);
  const [addNewPromptDrawer, setAddNewPromptDrawer] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(
    {} as HdChatPromptRequest
  );

  const onPageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
    dispatch(fetchAllPrompts({ api, page, size, type: chatType }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (v) => getShortId(v),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Content FR",
      dataIndex: "content_fr",
      key: "content_fr",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Content EN",
      dataIndex: "content_en",
      key: "content_en",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (v) => getShortFormattedDate(v),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (v) => getShortFormattedDate(v),
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      width: 60,
      align: "center",
      render: (_, prompt) => {
        const actionMenuItems: MenuProps["items"] = [
          {
            label: "Delete",
            style: { color: theme.colors.red_1 },
            key: "delete",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              dispatch(deletePrompt({ api, promptId: prompt.id }));
            },
          },
        ];
        return (
          <Div
            flex="row"
            gap={20}
            fitContent
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown items={actionMenuItems} cta={{ size: 24 }} />
          </Div>
        );
      },
    },
  ];

  return (
    <Div flex="column" style={{ margin: 20, width: "auto" }} gap={24}>
      <Button
        defaultStyle={theme.colors.purple_1}
        size="small"
        onClick={() => {
          setSelectedPrompt({} as HdChatPromptRequest);
          setAddNewPromptDrawer(true);
        }}
      >
        Add new prompt
      </Button>
      <Table
        rowKey="id"
        dataSource={prompts}
        columns={columns}
        scroll={{ y: "calc(100vh - 500px)" }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: pagination.total,
          onChange: onPageChange,
        }}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            setSelectedPrompt(record);
            setAddNewPromptDrawer(true);
          },
        })}
      />
      <CreateUpdatePromptDrawer
        prompt={selectedPrompt}
        visible={addNewPromptDrawer}
        onClose={() => setAddNewPromptDrawer(false)}
      />
    </Div>
  );
};

export default PromptsTab;

